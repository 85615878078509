export const primary = {
  //yellow
  main: '#fbd200',
  mainHover: '#fac400',
  mainPressed: '#edba00',
  //green
  secondary: '#006f45',
  secondaryHover: '#0e8a5b',
  secondaryPressed: '#227052',
  error: '#eb5757',
  success: '#27ae60',
  disabled: '#bac2b7',
  darkFont: '#494646',
  mediumFont: '#666666',
  lightFont: '#989898',
  extralightFont: '#faf9f9',
  formOutline1: '#90a18a',
  formOutline2: '#eaede9',
  formOutline3: '#bac2b7',
  radio: '#0054bc',
  reviewOrange: '#fdab00',
  backgroundColor: 'rgba(186, 194, 183, 0.3)',
  backgroundColor2: 'rgba(252, 253, 251, 0.938)',
  copyright: 'rgba(186, 194, 183)',
  overlay: 'rgba(53, 53, 53, 0.5)'
};

export const opposite = {
  main: '#1C00ff00',
};
export const secondary= {
  main: '#006f45'
}
