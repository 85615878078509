export function changeDeliverySelection(
  cart,
  orderId,
  deliveryMethod,
  user,
  dispatch
) {
  cart.orders.map((order) => {
    return order.farmId === orderId
      ? (order.delivery_selection = deliveryMethod)
      : null;
  });

  // const newCart = {
  //     userId: user.farm_era_id.split('|')[1],
  //     orders: cart.orders
  // }
  //    dispatch(act.saveCartToDB(newCart))
}

export const cartCount = (cart) => {
  let count = 0;

  cart.orders.map((order) => {
    return order.products.map((product) => {
      return (count += product.quantity);
    });
  });

  return count;
};

export const calculateTotals = (cart, amount_type, stateSetter) => {
  let amount = 0;
  if (amount_type === 'subtotal') {
    cart.payment.subtotal = 0;
    cart.payment.total = 0;

    cart.orders.map((order,) => {
      amount = 0;
   order.products.map((product) => {
        return (amount += product.price * product.quantity);
      });

      order.payment.subtotal = amount;
      order.payment.total = order.payment.subtotal + order.payment.shipping;

      cart.payment.subtotal = cart.payment.subtotal += order.payment.subtotal;
      cart.payment.total = cart.payment.total +=
        order.payment.subtotal + order.payment.shipping;
      return cart
      
    });
  } else if (amount_type === 'shipping') {
    cart.payment.shipping = 0;
    cart.payment.total = 0;

    cart.orders.map((order) => {
      return (amount += Number(order.delivery_selection.info?.amount || 0));
    });
    cart.payment.shipping = amount;
    cart.payment.total = cart.payment.subtotal + cart.payment.shipping;
  }

  cartCount(cart);
  if (stateSetter) {
    stateSetter(amount);
  }
};
