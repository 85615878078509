import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useFormContext} from "react-hook-form";


const UseFormRadioGroup = (props) => {
  const {name, value, array, onChange, rules} = props;

  const {register} = useFormContext({
    mode: 'onChange',
    reValidationMode: 'onChange'
  });


  return (
    <RadioGroup
    value={value}
    {...register(name, {rules})}
    onChange={onChange}
    >
{array.map(item => {
  return (
    <FormControlLabel
    key={item.label}
      sx={{ fontSize: '16px' }}
      value={item.value}
      control={<Radio size='medium' />}
      label={item.label}
    />
  );
})}

</RadioGroup>  
)
}

export default UseFormRadioGroup;