import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import PrimaryButton from '../../inputs/Buttons/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import * as act from '../../../store/actions/index';
import PasswordModal from '../profile/modals/PasswordModal';
import { useHistory } from 'react-router-dom';
import PasswordChangeModal from '../profile/modals/PasswordChange';

const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const error = useSelector((state) => state.UserReducer.error);
  const [passwordSuccess, setPasswordSuccess] = useState(false);

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    const helpers = {
      props: {
        history: history,
      },
      dispatch: dispatch,
    };

    dispatch(act.login(data, helpers));
  };

  const [show, setShow] = useState(false);

  const handleModal = () => {
    setShow(!show);
  };

  return (
    <Stack
      margin='100px auto'
      width='70%'
      height='70vh'
      sx={{
        backgroundImage:
          'url(https://res.cloudinary.com/tripp4077/image/upload/v1608318605/farm-era-public-images/login_sip0qk.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            height='70vh'
            justifyContent='center'
            marginLeft='45%'
            padding='30px'
            sx={{
              background: 'rgba(255, 255, 255, 0.6)',
            }}
          >
            <Typography variant='h2'>Welcome back!</Typography>
            {typeof error === 'string' && (
              <Typography variant='error'>
                <b>{error}</b>
              </Typography>
            )}
            <TextField
              {...register('email')}
              placeholder={'Email Address'}
              label={'Email Address'}
              fullWidth
              error={errors.email}
              rules={{
                required: 'Please enter an email address',
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: 'Invalid email address',
                },
              }}
            />
            <TextField
              {...register('password')}
              type={'password'}
              placeholder={'Password'}
              label={'Password'}
              fullWidth
              error={errors.password}
              rules={{
                required: 'Please enter your password',
              }}
            />
            <PrimaryButton margin='20px auto' type='submit'>
              Sign In
            </PrimaryButton>
            <Typography variant='p' textAlign='center'>
              Not a member?{' '}
              <Typography
                variant='a'
                onClick={() => {
                  dispatch(act.resetError());
                  props.setComponent('register');
                }}
              >
                Join Today!
              </Typography>{' '}
            </Typography>
            <Typography
              textAlign='center'
              fontSize='12px'
              variant='a'
              onClick={() => {
                handleModal();
              }}
            >
              Forgot Password
            </Typography>{' '}
            <PasswordModal
              message={'Forget Password?'}
              design={''}
              forgot
              open={show}
              setOpen={setShow}
              showModal={handleModal}
              setPasswordSuccess={setPasswordSuccess}
            />
            <PasswordChangeModal
              open={passwordSuccess}
              setOpen={setPasswordSuccess}
            />
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  );
};
export default Login;
