import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import Flag from "./Flag.js"
import Unflag from './Unflag.js';

const Flags = (props) => {
  const { history } = props;

  return (
    <Accordion
      elevation={0}
      sx={{
        bgcolor: 'primary.formOutline2',
        border: 'none',
        margin: '-10px 0',
        padding: '0',
      }}
    >
      <AccordionSummary
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={{ padding: '0 16px', border: 'none' }}
      >
        <ListItemIcon>
          <EmojiFlagsIcon sx={{ color: '#006F45' }} />
        </ListItemIcon>
        <ListItemText primary='Flags' />
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0 0 0 70px', border: 'none' }}>
        <Flag />
        <Unflag/>
      </AccordionDetails>
    </Accordion>
  );
};

export default Flags;
