import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as act from '../../../store/actions';
import Tooltip from '@mui/material/Tooltip';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import {
  Overview,
  Messages,
  FarmStand,
  Settings,
} from '../../assets/farmIconImages';
import Listings from '../accordions/Listings';
import Orders from '../accordions/Orders';
import Divider from '@mui/material/Divider';

export const FarmSideNav = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer.user);
  const managedFarms = useSelector((state) => state.FarmReducer.managedFarms);
  const [connected, setConnected] = useState(
    useSelector((state) => state.UserReducer.user.user_metadata.stripe_acct)
  );

  const history = useHistory();

  useEffect(() => {
    dispatch(act.getManagedFarms(user.user_id.split('|')[1]));
  }, []);

  return (
    <List>
      {managedFarms && managedFarms.length >= 1
        ? managedFarms.map((farm) => {
            return (
              <Accordion
                key={farm.id}
                elevation={0}
                fontSize='18px'
                sx={{
                  bgcolor: 'primary.formOutline2',
                  fontFamily: 'Open Sans',
                  cursor: 'pointer',
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <DoubleArrowIcon
                      sx={{ color: 'primary.secondary', cursor: 'pointer' }}
                    />
                  }
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography variant='p' sx={{ color: 'primary.secondary' }}>
                    {farm.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem
                      onClick={() => history.push(`/dashboard/overview/${farm.id}`)}
                      sx={{
                        display: 'flex',
                        color: 'primary.secondary',
                        cursor: 'pointer',
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: '30px' }}>
                        <Overview {...props} />
                      </ListItemIcon>
                      <ListItemText
                        primary='Overview'
                        primaryTypographyProps={{
                          color: 'primary.secondary',
                          fontSize: '18px',
                        }}
                      />
                    </ListItem>

                    <ListItem
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '0 0 0 -20px',
                        cursor: 'pointer',
                      }}
                    >
                      <Listings farm={farm} history={history} />
                    </ListItem>
                    <ListItem
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '0 0 0 -20px',
                        cursor: 'pointer',
                      }}
                    >
                      <Orders farm={farm} history={history} />
                    </ListItem>
                    <ListItem
                      sx={{
                        display: 'flex',
                        color: 'primary.disabled',
                        cursor: 'pointer',
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: '30px' }}>
                        <Messages {...props} />
                      </ListItemIcon>
                      <ListItemText
                        primary='Messages'
                        primaryTypographyProps={{
                          color: 'primary.disabled',
                          fontSize: '18px',
                        }}
                      />
                    </ListItem>
                    <ListItem
                      onClick={() =>
                        history.push(`/dashboard/farm-stand/${farm.id}`)
                      }
                      sx={{
                        display: 'flex',
                        cursor: 'pointer',
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: '30px' }}>
                        <FarmStand {...props} />
                      </ListItemIcon>
                      <ListItemText
                        primary='Farm Stand'
                        primaryTypographyProps={{
                          color: 'primary.secondary',
                          fontSize: '18px',
                        }}
                      />
                    </ListItem>
                    <ListItem
                      onClick={() =>
                        history.push(`/dashboard/edit_farm/${farm.id}`)
                      }
                      sx={{
                        display: 'flex',
                        color: 'text.disabled',
                        // cursor: 'pointer',
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: '30px' }}>
                        <Settings {...props} />
                      </ListItemIcon>
                      <ListItemText
                        primary='Settings'
                        primaryTypographyProps={{
                          color: 'primary.secondary',
                          fontSize: '18px',
                          cursor: 'pointer',
                        }}
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            );
          })
        : null}
      <Divider />
      <ListItem
        onClick={() => history.push(`/dashboard`)}
        display='flex'
        sx={{
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
      >
        {/* <ListItemIcon sx={{ minWidth: '30px' }}>
          <Overview {...props} />
        </ListItemIcon> */}
        <ListItemText
          primary='Dashboard'
          primaryTypographyProps={{
            color: 'primary.secondary',
            fontSize: '18px',
          }}
        />
      </ListItem>
      <ListItem
        onClick={() => history.push('/dashboard/profile')}
        sx={{ cursor: 'pointer' }}
      >
        <ListItemText
          primary='Your Profile'
          primaryTypographyProps={{
            color: 'primary.secondary',
            fontSize: '18px',
            cursor: 'pointer',
          }}
        />
      </ListItem>
      <ListItem sx={{ cursor: 'pointer' }}>
        <ListItemText
          primary='Help Center'
          primaryTypographyProps={{
            color: 'primary.disabled',
            fontSize: '18px',
            cursor: 'pointer',
          }}
        />
      </ListItem>
      {connected ? (
        <ListItem
          onClick={() => history.push('/dashboard/add_farm')}
          sx={{ cursor: 'pointer' }}
        >
          <ListItemText
            primary='Add a Farm'
            primaryTypographyProps={{
              color: 'primary.secondary',
              fontSize: '18px',
              cursor: 'pointer',
            }}
          />
        </ListItem>
      ) : (
        <Tooltip title='Must create Stripe account to add a farm'>
          <ListItem sx={{ opacity: '0.3' }}>
            <ListItemText
              primary='Add a Farm'
              primaryTypographyProps={{
                color: 'primary.secondary',
                fontSize: '18px',
                cursor: 'pointer',
              }}
            />
          </ListItem>
        </Tooltip>
      )}
      <ListItem
        onClick={() => dispatch(act.logout(history))}
        sx={{ cursor: 'pointer' }}
      >
        <ListItemText
          primary='Sign Out'
          primaryTypographyProps={{
            color: 'primary.secondary',
            fontSize: '18px',
            cursor: 'pointer',
          }}
        />
      </ListItem>
    </List>
  );
};

export default FarmSideNav;
