import React from 'react';
import Chart from 'react-google-charts';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

const FarmRatingChart = (props) => {
  const { farm, farmRatingData } = props;

  const reducer = (sum = 0, review) => {
    if (typeof sum === 'object') return sum.rate + review.rate;
    else return sum + review.rate;
  };

  const convertNumberToWord = (num) => {
    switch (num) {
      case 1:
        return 'one';
      case 2:
        return 'two';
      case 3:
        return 'three';
      case 4:
        return 'four';
      case 5:
        return 'five';
      default:
        return;
    }
  };

  const calculateAverageRating = (reviews) => {
    if (!Array.isArray(reviews)) return 0;

    const numberOfReviews = reviews.length;

    switch (numberOfReviews) {
      case 0:
        return 0;

      case 1:
        return reviews[0].rate;

      default:
        return reviews.reduce(reducer) / numberOfReviews;
    }
  };

  const calculateIndividualRatingData = () =>
    [1, 2, 3, 4, 5].map((rating) =>
      farmRatingData[farm.id]
        ? farmRatingData[farm.id][convertNumberToWord(rating)]
        : 0
    );

  const generateRatingChartData = () => {
    const formattedData = [['Reviews', 'Reviews']];
    let ratings = [0, 0, 0, 0, 0];

    if (farm && farm.id && farmRatingData[farm.id])
      ratings = calculateIndividualRatingData();

    formattedData.push(['Five-star', ratings[4]]);
    formattedData.push(['Four-star', ratings[3]]);
    formattedData.push(['Three-star', ratings[2]]);
    formattedData.push(['Two-star', ratings[1]]);
    formattedData.push(['One-star', ratings[0]]);

    return formattedData;
  };

  return (
    <Stack
      width='100%'
      height='fit-content'
      boxSizing='border-box'
      borderRadius='2px'
      padding='10px'
      marginBottom='15px'
    >
      <Typography variant='h6' lineHeight='22px' color='primary.lightFont'>
        Rating
      </Typography>

      <Stack
        width='100%'
        padding='0 20px'
        justifyContent='center'
        alignItems='flex-end'
      >
        <Typography
          variant='p'
          component='p'
          lineHeight='27px'
          color='primary.lightFont'
        >
          Total Reviews:{' '}
          <Typography variant='p' color='primary.darkFont'>
            {farm.reviews?.length || 0}
          </Typography>
        </Typography>
        <Typography
          variant='p'
          component='p'
          lineHeight='27px'
          color='primary.lightFont'
        >
          Avg Rating:{' '}
          <Typography variant='p' color='primary.darkFont'>
            {calculateAverageRating(farm.reviews).toFixed(2)}
          </Typography>
        </Typography>
      </Stack>
      <Card elevation={10} marginBottom='15px'>
        <Chart
          width={'100%'}
          height={'300px'}
          chartType='BarChart'
          loader={<Typography variant='h3'>Loading Chart</Typography>}
          data={generateRatingChartData()}
        />
      </Card>
    </Stack>
  );
};

export default FarmRatingChart;
