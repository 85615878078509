import { React } from 'react';
import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { useMediaQuery } from '@mui/material';

const ImageListComponent = (props) => {
  const {
    images,
    width,
    height,
    cols,
    rows,
    gap,
    rowHeight,
    margin,
    position,
    textAlign,
    fontWeight,
    imgHeight,
    onClick,
    itemBar,
  } = props;

  const ListBarHeight =
    position === 'below' ? `${rowHeight * imgHeight}px` : '50px';
  const imageHeight = rowHeight * imgHeight;

  const mobile = useMediaQuery('(max-width: 650px)');
  return (
    <ImageList
      cols={mobile ? 1 : cols}
      rows={rows}
      variant='standard'
      gap={gap}
      rowHeight={rowHeight}
      sx={{ margin: margin, width: '100%', height: 'fit-content' }}
    >
      {images.map((item) => {
        return (
          <ImageListItem
            key={item.img}
            rows={item.rows}
            value={item.title}
            cols={item.cols}
            sx={{ bgcolor: 'primary.backgroundColor', cursor: onClick ? 'pointer' : 'inherit' }}
          >
            <img
              src={item.img}
              alt={item.title}
              height={`${imageHeight}px`}
              loading='lazy'
              width={width}
              onClick={() => onClick(item.title)}
            />
            {itemBar ? (
              <ImageListItemBar
                position={position}
                title={item.title}
                value={item.title}
                sx={{
                  height: ListBarHeight,
                  textAlign: textAlign,
                  fontWeight: fontWeight,
                }}
              />
            ) : null}
          </ImageListItem>
        );
      })}
    </ImageList>
  );
};
export default ImageListComponent;
