import React, { useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import Dialog from '../../../../../display/dialog';
import Stack from '@mui/material/Stack';
import PrimaryButton from '../../../../../inputs/Buttons/Button';
import * as act from '../../../../../../store/actions';

const ShippingLabel = (props) => {
  const { open, order, handleModal } = props;

  const [label, setLabel] = useState({
    label: 'PDF',
  });

  const handleSelect = (e) => {
    setLabel({ [e.target.name]: e.target.value });
  };

  const labelOptions = ['PDF', 'PNG', 'PDF_4x6', 'ZPLII'];

  const title = <Typography>Choose Shipping Label Size</Typography>;

  const content = (
    <Select
      fullWidth
      name='label'
      onChange={(e) => handleSelect(e)}
      value={label.label}
    >
      {labelOptions.map((option, i) => {
        return (
          <MenuItem value={option} key={i}>
            {option}
          </MenuItem>
        );
      })}
    </Select>
  );

  const actions = (
    <Stack
      direction='row'
      justifyContent='space-between'
      width='100%'
      padding='20px'
    >
      <PrimaryButton
        width='100px'
        margin='0 10px 0 0'
        onClick={() => {
          act.getLabel(
            order.delivery_selection.info.rate_id,
            order,
            label.label
          );
          handleModal();
        }}
      >
        Print
      </PrimaryButton>
      <PrimaryButton width='100px' onClick={() => handleModal()}>
        Cancel
      </PrimaryButton>
    </Stack>
  );

  return (
    <Dialog open={open} title={title} content={content} actions={actions} />
  );
};

export default ShippingLabel;
