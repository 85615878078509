import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import ActiveListings from '../components/dashboard/listings/ActiveListings';
import { useParams } from 'react-router-dom';
import InactiveListings from '../components/dashboard/listings/InactiveListings';
import SoldOut from '../components/dashboard/listings/SoldOut';
import AddProduct from '../components/addProducts/addProduct';

const Components = (props) => {
  const params = useParams();
  const [farmProducts, setFarmProducts] = useState({
    active: [],
    inactive: [],
    soldOut: [],
  });

   const getFarmProducts = () => {
    API.get('FarmEraMarketAPI', `/products/${params.id}`)
      .then((response) => {
      
        let active = [];
        let inactive = [];
        let soldOut = [];

        response.map((product) => {
          return product.product_status === 'active'
            ? active.push(product)
            : product.product_status === 'not_active'
            ? inactive.push(product)
            : product.product_status === 'sold_out'
            ? soldOut.push(product)
            : null;
        });
        setFarmProducts({
          active: active,
          inactive: inactive,
          soldOut: soldOut,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getFarmProducts();
  }, [params.id]);
  
  switch (params.component) {
    case 'active_listings':
      return (
        <ActiveListings
          farmProducts={farmProducts.active}
          getFarmProducts={getFarmProducts}
          {...props}
        />
      );
    case 'inactive_listings':
      return (
        <InactiveListings
          farmProducts={farmProducts.inactive}
          getFarmProducts={getFarmProducts}
          {...props}
        />
      );
    case 'sold_out':
      return (
        <SoldOut
          farmProducts={farmProducts.soldOut}
          getFarmProducts={getFarmProducts}
          {...props}
        />
      );
    case 'add_product':
      return <AddProduct {...props} setFarmProducts={setFarmProducts} farmProducts={farmProducts} farm_id={params.farmId} />;
    default:
      return;
  }
};

const Listings = (props) => {
  return <Components props={props} />;
};
export default Listings;
