import React, { useState } from 'react';
import PrimaryButton from '../../../../inputs/Buttons/Button';
import Stack from '@mui/material/Stack';
import AddDocument from '../modals/AddDocument';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import * as act from '../../../../../store/actions';

const Documents = (props) => {
  const { farm, setFarm, display, id } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const remove = (i) => {
    farm.documents.splice(i, 1);
    dispatch(act.updateFarm(farm));
    act
      .getFarmById(id)
      .then((response) => {
        setFarm(response);
      })
      .catch((e) => {
        console.dir(e);
      });
  };

  return (
    <Stack marginTop='20px'>
      <PrimaryButton
        width='250px'
        margin='20px 0'
        onClick={() => setOpen(true)}
        display={display}
      >
        Upload New Document
      </PrimaryButton>
      <AddDocument
        open={open}
        setOpen={setOpen}
        farm={farm}
        setFarm={setFarm}
        id={id}
      />
      <Stack direction='row'>
        {farm?.documents.map((item, i) => {
          return (
            <Stack key={i}>
              <Typography variant='p' fontSize='24px'>
                {item.title}
              </Typography>
              <embed
                key={i}
                src={item.file}
                height='420px'
                width='300px'
                style={{ marginRight: '20px' }}
              />
              <PrimaryButton
                sx={{ display: display }}
                margin={'20px 0'}
                onClick={() => remove(i)}
              >
                Remove
              </PrimaryButton>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default Documents;
