import { API } from 'aws-amplify';

const api = 'FarmEraMarketAPI';

export const addCompletedOrder = (order) => {
  const myInIt = {
    body: order,
  };
  API.post(api, '/orders', myInIt)
    .then(() => {
      return;
    })
    .catch((err) => {
      return err;
    });
};

export const getOrderCount = () => {
  let count = 100000;

  API.get(api, '/orders')
    .then((response) => {
      count += response.count;
      return count;
    })
    .catch((err) => {
      return err;
    });
};

  
  
export const getAllCustomerReturns = (setCustomerReturns) => {
  let returnsByCustomer = [];
  let ids = [];
  API.get(api, '/returns')
    .then((response) => {
      let returns = response.returns;

      for (let i = 0; i < response.returns.length; i++) {
        if (returnsByCustomer.length === 0) {
          returnsByCustomer.push({
            id: returns[i].customer_id,
            returns: [response.returns[i]],
          });
        }
        if (returnsByCustomer.length >= 1) {
          for (let j = 0; j < returnsByCustomer.length; j++) {
            ids.push(returnsByCustomer[j].id);

            if (ids.includes(response.returns[i].customer_id)) {
              returnsByCustomer[j].returns.push(response.returns[i]);
            } else {
              returnsByCustomer.push({
                id: response.returns[i].customer_id,
                returns: [response.returns[i]],
              });
            }
          }
        }
      }

      setCustomerReturns(returnsByCustomer);
    })
    .catch((err) => {
      return err;
    });
};
export const getAllFarmReturns = (setFarmReturns) => {
  let returnsByFarm = [];
  let ids = [];
  API.get(api, '/returns')
    .then((response) => {
      let returns = response.returns;

      for (let i = 0; i < response.returns.length; i++) {
        if (returnsByFarm.length === 0) {
          returnsByFarm.push({
            id: returns[i].farm_id,
            returns: [response.returns[i]],
          });
        }
        if (returnsByFarm.length >= 1) {
          for (let j = 0; j < returnsByFarm.length; j++) {
            ids.push(returnsByFarm[j].id);

            if (ids.includes(response.returns[i].farm_id)) {
              returnsByFarm[j].returns.push(response.returns[i]);
            } else {
              returnsByFarm.push({
                id: response.returns[i].farm_id,
                returns: [response.returns[i]],
              });
            }
          }
        }
      }

      setFarmReturns(returnsByFarm);
    })
    .catch((err) => {
      console.dir(err, 'err');
    });
};
