import React from 'react';
import { useSelector } from 'react-redux';
import StackedLogoComponent from './components/StackedLogo';
import { MyAccountIcon } from '../assets/customerIconImages';
import { AppBar, Typography, Box, Toolbar, Divider } from '@mui/material';
import MobileViewAlert from '../misc/components/MobileViewAlert';

const AdminTopBar = (props) => {
  const user = useSelector((state) => state.UserReducer.user);
  return (
    <AppBar
      elevation={0}
      sx={{
        bgcolor: 'primary.background',
        color: 'text.dark',
        borderBottom: '1px solid rgba(186, 194, 183, 0.5)',
      }}
    >
      <MobileViewAlert />
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '120px',
          width: '70%',
        }}
      >
        <StackedLogoComponent />
        <Divider />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <MyAccountIcon />
          <Typography
            variant='h6'
            sx={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}
          >
            {user.user_metadata.first_name} {user.user_metadata.last_name}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AdminTopBar;
