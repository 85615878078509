import { createTheme } from '@mui/material/styles';
import { primary, opposite, secondary } from './colors';

import typography from './typography';
import components from './components';
import{ breakpoints} from "./breakpoints"

const theme = createTheme({
  palette: {
    primary,
    opposite,
    secondary
  },
  typography,
  components,
  spacing: 10,
  breakpoints: {
    values: breakpoints
  }
  
});



export default theme;