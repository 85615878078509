import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  Typography,
  Card,
  Stack,
} from '@mui/material';

const FarmProducts = (props) => {
  const { farm, productsCountMap } = props;

  const createData = (product, index) => {
    return {
      index: index + 1,
      name: product.name,
      status: product.product_status,
      price: product.price,
    };
  };

  const generateRows = () => {
    if (farm?.id && productsCountMap[farm.id]?.farmProducts) {
      return productsCountMap[farm.id]?.farmProducts.map((product, index) =>
        createData(product, index)
      );
    }

    return [];
  };

  const rows = generateRows();

  return (
    <Stack
      width='100%'
      height='fit-content'
      boxSizing='border-box'
      borderRadius='2px'
      padding='10px'
      marginBottom='15px'
    >
      <Typography variant='h6' lineHeight='22px' color='primary.lightFont'>
        Top Selling Products
      </Typography>
      <Stack
        width='100%'
        padding='0 20px'
        justifyContent='center'
        alignItems='flex-end'
      >
        <Typography
          variant='p'
          component='p'
          lineHeight='27px'
          color='primary.lightFont'
        >
          Items Listed:{' '}
          <Typography variant='p' color='primary.darkFont'>
            {productsCountMap[farm?.id]?.count || 0}
          </Typography>
        </Typography>
      </Stack>
      <Card elevation={10} marginBottom='15px'>
        <TableContainer component={Paper}>
          <Table
            stickyHeader
            style={{ width: '100%', margin: '0 auto' }}
            aria-label='sticky table'
          >
            <TableHead style={{ backgroundColor: '#EAEDE9' }}>
              <TableRow>
                <TableCell align='center'>Item Number</TableCell>
                <TableCell align='center'>Item Name</TableCell>
                <TableCell align='center'>Status</TableCell>
                <TableCell align='center'>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow hover key={`${row.name}-${row.index}`}>
                  <TableCell align='center' component='th' scope='row'>
                    {`#${row.index}`}
                  </TableCell>
                  <TableCell align='center'>{row.name}</TableCell>
                  <TableCell align='center'>{row.status}</TableCell>
                  <TableCell align='center'>{row.price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Stack>
  );
};

export default FarmProducts;
