import React from "react"
import Modal from "../../display/modal";
import PrimaryButton from "../../inputs/Buttons/Button";
import { useHistory } from "react-router-dom";

const ReviewSuccessfulModal = (props) => {
  const { open, setOpen } = props;
  const history = useHistory();
  
  const handleClick = () => {
    setOpen(false)
    history.push('/dashboard/history')
  }

  return (
    <Modal
      open={open}
      top='50%'
      left='50%'
      TitleMessage={'Review Submitted Successfully'}
      message={
        <PrimaryButton margin="20px 30%" onClick={() => handleClick()}>Close</PrimaryButton>
      }
    />
  );

}

export default ReviewSuccessfulModal;