import * as act from '../actions/index';

const InitialState = {
  newProduct: {
    id: '',
    farm_id: '',
    name: '',
    images: [],
    category: '',
    type: [],
    dietary: [],
    price: 0,
    inventory_qty: '',
    process_time: '',
    description_long: '',
    description_short: '',
    label: '',
    reviews: [],
    tags: [],
    weight: 0,
    quantity: 0,
    product_status: 'active',
    shipping_restrictions: [],
  },
  productsError: '',
  isLoading: false,
  allProducts: [],
  searchedProducts: [],
  searchedCategory: '',
  searchedDietary: [],
  query: '',
};

export const ProductsReducer = (state = InitialState, action) => {
  switch (action.type) {
    case act.GET_ALL_PRODUCTS:
      return {
        ...state,
        allProducts: action.payload.products,
        productCount: action.payload.count,
      };
    case act.SEARCHED_PRODUCTS:
      return {
        ...state,
        searchedProducts: action.payload,
      };
    case act.UPDATE_CATEGORY:
      return {
        ...state,
        searchedCategory: action.payload,
      };
    case act.UPDATE_DIETARY:
      return {
        ...state,
        searchedDietary: action.payload,
      };
    case act.PRODUCT_ERROR:
      return {
        ...state,
        productsError: action.payload,
      };
    case act.UPDATE_QUERY:
      return {
        ...state,
        query: action.payload,
      };
    default:
      return state;
  }
};
