import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PrimaryButton from '../../../../inputs/Buttons/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import * as act from '../../../../../store/actions';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '../../../../display/popover';
import NewOrders from '../components/NewOrders';


const Stripe = () => {
    const [loading, setLoading] = useState(false);
 
  const balance = useSelector((state) => state.UserReducer.stripeBalance);
  const [user, setUser] = useState(useSelector((state) => state.UserReducer.user));
  const [connected, setConnected] = useState(user.user_metadata.stripe_acct);
  
  const dispatch = useDispatch();

  useEffect(() => {
    if (connected) {
      dispatch(act.getBalance(connected));
    }
  }, []);


  useEffect(() => {
    act.updateUserMetadata(user.user_id, user.user_metadata)
  }, [user.user_metadata, connected])

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <Card
      sx={{
        width: '100%',
        border: '1px solid #90a18a',
        padding: '30px',
        margin: '20px',
        height: 'fit-content',
      }}
      elevation={5}
    >
      <Typography
        variant='h3'
        sx={({ breakpoints }) => ({
          display: 'flex',
          [breakpoints.down('sm')]: {
            fontSize: '20px',
          },
        })}
      >
        Stripe {connected ? `Account: ${connected} ` : null}
        <Popover
          component={
            <InfoOutlinedIcon sx={{ margin: '0 10px', fontSize: '16px' }} />
          }
          message={
            'We use Stripe to handle all transactions within our platform. Farm Era Market requires that all farm accounts have a valid Stripe account with a connected bank account to properly disburse funds for payment.'
          }
        />
      </Typography>

      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h6' margin='30px 0'>
          Status
        </Typography>

        {connected ? (
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography variant='p' component={'div'}>
              Connected
            </Typography>
            <Stack
              margin='0 10px'
              height='16px'
              width='16px'
              sx={{
                backgroundColor: connected ? '#28E13B' : 'red',
                borderRadius: '50%',
                display: 'inline-block',
              }}
            ></Stack>
          </Stack>
        ) : (
          <PrimaryButton
            width='250px'
            onClick={() => {
              dispatch(
                act.createStripeLink(setLoading, setUser, user, setConnected)
              );
            }}
          >
            {loading ? 'Connecting you to Stripe' : 'Connect to Stripe'}
          </PrimaryButton>
        )}
      </Stack>
      {connected ? (
        <Stack>
          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='h6'>Available Balance:</Typography>
            <Typography variant='p' component='p'>
              {isNaN(formatter.format(balance?.available))
                ? '$ 0'
                : formatter.format(balance?.available)}
            </Typography>
          </Stack>
          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='h6'>Pending Balance:</Typography>
            <Typography variant='p' component='p'>
              {isNaN(formatter.format(balance?.pending))
                ? '$ 0'
                : formatter.format(balance?.pending)}
            </Typography>
          </Stack>
          {formatter.format(balance?.available) > 0 ? (
            <PrimaryButton width='250px' margin='20px 0'>
              Withdraw Funds
            </PrimaryButton>
          ) : null}
        </Stack>
      ) : null}
    </Card>
  );

}

export default Stripe;
