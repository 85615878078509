import React from 'react';
import { useHistory } from 'react-router-dom';
import PrimaryButton from '../../../inputs/Buttons/Button';
import Stack from '@mui/material/Stack';
import TransparentButton from '../../../inputs/Buttons/TransparentButton';

const OrderHistoryButtons = (props) => {
  const { handleReorderOrder, order } = props;
  
  const history = useHistory();

  return (
    <Stack
      width='25%'
      marginTop='20px'
      sx={({ breakpoints }) => ({
        [breakpoints.down('1600')]: {
          width: '35%',
        },
        [breakpoints.down('1310')]: {
          width: '50%',
        },
      })}
    >
      <PrimaryButton
        // margin="5%"
        width='100%'
        height='40px'
        onClick={() => handleReorderOrder(order)}
      >
        Purchase Order Again
      </PrimaryButton>
      <TransparentButton
        width='100%'
        height='40px'
        opacity='0.3'
        margin='10px 0'
      >
        Contact Farm
      </TransparentButton>

      <TransparentButton
        width='100%'
        height='40px'
        onClick={() => history.push(`/dashboard/returns/${order.id}`)}
      >
        Request Refund
      </TransparentButton>
      <TransparentButton
        width='100%'
        height='40px'
        margin='10px 0'
        onClick={() => history.push(`/dashboard/review/${order.id}`)}
      >
        Leave Review
      </TransparentButton>
    </Stack>
  );
};

export default OrderHistoryButtons;
