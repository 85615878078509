import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { FarmReducer } from "./FarmReducer";
import { ProductsReducer } from "./ProductsReducer";
import { UserReducer } from "./UserReducer";
import { OrderReducer } from "./OrderReducer";
import { CartReducer } from "./CartReducer";
import {AdminReducer} from "./AdminReducer"

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["UserReducer", "ProductsReducer", "CartReducer", "FarmReducer"],
  //Add any new reducers to this array for persistance to work
};

const rootReducer = combineReducers({
  FarmReducer,
  ProductsReducer,
  UserReducer,
  OrderReducer,
  CartReducer,
  AdminReducer
});

export default persistReducer(persistConfig, rootReducer);
