import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
  Typography,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import PrimaryButton from '../../../inputs/Buttons/Button';
import Stack from '@mui/material/Stack';
import { roles } from '../../../../util';
import * as act from '../../../../store/actions';
import SuccessUser from '../modals/SuccessUser';

const CreateUser = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.UserReducer.error);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValue: {
      role: roles.customer,
    },
  });

  const values = watch();
  const [open, setOpen] = useState(false);

  const handleCreateUser = () => {
    dispatch(act.resetError());
    dispatch(act.createUser(values, setOpen));
  };

  return (
    <Stack marginTop='50px' width='80%' alignItems='center'>
      <Typography variant='h4'>Add New User</Typography>
      {error ? <Typography variant='error'>{error}</Typography> : null}
      <Stack width='50%'>
        <SuccessUser open={open} setOpen={setOpen} />
        <TextField
          className='largeInput'
          defaultValue={''}
          {...register('email', {
            required: 'Please enter an email address',
            pattern: {
              value:
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              message: 'Invalid email address',
            },
          })}
          label={
            errors && errors.email ? (
              <Typography variant='error'>{errors.email.message}</Typography>
            ) : (
              'Email Address'
            )
          }
        />

        <TextField
          placeholder={'Password'}
          type='password'
          {...register('password', {
            required: 'Password is required',
          })}
          defaultValue={''}
          label={
            errors && errors.password ? (
              <Typography variant='error'>{errors.password.message}</Typography>
            ) : (
              'Password'
            )
          }
          className='largeInput'
        />
        <Stack direction='row'>
          <TextField
            sx={{ margin: '10px 10px 0 0' }}
            placeholder={'First Name'}
            {...register('first_name', {
              required: 'First Name is required',
            })}
            label={
              errors && errors.first_name ? (
                <Typography variant='error'>
                  {errors.first_name.message}
                </Typography>
              ) : (
                'First Name'
              )
            }
            className='mediumInput'
            error={errors.first_name}
          />

          <TextField
            {...register('last_name', {
              required: 'Last Name is required',
            })}
            label={
              errors && errors.last_name ? (
                <Typography variant='error'>
                  {errors.last_name.message}
                </Typography>
              ) : (
                'Last Name'
              )
            }
            className='mediumInput'
            error={errors.last_name}
          />
        </Stack>
        <Stack direction='row'>
          <TextField
            {...register('zip', {
              maxLength: {
                value: 5,
                message: 'Please only use a 5-digit postal code',
              },
              required: 'Zip Code is required',
            })}
            inputProps={{
              maxLength: 5,
            }}
            label={
              errors && errors.zip ? (
                <Typography variant='error'>{errors.zip.message}</Typography>
              ) : (
                'Zip Code'
              )
            }
            className='mediumInput'
          />

          <Controller
            name='role'
            rules={{ required: 'Must select type of account to create' }}
            render={({ field }) => (
              <RadioGroup
                {...field}
                value={field.value}
                sx={{ margin: '0 20px' }}
              >
                <FormControlLabel
                  sx={{ fontSize: '1rem' }}
                  value={roles.farmer}
                  control={<Radio />}
                  label='Farmer'
                />
                <FormControlLabel
                  sx={{ fontSize: '1rem' }}
                  value={roles.customer}
                  control={<Radio />}
                  label='Customer'
                />
                <FormControlLabel
                  sx={{ fontSize: '1rem' }}
                  value={roles.admin}
                  control={<Radio />}
                  label='Admin'
                />
                <FormControlLabel
                  sx={{ fontSize: '1rem' }}
                  value={roles.super}
                  control={<Radio />}
                  label='Super User'
                />

                {errors && errors.role ? (
                  <Typography variant='error'>{errors.role.message}</Typography>
                ) : null}
              </RadioGroup>
            )}
            control={control}
            defaultValue={roles.customer}
          />
        </Stack>
        <Stack direction='row'>
          <PrimaryButton onClick={() => handleCreateUser()} margin='10px'>
            Add User
          </PrimaryButton>
          <PrimaryButton
            onClick={() => history.push('/dashboard')}
            margin='10px'
          >
            Cancel
          </PrimaryButton>
        </Stack>
      </Stack>
      {/* }
      /> */}
    </Stack>
  );
};

export default CreateUser;
