import * as act from '../store/actions';
import * as utils from './index';

export function calculateRating(reviews) {
  let rating = 0;

  reviews.map((review) => {
    return (rating += review.rate);
  });

  if (rating > 0) rating /= reviews.length;

  return rating;
}

export function formatNewOrder(product, userId, id) {
  return {
    user_id: userId,
    orders: [
      {
        customer_id: userId,
        farm_id: product.farm_id,
        created_date: '',
        delivery_selection: {
          type: '',
          info: {},
        },

        id: id,
        products: [{id: product.id, quantity: product.quantity, price: product.price, weight: product.weight}],
        payment: {
          payment_intent: '',
          payment_method: '',
          shipping: 0,
          subtotal: parseFloat(product.price) * parseFloat(product.quantity),
          tax: 0,
          total: 0,
        },
        acquisitionData: {
          deliveryDetails: {
            day: '',
            deliveredFrom: {},
          },
          shippingDetails: {
            shippingLabel: '',
            trackingInfo: '',
          },
          pickupDetails: {
            date: '',
            pickupFrom: '',
          },
        },
        order_status: 'new',
      },
    ],
  };
}

export function changeQuantity(
  cart,
  product,
  quantity,
  stateSetter,
  stateSetter2,
  dispatch
) {
  for (let i = 0; i < cart.orders.length; i++) {
    let cancel = false;

    for (let j = 0; j < cart.orders[i].products.length; j++) {
      if (cart.orders[i].products[j].id === product.id) {
        cart.orders[i].products[j].quantity = quantity;

        utils.calculateTotals(cart, 'subtotal', stateSetter);
        utils.calculateTotals(cart, 'total', stateSetter2);
        dispatch(act.saveCartToDB(cart));
        cancel = true;
        break;
      }
    }
    if (cancel) break;
  }
}

export function calculateSubtotal(subtotal, newPrice, quantity) {
  newPrice *= quantity;
  const Sum = subtotal + newPrice;
  return Sum;
}
export function calculateSubtotal_QuantityChange(
  subtotal,
  price,
  prevQuantity,
  quantity
) {
  subtotal -= price * prevQuantity;

  let newQuantity = price * quantity;
  let newSub = subtotal + newQuantity;

  return newSub;
}

export function changeDeliverySelection(
  cart,
  orderId,
  deliveryMethod,
  user,
  dispatch
) {
  cart.orders.map((order) => {
    return order.farmId === orderId
      ? (order.delivery_selection = deliveryMethod)
      : null;
  });
  const newCart = {
    userId: user.farm_era_id.split('|')[1],
    orders: cart.orders,
  };
  dispatch(act.saveCartToDB(newCart));
}

export function isEmptyObject(value) {
  return Object.keys(value).length === 0 && value.constructor === Object;
}

export const time = () => {
  var arr = [],
    i,
    j;
  for (i = 0; i < 24; i++) {
    for (j = 0; j < 2; j++) {
      arr.push(i + ':' + (j === 0 ? '00' : 30 * j));
    }
  }
  return arr;
};
