import React, { useState } from 'react';
import Dialog from '../../../../display/dialog';
import { useForm, FormProvider } from 'react-hook-form';
import FileUpload from '../components/FileUpload';
import PrimaryButton from '../../../../inputs/Buttons/Button';
import Stack from '@mui/material/Stack';
import * as act from '../../../../../store/actions'
import { useDispatch } from "react-redux";

const AddDocument = (props) => {
  const { open, setOpen, farm, setFarm, id } = props;
  const dispatch = useDispatch()
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { watch } = methods;
  const value = watch()

  const onSubmit = () => {    
    dispatch(
      act.updateFarm({
        ...farm,
        documents: [
          ...farm.documents,
          {
            file: value.files,
            title: value.title,
          },
        ],
      })
    );
    setOpen(false)
     act
       .getFarmById(id)
       .then((response) => {
         setFarm(response);
       })
       .catch((e) => {
         console.dir(e);
       });
}

  const actions = (
    <Stack direction='row'>
      <PrimaryButton onClick={() => onSubmit()}>Upload</PrimaryButton>
      <PrimaryButton onClick={() => setOpen(false)}>Cancel</PrimaryButton>
    </Stack>
  );

  return (
    <FormProvider {...methods}>
      <Dialog
        title={'Add New Document'}
        content={<FileUpload />}
        actions={actions}
        open={open}
        setOpen={setOpen}
      />
    </FormProvider>
  );
};

export default AddDocument;
