import React from 'react';
import PaymentIcon from '@mui/icons-material/Payment';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';

const Refunds = (props) => {
  const history = useHistory();

  return (
    <Accordion
      elevation={0}
      disableGutters
      sx={{
        bgcolor: 'primary.formOutline2',
        border: 'none',
        margin: '-10px 0',
        padding: '0',
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={{ padding: '0 16px', border: 'none' }}
      >
        <ListItemIcon>
          <PaymentIcon sx={{ color: '#006F45' }} />
        </ListItemIcon>
        <ListItemText primary='Refunds' />
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0 0 0 70px', border: 'none' }}>
        <ListItem
          sx={{ cursor: 'pointer' }}
          onClick={() => history.push('/dashboard/refunds-by-customer')}
        >
          <ListItemText primary='Refunds By Customer' />
        </ListItem>
        <ListItem
          sx={{ cursor: 'pointer' }}
          onClick={() => history.push('/dashboard/refunds-by-farm')}
        >
          <ListItemText primary='Refunds By Farm' />
        </ListItem>
      </AccordionDetails>
    </Accordion>
  );
};

export default Refunds;
