import React from 'react';
import Modal from '../../display/modal';
import { Box, Typography } from '@mui/material';

const AddToBasket = (props) => {
  const { product, open } = props;

  return (
    <Modal
      product={product}
      top={'150px'}
      left={'1200px'}
      width={'400px'}
      open={open}
      TitleMessage={'Added to Basket'}
      message={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '250px',
          }}
        >
          <img
            src={product?.images[0]}
            width='120px'
            height='85px'
            alt={product?.name}
          />
          <Box>
            <Typography variant='h5'>{product?.name}</Typography>
            <Typography variant='h5'>
              <span>${product?.price.toFixed(2)}</span> /{product?.label}
            </Typography>
          </Box>
        </Box>
      }
    />
  );
};

export default AddToBasket;
