import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom"
import * as act from "../../../../store/actions";
import Stack from "@mui/material/Stack";
import ProductCard from "../../../products/components/productCard";



const AllProducts = () => {
  const params = useParams()
  const [products, setProducts] = useState([])

  useEffect(() => {
    if(params.id){
      act.getFarmProducts(params.id, setProducts);
    } else {
    act.getFarmProducts(params.component, setProducts)
    }
  }, [params.component])
  return (
    <Stack direction="row" flexWrap="wrap">
      {products.map(product => {
       return(product.product_status === "active")?
           <ProductCard key={product.id} product={product} /> : null
      })}

    </Stack>
  )
}

export default AllProducts;