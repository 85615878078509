import * as act from '../actions/index';
import { ORDER_ERROR } from '../actions/index';

const InitialState = {
  allOrders: [],
  history: [],
  orderError: '',
  completedOrder: [],
  userHistoryLoading: false,
};

export const OrderReducer = (state = InitialState, action) => {
  switch (action.type) {
    case act.GET_ALL_ORDERS:
      return {
        ...state,
        allOrders: action.payload,
      };
    case act.GET_COMPLETED_ORDER:
      return {
        ...state,
        completedOrder: action.payload,
      };

    case ORDER_ERROR:
      return {
        ...state,
        orderError: action.payload,
      };
    case act.ADD_USER_ORDER_HISTORY:
      return {
        ...state,
        history: action.payload,
      };
    case act.USER_HISTORY_LOADING:
      return {
        ...state,
        userHistoryLoading: action.payload,
      };
    default:
      return state;
  }
};
