import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as act from '../../../store/actions';
import { MoreLikeItemCard } from '../components/MoreLikeItemCard';
import { Stack, Container, Typography, Box } from '@mui/material';
import * as util from '../../../util';

import ProductDescription from '../accordions/ProductDescription';
import ProductDescr from '../components/productDescription';
import ShippingDelivery from '../accordions/ShippingDelivery';
import Reviews from '../accordions/Reviews';
import Policies from '../accordions/Policies';
import ShoppingNav from '../../nav/ShoppingNav';
import { categories } from '../../../util/data';

const ProductDetailsPage = (props) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [product, setProduct] = useState();
  const [activeImage, setActiveImage] = useState();
  const [likeProducts, setLikeProducts] = useState([]);
  const [farmProducts, setFarmProducts] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [open, setOpen] = useState(false);

  const cart = useSelector((state) => state.CartReducer.userCart);
  const farms = useSelector((state) => state.FarmReducer.allFarms);
  const farm = farms.find((farm) => farm.id === product?.farm_id);

  const [anchorEl, setAnchorEl] = React.useState(null);
  let start;
  let end;

  if (farmProducts) {
    start = util.FilterFarmProducts(farmProducts);
    end = start + 3;
    console.log(start, end);
  }
  useEffect(() => {
    act
      .getProduct(params.id)
      .then((response) => {
        setProduct(response);
        setActiveImage(response.images[0]);
        setLikeProducts(
          props.products?.filter((prod) => prod.category === product?.category)
        );
        setFarmProducts(
          farm && props.products?.filter((prod) => prod.farm_id === farm.id)
        );
      })
      .catch((err) => {
        console.dir(err);
      });
  }, [params.id, farm]);

  const showModal = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  const addProductToCart = async (e, quantity) => {
    e.preventDefault();
    if (!cart.user_id) {
      props.history.push('/login');
    } else if (product.inventory_qty < 0) {
      setAnchorEl(e.currentTarget);
    } else {
      await dispatch(act.addToCart(cart, product, quantity));
      showModal();
      dispatch(act.saveCartToDB(cart));
    }
  };

  return (
    <Container>
      <ShoppingNav categories={categories} />
      {product ? (
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mr: 5 }}>
            <Box sx={{ flexDirection: 'column' }}>
              <img
                src={activeImage}
                alt={product.name}
                // width="400px"
                height='400px'
              />
              <Box>
                {product.images.map((image, i) => {
                  return (
                    <img
                      key={i}
                      src={image}
                      onClick={() => setActiveImage(product.images[i])}
                      alt={product.name}
                      // width="125px"
                      height='85px'
                      style={{ marginRight: '10px' }}
                    />
                  );
                })}
              </Box>
            </Box>
            <ProductDescr
              farm={farm}
              product={product}
              setActiveImage={setActiveImage}
              setProduct={setProduct}
              addProductToCart={addProductToCart}
              open={open}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              quantity={quantity}
              setQuantity={setQuantity}
            />
          </Box>

          <Box sx={{ my: 2 }}>
            <ProductDescription product={product} image={'Plus'} />
            <ShippingDelivery farm={farm} image={'Plus'} />
            <Reviews product={product} />
            <Policies farm={farm} />
          </Box>
        </Box>
      ) : (
        <h2>Loading</h2>
      )}
      <Box className='productPageMoreItems'>
        <Typography variant='h5' className='moreItemText'>
          More Items from {farm?.name}
        </Typography>
        <Box className='moreItemsBox'>
          {farmProducts &&
            farmProducts.splice(start, end).map((product, i) => {
              return (
                <MoreLikeItemCard
                  key={product.id + i}
                  product={product}
                  deliveryDisplay={'none'}
                  buttonDisplay={'none'}
                  cardHeight={'380px'}
                />
              );
            })}
        </Box>
        <Typography variant='h5' marginTop='50px'>
          More Items like {product?.name}
        </Typography>
        <Stack direction="row">
          {likeProducts &&
            likeProducts.slice(0, 4).map((product, i) => {
              return (
                <MoreLikeItemCard
                  key={product.id + i}
                  elevation={10}
                  product={product}
                  deliveryDisplay={'none'}
                  buttonDisplay={'none'}
                  cardHeight={'380px'}
                />
              );
            })}
        </Stack>
      </Box>
    </Container>
  );
};

export default ProductDetailsPage;
