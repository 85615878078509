import { API } from 'aws-amplify';
import usZips from 'us-zips/key-value';
import { isPointWithinRadius } from 'geolib';

const api = 'FarmEraMarketAPI';

export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';

//get all products
export const getAllProducts = (setProducts) => {
  return (dispatch) => {
    API.get(api, '/products')
      .then((response) => {
        dispatch({ type: GET_ALL_PRODUCTS, payload: response });
        dispatch({ type: SEARCHED_PRODUCTS, payload: response.products });
        if (setProducts) {
          setProducts(response.products);
        }
      })
      .catch((err) => {
        dispatch({ type: PRODUCT_ERROR, payload: err });
      });
  };
};

//adding a new product
export const addNewProduct = (product) => {
  product.created_date = new Date();
  return (dispatch) => {
    const myInIt = {
      body: product,
    };
    API.post(api, '/products', myInIt)
      .then((response) => {
        dispatch({ type: GET_ALL_PRODUCTS, payload: response.Items });
      })
      .catch((err) => {
        dispatch({ type: PRODUCT_ERROR, payload: err });
      });
  };
};

//update fields in product object
export const updateProduct = (product) => {
  product.updated_date = new Date();
  return (dispatch) => {
    const myInIt = {
      body: product,
    };

    API.post(api, '/products', myInIt)
      .then((response) => {
        dispatch({ type: GET_ALL_PRODUCTS, payload: response });
      })
      .catch((err) => {
        dispatch({ type: PRODUCT_ERROR, payload: err });
      });
  };
};

//delete a product
// export const deleteProduct = (product) => {
//   return (dispatch) => {
//     const myInIt = {
//       body: {
//         farmId: product.farm_id,
//       },
//     };
//     API.del(api, `/products/${product.id}`, myInIt)
//       .then((response) => {})
//       .catch((err) => {
//         dispatch({ type: PRODUCT_ERROR, payload: err });
//       });
//   };
// };

//get products by farm
export const getFarmProducts = (farmId, setProducts) => {
  let products = [];
  API.get(api, `/products/${farmId}`)
    .then((response) => {
      products = response;

      setProducts(products);
    })
    .catch((err) => {
      console.log(err);
    });
};
//get single product
export const getProduct = (productId, farmId) => {
  // let products = [];
  try {
    const response = API.get(api, `/product/${productId}`);

    return response;
  } catch (err) {
    console.dir(err);
  }
};

//update product status - active, inactive, sold out,archived
export const updateProductStatus = (data) => {
  const myInIt = {
    body: {
      key: Object.keys(data)[0],
      value: data.product_status,
      id: data.id,
      farmId: data.farmId,
    },
  };

  API.put(api, '/products', myInIt)
    .then((response) => {})
    .catch((err) => {
      console.log(err);
    });
};

export const SEARCHED_PRODUCTS = 'SEARCHED_PRODUCTS';
export const UPDATE_DIETARY = 'UPDATE_DIETARY';
//search all products by distance
export const searchByDistance = (products, farms, miles, zip, stateSetter) => {
  const KM = miles * 1.60934;
  const searched = [];
  const response = usZips.find(([key, value]) => key === zip);
  return (dispatch) => {
    products.map((product) => {
      const farm = farms.find((farm) => farm.id === product.farm_id);

      if (
        isPointWithinRadius(
          { latitude: farm.latitude, longitude: farm.longitude },
          {
            latitude: response[1].latitude,
            longitude: response[1].longitude,
          },
          KM * 1000
        )
      ) {
        searched.push(product);
      }
      return searched;
    });
    dispatch({ type: SEARCHED_PRODUCTS, payload: searched });
  };
};

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
//search all products by category
export const searchByCategory = (category) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_CATEGORY, payload: category });
    if (category === 'All') {
      API.get(api, '/products')
        .then((response) => {
          dispatch({ type: SEARCHED_PRODUCTS, payload: response.products });
        })
        .catch((err) => {
          dispatch({ type: PRODUCT_ERROR, payload: err });
        });
    } else {
      API.get(api, `/${category}`)
        .then((response) => {
          dispatch({ type: SEARCHED_PRODUCTS, payload: response });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
};

export const updateCategory = (category) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_CATEGORY, payload: category });
  };
};

//search by price
export const searchByPrice = (low, high, products, stateSetter) => {
  const searched = [];
  return (dispatch) => {
    products.map((product) => {
      return product.price <= high && product.price >= low
        ? searched.push(product)
        : null;
    });
    dispatch({ type: SEARCHED_PRODUCTS, payload: searched });
  };
};

export const searchByDietary = (products, dietaryArray, stateSetter) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_DIETARY, payload: dietaryArray });
    if (dietaryArray.length === 0) {
      dispatch({ type: SEARCHED_PRODUCTS, payload: products });
      return;
    }
    const checker = (arr, target) => target.every((v) => arr.includes(v));

    const searched = [];
    products.map((product) => {
      const productArray = product.dietary;

      return checker(productArray, dietaryArray)
        ? searched.push(product)
        : null;
    });

    dispatch({ type: SEARCHED_PRODUCTS, payload: searched });
  };
};

export const UPDATE_QUERY = 'UPDATE_QUERY';

export const filterProducts = (e, query, products, history) => {
  if (window.location.pathname !== '/store/products') {
    history.push('/store/products');
  }
  return (dispatch) => {
    if (!query) {
      return products;
    }
    dispatch({ type: UPDATE_QUERY, payload: query });
    let searchQuery = query.toLowerCase();
    const filtered = products.filter((product) => {
      const productName = product.name.toLowerCase();
      const productDescription = product.description_short.toLowerCase();

      return productName.includes(searchQuery) ||
        productDescription.includes(searchQuery)
        ? product
        : null;
    });
    dispatch({ type: SEARCHED_PRODUCTS, payload: filtered });
    dispatch({ type: UPDATE_QUERY, payload: '' });
  };
};
