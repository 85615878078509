import React, { useRef } from 'react';
import Modal from '../../display/modal';
import Stack from '@mui/material/Stack';
import PrimaryButton from '../../inputs/Buttons/Button';
import OrderInfo from '../components/dashboard/orders/OrderInfo';

import { useReactToPrint } from 'react-to-print';

const ViewOrder = (props) => {
  const {
    order,
    open,
    setOpen,
    customerID,
    setNewOrders,
    farm,
    buttonDisplay,
    act
  } = props;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleProcessOrder = () => {
    let data = {
      order_status: 'processing',
      id: order.id,
    };

    handlePrint();
    act.updateOrderStatus(data);
    act.getFarmNewOrders(farm.id, setNewOrders);
    setOpen();
  };

  return (
    <Modal
      open={open}
      top='50%'
      left='50%'
      width='60%'
      TitleMessage={'View Order'}
      message={
        <Stack>
          <OrderInfo
            componentRef={componentRef}
            order={order}
            iconDisplay='0%'
            width='100%'
            margin='0'
            customerID={customerID}
          />
          <Stack
            direction='row'
            width='40%'
            justifyContent='space-between'
            margin='20px 0'
          >
            <PrimaryButton
              width='200px'
              display={buttonDisplay}
              onClick={() => handleProcessOrder()}
            >
              Process order
            </PrimaryButton>
            <PrimaryButton width='200px' onClick={() => setOpen(false)}>
              Close
            </PrimaryButton>
          </Stack>
        </Stack>
      }
    />
  );
};

export default ViewOrder;
