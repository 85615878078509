import React from 'react';
import { useSelector } from 'react-redux';
import {
  CustomerOrderPurchases,
  ProfileIcon,
  CustomerMessages,
  CustomerFav,
} from '../../assets/customerIconImages';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

const CustomerDashboard = (props) => {
  const user = useSelector((state) => state.UserReducer.user);

  return (
    <Stack
      marginTop='50px'
      width='90%'
      sx={({ breakpoints }) => ({
        [breakpoints.down('sm')]: {
          marginLeft: '0px',
          margin: '0 auto',
        },
      })}
    >
      <Typography variant='h4' marginLeft='20px'>
        Hello {user.user_metadata.first_name}
      </Typography>
      <Stack
        flexWrap='wrap'
        direction='row'
        sx={({ breakpoints }) => ({
          [breakpoints.down('lg')]: {
            flexDirection: 'column',
          },
        })}
      >
        <Card
          sx={({ breakpoints }) => ({
            display: 'flex',
            flexDirection: 'column',
            width: '47%',
            padding: '20px',
            margin: '10px',
            [breakpoints.down('lg')]: {
              width: '80%',
            },
          })}
          elevation={10}
        >
          <CardContent>
            <CustomerOrderPurchases sx={{ fontSize: '40px' }} />
            <Typography variant='h4' margin='10px 0'>
              Orders and Purchases
            </Typography>
            <Typography variant='p'>
              Check order status, view purchase history, reorder a previous
              purchase or leave reviews for items and farms
            </Typography>
          </CardContent>
          <CardActions
            sx={{ cursor: 'pointer' }}
            onClick={() => props.history.push('/dashboard/history')}
          >
            <Typography variant='p' marginLeft='8px' color='primary.radio'>
              View All
            </Typography>
          </CardActions>
        </Card>
        <Card
          sx={({ breakpoints }) => ({
            display: 'flex',
            flexDirection: 'column',
            width: '47%',
            padding: '20px',
            margin: '10px',
            [breakpoints.down('lg')]: {
              width: '80%',
            },
          })}
          elevation={10}
        >
          <CardContent>
            <ProfileIcon sx={{ fontSize: '40px' }} />
            <Typography variant='h4' margin='10px 0'>
              Profile
            </Typography>
            <Typography variant='p'>
              Update account information, password, add or edit your address and
              personalize your profile
            </Typography>
          </CardContent>
          <CardActions
            sx={{ cursor: 'pointer' }}
            onClick={() => props.history.push('/dashboard/profile')}
          >
            <Typography variant='p' marginLeft='8px' color='primary.radio'>
              Manage Profile
            </Typography>
          </CardActions>
        </Card>
        <Card
          sx={({ breakpoints }) => ({
            display: 'flex',
            flexDirection: 'column',
            width: '47%',
            padding: '20px',
            margin: '10px',
            [breakpoints.down('lg')]: {
              width: '80%',
            },
          })}
          elevation={10}
        >
          <CardContent>
            <CustomerFav sx={{ fontSize: '40px' }} />
            <Typography variant='h4' margin='10px 0'>
              Saved Farms and Favorites
            </Typography>
            <Typography variant='p'>
              Find your favorite farms easily and go directly to their farm
              page. View your most loved products
            </Typography>
          </CardContent>
          <CardActions
            sx={{ cursor: 'pointer' }}
            onClick={() => props.history.push('/dashboard/favorites')}
          >
            <Typography variant='p' marginLeft='8px' color='primary.radio'>
              View All
            </Typography>
          </CardActions>
        </Card>
        <Card
          sx={({ breakpoints }) => ({
            display: 'flex',
            flexDirection: 'column',
            width: '47%',
            padding: '20px',
            margin: '10px',
            [breakpoints.down('lg')]: {
              width: '80%',
            },
          })}
          elevation={10}
        >
          <CardContent>
            <CustomerMessages sx={{ fontSize: '40px' }} />
            <Typography variant='h4' margin='10px 0'>
              Messages
            </Typography>
            <Typography variant='p'>
              Manage and view all your conversations
            </Typography>
          </CardContent>

          <CardActions
            onClick={() => props.history.push('/dashboard/messages')}
          >
            <Typography variant='p' marginLeft='8px' color='primary.radio'>
              View All Messages
            </Typography>
          </CardActions>
        </Card>
      </Stack>
    </Stack>
  );
};
export default CustomerDashboard;
