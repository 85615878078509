import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Badge } from '@mui/material';
import {
  NotificationIcon,
  ShoppingFarmStand,
  Basket,
} from '../../assets/farmIconImages';
import LoginIcon from '@mui/icons-material/Login';
import { withStyles } from '@material-ui/core/styles';
import * as act from '../../../store/actions';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

const AccountIcons = (props) => {
  const count = useSelector((state) => state.CartReducer.itemCount);
  const [category, setCategory] = useState(
    useSelector((state) => state.ProductsReducer.searchedCategory)
  );
  const [products, setProducts] = useState(
    useSelector((state) => state.ProductsReducer.searchedProducts)
  );
  const user = useSelector((state) => state.UserReducer.user);
  const history = useHistory();
  const dispatch = useDispatch();

  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: 5,
      top: 0,
      padding: '0 4px',
    },
  }))(Badge);

  const handleFarmStand = () => {
    setCategory('All');
    dispatch(act.getAllProducts(setProducts));
    history.push('/store/products');
  };

  return (
    <Stack
      width='30%'
      sx={({ breakpoints }) => ({
        [breakpoints.down('lg')]: {
          display: 'none',
        },
      })}
    >
      {user.user_id ? (
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='flex-end'
          width='100%'
          sx={({ breakpoints }) => ({
            [breakpoints.down('lg')]: {
              display: 'none',
            },
          })}
        >
          <Stack
            alignItems='center'
            margin='0 10px'
            marginTop='1px'
            sx={{ opacity: 0.3 }}
          >
            <NotificationsOutlinedIcon
              sx={{
                fontSize: '25px',
                cursor: 'pointer',
                marginBottom: '3px',
              }}
            />
            <Typography variant='p' fontSize='13px'>
              Notifications
            </Typography>
          </Stack>
          <Stack alignItems='center' margin='0 10px' marginTop='1px'>
            <AccountCircleOutlinedIcon
              onClick={() => history.push('/dashboard')}
              sx={{
                fontSize: '25px',
                cursor: 'pointer',
                marginBottom: '3px',
              }}
            />
            <Typography variant='p' fontSize='13px'>
              Account
            </Typography>
          </Stack>
          <Stack alignItems='center' margin='0 10px' marginTop='4px'>
            <ShoppingFarmStand
              onClick={() => handleFarmStand()}
              sx={{
                fontSize: '25px',
                cursor: 'pointer',
              }}
            />
            <Typography variant='p' fontSize='13px'>
              Marketplace
            </Typography>
          </Stack>
          <Stack alignItems='center' margin='0 10px' marginTop='4px'>
            <StyledBadge
              badgeContent={count}
              color='primary'
              sx={{
                margin: '0 10px',
                cursor: 'pointer',
              }}
            >
              <Basket
                onClick={() => history.push('/store/cart')}
                sx={{
                  fontSize: '25px',
                  cursor: 'pointer',
                }}
              />
            </StyledBadge>
            <Typography
              variant='p'
              fontSize='13px'
              sx={{ color: 'primary.darkFont' }}
            >
              Basket
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='flex-end'
          width='100%'
          sx={({ breakpoints }) => ({
            [breakpoints.down('lg')]: {
              display: 'none',
            },
          })}
        >
          <Stack
            alignItems='center'
            sx={{ cursor: 'pointer' }}
            marginRight='10px'
            onClick={() => history.push('/auth/login')}
          >
            <LoginIcon sx={{ fontSize: 25 }} />
            <Typography variant='p' fontSize='13px' marginTop='6px'>
              Login
            </Typography>
          </Stack>
          <Stack
            alignItems='center'
            sx={{ cursor: 'pointer' }}
            onClick={() => history.push('/auth/register')}
          >
            <PersonAddAltOutlinedIcon sx={{ fontSize: 25 }} />
            <Typography variant='p' fontSize='13px' marginTop='6px'>
              Sign Up
            </Typography>
          </Stack>
          <Stack alignItems='center' margin='0 10px' marginTop='4px'>
            <ShoppingFarmStand
              onClick={() => handleFarmStand()}
              sx={{
                fontSize: '30px',
                margin: '0 10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            />
            <Typography variant='p' fontSize='13px'>
              Marketplace
            </Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default AccountIcons;
