import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {
  UpdateIcon,
  CreateInvoiceIcon,
  MessageIcon,
  ShippingLabelIcon,
  CancelOrderIcon,
  EditIconBlack,
} from '../../../../assets/farmIconImages';
import * as act from '../../../../../store/actions';
import * as utils from '../../../../../util';
import UpdateOrderModal from './modals/updateOrderModal';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import CancelOrder from '../../../modals/CancelOrder';
import ShippingLabel from './modals/ShippingLabel';
import Popover from '../../../../display/popover';
import UpdateOrderStatusModal from './modals/updateOrderStatusModal';

const OrderIcons = (props) => {
  const {
    order,
    setFarmOrders,
    updatedOpen,
    setUpdatedOpen,
    cancelledOpen,
    setCancelledOpen,
  } = props;
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [updateOrderStatus, setUpdateOrderStatus] = useState(false);

  const handleModal = () => {
    setOpen(!open);
  };

  const {
    watch,
    formState: { errors },
    setValue,
  } = useFormContext();

  const values = watch();

  const handleCancel = (order) => {
    if (!utils.isEmptyObject(errors)) {
      return;
    }
    if (!values.payment.refund) {
      order.payment.refund = [];
    }

    let amount = values.payment.subtotal;
    const data = values;
    delete data['original_products'];
    delete data['original_payment'];
    delete data['refundAmount'];
    data.order_status = 'cancelled';

    const options = {
      id: params.id,
      payment_intent: data.payment.payment_intent,
      amount: amount,
      reason: 'requested_by_customer',
      transfer_id: data.payment.transfer.id,
      refund: {
        order: data,
      },
      stateSetter: setFarmOrders,
    };
    act.sendRefund(options);
    act.addCompletedOrder(values);
    setCancelledOpen(false);
  };

  return (
    <Stack>
      <ShippingLabel open={open} order={order} handleModal={handleModal} />
      <Stack direction='row' justifyContent='space-between' marginBottom='20px'>
        <Popover
          component={
            <EditIconBlack
              onClick={() => {
                setUpdatedOpen(order.id);
              }}
            />
          }
          message={'Edit Order Items'}
        />
        <Popover
          component={
            <UpdateIcon
              onClick={() => {
                setUpdateOrderStatus(!updateOrderStatus);
              }}
            />
          }
          message={'Update Order Status'}
        />
        <Popover
          component={<CreateInvoiceIcon opacity='0.3' />}
          message={'Print packing slip'}
        />

        <Popover
          component={
            <ShippingLabelIcon
              sx={{
                opacity: order.delivery_selection.type !== 'ship' && 0.3,
              }}
              onClick={() => handleModal()}
            />
          }
          message={'Print Shipping Label'}
        />

        <Popover
          component={<MessageIcon opacity='0.3' />}
          message={'Send Message to Customer'}
        />
        <Popover
          component={
            <CancelOrderIcon
              onClick={() => {
                setCancelledOpen(true);
                setValue('order_status', 'cancelled');
              }}
            />
          }
          message={'Cancel order'}
        />
      </Stack>
      <Typography variant='p' component='p'>
        Status: <b>{order.order_status}</b>
      </Typography>
      {cancelledOpen ? (
        <CancelOrder
          handleCancel={handleCancel}
          setCancelledOpen={setCancelledOpen}
          order={order}
          setFarmOrders={setFarmOrders}
        />
      ) : null}

      <UpdateOrderModal
        order={order}
        open={updatedOpen === order.id}
        setOpen={setUpdatedOpen}
        setFarmOrders={setFarmOrders}
      />
      <UpdateOrderStatusModal
        order={order}
        open={updateOrderStatus}
        setOpen={setUpdateOrderStatus}
      />
    </Stack>
  );
};
export default OrderIcons;
