import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { useFormContext } from 'react-hook-form';

const SearchBar = (props) => {
  const { title, name } = props;
  const {register, handleSubmit, watch, formState: {errors}} = useFormContext()

  return (
    <Stack >
      <FormControl>
        <TextField
          label={title}
          id='outlined-start-adornment'
          sx={{ m:1,  width: '250px'}}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon size="small"/>
              </InputAdornment>
            ),
          }}
          {...register(name)}
        />
      </FormControl>
    </Stack>
  );
};

export default SearchBar;
