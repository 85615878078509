import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';

import * as act from '../../../store/actions';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography"
import ReturnProducts from '../components/returns/returnProducts';
import ReturnReason from '../components/returns/returnReason';
import ReturnImages from '../components/returns/returnImages';
import ReturnButtons from '../components/returns/returnButtons';
import customId from 'custom-id';

const Returns = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const order = useSelector((state) => state.OrderReducer.completedOrder);
  const id = params.component;

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      return_products: [],
      return_images: [],
      reason: '',
    },
  });

  const { watch } = methods;

  useEffect(() => {
    dispatch(act.getCompletedOrder(id));   
  }, []);

  const values = watch();

 
  const handleReturn = (e) => {
    const data = {
      customer_id: order.customer_id,
      farm_id: order.farm_id,
      order_id: order.id,
      order_date: order.created_date,
      id: customId({}),
      created_date: new Date(),
      status: 'refund requested',
      images: values.return_images,
      reason: values.reason,
      products: values.return_products,
    };
    let updateInfo = {
      order_status: 'refund requested',
      id: id,
    };

    act.addReturn(data);
    act.updateOrderStatus(updateInfo);
    setTimeout(() => {
      history.push('/dashboard/history');
    }, 1000);
  };

  return (
    <Stack marginTop='50px'>
      {order && (
        <FormProvider {...methods}>
          <Typography variant='h3'>Select items for Refund</Typography>
          {order?.products?.map(product => <ReturnProducts product={product} />)}         
          <ReturnReason />
          <ReturnImages />
          <ReturnButtons history={history} handleReturn={handleReturn} />
        </FormProvider>
      )}
    </Stack>
  );
};

export default Returns;
