import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import PrimaryButton from '../../../../../inputs/Buttons/Button';
import * as act from '../../../../../../store/actions';
import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import DialogModal from '../../../../../display/dialog';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { range } from '../../../../../../util';

const UpdateOrderModal = (props) => {
  const { open, setOpen, setFarmOrders, order } = props;

  const [products, setProducts] = useState([]);
  const [error, setError] = useState();
  const { watch, register, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    revalidationMode: 'onBlur',
    defaultValues: {
      order,
      original_payment: order.original_payment ? order.original_payment : [],
      original_products: order.original_products ? order.original_products : [],
    },
  });
  const values = watch();

  useEffect(() => {
    const array = [];
    order.products.map(async (product) => {
      const response = await act.getProduct(product?.id);
      response.quantity = product.quantity;
      array.push(response);
    });
    setProducts(array);
    setValue('order.original_payment', [
      ...values.original_payment,
      order.payment,
    ]);
    setValue('order.original_products', [
      ...values.original_products,
      order.products,
    ]);
  }, []);

  const handleUpdatedOrder = () => {
    setError();
    const data = values.order;
    if (!data.payment.refund) {
      data.payment.refund = [];
    }
    const options = {
      id: data.id,
      payment_intent: data.original_payment[0].payment_intent,
      amount: values.refundAmount,
      reason: 'requested_by_customer',
      transfer_id: data.original_payment[0].transfer.id,
      order: data,
      stateSetter: setFarmOrders,
      setError: setError,
      setOpen: setOpen,
    };
    act.sendRefund(options);
  };

  const updateQuantity = (e) => {
    setValue(`${e.target.name}`, e.target.value);
    let amount = 0;
    values.order.products.map((product) => {
      return (amount += product.price * parseInt(product.quantity));
    });
    setValue('order.payment.subtotal', amount);
    setValue(
      'order.payment.total',
      amount +
        values.order.original_payment[values.order.original_payment.length - 1]
          .shipping
    );
    setValue('order.order_status', 'updated');
    setValue(
      'refundAmount',
      (
        values?.order.original_payment[values.order.original_payment.length - 1]
          .subtotal - values?.order.payment?.subtotal
      ).toFixed(2)
    );
  };

  const title = (
    <Typography variant='p' fontSize='20px'>
      Update Order Items
    </Typography>
  );

  const content = (
    <Stack>
      <Typography variant='p' component='p'>
        Refund Amount: {values.refundAmount}
      </Typography>

      {products.map((product, i) => {
        return (
          <Stack
            key={product?.id}
            direction='row'
            height='60px'
            justifyContent='space-between'
            padding='20px 0'
            sx={({ breakpoints }) => ({ width: '100%' })}
          >
            <Stack height='fit-content' width='50%'>
              <Typography variant='h6'>{product?.name}</Typography>
              <Typography variant='p' component='p'>
                Price:{' '}
                <b>
                  ${product?.price}/{product?.label}
                </b>
              </Typography>
            </Stack>
            <Stack width='50%'>
              <FormControl>
                <Select
                  size='small'
                  {...register(`order.products[${i}].quantity`)}
                  value={values.order.products[i].quantity}
                  onChange={(e) => updateQuantity(e)}
                >
                  {range.map((num) => {
                    return (
                      <MenuItem key={num} value={num}>
                        {num} {product.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );

  const actions = (
    <Stack direction='row'>
      <PrimaryButton
        width='250px'
        margin='20px 10px 0 0'
        onClick={() => handleUpdatedOrder()}
      >
        Save
      </PrimaryButton>
      <PrimaryButton
        width='250px'
        margin='20px 0 0 10px'
        onClick={() => setOpen(false)}
      >
        Cancel
      </PrimaryButton>
    </Stack>
  );

  return (
    <DialogModal
      title={title}
      content={content}
      actions={actions}
      open={open}
    />
  );
};

export default UpdateOrderModal;
