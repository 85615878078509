import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import UseFormCheckbox from '../../../inputs/CheckBox/UseFormCheckbox';
import UseFormTextField from '../../../inputs/TextFields/UseFormTextField';

const Deliver = (props) => {
  const {
    watch,
    setValue,
    setError,
    clearErrors,
    trigger,
    unregister,
    formState: { errors },
  } = useFormContext({
    mode: 'onBlur',
    reValidationMode: 'onChange',
  });

  const { farm, name, setFarm } = props;
  const deliver = watch(`${name}`);

  const appendDeliver = (value) => {
    if (value.length > 5 || value.length < 5) {
      setError('delivery_methods.zip_codes', {
        type: 'length',
        message: 'Zip code must be 5 digits',
      });
      return;
    } else {
      clearErrors('delivery_methods.zip_codes');
      let array = farm.delivery_methods.zip_codes;
      const found = array.some((field) => field === value);
      if (!found) {
        let newArray = [...array, value];
        setValue(`delivery_methods.zip_codes`, newArray);

        setFarm({
          ...farm,
          delivery_methods: {
            ...farm.delivery_methods,
            zip_codes: newArray,
          },
        });
      }
    }
  };
  useEffect(() => {
    if (!deliver) {
      unregister('delivery_methods.zip_codes');
    } else {
      setValue('delivery_methods.zip_codes', []);
    }
  }, [deliver]);

  const handleCheck = (value) => {
    setValue('delivery_methods.deliver', !value);
    setFarm({
      ...farm,
      delivery_methods: {
        ...farm.delivery_methods,
        deliver: !value,
      },
    });
    if (value) {
      setValue('delivery_methods.zip_codes', []);
      setValue('delivery_methods.delivery_fee', 0);
      unregister('delivery_methods.zip_codes');
      unregister('delivery_methods.delivery_fee');
      setFarm({
        ...farm,
        delivery_methods: {
          ...farm.delivery_methods,
          zip_codes: [],
          deliver: false,
          delivery_fee: 0,
        },
      });
    }
    trigger()
  };

  const addDeliveryFee = (e) => {
    if (isNaN(e.target.value)) {
      setError('delivery_methods.delivery_fee', {
        type: 'value',
        message: 'Delivery Fee must be a number',
      });
      setValue('delivery_methods.delivery_fee', 0);
    } else {
      setValue(e.target.name, Number(e.target.value));
      setFarm({
        ...farm,
        delivery_methods: {
          ...farm.delivery_methods,
          delivery_fee: Number(e.target.value),
        },
      });
    }
  };

  const removeDeliver = (index) => {
    let zips = farm.delivery_methods.zip_codes;
    zips.splice(index, 1);
    setValue('delivery_methods.zip_codes', zips);
    setFarm({
      ...farm,
      delivery_methods: {
        ...farm.delivery_methods,
        zip_codes: zips,
      },
    });
    trigger();
  };

  return (
    <Stack sx={{ margin: deliver && '50px 0' }}>
      <UseFormCheckbox
        name='delivery_methods.deliver'
        value={farm.delivery_methods.deliver}
        onChange={(e) => handleCheck(farm.delivery_methods.deliver)}
        label={'Home Delivery'}
      />
      {deliver ? (
        <Stack>
          <Stack direction='row' justifyContent='space-between'>
            <UseFormTextField
              label={
                errors?.delivery_methods?.zip_codes ? (
                  <Typography variant='error'>
                    {errors?.delivery_methods.zip_codes?.message}
                  </Typography>
                ) : (
                  'Enter Delivery Zip Codes'
                )
              }
              width='49%'
              inputProps={{
                maxLength: 5,
              }}
              name='delivery_methods.zip_codes'
              rules={{
                validate: {
                  length: (value) =>
                    value.length > 0 || 'Must enter at least one zip code',
                },
                maxLength: {
                  value: 5,
                  message: 'Zip Codes must be 5 digits',
                },
                minLength: {
                  value: 5,
                  message: 'Zip Codes must be 5 digits',
                },
              }}
              onInput={() => null}
              onChange={() => null}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  appendDeliver(e.target.value);
                  e.target.value = '';
                }
              }}
            />
            <UseFormTextField
              name='delivery_methods.delivery_fee'
              rules={{
                shouldUnregister: true,
                valueAsNumber: true,
              }}
              label={
                errors?.delivery_methods?.delivery_fee ? (
                  <Typography variant='error'>
                    {errors?.delivery_methods.delivery_fee?.message}
                  </Typography>
                ) : (
                  'Delivery Fee (if applicable)'
                )
              }
              onKeyDown={(e) => {
                addDeliveryFee(e);
              }}
              onInput={(e) => {
                addDeliveryFee(e);
              }}
              onChange={() => null}
              width='49%'
              value={farm.delivery_methods?.delivery_fee}
            />
          </Stack>
          {farm.delivery_methods.zip_codes ? (
            <Stack
              direction='row'
              flexWrap='wrap'
              marginTop='10px'
              sx={{
                gap: 0.5,
              }}
            >
              {farm.delivery_methods.zip_codes.map((value, i) => (
                <Chip
                  key={value}
                  label={value}
                  onDelete={() => removeDeliver(i)}
                />
              ))}
            </Stack>
          ) : null}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default Deliver;
