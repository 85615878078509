import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


const UseFormCheckbox = (props) => {
  const { name, value, onChange, rules, label } = props;

  const { register } = useFormContext({
    mode: 'onBlur',
    revalidationMode: 'onChange',
  });

  return (
    <FormControlLabel
      sx={{ fontSize: '16px' }}
      {...register(name, {rules})}
      value={value}
      onChange={(e) => onChange(e)}
      control={<Checkbox checked={value} size='medium' color='primary' />}
      label={label}
    />
  );
};

export default UseFormCheckbox;
