
const components = {
  MuiLink: {
    styleOverrides: {
      root: {
        fontSize: '16px',
        letterSpacing: '1.75px',
      },
    },
  },
 
  MuiBox: {
    styleOverrides: {
      root: {
      
        width: '100%',
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        margin: '0 auto',
        width: '100%',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        width: '150px',
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        margin: '10px 0',
        fontSize: '1rem',
      },
    },
  },
  MuiImageListItemBar: {
    styleOverrides: {
      root: {
        background: 'rgba(250, 249, 249, 0.4)',
      },
      title: {
        whiteSpace: 'normal',
        color: 'black',
        fontSize: '18px',
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        fontSize: '1rem',
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      colorPrimary: {
        backgroundColor: 'white',
      },
    },
  },
  // MuiDrawer: {
  //   styleOverrides: {
     
  //     paper: {
  //       zIndex: 0,
  //     },
  //   },
  // },
  MuiRadio: {
    styleOverrides: {
      colorPrimary: {
        color: '#0054bc',
        '&.Mui-checked': {
          color: '#0054bc',
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      colorPrimary: {
        color: '#0054bc',
        '&.Mui-checked': {
          color: '#0054bc',
        },
      },
    },
  },
};

export default components;