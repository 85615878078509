import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/configureStore";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CssBaseline } from "@mui/material";
import theme from './theme/theme';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY_LIVE);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <PersistGate persistor={persistor}>
        <MuiThemeProvider theme={theme}>
          <Elements stripe={stripePromise}>
            <CssBaseline>
              <App />
            </CssBaseline>
          </Elements>
        </MuiThemeProvider>
      </PersistGate>
    </Router>
  </Provider>,
  document.getElementById('root')
);
