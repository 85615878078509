import React from "react";
import { useSelector } from "react-redux";
import DialogModal from "../../../display/dialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PrimaryButton from "../../../inputs/Buttons/Button";

const PasswordChangeModal = (props) => {
  const { open, setOpen } = props;
  const message = useSelector(state => state.UserReducer.changePassword)

  const title = (
    <Stack>Success</Stack>
  )

  const content = (
    <Typography>{message}</Typography>
  )

  const actions = (
    <PrimaryButton width='100px' onClick={() => setOpen(false)}>Close</PrimaryButton>
  )

  return (
  <DialogModal open={open} title={title} content={content} actions={actions}/>
  )
}

export default PasswordChangeModal;