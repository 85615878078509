import React, { useState } from 'react';
import ReturnApproved from './ReturnApproved';
import ReturnDeclined from './ReturnDeclined';
import Stack from '@mui/material/Stack';
import PrimaryButton from '../../../../inputs/Buttons/Button';

const ReturnsButtons = (props) => {
  const { refund, order, setReturns, returns, id, users } = props;
  const [openApprove, setOpenApprove] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);

  return (
    <Stack
      height='100px'
      justifyContent='space-between'
      width='30%'
      margin='20px'
    >
      <PrimaryButton width='fit-content' onClick={() => setOpenApprove(true)}>
        Approve Refund
      </PrimaryButton>
      <PrimaryButton width='fit-content' onClick={() => setOpenDecline(true)}>
        Decline Refund
      </PrimaryButton>
      <ReturnApproved
        open={openApprove}
        setOpen={setOpenApprove}
        refund={refund}
        order={order}
        setReturns={setReturns}
        id={id}
        returns={returns}
        users={users}
      />
      <ReturnDeclined
        open={openDecline}
        setOpen={setOpenDecline}
        refund={refund}
        order={order}
        setReturns={setReturns}
        id={id}
        returns={returns}
        users={users}
      />
    </Stack>
  );
};
export default ReturnsButtons;
