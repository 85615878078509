import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Favorite from '../../customer/components/Favorite';
import ClearIcon from '@mui/icons-material/Clear';
import * as act from '../../../store/actions';

const ProductImage = (props) => {
  const { product, i, j, removeProduct } = props;
  const [item, setItem] = useState();

  useEffect(() => {
    act
      .getProduct(product?.id)
      .then((response) => {
        setItem(response);
      })
      .catch((err) => {
        console.dir(err);
      });
  }, [product]);

  return (
    <Stack padding='20px 0'>
      <img
        width='120px'
        height='84px'
        style={{ marginBottom: '10px' }}
        src={item?.images[0]}
        alt={item?.name}
      />
      <Stack direction='row' width='150px'>
        <Favorite item={product.id} type='products' />
        <Typography variant='p' component='p'>
          Add to favorites
        </Typography>
      </Stack>
      <Stack
        direction='row'
        width='150px'
        onClick={(e) => removeProduct(i, j)}
        sx={{ cursor: 'pointer' }}
      >
        <ClearIcon />
        <Typography variant='p' component='p'>
          Remove
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ProductImage;
