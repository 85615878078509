import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CustomerInfo from '../orders/CustomerInfo';
import * as act from "../../../../../store/actions"
import ViewReturnOrder from './ViewReturnOrder';;


const ReturnsHeader = (props) => {
  const { refund, order, setOrder } = props;
  const [open, setOpen] = useState(false);
  const [customer, setCustomer] = useState();

  useEffect(async () => {
    const returnOrder = await act.getReturnOrder(refund.order_id);
    setOrder(returnOrder.Item);
  }, []);

  const date = new Date(refund.order_date).toLocaleDateString();
  const refundDate = new Date(refund.created_date).toLocaleDateString();

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      backgroundColor='primary.backgroundColor'
      padding='20px'
    >
      <CustomerInfo
        customerID={refund.customer_id}
        customer={customer}
        setCustomer={setCustomer}
      />
      <Stack
        direction='row'
        width='60%'
        justifyContent='space-between'
        marginRight='100px'
      >
        <Stack>
          <Typography variant='p' component='p'>
            Order Date: <b>{date}</b>
          </Typography>
          <Typography variant='p' component='p'>
            Order Number: <b>{refund.order_id}</b>
          </Typography>
          <Typography variant='p' component='p'>
            Refund Date: <b>{refundDate}</b>
          </Typography>
          <Typography variant='p' component='p'>
            Refund Number: <b>{refund.id}</b>
          </Typography>
        </Stack>

        <Stack>
          <Typography
            variant='a'
            opacity='0.3'
            onClick={() => setOpen(refund.id)}
          >
            Order Details
          </Typography>
          <ViewReturnOrder
            refund={refund.order_id}
            open={open === refund.id}
            customerID={refund.customer_id}
            buttonDisplay='none'
            setOpen={setOpen}
            act={act}
          />

          <Typography variant='p' component='p'>
            Delivered:{'5/10/2010 '}
          </Typography>

          <Typography variant='p' component='p'>
            Refund Status:{' '}
            <Typography
              variant='p'
              sx={{
                color:
                  refund.status === 'refund approved'
                    ? 'primary.secondary'
                    : refund.status === 'refund declined'
                    ? 'primary.error'
                    : 'primary.darkFont',
              }}
            >
              <b>{refund.status}</b>
            </Typography>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default ReturnsHeader;
