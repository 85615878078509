import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { AddPhoto } from './addPhoto';
import { ProductDetails } from './productDetails';
import { PricingDetails } from './pricingDetails';
import Stack from "@mui/material/Stack";
import PrimaryButton from "../../../inputs/Buttons/Button"
import { useParams, useHistory } from 'react-router-dom';
import customId from 'custom-id';
import * as act from '../../../../store/actions';

const AddProduct = (props) => {
  const { farmProducts, setFarmProducts } = props;
  const [product, setProduct] = useState(
    useSelector((state) => state.ProductsReducer.newProduct)
  );
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const dietaryOptions = [
    'Vegetarian',
    'Vegan',
    'Pescetarian',
    'Keto',
    'Paleo',
    'DASH',
    'Non-GMO',
    'Organic',
    'Diabetic Safe',
    'Gluten-Free',
    'Kosher',
  ];

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      reviews: [],
      quantity: 0,
      product_status: 'active',
      dietary: [],
    },
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    setValue,
  } = methods;

  const handleChange = (e, option) => {
    let value = e.target.value;
    if (option === 'number') {
      value = Number(e.target.value);
    }
    setValue(e.target.name, e.target.value);
    setProduct({
      ...product,
      [e.target.name]: value,
    });
  };

  const doSubmit = async (e) => {
    if (!isValid) {
      trigger();
    }
    e.preventDefault();
    product.id = customId({});
    product.farm_id = params.id;

    dispatch(act.addNewProduct(product));
    setFarmProducts({
      ...farmProducts,
      active: [...farmProducts.active, product],
    });

    history.push(`/dashboard/listings/active_listings/${product.farm_id}`);
  };

  return (
    <FormProvider {...methods}>
      <Stack marginTop="50px" width="80%">
        <form onSubmit={(e) => doSubmit(e)}>
          <AddPhoto errors={errors} product={product} setProduct={setProduct} />
          <ProductDetails
            errors={errors}
            product={product}
            setProduct={setProduct}
            handleChange={handleChange}
            dietary={dietaryOptions}
          />
          <PricingDetails
            product={product}
            errors={errors}
            setProduct={setProduct}
            handleChange={handleChange}
          />
          <Stack direction="row" justifyContent='space-between' width="40%" marginTop="20px">
            <PrimaryButton
              type='button'
              onClick={(e) => handleSubmit(doSubmit(e, product))}
            >
              Save
            </PrimaryButton>
            <PrimaryButton
              
              type='button'
              onClick={() => history.goBack()}
            >
              Cancel{' '}
            </PrimaryButton>
          </Stack>
        </form>
      </Stack>
    </FormProvider>
  );
};

export default AddProduct;
