import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import Typography from "@mui/material/Typography";
import {useHistory} from "react-router-dom"

const Block = (props) => {
  const history = useHistory();

  return (
    <Accordion
      elevation={0}
      disableGutters
      sx={{
        bgcolor: 'primary.formOutline2',
        border: 'none',
        marginTop: '-10px',
      }}
    >
      <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
        <Typography>Block</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          sx={{ cursor: 'pointer', color: 'rgba(0, 111, 69, .50)' }}
          onClick={() => history.push(`/dashboard/block-customer`)}
        >
          Block Customer
        </Typography>
        <Typography
          sx={{ cursor: 'pointer' }}
          onClick={() => history.push(`/dashboard/block-farm`)}
        >
          Block Farm
        </Typography>
        <Typography
          sx={{ cursor: 'pointer' }}
          onClick={() => history.push(`/dashboard/block-farmer`)}
        >
          Block Farmer
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default Block;
