import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom';
import { FarmLogo } from '../components/FarmLogo';
import FarmStandButtons from '../components/farmStand/FarmStandButtons';
import * as act from '../../../store/actions';
import Typography from '@mui/material/Typography';
import FarmTabs from '../components/farmStand/FarmTabs';

const FarmStand = (props) => {
  const [farm, setFarm] = useState();
  const [error, setError] = useState();
  const { id } = props;

  useEffect(() => {
      act
        .getFarmById(id)
        .then((response) => {
          setFarm(response);
        })
        .catch((e) => {
          setError(e);
        });
    
  }, [id]);
 
  return (
    <Stack marginTop='50px'>
      {farm ? (
        <FarmLogo
          nameFontSize='28px'
          ratingSize='medium'
          ratingFontSize='16px'
          farm={farm}
          milesDisplay='none'
          cityFontSize='22px'
          linkDisplay='none'
          imgSize={{ width: '84px', height: '84px' }}
        />
      ) : null}
      {error && <Typography variant='error'>{error}</Typography>}
      <FarmStandButtons farm={farm} />
      <FarmTabs farm={farm} id={id} />
    </Stack>
  );
};
export default FarmStand;
