import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";

const CustomerFAQs = (props) => {
const { expanded, handleChange } = props;
  
  return (
    <Stack margin="20px">
      <Typography variant='h4' marginBottom="20px">Customer FAQs</Typography>

      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
          <Typography
            color='primary.secondary'
            sx={{
              fontSize: '18px',
            }}
          >
            How does Farm Era work
          </Typography>
        </AccordionSummary>
        <AccordionDetails className='accordionDetails'>
          <Typography>
            Farm Era Market brings the farmer's market to your front door! Farm
            Era Market is your direct-to-farm food and products marketplace. Our
            platform allows you to order fresh foods from any farmer in our
            marketplace. You can search for a particular food item, browse by
            farms closest to you, or even browse farms located further away.
            Orders can be scheduled for pickup, delivery, or shipped directly to
            you.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
          <Typography
            color='primary.secondary'
            sx={{
              fontSize: '18px',
            }}
          >
            Is there a money-back guarantee
          </Typography>
        </AccordionSummary>
        <AccordionDetails className='accordionDetails'>
          <Typography>
            You can request a refund within five(5) days of receiving
            your order. Initiate a refund by accessing your previous orders and
            selecting the refund option under the item you would like to refund.
            Refunds issued get returned to the payment method used when placing
            your order.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
          <Typography
            color='primary.secondary'
            sx={{
              fontSize: '18px',
            }}
          >
            How can I contact Farm Era Market customer service
          </Typography>
        </AccordionSummary>
        <AccordionDetails className='accordionDetails'>
          <Typography>
            You can reach out to us via email at hello@farmeramarket.com or
            submit a help request by opening the help desk widget found on the
            bottom right side of the screen. We are available during regular
            business hours, 9-5 pm ET Monday through Friday. Response times may
            vary depending on availability.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
      >
        <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
          <Typography
            color='primary.secondary'
            sx={{
              fontSize: '18px',
            }}
          >
            How do I get started
          </Typography>
        </AccordionSummary>
        <AccordionDetails className='accordionDetails'>
          <Typography>
            Setting up an account is easy! There are two ways to get started.
            Select the Account icon on the top right of the search bar and click
            on the “Join Today” sign-up link. Or start by browsing and filling
            your basket with items found in our marketplace; our platform will
            require you to sign up before checking out.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default CustomerFAQs;
