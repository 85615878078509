import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as act from '../../../../store/actions';
import { API } from 'aws-amplify';
import { AddPhoto } from './addPhoto';
import { ProductDetails } from './productDetails';
import { PricingDetails } from './pricingDetails';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import Stack from "@mui/material/Stack"

const EditProduct = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [product, setProduct] = useState();
 
  
  useEffect(() => {
    API.get('FarmEraMarketAPI', `/product/${params.component}`)
      .then((response) => {
        setProduct(response);
        setValue('images', response.images);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (e, option) => {
    let value = e.target.value;

    if (option === 'number') {
      value = Number(e.target.value);
    }
    setValue(e.target.name, e.target.value);
    setProduct({
      ...product,
      [e.target.name]: value,
    });
  };

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      reviews: !!product ? product.reviews : [],
      quantity: 0,
      product_status: !!product ? product.product_status : 'active',
      dietary: !!product ? product.dietary : [],
      images: !!product ? product.images : [],
    },
  });

  const {
    handleSubmit,
    trigger,
    formState: { errors, isValid },
    setValue,
  } = methods;

  const doSubmit = async (e) => {
    if (!isValid) {
      trigger();
    } else {
      e.preventDefault();
      product.farm_id = params.id;
      dispatch(act.addNewProduct(product));
      history.push(`/dashboard/listings/active_listings/${product.farm_id}`);
    }
  };

  return (
    <FormProvider {...methods}>
      <Stack marginTop='50px' width="80%">
        {!!product ? (
          <form
            
            onSubmit={(e) => handleSubmit(doSubmit(e))}
          >
            <AddPhoto
              errors={errors}
              product={product}
              setProduct={setProduct}
            />
            <ProductDetails
              errors={errors}
              product={product}
              setProduct={setProduct}
              handleChange={handleChange}
            />
            <PricingDetails
              product={product}
              errors={errors}
              setProduct={setProduct}
              handleChange={handleChange}
            />
            <Box className='primaryButton mediumButton buttonRow'>
              <Button
                type='button'
                onClick={(e) => handleSubmit(doSubmit(e, product))}
              >
                Save
              </Button>
              <Button type='button' onClick={() => history.goBack()}>
                Cancel
              </Button>
            </Box>
          </form>
        ) : null}
      </Stack>
    </FormProvider>
  );
};

export default EditProduct;
