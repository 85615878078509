import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ListingsIcon } from '../../assets/farmIconImages';

const Listings = (props) => {
  const { farm, history } = props;

  return (
    <Accordion
      elevation={0}
      sx={{ bgcolor: 'primary.formOutline2', border: 'none' }}
    >
      <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
        <ListItemIcon sx={{ minWidth: '30px' }}>
          <ListingsIcon {...props} />
        </ListItemIcon>
        <Typography
          color='primary.secondary'
          sx={{
            fontSize: '18px',
          }}
        >
          Listings
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingLeft: '15px' }}>
        <Typography
          sx={{
            fontSize: '16px',
            margin: '5px 0 0 15px',
            color: 'primary.secondary',
          }}
          onClick={() =>
            history.push(`/dashboard/listings/active_listings/${farm.id}`)
          }
        >
          Active Listings
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            margin: '5px 0 0 15px',
            color: 'primary.secondary',
          }}
          onClick={() =>
            history.push(`/dashboard/listings/inactive_listings/${farm.id}`)
          }
        >
          Inactive Listings
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            margin: '5px 0 0 15px',
            color: 'primary.secondary',
          }}
          onClick={() =>
            history.push(`/dashboard/listings/sold_out/${farm.id}`)
          }
        >
          Sold Out
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default Listings;
