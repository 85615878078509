import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as act from "../../../store/actions"
import Orders from '../components/dashboard/orders/Orders';
import Returns from "../pages/Returns"

const Components = (props) => {
  const params = useParams();
  const [farmOrders, setFarmOrders] = useState({
    open: [],
    completed: [],
  });
 

  useEffect(() => {
    act.getFarmOrders(params.id, setFarmOrders)
     .then((response) => {
      let open = [];
      let completed = [];
      response.map((order) => {
        return order.order_status === 'new' ||  order.order_status === 'pending' ||order.order_status === 'processing' || order.order_status === 'updated'
          ? open.push(order)
          :
         completed.push(order)
       
      });
      setFarmOrders({
        open: open,
        completed: completed,
      });
    })
    .catch((err) => {
      console.dir(err);
    });
  }, [params.id]);
  useEffect(() => {

  }, [farmOrders])
 
  switch (params.component) {
    case 'open':
      return (
        <Orders
          orders={farmOrders.open}
          setFarmOrders={setFarmOrders}
          {...props}
        />
      );
    case 'completed':
      return <Orders orders={farmOrders.completed} {...props} />;
    case 'returns':
      return<Returns/>;
    default:
      return;
  }
};

const FarmOrders = (props) => {
  return <Components {...props} />;
};

export default FarmOrders;
