import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { stateAbbrev} from '../../../util/data';
import ReactPhoneInput from 'react-phone-input-mui';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';


import Typography from '@mui/material/Typography';
import State from "../../misc/components/state"




const ContactInfo = (props) => {
  const [user, setUser] = useState(props.user);

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext({
   
  });

  const values = watch();
  
  const handleInput = (e) => {
    setUser({
      ...user,
      user_metadata: {
        ...user.user_metadata,
        phone: e.target.value,
      },
    });
    setValue(`${e.target.name}`, e.target.value);
  };
  const billingSame = watch('billingSame');

  const createPhoneLabel = () => {
    if (errors?.addressTo?.phone) {
      return (
        <Typography variant='error'>
          {errors.addressTo.phone.message}
        </Typography>
      );
    } else {
      return 'Phone Number';
    }
  };
  return (
    <Stack margin='20px 0' width='100%'>
      <Stack direction='row' justifyContent='space-between'>
        <TextField
          {...register('addressTo.first_name', {
            required: 'First Name is required',
          })}
          label={
            errors?.addressTo?.first_name ? (
              <Typography variant='error'>
                {errors.addressTo.first_name.message}
              </Typography>
            ) : (
              'First Name'
            )
          }
          defaultValue={user.user_metadata.first_name}
          sx={{ width: '49%' }}
        />
        <TextField
          {...register('addressTo.last_name', {
            required: 'Last Name is required',
          })}
          label={
            errors?.addressTo?.last_name ? (
              <Typography variant='error'>
                {errors.addressTo.last_name.message}
              </Typography>
            ) : (
              'Last Name'
            )
          }
          defaultValue={user.user_metadata.last_name}
          sx={{ width: '49%' }}
        />
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <TextField
          {...register('addressTo.email', {
            required: 'Email is required',
          })}
          label={
            errors?.addressTo?.email ? (
              <Typography variant='error'>
                {errors.addressTo.email.message}
              </Typography>
            ) : (
              'Email Address'
            )
          }
          defaultValue={user.email}
          sx={{ width: '49%' }}
        />
        <ReactPhoneInput
          {...register('addressTo.phone', {
            required: 'Phone is required',
          })}
          id='phone'
          component={TextField}
          placeholder={createPhoneLabel()}
          disableCountry={['us']}
          inputExtraProps={{
            name: 'addressTo.phone',
            margin: 'normal',
            autoComplete: 'phone',
            width: '100%',
            label: createPhoneLabel(),
          }}
          onlyCountries={['us']}
          containerStyle={{ width: '49%' }}
          value={user.user_metadata.phone}
          defaultValue={user.user_metadata.phone}
          onChange={(e) => {
            handleInput({
              target: {
                name: 'addressTo.phone',
                value: e,
              },
            });
          }}
        />
      </Stack>
      <TextField
        {...register('addressTo.street1', {
          required: 'Address is required',
        })}
        label={
          errors?.addressTo?.street1 ? (
            <Typography variant='error'>
              {errors.addressTo.street1.message}
            </Typography>
          ) : (
            'Street Address'
          )
        }
        defaultValue={user.user_metadata.address}
        sx={{ width: '100%' }}
      />
      <Stack direction='row' justifyContent='space-between'>
        <TextField
          {...register('addressTo.street2')}
          label={'Apt #, floor, etc (optional)'}
          defaultValue={user.user_metadata.apt}
          sx={{ width: '49%' }}
        />
        <TextField
          {...register('addressTo.zip', {
            required: 'Zip code is required',
            maxLength: {
              value: 5,
            },
          })}
          label={
            errors?.addressTo?.zip ? (
              <Typography variant='error'>
                {errors.addressTo.zip.message}
              </Typography>
            ) : (
              'Zip Code'
            )
          }
          maxLength={5}
          defaultValue={user.user_metadata.zip}
          sx={{ width: '49%' }}
        />
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <TextField
          {...register('addressTo.city', {
            required: 'City is required',
          })}
          label={
            errors?.addressTo?.city ? (
              <Typography variant='error'>
                {errors.addressTo.city.message}
              </Typography>
            ) : (
              'City'
            )
          }
          defaultValue={user.user_metadata.city}
          sx={{ width: '49%' }}
        />
        <State
          name='addressTo.state'
          style='mediumSelect'
          width='49%'
          errorName={'errors.addressTo.state'}
          onClick={(value) => {
            setValue('state', value);
            setUser({
              ...user,
              state: value
            })
          }}
          defaultValue={
            user?.user_metadata?.state
              ? user.user_metadata.state
              : stateAbbrev[0]
          }
        />
      </Stack>
      
     
    </Stack>
  );
};

export default ContactInfo;
