import { SvgIcon } from '@mui/material';

export const MyAccountIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.75 9.75C6.75 11.1424 7.30312 12.4777 8.28769 13.4623C9.27226 14.4469 10.6076 15 12 15C13.3924 15 14.7277 14.4469 15.7123 13.4623C16.6969 12.4777 17.25 11.1424 17.25 9.75C17.25 8.35761 16.6969 7.02226 15.7123 6.03769C14.7277 5.05312 13.3924 4.5 12 4.5C10.6076 4.5 9.27226 5.05312 8.28769 6.03769C7.30312 7.02226 6.75 8.35761 6.75 9.75V9.75Z'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M18.9119 20.8761C18.0062 19.9647 16.9291 19.2413 15.7427 18.7477C14.5562 18.2541 13.2839 18 11.9989 18C10.7139 18 9.44163 18.2541 8.25522 18.7477C7.06881 19.2413 5.99172 19.9647 5.08594 20.8761'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M0.75 12C0.75 14.9837 1.93526 17.8452 4.04505 19.955C6.15483 22.0647 9.01631 23.25 12 23.25C14.9837 23.25 17.8452 22.0647 19.955 19.955C22.0647 17.8452 23.25 14.9837 23.25 12C23.25 9.01631 22.0647 6.15483 19.955 4.04505C17.8452 1.93526 14.9837 0.75 12 0.75C9.01631 0.75 6.15483 1.93526 4.04505 4.04505C1.93526 6.15483 0.75 9.01631 0.75 12V12Z'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}

export const ProfileIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width='36'
      height='40'
      viewBox='0 0 36 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.75 1.25H34.25V38.75H1.75V1.25Z'
        stroke='#494646'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M11.1484 15.1391C11.1484 16.0386 11.3256 16.9294 11.6699 17.7604C12.0141 18.5915 12.5187 19.3467 13.1548 19.9827C13.7908 20.6188 14.546 21.1234 15.3771 21.4676C16.2081 21.8119 17.0989 21.9891 17.9984 21.9891C18.898 21.9891 19.7887 21.8119 20.6198 21.4676C21.4509 21.1234 22.206 20.6188 22.8421 19.9827C23.4782 19.3467 23.9828 18.5915 24.327 17.7604C24.6713 16.9294 24.8484 16.0386 24.8484 15.1391C24.8484 14.2395 24.6713 13.3488 24.327 12.5177C23.9828 11.6866 23.4782 10.9315 22.8421 10.2954C22.206 9.6593 21.4509 9.15473 20.6198 8.81049C19.7887 8.46624 18.898 8.28906 17.9984 8.28906C17.0989 8.28906 16.2081 8.46624 15.3771 8.81049C14.546 9.15473 13.7908 9.6593 13.1548 10.2954C12.5187 10.9315 12.0141 11.6866 11.6699 12.5177C11.3256 13.3488 11.1484 14.2395 11.1484 15.1391V15.1391Z'
        stroke='#006F45'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M29.25 31.7117C28.1705 29.6632 26.5521 27.9484 24.5696 26.7522C22.587 25.556 20.3155 24.9238 18 24.9238C15.6845 24.9238 13.413 25.556 11.4304 26.7522C9.44787 27.9484 7.82954 29.6632 6.75 31.7117'
        stroke='#006F45'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
};

export const CustomerOrderPurchases = (props) => {
  return (
    <SvgIcon
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 0.75L0.75 5.25L12 9.75L23.25 5.25L12 0.75Z'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M0.75 5.25V18.75L12 23.25V9.75L0.75 5.25Z'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M23.25 5.25V18.75L12 23.25V9.75L23.25 5.25Z'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M18.1875 7.27539L6.9375 2.77539'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M20.625 16.5L18.75 17.25'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
};

export const CustomerMessages = (props) => {
  return (
    <SvgIcon
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.75 18.75H11.25L5.25 23.25V18.75H2.25C1.85218 18.75 1.47064 18.592 1.18934 18.3107C0.908035 18.0294 0.75 17.6478 0.75 17.25V2.25C0.75 1.85218 0.908035 1.47064 1.18934 1.18934C1.47064 0.908035 1.85218 0.75 2.25 0.75H21.75C22.1478 0.75 22.5294 0.908035 22.8107 1.18934C23.092 1.47064 23.25 1.85218 23.25 2.25V17.25C23.25 17.6478 23.092 18.0294 22.8107 18.3107C22.5294 18.592 22.1478 18.75 21.75 18.75Z'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
};

export const CustomerFav = (props) => {
  return (
    <SvgIcon
      {...props}
      width='24'
      height='22'
      viewBox='0 0 24 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.0009 20.8446L2.41287 10.8446C1.57021 10.0026 1.01404 8.91664 0.823235 7.74078C0.632434 6.56492 0.816691 5.35883 1.34987 4.29358V4.29358C1.75193 3.4897 2.33924 2.79285 3.06342 2.26046C3.7876 1.72807 4.62791 1.37537 5.51513 1.23143C6.40234 1.08748 7.31107 1.15641 8.16642 1.43252C9.02178 1.70864 9.79928 2.18405 10.4349 2.81958L12.0009 4.38458L13.5669 2.81958C14.2025 2.18405 14.98 1.70864 15.8353 1.43252C16.6907 1.15641 17.5994 1.08748 18.4866 1.23143C19.3738 1.37537 20.2141 1.72807 20.9383 2.26046C21.6625 2.79285 22.2498 3.4897 22.6519 4.29358C23.1843 5.35842 23.3683 6.56379 23.1777 7.73898C22.9871 8.91417 22.4316 9.99961 21.5899 10.8416L12.0009 20.8446Z'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}

export const SignOutIcon = (props) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0)'>
        <path
          d='M7.49609 12.0039H23.2461'
          stroke='#353535'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
        <path
          d='M19.4961 15.7539L23.2461 12.0039L19.4961 8.25391'
          stroke='#353535'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
        <path
          d='M15.7476 16.5V21C15.7647 21.3796 15.6308 21.7505 15.3752 22.0316C15.1196 22.3127 14.7631 22.4811 14.3836 22.5H2.1106C1.73131 22.4808 1.37502 22.3123 1.11962 22.0312C0.864219 21.7501 0.730468 21.3794 0.747603 21V3C0.730202 2.62054 0.863861 2.24964 1.11932 1.96851C1.37477 1.68737 1.73121 1.51891 2.1106 1.5H14.3836C14.7631 1.51891 15.1196 1.68734 15.3752 1.96845C15.6308 2.24955 15.7647 2.62044 15.7476 3V7.5'
          stroke='#353535'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
