import React from 'react';
import { Box, Typography} from '@mui/material';
import { styled } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';

export default function BasicModal(props) {
  const { open, setOpen, message, TitleMessage, top, left, width, messageStyle, height } = props;

  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: top,
    left: left,
    transform: 'translate(-50%, -50%)',
    width: width,
    bgcolor: 'background.paper',
    color: 'primary.text',
    boxShadow: 24,
    height: height,
    overflow: 'scroll',
    p: 4,
  };

  const StyledModal = styled(ModalUnstyled)`
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const Backdrop = styled('div')`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
  `;

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      BackdropComponent={Backdrop}
    >
      <Box
        sx={style}

      >
        <Typography id='modal-modal-title' variant='h3'>
          {TitleMessage}
        </Typography>
        <Box id='modal-modal-description' sx={{ messageStyle }}>
          {message}
        </Box>
      </Box>
    </StyledModal>
  );
}
