import React from 'react';
import { TextField, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as act from '../../../../../store/actions';
import PrimaryButton from '../../../../inputs/Buttons/Button';
import Dialog from '../../../../display/dialog';

const ReturnDeclined = (props) => {
  const { refund, open, setOpen, setReturns, id, returns, users } = props;

  const { register, watch } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const values = watch();

  const content = (
    <Stack width='500px'>
      <TextField
        className='largeInput'
        {...register(`message`)}
        autoFocus
        defaultValue={values.message}
        label={'Reason for refund decline'}
      />
    </Stack>
  );
  const actions = (
    <Stack direction='row' justifyContent='space-between' width='70%'>
      <PrimaryButton onClick={() => refundDeclined()}>Confirm</PrimaryButton>
      <PrimaryButton onClick={() => setOpen(false)}>Cancel</PrimaryButton>
    </Stack>
  );

  const refundDeclined = () => {
    let refundObject = {
      refund: 'declined',
      message: values.message,
    };
    refund.order.payment.refund.push(refundObject);
    refund.order.status = 'refund declined';
    act.orderRefundUpdate(refund.order);
    refund.return.status = 'declined';
    act.updateRefund(refund.return, setReturns, returns, users);
    setOpen(false);
    act.getFarmReturns(id, setReturns, returns, users);
  };

  return (
    <Dialog
      open={open}
      top='50%'
      left='50%'
      title={'Refund Decline'}
      content={content}
      actions={actions}
    />
  );
};

export default ReturnDeclined;
