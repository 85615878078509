import React, { useState, useEffect } from 'react';
import { Box, Stepper, Step, StepButton } from '@mui/material';
import PrimaryButton from '../inputs/Buttons/Button';
import { useFormContext } from 'react-hook-form';
import Stack from '@mui/material/Stack';

export const FormStepper = (props) => {
  const {
    stepLabels,
    StepContent,
    extraElement,
    handleFinish,
    handleUpdate,
    processing,
    handleTrigger,    
  } = props;

  const {
    formState: { isValid },
    trigger,
  } = useFormContext();

  const [activeStep, setActiveStep] = useState(0);
  const steps = stepLabels;
  

  const totalSteps = () => {
    return steps.length;
  };
 
  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleClick = (e) => {
    if (handleUpdate && activeStep === 0) {
      handleUpdate();
    }
    if (handleTrigger) {
      handleTrigger();
    }
    !isValid ? trigger() : isLastStep() ? handleLastStep(e) : handleNext();
  };

  const handleLastStep = (e) => {
    handleFinish(e);
  
  };

  const handleNext = () => {
    const newActiveStep = isLastStep() ? totalSteps() - 1 : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ margin: '50px auto', width: '80%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton color='inherit' onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box>
        <React.Fragment>
          <Box className='contentBox'>
            {StepContent[activeStep]}
            {extraElement}
          </Box>
          <Stack direction='row' justifyContent='space-between'>
            <PrimaryButton
              color='inherit'
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </PrimaryButton>
           
              <PrimaryButton type='button' onClick={(e) => handleClick(e)} disabled={!isValid}>
                {processing ? 'Processing' : isLastStep() ? 'Finish' : 'Next'}
              </PrimaryButton>
           
          </Stack>
        </React.Fragment>
      </Box>
    </Box>
  );
};

export default FormStepper;
