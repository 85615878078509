import React from "react";
import {Box, Typography} from "@mui/material"
import {
  ShippingAvailable,
  PickupAvailable,
  DeliveryAvailable,
} from '../../assets/imageList';


const ProductCardFarmDelivery = (props) => {
  const { farm, deliveryDisplay } = props;
  return (
    <Box
      sx={{
        display: deliveryDisplay,
        flexDirection: 'column',
        width: '150px',
        my: 1.5,
      }}
    >
      {farm.delivery_methods.pickup ? (
        <Typography
          variant='p'
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '138px',
            mb: 1,
            fontSize: '14px',
          }}
        >
          <PickupAvailable sx={{ fontSize: '18px' }} />
          Pickup Available
        </Typography>
      ) : null}
      {farm.delivery_methods.ship ? (
        <Typography
          variant='p'
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1,
            fontSize: '14px',
          }}
        >
          <ShippingAvailable sx={{ fontSize: '18px' }} />
          Shipping Available
        </Typography>
      ) : null}
      {farm.delivery_methods.deliver ? (
        <Typography
          variant='p'
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '142px',
            fontSize: '14px',
            mb: 1,
          }}
        >
          <DeliveryAvailable sx={{ fontSize: '18px' }} />
          {/* <img src={deliveryAvailable} alt="" /> */}
          Delivery to home
        </Typography>
      ) : null}
    </Box>
  );
}
export default ProductCardFarmDelivery;