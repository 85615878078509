import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from "@mui/material/Typography"
import * as act from "../../store/actions";

const CategoryMenu = (props) => {
  const { categories, mediaSize, media, display} = props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box
        sx={({ breakpoints }) => ({
          display: display,
          alignItems: 'center',
          textAlign: 'center',
          [breakpoints.down(mediaSize)]: media,
        })}
      >
        
          <Typography variant='p' onClick={handleClick}>
            Categories
          </Typography>
      
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {categories.map((item) => {
          return <MenuItem key={item} sx={{ fontSize: '1rem' }} onClick={() => dispatch(act.searchByCategory(item))}>{item}</MenuItem>;
        })}
       
      </Menu>
    </React.Fragment>
  );
}

export default CategoryMenu;
