import React, { useState } from 'react';
import Modal from '../../display/modal';
import { Box, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FarmerFAQ from "../accordions/FarmerFAQ"
import CustomerFAQs from '../accordions/CustomerFAQs';

const FAQ = (props) => {
  const { open, setOpen } = props;
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Modal
      top={'50%'}
      left={'50%'}
      width={'800px'}
      open={open}
      TitleMessage={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            overflow: 'scroll',
          }}
        >
          <Typography variant='h4'>Frequently Asked Questions</Typography>
          <CloseIcon
            onClick={() => setOpen(false)}
            sx={{ marginRight: '20px' }}
          />
        </Box>
      }
      message={
        <Stack sx={{ overflow: 'scroll' }}>
          <CustomerFAQs
            expanded={expanded}
            setExpanded={setExpanded}
            handleChange={handleChange}
          />
          <FarmerFAQ
            expanded={expanded}
            setExpanded={setExpanded}
            handleChange={handleChange}
          />
        </Stack>
      }
    />
  );
};

export default FAQ;
