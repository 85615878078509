import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import { range } from '../../../util/data';
import * as utils from '../../../util';
import * as act from '../../../store/actions';

const ProductCard = (props) => {
  const { product, cart, setSubtotal, setTotal } = props;
  const dispatch = useDispatch();
  const { register } = useFormContext();
  const [item, setItem] = useState();

  useEffect(() => {
    act
      .getProduct(product?.id)
      .then((response) => {
        setItem(response);
      })
      .catch((err) => {
        console.dir(err);
      });
  }, [product]);

  return (
    <Stack
      direction='row'
      width='100%'
      justifyContent='space-between'
      alignItems='center'
      height='fit-content'
      margin='0 10px'
      marginTop='-30px'
    >
      <Typography variant='h6' width='40%' padding='5px'>
        {item?.name}
      </Typography>
      <Stack width='40%' direction='row' alignItems='center'>
        <InputLabel
          htmlFor='quantity'
          sx={{ fontSize: '1rem', marginRight: '10px' }}
        >
          Qty:{' '}
        </InputLabel>
        <FormControl width='300px' padding='5px'>
          <Select
            size='small'
            labelId='quantity'
            {...register('quantity')}
            value={product.quantity}
            sx={{ width: '100px' }}
            onChange={(e) =>
              utils.changeQuantity(
                cart,
                product,
                e.target.value,
                setSubtotal,
                setTotal,
                dispatch
              )
            }
          >
            {range.map((num) => {
              return (
                <MenuItem key={num} value={num}>
                  {num} {item?.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Stack>
      <Stack width='20%'>
        <Typography variant='h5'>
          ${(item?.price * product.quantity).toFixed(2)}
        </Typography>
        <Typography variant='p'>
          ${item?.price.toFixed(2)} / {item?.label}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ProductCard;
