import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { roles } from '../util/data';
import Box from '@mui/material/Box';
import CustomerDashboard from '../components/customer/pages/Dashboard';
import Auth from '../components/account/pages/auth';
import FarmDashboard from '../components/farm/pages/dashboard';
import NavBar from '../views/NavBar';
import SideBar from './SideBar';
import AdminDashboard from '../components/admin/pages/AdminDashboard';
import * as act from '../store/actions';

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer.user);

  useEffect(() => {
    if (user.app_metadata.roles[0] === roles.farmer) {
      dispatch(act.getManagedFarms(user.user_id.split('|')[1]));
    }
  }, []);

  switch (user.app_metadata.roles[0]) {
    case roles.customer:
      return <CustomerDashboard {...props} />;
    case roles.farmer:
      return <FarmDashboard />;
    case roles.admin:
      return <AdminDashboard />;
    case roles.super:
      return <AdminDashboard />;
    default:
      return (
        <Box>
          <NavBar {...props} />
          <Auth />
        </Box>
      );
  }
};

export default Dashboard;
