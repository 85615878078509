import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as act from '../../../../../store/actions';

const ReturnsInfo = (props) => {
  const { refund} = props;
  const [products, setProducts] = useState([]);

  useEffect(() => {
    refund?.products?.forEach((product) => {
      act
        .getProduct(product.id)
        .then((response) => {
          const newItem = {
            ...response,
            product
          }
          
          setProducts([...products, newItem]);
        })
        .catch((err) => {
          console.dir(err);
        });
    });
  }, []);

  return (
    <Stack direction='row' width='100%' margin='10px 0'>
      <Stack>
        <Stack direction='column'>
          {refund?.images?.map((image, i) => {
            return (
              <img
                key={i}
                width='150px'
                height='80px'
                src={image}
                id={i}
                alt=''
                style={{ margin: '0 10px' }}
                onMouseOver={() =>
                  document.getElementById(i).classList.add('refundScale')
                }
                onMouseLeave={() =>
                  document.getElementById(i).classList.remove('refundScale')
                }
              />
            );
          })}
          <Typography variant='p' component='p' width='250px' margin={'10px'}>
            <b> Reason for Refund:</b> {refund.reason}
          </Typography>
        </Stack>
      </Stack>
      <Stack width='70%' margin='0 50px'>
        <Typography variant='h6'>Items to Refund</Typography>
        {products?.map((product, i) => {
          return (
            <Stack key={i} width='100%' margin='10px 0' direction='row'>
              <Stack>
              <Typography variant='p' component='p'>
                <b>{product?.name}</b>
              </Typography>
              <Typography variant='p' component='p'>
                Price:{' '}
                <b>
                  ${product?.price}/{product?.label}
                </b>
              </Typography>
              </Stack>
              <Stack marginLeft="50px">
              <Typography variant='p' component='p'>
                Item Number: <b>{product?.id}</b>
              </Typography>
              <Typography variant='p' component='p'>
                Quantity: <b>{product.product.quantity}</b>
              </Typography>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default ReturnsInfo;
