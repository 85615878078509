import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

const FarmOrders = (props) => {
  const { farm, farmOrderData, users } = props;

  const createData = (order, index) => {
    const dateString = new Date(order.created_date).toISOString().slice(0, 10);
    const formattedDate = dateString.split('-').reverse().join('/');
    const customer = users.find(
      (user) => user.user_id.split('|')[1] === order.customer_id
    );
    return {
      id: order.id,
      index: index + 1,
      customer: customer.name,
      date: formattedDate,
      total: '$' + order.payment.total.toFixed(),
      status: order.order_status,
    };
  };

  const generateRows = () => {
    if (farm?.id && farmOrderData[farm.id]) {
      return farmOrderData[farm.id].map((product, index) =>
        createData(product, index)
      );
    }

    return [];
  };

  const rows = generateRows();

  return (
    <Stack
      width='100%'
      height='fit-content'
      boxSizing='border-box'
      borderRadius='2px'
      padding='10px'
      marginBottom='15px'
    >
      <Typography variant='h6' lineHeight='22px' color='primary.lightFont'>
        Orders
      </Typography>
      <Stack
        width='100%'
        padding='0 20px'
        justifyContent='center'
        alignItems='flex-end'
      >
        <Typography
          variant='p'
          component='p'
          lineHeight='27px'
          color='primary.lightFont'
        >
          Total Orders:{' '}
          <Typography variant='p' color='primary.darkFont'>
            {farmOrderData[farm?.id]?.length || 0}
          </Typography>
        </Typography>
      </Stack>
      <Card elevation={10} marginBottom='15px'>
        <Table
          stickyHeader
          style={{
            width: '100%',
            margin: '0 auto',
          }}
          aria-label='sticky table'
        >
          <TableHead style={{ backgroundColor: '#EAEDE9' }}>
            <TableRow>
              <TableCell align='center'>Order Number</TableCell>
              <TableCell align='center'>Customer Name</TableCell>
              <TableCell align='center'>Date</TableCell>
              <TableCell align='center'>Total</TableCell>
              <TableCell align='center'>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow hover key={`${row.name}-${row.index}`}>
                  <TableCell align='center' component='th' scope='row'>
                    {`${row.id}`}
                  </TableCell>
                  <TableCell align='center'>{row.customer}</TableCell>
                  <TableCell align='center'>{row.date}</TableCell>
                  <TableCell align='center'>{row.total}</TableCell>
                  <TableCell align='center'>{row.status}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Card>
    </Stack>
  );
};

export default FarmOrders;
