import React from 'react';
import Modal from '../../../display/modal';
import { useDispatch } from 'react-redux';
import * as act from '../../../../store/actions';
import { useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import PrimaryButton from '../../../inputs/Buttons/Button';

const PasswordModal = (props) => {
  const { user, open, setOpen, setPasswordSuccess } = props;

  const dispatch = useDispatch();
  const { register, getValues, watch } = useForm();

  const values = watch();

  const handlePasswordChange = () => {
    dispatch(act.changePassword(values.email, setPasswordSuccess));

    setTimeout(() => {
      setOpen(false);
    });
  };

  return (
    <Modal
      open={open}
      top='50%'
      left='50%'
      width={'600px'}
      TitleMessage={'Enter email to reset password'}
      message={
        <Stack direction='column' padding='20px'>
          <TextField {...register('email')} label={'Email'} />

          <Stack direction='row' justifyContent='space-between' width='80%'>
            <PrimaryButton
              type='button'
              width='150px'
              margin='20px 0'
              onClick={handlePasswordChange}
            >
              Submit
            </PrimaryButton>
            <PrimaryButton
              type='button'
              width='150px'
              margin='20px 0'
              onClick={() => setOpen(false)}
            >
              Cancel
            </PrimaryButton>
          </Stack>
        </Stack>
      }
    />
  );
};

export default PasswordModal;
