import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AllProducts from './AllProducts';
import FarmShippingDelivery from './ShippingDelivery';
import Reviews from './Reviews';
import Policies from './Policies';
import Documents from '../dashboard/components/Documents';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const FarmTabs = (props) => {
  const [value, setValue] = React.useState(0);
  const { farm, id } = props;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='secondary'
          aria-label='basic tabs example'
        >
          <Tab
            sx={{
              fontSize: '20px',
              color: 'primary.disabled',

              textTransform: 'none',
              '&.Mui-selected': {
                color: 'primary.darkFont',
              },
            }}
            label='All Items'
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              fontSize: '20px',
              color: 'primary.disabled',

              textTransform: 'none',
              '&.Mui-selected': {
                color: 'primary.darkFont',
              },
            }}
            label='Shipping & Delivery'
            {...a11yProps(1)}
          />
          <Tab
            sx={{
              fontSize: '20px',
              color: 'primary.disabled',

              textTransform: 'none',
              '&.Mui-selected': {
                color: 'primary.darkFont',
              },
            }}
            label='Policies'
            {...a11yProps(2)}
          />
          <Tab
            sx={{
              fontSize: '20px',
              color: 'primary.disabled',

              textTransform: 'none',
              '&.Mui-selected': {
                color: 'primary.darkFont',
              },
            }}
            label='Reviews'
            {...a11yProps(3)}
          />
          <Tab
            sx={{
              fontSize: '20px',
              color: 'primary.disabled',

              textTransform: 'none',
              '&.Mui-selected': {
                color: 'primary.darkFont',
              },
            }}
            label='Farm Documents'
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <AllProducts farm={farm} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FarmShippingDelivery farm={farm} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Policies farm={farm} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Reviews farm={farm} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Documents display={'none'} farm={farm} id={id} />
      </TabPanel>
    </Box>
  );
};
export default FarmTabs;
