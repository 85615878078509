import React from 'react';
import Modal from '../../display/modal';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack"
import PrimaryButton from '../../inputs/Buttons/Button';


const OutOfStock = (props) => {
  const { products, open, setOpen } = props;
 
  return (
    <Modal
      top='50%'
      left='50%'
      width={'500px'}
      open={open}
      setOpen={setOpen}
      TitleMessage={'Out of Stock'}
      message={
        <Stack marginTop='20px'>
          {products?.map((product) => (
            <Stack>
            
              <img
                src={product.images ? product.images[0] : null}
                width='120px'
                height='85px'
                alt={product?.name}
              />

              <Typography variant='h5' margin='20px 0'>
                {product?.name}
              </Typography>
             
              {product.product_status === 'archived' ?
                <Typography variant='p'>
                  {product.name} is/are no longer available. Please remove from cart
                </Typography>
               
                : <Typography variant='p'> Currently, there is not enough stock to fulfill your order.
                  There is/are {product?.inventory_qty} {product?.name} left.
                  Please change quantity or remove item from cart.
                </Typography>}
            </Stack>
          ))}

          <PrimaryButton
            width='150px'
            onClick={() => setOpen([])}
            margin='20px 0'
          >
            Close
          </PrimaryButton>
        </Stack>
      }
    />
  );

};

export default OutOfStock;
