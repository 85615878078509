import './css/index.css';
import { Route } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Amplify from 'aws-amplify';
import config from './aws-exports';
import * as act from './store/actions/index';

//components
import MarketingPage from './components/misc/MarketingPage';
import FarmerMarketingPage from './components/misc/FarmMarketingPage';
import LeaveReviewPage from './components/forms/pages/LeaveReviewPage';
import AdminDashboard from './components/admin/pages/AdminDashboard';
import Dashboard from './views/dashboard';
import ShopPages from './components/shop/shopPages';
import Footer from "./components/misc/Footer"

import AuthPage from './components/account/pages/auth';
import OrderSuccessful from './components/checkout/modals/OrderSuccessful';

Amplify.configure(config);

function App(props) {
  const dispatch = useDispatch();

  const [users, setUsers] = useState(
    useSelector((state) => state.UserReducer.allUsers)
  );
  const user = useSelector((state) => state.UserReducer.user);
  const [products, setProducts] = useState(
    useSelector((state) => state.ProductsReducer.allProducts)
  );
  const [filtered, setFiltered] = useState(
    useSelector((state) => state.ProductsReducer.searchedProducts)
  );

  useEffect(() => {
    dispatch(act.getAllFarms());

    if (!users) {
      dispatch(act.getAllUsers());
    }

    dispatch(act.getAllProducts(setProducts));
  }, []);
  const [orderSuccessOpen, setOrderSuccessOpen] = useState(false);
  return (
    <div className='App'>
      <Route
        path='/dashboard/:path?/:component?/:id?'
        render={(props) => (
          // <FarmDashboard {...props}/>
          <Dashboard
            products={products}
            setProducts={setProducts}
            filtered={filtered}
            setFiltered={setFiltered}
            user={user}
            {...props}
          />
        )}
      />
      <OrderSuccessful
        open={orderSuccessOpen}
        setOpen={setOrderSuccessOpen}
        user={user}
      />
      <Route
        exact
        path='/'
        render={(props) => (
          <MarketingPage
            products={products}
            setProducts={setProducts}
            filtered={filtered}
            setFiltered={setFiltered}
            {...props}
          />
        )}
      />
    
      <Route
        exact
        path='/marketing/farm'
        render={(props) => <FarmerMarketingPage {...props} />}
      />
      <Route exact path='/login' render={(props) => <AuthPage {...props} />} />

      <Route
        exact
        path='/store/:page?/:id?'
        render={(props) => (
          <ShopPages
            orderSuccessOpen={orderSuccessOpen}
            setOrderSuccessOpen={setOrderSuccessOpen}
            products={products}
            setProducts={setProducts}
            filtered={filtered}
            setFiltered={setFiltered}
            {...props}
          />
        )}
      />
      <Route
        exact
        path='/review/:id'
        render={(props) => <LeaveReviewPage {...props} />}
      />
      <Route path='/auth/:path' render={(props) => <AuthPage {...props} />} />
      <Route path='/admin' render={(props) => <AdminDashboard {...props} />} />
    
    </div>
  );
}

export default App;
