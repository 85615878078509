import React from 'react';
import { useParams } from 'react-router-dom';
import ProductList from '../products/pages/productsList';
import ProductPage from '../products/pages/productPage';
import CartPage from '../cart/pages/CartPage';
import CheckoutPage from '../checkout/pages/CheckoutPage';
import FarmStand from "../farm/pages/FarmStand"
import { categories, dietaryOptions } from '../../util';
import ShoppingNav from '../nav/ShoppingNav';
import Stack from '@mui/material/Stack';

const ShopPages = (props) => {
  const { setProducts, products, filtered, setFiltered, setOrderSuccessOpen } =
    props;
  const params = useParams();
  const Pages = () => {
    switch (params.page) {
      case 'products':
        return (
          <ProductList
            products={products}
            setProducts={setProducts}
            filtered={filtered}
            setFiltered={setFiltered}
            categories={categories}
            dietaryOptions={dietaryOptions}
            {...props}
          />
        );

      case 'product':
        return (
          <ProductPage
            categories={categories}
            dietaryOptions={dietaryOptions}
            {...props}
          />
        );
      case 'farmstand':
        return <FarmStand {...props} {...params} />;

      case 'cart':
        return <CartPage products={products} {...props} />;

      case 'checkout':
        return (
          <CheckoutPage {...props} setOrderSuccessOpen={setOrderSuccessOpen} />
        );

      default:
        return (
          <ProductList
            products={products}
            setProducts={setProducts}
            filtered={filtered}
            setFiltered={setFiltered}
            categories={categories}
            dietaryOptions={dietaryOptions}
            {...props}
          />
        );
    }
  };

  return (
    <Stack margin='0 auto' width="80%" marginTop="250px" >
      <ShoppingNav
        products={products}
        setProducts={setProducts}
        filtered={filtered}
        setFiltered={setFiltered}
        categories={categories}
        {...props}
      />
      <Pages />
    </Stack>
  );
};
export default ShopPages;
