import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';

const Unblock = (props) => {
  const history = useHistory();

  return (
    <Accordion
      elevation={0}
      disableGutters
      sx={{
        bgcolor: 'primary.formOutline2',
        border: 'none',
        marginTop: '-10px',
      }}
    >
      <AccordionSummary
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={{ border: 'none' }}
      >
        <Typography
          sx={{
            fontSize: '18px',
            color: 'primary.secondary',
          }}
        >
          Unblock
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          color='primary.secondary'
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => history.push(`/dashboard/unblock-customer`)}
        >
          Unblock Customer
        </Typography>
        <Typography
          color='primary.secondary'
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => history.push(`/dashboard/unblock-farm`)}
        >
          Unblock Farm
        </Typography>
        <Typography
          color='primary.secondary'
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => history.push(`/dashboard/unblock-farmer`)}
        >
          Unblock Farmer
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default Unblock;
