import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { Badge } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';

import * as act from '../../../store/actions';
import {
  NotificationIcon,
  ShoppingFarmStand,
  Basket,
  AccountIcon,
} from '../../assets/farmIconImages';
import MenuIcon from '@mui/icons-material/Menu';
import Stack from '@mui/material/Stack';

export default function AccountMenu(props) {
 const count = useSelector((state) => state.CartReducer.itemCount);
 const StyledBadge = withStyles((theme) => ({
   badge: {
     right: 5,
     top: 0,
     padding: '0 4px',
   },
 }))(Badge);

  const [category, setCategory] = useState(
    useSelector((state) => state.ProductsReducer.searchedCategory)
  );
  const [products, setProducts] = useState(
    useSelector((state) => state.ProductsReducer.searchedProducts)
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const handleFarmStand = () => {
    setCategory('All');
    dispatch(act.getAllProducts(setProducts));
    history.push('/store/products');
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box
        sx={({ breakpoints }) => ({
          display: 'none',
          [breakpoints.down('lg')]: {
            display: 'flex',
          },
        })}
      >
        <Tooltip title='Account Menu'>
          <MenuIcon onClick={handleClick} />
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Stack >
          <Stack
            alignItems='center'
            margin='10px'
           
            sx={{ opacity: 0.3 }}
          >
            <NotificationsOutlinedIcon
              sx={{
                fontSize: '25px',
                cursor: 'pointer',
                marginBottom: '3px',
              }}
            />
            <Typography variant='p' fontSize='13px'>
              Notifications
            </Typography>
          </Stack>
          <Stack alignItems='center' margin='10px'>
            <AccountCircleOutlinedIcon
              onClick={() => history.push('/dashboard')}
              sx={{
                fontSize: '25px',
                cursor: 'pointer',
                marginBottom: '3px',
              }}
            />
            <Typography variant='p' fontSize='13px'>
              Account
            </Typography>
          </Stack>
          <Stack alignItems='center' margin='10px' >
            <ShoppingFarmStand
              onClick={() => handleFarmStand()}
              sx={{
                fontSize: '25px',
                cursor: 'pointer',
              }}
            />
            <Typography variant='p' fontSize='13px'>
              Marketplace
            </Typography>
          </Stack>
          <Stack alignItems='center' margin='10px' >
            <StyledBadge
              badgeContent={count}
              color='primary'
              sx={{
                margin: '0 10px',
                cursor: 'pointer',
              }}
            >
              <Basket
                onClick={() => history.push('/store/cart')}
                sx={{
                  fontSize: '25px',
                  cursor: 'pointer',
                }}
              />
            </StyledBadge>
            <Typography
              variant='p'
              fontSize='13px'
              sx={{ color: 'primary.darkFont' }}
            >
              Basket
            </Typography>
          </Stack>
        </Stack>
      </Menu>
    </React.Fragment>
  );
}
