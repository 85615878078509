import React from "react";
import { useFormContext } from "react-hook-form";
import { Box, Typography, TextField } from "@mui/material";
import Stack from "@mui/material/Stack"

const ReturnReason = (props) => {
  const methods = useFormContext();

  const { register } = methods;

  return (
    <Stack width="70%">
      <Typography variant="p" component={'p'}>What is your reason for refund?</Typography>
      <TextField
        rows={5}
        multiline
      fullWidth
        {...register('reason', { required: 'Refund reason required'})}
      />
    </Stack>
  );
}

export default ReturnReason;