import React from 'react';
import Button from '@mui/material/Button';

const TransparentButton = ({
  children,
  onClick,
  width,
  fontSize,
  color,
  opacity,
  margin,
}) => (
  <Button
    onClick={onClick}
    variant='contained'
    sx={{
      height: '40px',
      width: width,
      fontSize: fontSize || '1rem',
      color: color ? color : 'primary.darkFont',
      backgroundColor: 'opposite.main',
      border: `1px solid #fbd200`,
      boxShadow: 'none',
      margin: margin,
      opacity: opacity,
      cursor: 'pointer',
      '&:hover': {
        border: `2px solid #fac400`,
        color: color ? color : 'primary.darkFont',
        backgroundColor: 'opposite.main',
        boxShadow: 'none',
      },
      '&:active': {
        border: `3px solid #edba00`,
        color: color ? color : 'primary.darkFont',
        backgroundColor: 'opposite.main',
        boxShadow: 'none',
      },
    }}
  >
    {children}
  </Button>
);

export default TransparentButton;
