import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useForm, FormProvider } from 'react-hook-form';
import PrimaryButton from '../../../../../inputs/Buttons/Button';
import * as act from '../../../../../../store/actions';
import Dialog from '../../../../../display/dialog';
import UseFormRadioGroup from '../../../../../inputs/Radio/UseFormRadioGroup';

const UpdateOrderStatusModal = (props) => {
  const { open, order, setOpen } = props;
  const [updatedOrder, setUpdatedOrder] = useState(order);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      order_status: order.order_status,
      id: order.id,
    },
  });

  const handleSubmit = () => {
    act.addCompletedOrder(updatedOrder);
  };

  const handleSelect = (e) => {
    setUpdatedOrder({
      ...order,
      [e.target.name]: e.target.value,
    });
  };

  const title = 'Update Order Status';
  const content = (
    <Stack>
      <Typography variant='p' component='p'>
        {' '}
        Order ID:{order.id}
      </Typography>
      <UseFormRadioGroup
        name='order_status'
        onChange={(e) => handleSelect(e)}
        array={[
          { value: 'delivered', label: 'Delivered' },
          { value: 'picked_up', label: 'Picked Up' },
        ]}
      />
    </Stack>
  );
  const actions = (
    <Stack direction='row' justifyContent='space-between'>
      <PrimaryButton
        width='200px'
        margin='0 10px'
        onClick={() => {
          handleSubmit();
          setOpen(false);
        }}
      >
        Update Status
      </PrimaryButton>

      <PrimaryButton
        width='200px'
        margin='0 10px'
        onClick={() => setOpen(false)}
      >
        Cancel
      </PrimaryButton>
    </Stack>
  );

  return (
    <FormProvider {...methods}>
      <Dialog
        title={title}
        content={content}
        actions={actions}
        open={open}
        setOpen={setOpen}
      />
    </FormProvider>
  );
};

export default UpdateOrderStatusModal;
