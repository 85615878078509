import Stepper from '../../display/Stepper';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';
import FarmInfo from '../components/addFarm/farmInfo';
import ShippingDelivery from '../components/addFarm/ShippingDelivery';
import Disclaimers from '../components/addFarm/Disclaimers';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import customId from 'custom-id';
import * as act from '../../../store/actions/index';

const AddFarmPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [farm, setFarm] = useState(
    useSelector((state) => state.FarmReducer.newFarm)
  );

  const user = useSelector((state) => state.UserReducer.user);



  const methods = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    criteriaMode: 'firstError',
    reValidateMode: 'onChange',
    defaultValues: {
      delivery_methods: {
        ship: false,
        free_shipping: false,
        deliver: false,
        delivery_fee: 0,
        pickup: false,
      },
      stripe_acct: user.user_metadata.stripe_account
    },
  });
  const {
    watch,
    formState: {isValid },
    trigger,
  } = methods;

  const values = watch();
 
  const doSubmit = (e) => {
    e.preventDefault();
    let returnData = farm;

    typeof values.farm_logo === 'object'
      ? (values.farm_logo = ' ')
      : (values.farm_logo = values.farm_logo);
    typeof values.farm_image === 'object'
      ? (values.farm_image = ' ')
      : (values.farm_image = values.farm_image);

    returnData.id = customId({});
    returnData.user_id = user.user_id.split('|')[1];
    returnData.farm_logo = values.farm_logo;
    returnData.farm_image = values.farm_image;
    returnData.stripe_acct = values.stripe_acct;
    
   

    dispatch(act.addFarm(farm, history));
    dispatch(act.addManaged(farm));
  };

  

  return (
    <FormProvider {...methods}>
      <Stepper
        stepLabels={['Farm Information', 'Shipping & Delivery', 'Disclaimers']}
        handleTrigger={() => {
          console.log(isValid, "is valid")
          if (!isValid) {
            trigger();
          }
        }}
        handleFinish={(e) => doSubmit(e, values)}
        {...props}
        StepContent={[
          <FarmInfo farm={farm} setFarm={setFarm} add />,
          <ShippingDelivery farm={farm} setFarm={setFarm} />,
          <Disclaimers farm={farm} setFarm={setFarm} />,
        ]}
      />
    </FormProvider>
  );
};

export default AddFarmPage;
