import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom';
import * as act from '../../../store/actions';
import Documents from '../components/dashboard/components/Documents';

const Overview = (props) => {
  const [farm, setFarm] = useState();
  const params = useParams();
  const { id } = props;

  useEffect(() => {
    act
      .getFarmById(id)
      .then((response) => {
        setFarm(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params]);

  return (
    <Stack marginTop='50px'>
      <Documents
        documents={farm?.documents}
        farm={farm}
        setFarm={setFarm}
        id={params.component}
      />
    </Stack>
  );
};

export default Overview;
