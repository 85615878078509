import { API } from 'aws-amplify';
import zipcodes from 'zipcodes';
import { ADD_SEARCHED_USER } from '.';

const api = 'FarmEraMarketAPI';

export const ADD_FARM_INFO = 'ADD_FARM_INFO';
export const ADD_FARM_SHIPPING = 'ADD_FARM_SHIPPING';
export const ADD_FARMS = 'ADD_FARMS';
export const FARM_ERROR = 'FARM_ERROR';
export const ADD_STRIPE = 'ADD_STRIPE';
export const ADD_LOCATION = 'ADD_LOCATION';

export const getAllFarms = () => {
  return (dispatch) => {
    API.get(api, '/farms')
      .then((response) => {
        dispatch({ type: ADD_FARMS, payload: response.data });
      })
      .catch((e) => {
        dispatch({ type: FARM_ERROR, payload: e });
      });
  };
};

export const updateFarmInfo = (id, key, value) => {
  // farm.updatedDate = new Date();
  return (dispatch) => {
    const myInIt = {
      body: {
        id: id,
        key: key,
        value: value,
      },
    };
    API.put(api, '/farms', myInIt)
      .then((response) => {})
      .catch((e) => {
        dispatch({ type: FARM_ERROR, payload: e });
      });
  };
};

export const addFarm = (farm, history) => {
  farm.createdDate = new Date();
  const farmZip = zipcodes.lookup(parseInt(farm.zip));

  //add lat and long for farm
  farm.latitude = farmZip.latitude;
  farm.longitude = farmZip.longitude;

  //add lat and long for all locations
  farm.delivery_methods?.locations?.map((location) => {
    const locZip = zipcodes.lookup(location.zip);
    return (location.latitude = locZip.latitude,
      location.longitude = locZip.longitude
    )
  });
  return (dispatch) => {
    const myInIt = {
      body: farm,
    };
    API.post(api, '/farms', myInIt)
      .then((response) => {
        dispatch({ type: ADD_FARMS, payload: response });
        dispatch({ type: RESET_NEW_FARM });

        history.push('/dashboard');
      })
      .catch((e) => {
        dispatch({ type: FARM_ERROR, payload: e });
      });
  };
};

export const updateFarm = (farm, history) => {
  console.log(farm)
  return (dispatch) => {
    const myInIt = {
      body: farm,
    };
    API.post(api, '/farms', myInIt)
      .then((response) => {
        dispatch({ type: ADD_FARMS, payload: response });
        dispatch({ type: RESET_NEW_FARM });
        if (history) {
          history.push('/dashboard');
        }
      })
      .catch((e) => {
        dispatch({ type: FARM_ERROR, payload: e });
      });
  };
};
export const ADD_MANAGED_FARMS = 'ADD_MANAGED_FARMS';
export const RESET_NEW_FARM = 'RESET_NEW_FARM';

export const getManagedFarms = (id) => {
  return (dispatch) => {
    API.get(api, `/managed/${id}`)
      .then((response) => {
        dispatch({ type: ADD_MANAGED_FARMS, payload: response });
      })
      .catch((err) => {
        dispatch({ type: FARM_ERROR, payload: err });
      });
  };
};

export const getFarmById = async(farmId) => {
  try {
    const farm = await API.get(api, `/farms/${farmId}`);
    return farm
    
  } catch (e) {
      console.log(e)
  }
  
};
export const getFarmOrders = async (id, stateSetter) => {
  try {
    const orders = API.get(api, `/orders/farm/${id}`)
    return orders
  }
  catch (e) {
    return e;
  }
 
};

export const getFarmNewOrders = (id, stateSetter) => {
  let orders = [];
  // return dispatch => {
  API.get(api, `/orders/farm/${id}`)
    .then((response) => {
      response.map((order) => {
        return order.order_status === 'new' ? orders.push(order) : null;
      });
      stateSetter(orders);
    })
    .catch((err) => {
     return err
    });
  // }
};

export const ADD_FARM_RETURNS = 'ADD_FARM_RETURNS';
export const getFarmReturns = (id, setReturns) => {


  API.get(api, `/returns/farm/${id}`)
    .then((response) => {
      setReturns(response)
     
    })
    .catch((err) => {
      return err
    });
};

export const ADD_SHIPPING_LABEL = 'ADD_SHIPPING_LABEL';

export const getLabel = (rateId, order, type) => {
  const myInIt = {
    body: {
      rate: rateId,
      type: type || 'PDF',
      metadata: order.id
    },
  };
  API.post(api, `/shipping/get-label`, myInIt)
    .then((response) => {
      console.log(response)
      order.delivery_selection.info.transaction_id = response.object_id;
      order.delivery_selection.info.tracking_number = response.tracking_number;
      order.delivery_selection.info.tracking_url = response.tracking_url_provider;
      order.delivery_selection.info.object_id = response.object_id
      window.open(response.label_url);
      const myInIt2 = {
        body: order,
      };
      API.post(api, '/orders', myInIt2)
        .then((response) => {
         
        })
        .catch((err) => {
        return err
        });
    })
    .catch((err) => {
      return err
    });
};

export const setFarmInfo = (values) => {
  return (dispatch) => {
    dispatch({ type: ADD_FARM_INFO, payload: values });
  };
};
export const setShippingInfo = (values) => {
  return (dispatch) => {
    dispatch({ type: ADD_FARM_SHIPPING, payload: values });
  };
};
export const setLocationInfo = (values) => {
  return (dispatch) => {
    dispatch({ type: ADD_LOCATION, payload: values });
  };
};

export const ADD_MANAGED_TO_FARMS = 'ADD_MANAGED_TO_FARMS';

export const addManaged = (farm) => {
  return (dispatch) => {
    dispatch({ type: ADD_MANAGED_TO_FARMS, payload: farm });
  };
};

export const searchFarmsByName = (name, farms, setValue) => {
  const searchedFarm = farms.filter((farm) =>
    farm.name.toLowerCase().includes(name.toLowerCase())
  )[0];
 
  setValue('farm', searchedFarm);
  return (dispatch) => {
    dispatch({ type: ADD_SEARCHED_USER, payload: searchedFarm });
  };
};
