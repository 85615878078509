import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '../Search';
import * as act from '../../../../../store/actions';
import PrimaryButton from '../../../../inputs/Buttons/Button';
import SuccessFlag from '../../modals/SuccessFlag';

const FlagFarm = (props) => {
  const { title, successTitle, toFlagTitle } = props;
  const allFarms = useSelector((state) => state.FarmReducer.allFarms);
  const dispatch = useDispatch();
  const history = useHistory();
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { register, watch, setValue } = methods;
  const values = watch();

  const [open, setOpen] = useState(false);

  const SearchUser = () => {
    dispatch(act.searchFarmsByName(values.name, allFarms, setValue));
  };

  const handleFlag = () => {
    setValue('farm.flagged', true);
    setValue('farm.flaggedReason', values.flagReason);
    dispatch(act.updateFarm(values.farm));
    setOpen(true);
  };

  return (
    <FormProvider {...methods}>
      <Stack width='80%' alignItems='center' marginTop='50px'>
        <Typography variant='h3'>{title}</Typography>
        <Stack direction='row' alignItems='center' marginTop='20px'>
          <Search title='Search by Farm Name' name='name' />
          <PrimaryButton
            margin='0 20px'
            onClick={() => {
              SearchUser();
            }}
          >
            Search
          </PrimaryButton>
        </Stack>
        <SuccessFlag
          title={successTitle}
          open={open}
          setOpen={setOpen}
          history={history}
        />
        <Stack>
          {values.farm?.length > 1 ? (
            <Stack>
              <Typography>Click on Farm to Select</Typography>
              {values.farm.map((farm) => {
                return (
                  <Stack
                    key={farm.id}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setValue('farm', farm);
                    }}
                  >
                    <Typography variant='p' marginTop='20px'>
                      <b>Name:</b> {farm.name}{' '}
                    </Typography>

                    <Typography variant='p'>
                      <b>City, State:</b> {farm.city} {farm.state}
                    </Typography>
                    <Typography variant='p'>
                      <b>Farm Id:</b> {farm.id}
                    </Typography>
                  </Stack>
                );
              })}{' '}
            </Stack>
          ) : !!values.farm && Array.isArray(values.farm) === false ? (
            <Stack marginTop='50px'>
                <Typography variant='h4'>{toFlagTitle}</Typography>
              <Typography variant='p' marginTop='20px'>
                <b>Name:</b> {values.farm?.name}{' '}
              </Typography>

              <Typography variant='p'>
                <b>City:</b> {values.farm?.city}
              </Typography>
              <Typography variant='p'>
                <b>Farm Id:</b> {values.farm?.id}
              </Typography>
            </Stack>
          ) : null}

          <TextField
            label='Reason for flag'
            multiline
            rows={5}
            sx={{ width: '500px' }}
            {...register('flagReason', {
              required: 'Must add a reason',
            })}
          />
        </Stack>
        <Stack direction='row' marginTop='20px'>
          <PrimaryButton
            width='250px'
            margin='0 20px 0 0'
            onClick={handleFlag}
            disabled={!values.farm ? true : false}
          >
           {title}
          </PrimaryButton>
          <PrimaryButton
            width='250px'
            onClick={() => history.push('/dashboard')}
          >
            Cancel
          </PrimaryButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
export default FlagFarm;
