import { API } from 'aws-amplify';

import jwt_decode from 'jwt-decode';
import * as act from '../actions';

import zipcodes from 'zipcodes';
import { roles } from '../../util/data';

const api = 'FarmEraMarketAPI';

//exporting dispatch types
export const LOADING = 'LOADING';
export const ERROR = 'ERROR';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const UPDATE_USER_METADATA_SUCCESS = 'UPDATE_USER_METADATA_SUCCESS';
export const AUTH_RESULT_SUCCESS = 'AUTH_RESULT_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const ADD_FAVORITE = 'ADD_FAVORITE';

//registering and first login
export const register = (user, helpers) => {
  //set UserNmae
  const userName = user.email.split('@')[0];

  return (dispatch) => {
    dispatch({ type: 'LOADING', payload: true });

    //add lat and long to user object
    const zip = zipcodes.lookup(`${user.zip}`);
    user.latitude = zip.latitude;
    user.longitude = zip.longitude;

    //set body to send to BE
    const myInIt = {
      body: {
        connection: 'Username-Password-Authentication',
        email: user.email,
        password: user.password,
        username: userName,
        user_metadata: {
          first_name: user.first_name,
          last_name: user.last_name,
          zip: user.zip,
          latitude: user.latitude,
          longitude: user.longitude,
          favorites: {
            products: [],
            farms: [],
          },
        },
        app_metadata: {
          roles: user.role,
        },
      },
    };
    //register call
    API.post(api, '/user/create', myInIt)
      .then((response) => {
        console.log(response, 'register');
        //login call
        API.post(api, '/user/login', myInIt)
          .then((response) => {
            //set token
            console.log(response, 'login');
            let token = response.id_token;
            //decode token
            let decoded = jwt_decode(token);
            //create user cart
            dispatch(act.createUserCart(decoded.sub.split('|')[1]));
            //add auth results and user object to store
            dispatch({ type: AUTH_RESULT_SUCCESS, payload: response });
            dispatch({ type: LOGIN_SUCCESS, payload: decoded });
            helpers.history.push('/dashboard');
            dispatch({ type: 'LOADING', payload: false });
          })
          .catch((err) => {
            // const error = JSON.parse(err.response.data.message);
            console.dir(err);
            dispatch({
              type: 'ERROR',
              payload: `Error logging into account: ${err}`,
            });
          });
      })
      .catch((err) => {
        console.dir(err);

        dispatch({
          type: 'ERROR',
          payload: `Error registering account: ${err.response.data.message}`,
        });
      });
  };
};

//login
export const login = (user, helpers) => {
  //set username
  const userName = user.email.split('@')[0];
  return (dispatch) => {
    //set body to send to BE
    const myInIt = {
      body: {
        connection: 'Username-Password-Authentication',
        username: userName,
        password: user.password,
      },
    };

    //add loading and clear errors
    dispatch({ type: LOADING, payload: true });
    dispatch({ type: ERROR, payload: '' });
    //login call
    API.post(api, '/user/login', myInIt)
      .then((response) => {
        //set toekn and decode token
        let token = response.id_token;
        let decoded = jwt_decode(token);

        //set token and user object to store
        dispatch({ type: AUTH_RESULT_SUCCESS, payload: response });
        dispatch({ type: LOGIN_SUCCESS, payload: decoded });

        helpers.dispatch(act.getUserCart(decoded.sub.split('|')[1]));
        // helpers.dispatch(getUserFavorites(decoded.sub.split("|")[1]));
        //if farmer get all farms
        if (
          decoded['https://farmera.us.auth.com/app_metadata'].roles.includes(
            `${roles.farmer}`
          )
        ) {
          API.get(api, '/farms')
            .then((response) => {
              //set all farms to store
              dispatch({
                type: 'UPDATE_ALL_FARMS',
                payload: response.data,
              });
              //filter managed farms and set to store
              const farms = response.data.filter(
                (farm) => farm.userId === decoded.sub.split('|')[1]
              );
              helpers.props.history.push('/dashboard');
              dispatch({ type: 'UPDATE_MANAGED_FARMS', payload: farms });
            })
            .catch((err) => {
              dispatch({
                type: 'ERROR',
                payload: `There was an error getting managed farms: ${err}`,
              });
            });
        }

        if (
          decoded['https://farmera.us.auth.com/user_metadata'].signUp === true
        ) {
          //update user object to show not first time signing up
          API.put(api, `user/update-user-metadata`, {
            body: {
              id: decoded.sub,
              user_metadata: {
                first_name:
                  decoded['https://farmera.us.auth.com/user_metadata']
                    .first_name,
                last_name:
                  decoded['https://farmera.us.auth.com/user_metadata']
                    .last_name,
                zip: decoded['https://farmera.us.auth.com/user_metadata'].zip,
                receiveEmails:
                  decoded['https://farmera.us.auth.com/user_metadata']
                    .receiveEmails,
                privacyPolicy:
                  decoded['https://farmera.us.auth.com/user_metadata']
                    .privacyPolicy,
                signUp: false,
              },
            },
          })
            .then((response) => {
              //add user object to store
              dispatch({
                type: UPDATE_USER_METADATA_SUCCESS,
                payload: response.data,
              });
              dispatch({ type: LOADING, payload: false });
            })
            .catch((err) => {
              dispatch({
                type: ERROR,
                payload: `There was an err updating user info: ${err}`,
              });
            });
        }
        helpers.props.history.push('/dashboard');
      })
      .catch((err) => {
        console.dir(err);
        const error = JSON.parse(err.response.data.message);
        console.dir(error, err, 'login');

        dispatch({
          type: ERROR,
          payload: error.error_description,
        });
      });
  };
};

//change password will send password change email to user
export const changePassword = (email, setOpen) => {
  const myInIt = {
    body: {
      email: email,
    },
  };
  console.log(email, myInIt);
  return (dispatch) => {
    API.post(api, `/user/change_password`, myInIt)
      .then((response) => {
        // window.alert(response.msg);
        setOpen(true);

        dispatch({ type: CHANGE_PASSWORD, payload: response.msg });
      })
      .catch((err) => {
        dispatch({
          type: ERROR,
          payload: `There was an error changing password: ${err.message}`,
        });
      });
  };
};

//update user info
export const updateUser = (id, data) => {
  const myInIt = {
    body: {
      id: `${id}`,
      data,
    },
  };

  return (dispatch) => {
    API.put(api, `/user/update_user`, myInIt)
      .then((response) => {
        dispatch({ type: UPDATE_USER_SUCCESS, payload: response });
      })
      .catch((err) => {
        dispatch({
          type: ERROR,
          payload: `There was an error updating user data: ${err.message}`,
        });
      });
  };
};

export const ADD_ALL_USERS = 'ADD_ALL_USERS';
//getAllUsers
export const getAllUsers = () => {
  return (dispatch) => {
    API.get(api, '/user')
      .then((response) => {
        dispatch({ type: ADD_ALL_USERS, payload: response });
      })
      .catch((err) => dispatch({type: ERROR, payload: err}));
  };
};

//logout user
export const logout = (history) => (dispatch) => {
  dispatch({ type: LOGOUT });
  localStorage.clear();
 history.push('/auth/login')
};

//add new favorite

export const addFavorite = (user) => {
  const myInIt = {
    body: {
      user_id: user.user_id,
      user_metadata: user.user_metadata,
    },
  };

  return (dispatch) => {
    API.put(api, `/favorites`, myInIt)
      .then((response) => {
        dispatch({ type: UPDATE_USER_SUCCESS, payload: response });
      })
      .catch((err) => {
        dispatch({ type: ERROR, payload: err });
      });
  };
};

export const getUserFavorites = (userId) => {
  return (dispatch) => {
    API()
      .get(`/favorites`)
      .then((response) => {
        dispatch({ type: ADD_FAVORITE, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: ERROR, payload: err });
      });
  };
};

export const resetError = () => {
  return (dispatch) => {
    dispatch({ type: ERROR, payload: '' });
  };
};

//super user creating a new user
export const createUser = (user, setOpen) => {
  const userName = user.email.split('@')[0];

  return (dispatch) => {
    dispatch({ type: 'LOADING', payload: true });
    resetError();
    //add lat and long to user object
    const zip = zipcodes.lookup(`${user.zip}`);
    user.latitude = zip.latitude;
    user.longitude = zip.longitude;

    //set body to send to BE
    const myInIt = {
      body: {
        connection: 'Username-Password-Authentication',
        email: user.email,
        password: user.password,
        username: userName,
        user_metadata: {
          first_name: user.first_name,
          last_name: user.last_name,
          zip: user.zip,
          latitude: user.latitude,
          longitude: user.longitude,
        },
        app_metadata: {
          roles: user.role,
        },
      },
    };
    //register call
    API.post(api, '/createUser', myInIt)
      .then((response) => {
        setOpen(true);
      })
      .catch((err) => {
       
        dispatch({
          type: 'ERROR',
          payload: `Error registering account: ${err.response.data.message}`,
        });
      });
  };
};

export const ADD_SEARCHED_USER = 'ADD_SEARCHED_USER';
export const SearchUserByEmail = (email, stateSetter) => {
  const myInIt = {
    body: {
      email: email,
    },
  };
  return (dispatch) => {
    API.post(api, '/search', myInIt)
      .then((response) => {
        dispatch({ type: ADD_SEARCHED_USER, payload: response[0] });
        stateSetter('user', response[0]);
      })

      .catch((err) => dispatch({type: ERROR, payload: err}));
  };
};

export const updateUserMetadata = (id, metadata) => {
  const myInIt = {
    body: {
      id: id,
      user_metadata: metadata,
    },
  };

  API.put(api, '/update-metadata', myInIt)
    .then(() => {})
    .catch((err) => {
     return err
    });
};

export const flagUser = (id, metadata) => {
  const myInIt = {
    body: {
      id: id,
      user_metadata: metadata,
    },
  };

  API.post(api, '/update-metadata', myInIt)
    .then((response) => {})
    .catch((err) => {
      return err
    });
};

export const blockUser = (id, blocked, blockedReason, setOpen) => {
  const myInIt = {
    body: {
      id: id,
      blocked: blocked,
    },
  };

  API.put(api, '/block', myInIt)
    .then((response) => {
      updateUserMetadata(id, { blockReason: blockedReason });
      setOpen(true);
    })
    .catch((err) => {
      const error = JSON.parse(err);
      console.dir(error, err);
    });
};

export const getUser = (id) => {
  try {
    const response = API.get(api, `/get-user/${id}`);

    return response;
  } catch (err) {
   return err
  }
};
