import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as act from '../../../store/actions';
import EmptyCart from '../components/EmptyCart';
import ShoppingBasket from '../components/ShoppingBasket';

const CartPage = (props) => {
  const { products } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.CartReducer.userCart);
  const itemCount = useSelector((state) => state.CartReducer.itemCount);
  const user = useSelector((state) => state.UserReducer.user);
  const [open, setOpen] = useState([]);
  const [outOfStock, setOutOfStock] = useState([]);

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: cart,
  });
  const {
    watch,
    setError,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = methods;

  const values = watch();

  const handleProceedToCheckout = () => {
    if (outOfStock.length > 0) {
      setError('OutOfStock', {
        type: 'manual',
        message: 'Must remove out of stock products',
      });
      return;
    }

    values.delivery_selection.map((selection, i) => {
      if (selection === 'pickup' && values.delivery_info[i] === undefined) {
        setError(`pickup[${i}]`, {
          type: 'manual',
          message: 'Must choose a location for pickup',
        });
        return;
      } else {
        cart.orders.map((order, i) => {
          order.delivery_selection = {
            ...order.delivery_selection,
            type: values.delivery_selection[i],
          };
        });
      }
    });

    cart.orders.map((order, i) => {
      if (order.delivery_selection.type === 'deliver') {
        cart.orders[i].delivery_selection.info = {
          street1: user.user_metadata.address,
          city: user.user_metadata.city,
          state: user.user_metadata.state,
          zip: user.user_metadata.zip,
          phone: user.user_metadata.phone,
          email: user.user_metadata.email,
          amount: order.delivery_selection.info.amount,
        };
      }

      dispatch(act.saveCartToDB(cart));
      setTimeout(() => {
        history.push('/store/checkout');
      }, 500);
    });
  };

  useEffect(() => {
    clearErrors();
    setOutOfStock([]);
    if (!user.user_id) {
      props.history.push('/login');
    } else if (!cart.user_id) {
      dispatch(act.getUserCart(user.user_id.split('|')[1]));
    } else {
      cart.orders.map((order) => {
        return order.products.map(async (product) => {
          setOpen([]);
          const response = await act.getProduct(product.id);
          product.inventory_qty = response.inventory_qty;
          if (
            product.inventory_qty < product.quantity ||
            response.product_status === 'archived'
          ) {
            setOpen([...open, response]);
            setOutOfStock([...outOfStock, response]);
          }
        });
      });
    }
  }, [cart.user_id, itemCount]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleProceedToCheckout)}>
        {cart && cart.orders?.length === 0 ? (
          <EmptyCart products={products} />
        ) : (
          <ShoppingBasket
            handleProceedToCheckout={handleProceedToCheckout}
            open={open}
            setOpen={setOpen}
            outOfStock={outOfStock}
            errors={errors}
          />
        )}
      </form>
    </FormProvider>
  );
};
export default CartPage;
