import React from "react";
import PrimaryButton from "../../../inputs/Buttons/Button";
import TransparentButton from "../../../inputs/Buttons/TransparentButton";
import Stack from "@mui/material/Stack";
import Favorite from "../../../customer/components/Favorite";
import {useParams} from "react-router-dom"

const FarmStandButtons = () => {
  const params = useParams(); 

  return (
    <Stack direction="row" width="430px" margin="30px 95px" justifyContent="space-between">
      <TransparentButton width="210px"><Favorite item={params?.id} marginRight="5px" type="farms"/>Love This Farm</TransparentButton>
      <PrimaryButton width="210px" disabled >Contact Farm</PrimaryButton>
    </Stack>
  )
}

export default FarmStandButtons;