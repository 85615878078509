import React from 'react';
import Modal from '../../display/modal';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const About = (props) => {
  const {open, setOpen } = props;

  return (
    <Modal
      top={'50%'}
      left={'50%'}
      width={'800px'}
      open={open}
      TitleMessage={
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h4">About Us</Typography>
          <CloseIcon onClick={() => setOpen(false)} sx={{marginRight: "20px"}}/>
        </Box>
      }
      message={
        <Box>
          <Typography variant='p' component='p' sx={{ margin: '10px 0' }}>
            Farm Era Market is an online marketplace that connects farmers
            directly with their customers.
          </Typography>{' '}
          <Typography variant='p' component='p' sx={{ margin: '10px 0' }}>
            By using our marketplace, farmers can easily manage their inventory
            and sales online, earn more money by selling directly to the
            customer, and have access to a nationwide market by offering
            shipping. Customers will know exactly where their food is coming
            from and it will be healthier and tastier because it comes directly
            from the farm that produced it.{' '}
          </Typography>
          <Typography variant='p' component='p' sx={{ margin: '10px 0' }}>
            There has been a large shift towards buying food online since the
            pandemic. Customers are more comfortable going online to order
            rather than to a traditional grocery store. At the same time,
            traditional supply chain channels for farmers were shut down during
            the pandemic causing billions of dollars worth of food to be wasted
            because they didn’t have direct access to their customer base.
          </Typography>{' '}
          <Typography variant='p' component='p' sx={{ margin: '10px 0' }}>
            Our goals for Farm Era Market is to make sales easier and more
            profitable for farmers, make it easy and convenient to order farm
            fresh food for every meal, to have a thriving customer community
            with an active lifestyle blog, to feed everyone including those that
            would not otherwise have access to farm fresh food and finally, to
            support the sustainability of the planet by supporting farmers that
            are passionate about taking care of the land.
          </Typography>
        </Box>
      }
    />
  );
};

export default About;
