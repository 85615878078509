import React, { useRef, useEffect, useState } from 'react';
import Modal from '../../../../display/modal';
import Stack from '@mui/material/Stack';
import PrimaryButton from '../../../../inputs/Buttons/Button';
import OrderInfo from '../orders/OrderInfo';
import * as act from '../../../../../store/actions';
import { useReactToPrint } from 'react-to-print';

const ViewReturnOrder = (props) => {
  const {
    refund,
    open,
    setOpen,
    customerID,
    setNewOrders,
    farm,
    buttonDisplay,
  } = props;
  const componentRef = useRef();
  
  const [order, setOrder] = useState();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleProcessOrder = () => {
    let data = {
      order_status: 'processing',
      id: order.id,
    };

    handlePrint();
    act.updateOrderStatus(data);
    act.getFarmNewOrders(farm.id, setNewOrders);
    setOpen();
  };

  useEffect(() => {
    act
      .getReturnOrder(refund)
      .then((response) => {
        setOrder(response.Item);
      })
      .catch((err) => {
        console.dir(err);
      });
  }, []);

  return (
    <Modal
      open={open}
      top='50%'
      left='50%'
      width='60%'
      TitleMessage={'View Order'}
      message={
        <Stack>
          <OrderInfo
            componentRef={componentRef}
            order={order}
            iconDisplay='0%'
            width='100%'
            margin='0'
            customerID={customerID}
          />
          <Stack
            direction='row'
            width='40%'
            justifyContent='space-between'
            margin='20px 0'
          >
            <PrimaryButton
              width='200px'
              display={buttonDisplay}
              onClick={() => handleProcessOrder()}
            >
              Process order
            </PrimaryButton>
            <PrimaryButton width='200px' onClick={() => setOpen(false)}>
              Close
            </PrimaryButton>
          </Stack>
        </Stack>
      }
    />
  );
};

export default ViewReturnOrder;
