import React from 'react';
import Button from '@mui/material/Button';

const SecondaryButton = (props) => {
  const {
    onClick,
    children,
    width,
    fontSize,
    margin,
    type,
    display,
    media,
    mediaSize,
  } = props;
  
  return (
    <Button
      onClick={onClick}
      variant='contained'
      type={type}
      sx={({ breakpoints }) => ({
        height: '40px',
        display: display,
        backgroundColor: 'primary.secondary',
        width: width,
        fontSize: fontSize || '1rem',
        color: 'white',
        margin: margin,
        boxShadow: 'none',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'primary.secondaryHover',
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: 'primary.secondaryPressed',
          boxShadow: 'none',
        },
        [breakpoints.down(mediaSize)]: media,
      })}
    >
      {children}
    </Button>
  );
};

export default SecondaryButton;
