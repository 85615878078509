import React, {useState} from "react";
import { useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar"

const Reviews = (props) => {
  const { farm } = props;
  const [allUsers, setAllUsers] = useState(useSelector(state => state.UserReducer.allUsers))
  console.log(allUsers)

  return (
    <Stack>
      {farm.reviews.map((review, i) => {
        const user = allUsers?.find(
          (user) => user.user_id.split('|')[1] === review.user_id
        );

        return (
          <Stack
            key={i}
            direction="row"
            alignItems="center"
            marginBottom="20px"
          >
            <Avatar sx={{ marginRight:"10px" }} src={user?.picture} />
            <Stack >
              <Typography variant='h5'>
                {user?.user_metadata.first_name} {user?.user_metadata.last_name}
              </Typography>
              <Rating value={review?.rate} readOnly />

              <Typography marginTop="20px" variant='p'>{review?.review}</Typography>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );

}

export default Reviews;