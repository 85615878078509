import React from 'react';
import { useFormContext } from 'react-hook-form';
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography"
import * as utils from '../../../util';

const Password = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Stack width="49%">
      <TextField
        {...register('password', {
          required: 'Please enter a password',
          pattern: {
            value: /^[0-9a-zA-Z!@#$%^&*)(+=._-]+$/g,
            message: 'Password must contain at least one number',
          },
          minLength: {
            value: 8,
            message: 'Password must be at least 8 characters long',
          },
        })}
        type={'password'}
        label={'Password'}
        fullWidth
        onFocus={() =>
          (document.getElementById('message').style.display = 'flex')
        }
        onBlur={() =>
          (document.getElementById('message').style.display = 'none')
        }
        onKeyUp={utils.handleKeyUp}
        error={errors.password}
      />
      <Stack id='message' >
        <Typography variant='h6'>
          Password must contain the following:
        </Typography>
        <Typography variant='p' id='letter' className='invalid'>
          A <b>lowercase</b> letter
        </Typography>
        <Typography variant='p' id='capital' className='invalid'>
          A <b>capital (uppercase)</b> letter
        </Typography>
        <Typography variant='p' id='number' className='invalid'>
          A <b>number</b>
        </Typography>
        <Typography variant='p' id='length' className='invalid'>
          Minimum <b>8 characters</b>
        </Typography>
      </Stack>
    </Stack>
  );
};
export default Password;
