import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const FarmRefunds = (props) => {
  const { farm, farmReturnsRequestedData, farmReturnsSentData } = props;

  return (
    <Stack
      width='300px'
      height='fit-content'
      border='1px solid #bac2b7'
      boxSizing='border-box'
      borderRadius='2px'
      padding='12px'
      marginBottom='15px'
    >
      <Typography variant='h6' color='primary.lightFont' lineHeight='22px'>
        Refunds
      </Typography>
      <Stack justifyContent='flex-start' alignItems='center' padding='10px'>
        <Typography variant='h6' marginRight='10px'>
          Requested:
        </Typography>
        <Typography variant='p' component='p' color='primary.lightFont'>
          {farmReturnsRequestedData[farm?.id]
            ? farmReturnsRequestedData[farm.id].length
            : 0}
        </Typography>
      </Stack>
      <Stack justifyContent='flex-start' alignItems='center' padding='10px'>
        <Typography variant='h6' marginRight='10px'>
          Accepted:
        </Typography>
        <Typography variant='p' component='p' color='primary.lightFont'>
          {farmReturnsSentData[farm?.id]
            ? farmReturnsSentData[farm.id].length
            : 0}
        </Typography>
      </Stack>
      <Stack justifyContent='flex-start' alignItems='center' padding='10px'>
        <Typography variant='h6' marginRight='10px'>
          Declined:
        </Typography>
        <Typography variant='p' component='p' color='primary.lightFont'>
          0
        </Typography>
      </Stack>
    </Stack>
  );
};

export default FarmRefunds;
