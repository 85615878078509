import React, { useEffect, useState } from 'react';
import * as act from '../../../store/actions';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { FarmReview, ProductReview } from '../leaveReview';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PrimaryButton from '../../inputs/Buttons/Button';
import ReviewSuccessfulModal from '../modals/ReviewSuccessful';

const LeaveReviewPage = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const order = useSelector((state) => state.OrderReducer.completedOrder);
  const allProducts = useSelector((state) => state.ProductsReducer.allProducts);
  const user = useSelector((state) => state.UserReducer.user);

  const [open, setOpen] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const {
    handleSubmit,
    trigger,
    formState: { isValid },
    control,
  } = methods;

  const values = methods.watch();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'products',
  });

  useEffect(() => {
    dispatch(act.getCompletedOrder(params.component));
  }, [params.component]);

  const farms = useSelector((state) => state.FarmReducer.allFarms);
  const farm = farms.find((farm) => farm.id === order?.farm_id);

  const handleClick = () => {
    if (!isValid) {
      trigger();
      return;
    }
    farm.reviews.push({
      rate: methods.getValues('farmRate'),
      review: methods.getValues('farmReview'),
      user_id: user.user_id,
    });
    dispatch(act.updateFarmInfo(farm.id, 'reviews', farm.reviews));

    order.products.map((item, i) => {
      const product = allProducts.find((prod) => prod.id === item.id);

      let rate = values.products[i].rate;
      let review = values.products[i].review;
      product.reviews.push({
        rate: rate,
        review: review,
        user_id: user.user_id.split('|')[1],
      });
      return dispatch(act.updateProduct(product));
    });
    setOpen(true);
  };

  return (
    <Stack marginTop='50px'>
      <ReviewSuccessfulModal open={open} setOpen={setOpen} />
      <FormProvider {...methods}>
        {!order || order.length === 0 ? (
          <Typography variant='h3'>Loading</Typography>
        ) : (
          <form onSubmit={handleSubmit(handleClick)}>
            <Typography variant='h4'>Leave Reviews</Typography>

            <PrimaryButton
              width='fit-content'
              margin='50px 0 0 0'
              onClick={() => handleSubmit(handleClick())}
            >
              Submit All Feedback
            </PrimaryButton>

            <FarmReview
              farm={farm}
              user_id={order.customer_id}
              handleClick={handleClick}
            />
            <ProductReview
              products={order?.products}
              fields={fields}
              append={append}
              remove={remove}
              allProducts={allProducts}
              handleClick={handleClick}
            />

            <PrimaryButton
              width='fit-content'
              onClick={() => handleSubmit(handleClick())}
            >
              Submit All Feedback
            </PrimaryButton>
          </form>
        )}
      </FormProvider>
    </Stack>
  );
};

export default LeaveReviewPage;
