import React from 'react';
import PrimaryButton from '../../../inputs/Buttons/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useForm} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import DialogModal from '../../../display/dialog';
import * as act from '../../../../store/actions';
import ReactPhoneInput from 'react-phone-input-mui';

export const EditAccountInfo = (props) => {
  const { open, showModal } = props;
  const {register, getValues, setValue}= useForm({
    mode: 'onSubmit',
  });
const handleInput = (e) => {
  setValue(`${e.target.name}`, e.target.value);
};
  
  const values = getValues();
  const dispatch = useDispatch();
  const handleUpdateAccountInfo = (e) => {
    let user_id = props.user.user_id;
    delete values['user_id'];
    delete values['username'];
    dispatch(act.updateUser(user_id, values));
    showModal();
  };

  const content = (
    <Stack>
      <Stack direction='row' justifyContent='space-between'>
        <TextField
          sx={{ width: '250px', marginRight: '10px' }}
          {...register('user_metadata.first_name')}
          label={'First Name'}
          defaultValue={props.user.user_metadata.first_name}
        />
        <TextField
          sx={{ width: '250px' }}
          {...register('user_metadata.last_name')}
          label={'Last Name'}
          defaultValue={props.user.user_metadata.last_name}
        />
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <TextField
          sx={{ width: '250px', marginRight: '10px' }}
          {...register('email')}
          label={'Email'}
          defaultValue={props.user.email}
        />
        <ReactPhoneInput
          {...register('user_metadata.phone')}
          id='phone'
          component={TextField}
          defaultCountry={['us']}
         
          inputExtraProps={{
            name: 'user_metadata.phone',
            margin: 'normal',
            autoComplete: true,
            width: '100%',
            label: 'Phone Number',
          }}
          onlyCountries={['us']}
          containerStyle={{ width: '49%' }}
          value={props.user.user_metadata.phone}
          defaultValue={props.user.user_metadata.phone}
          onChange={(e) => {
            handleInput({
              target: {
                name: 'user_metadata.phone',
                value: e,
              },
            });
          }}
        />
      </Stack>
    </Stack>
  );
  const actions = (
    <Stack direction='row' justifyContent='space-between' width='70%'>
      <PrimaryButton
        type='button'
        width='150px'
        onClick={handleUpdateAccountInfo}
      >
        Save
      </PrimaryButton>
      <PrimaryButton type='button' width='150px' onClick={() => showModal()}>
        Cancel
      </PrimaryButton>
    </Stack>
  );

  return (
    <DialogModal
      open={open}
      title={'Edit Account Information'}
      content={content}
      actions={actions}
    />
  );
};
export default EditAccountInfo;
