import React from 'react';
import Chart from 'react-google-charts';
import { Typography, Card } from '@mui/material';
import Stack from '@mui/material/Stack';
const UserChart = (props) => {
  const { users } = props;

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const year = new Date().getFullYear();

  const generateLineChartData = (users) => {
    if (!Array.isArray(users)) return [];
    const formattedData = [['month', 'farmers', 'customers']];

    months.forEach((month, index) => {
      const farmers = users.filter((user) => {
        const userYear = new Date(user.created_at).getFullYear();
        const userMonth = new Date(user.created_at).getMonth();

        return (
          userYear === year &&
          userMonth === `${index + 1}` &&
          user.app_metadata.roles.includes('rol_6iKGy6zISzQvAKQv')
        );
      });

      const customers = users.filter((user) => {
        const userYear = new Date(user.created_at).getFullYear();
        const userMonth = new Date(user.created_at).getMonth();

        return (
          userYear === year &&
          userMonth === `${index + 1}` &&
          user.app_metadata.roles.includes('rol_wrGOKhZXp0Yttlen')
        );
      });

      formattedData.push([month, farmers.length, customers.length]);
    });

    return formattedData;
  };

  return (
    <Stack
      boxSizing='border-box'
      borderRadius='2px'
      width='100%'
      height='360px'
      marginBottom='15px'
    >
      <Stack>
        <Typography
          variant='h6'
          variant='h6'
          lineHeight='22px'
          color='primary.lightFont'
        >
          New Users
        </Typography>

        <Card elevation={20} sx={{ marginTop: '23px' }}>
          <Chart
            width={'100%'}
            height={'300px'}
            chartType='LineChart'
            loader={<Typography variant='h3'>Loading Chart</Typography>}
            data={generateLineChartData(users)}
            options={{
              hAxis: {
                title: 'Month',
              },
              vAxis: {
                title: 'Users',
              },
              curveType: 'function',
              colors: ['#006F45', '#ED9013'],
              animation: {
                startup: true,
              },
            }}
          />
        </Card>
      </Stack>
    </Stack>
  );
};

export default UserChart;
