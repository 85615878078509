import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import Typography from "@mui/material/Typography"
import BlockIcon from '@mui/icons-material/Block';
import Block from "./Block";
import Unblock from "./Unblock"

const Blocks = (props) => {
  const { history } = props;

  return (
    <Accordion
      elevation={0}
      disableGutters
      sx={{
        bgcolor: 'primary.formOutline2',
        border: 'none',
        marginTop: '-10px',
      }}
    >
      <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
        <ListItemIcon >
          <BlockIcon sx={{ color: '#006F45' }} />
        </ListItemIcon>
        <Typography
          sx={{
            fontSize: '18px',
            color: 'primary.secondary',
          }}
        >
          Blocks
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0 0 0 70px', border: 'none' }}>
        <Block />
        <Unblock />
      </AccordionDetails>
    </Accordion>
  );
};

export default Blocks;
