import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Stack } from '@mui/material';
import * as act from '../../../store/actions';
import Popover from '../../display/popover';

const Favorite = (props) => {
  const { item, type, marginRight } = props;
  const [user, setUser] = useState(
    useSelector((state) => state.UserReducer.user)
  );

  const dispatch = useDispatch();

  const handleFavorite = (id) => {
    if (!user.user_id) {
      return
    }
    else {
      const array = user.user_metadata.favorites[type];
      if (array.includes(id)) {
        const index = array.indexOf(id);
        array.splice(index, 1);
        setUser({
          ...user,
          user_metadata: {
            ...user.user_metadata,
            favorites: {
              ...user.user_metadata.favorites,
              [`${type}`]: array,
            },
          },
        });
       dispatch(act.addFavorite(user));
      } else {
        const array = user.user_metadata.favorites[type];
        array.push(id);
        setUser({
          ...user,
          user_metadata: {
            ...user.user_metadata,
            favorites: {
              ...user.user_metadata.favorites,
              [`${type}`]: array,
            },
          },
        });
      
       dispatch(act.addFavorite(user));
      }
    }
  };

  return (
    <Stack>
      {user.user_id && user?.user_metadata?.favorites[type]?.includes(item) ? (
        <FavoriteIcon
          onClick={() => handleFavorite(item)}
          sx={{ color: 'primary.error', marginRight: marginRight }}
        />
      ) : user.user_id ? (
        <FavoriteBorderIcon
          onClick={() => handleFavorite(item)}
          sx={{ color: 'primary.error', marginRight: marginRight }}
        />
      ) : (
        <Popover
          component={<FavoriteBorderIcon />}
          message={'You must be signed in to add a favorite'}
        />
      )}
    </Stack>
  );
};

export default Favorite;
