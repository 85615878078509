import React from 'react';
import { useHistory } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { FarmOrdersIcon } from '../../assets/farmIconImages';

const Orders = (props) => {
  const history = useHistory();

  const { farm } = props;

  return (
    <Accordion
      elevation={0}
      sx={{
        bgcolor: 'primary.formOutline2',
        border: 'none',
        marginTop: '-10px',
      }}
    >
      <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
        <ListItemIcon sx={{ minWidth: '30px' }}>
          <FarmOrdersIcon {...props} />
        </ListItemIcon>
        <Typography
          sx={{
            fontSize: '18px',
            color: 'primary.secondary',
          }}
        >
          Orders
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          sx={{
            fontSize: '16px',
            margin: '5px 15px',
            color: 'primary.secondary',
          }}
          onClick={() => history.push(`/dashboard/orders/open/${farm.id}`)}
        >
          Open Orders
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            margin: '5px 15px',
            color: 'primary.secondary',
          }}
          onClick={() => history.push(`/dashboard/orders/completed/${farm.id}`)}
        >
          Completed
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            margin: '5px 15px',
            color: 'primary.secondary',
          }}
          onClick={() => history.push(`/dashboard/orders/returns/${farm.id}`)}
        >
          Refunds
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default Orders;
