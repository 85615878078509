import React from "react";
import { useFormContext } from "react-hook-form";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton"
import * as utils from "../../../../util"
import { styled } from '@mui/material/styles';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

 

const ReturnImages = (props) => {
  const { imageRemove} = props;
  const { watch, register, setValue } = useFormContext();
 

  const Input = styled('input')({
    display: 'none',
  });

  const handleImages = async (e) => {
  
     const options = {
      multi: true ,
       array: values.return_images,
      arrayName: e.target.id
     };
     utils.uploadImages(e, e.target.name, setValue, options);
  };
  
const values = watch()


  return (
    <Stack>
      <Typography component='p' variant='p'>
        Please add at least one picture of item(s) you would like refunded
      </Typography>
      <Stack direction='row'>
      {values.return_images
        ? values.return_images.map((field, i) => {
            return (
              <Stack key={i}  width="150px">
                <img
                  style={{ width: '120px', height: '80px', margin: '0 10px' }}
                  src={field}
                  alt='product'
                />
                <Typography component="p" variant="p"
                  style={{ textAlign: 'center', cursor: 'pointer' }}
                  onClick={() => imageRemove(i)}
                >
                  remove
                </Typography>
              </Stack>
            );
          })
        : null}
        </Stack>
      <InputLabel
        htmlFor='return_images'
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '125px',
          alignItems: 'center',
          textAlign: 'center',
          margin: '20px 0',
          color: 'black',
          border: '1px solid black',
          padding: '10px',
        }}
      >
        <Input
          accept='image/*'
          id='return_images'
          type='file'
          name='return_images'
          {...register('return_images', {
            validate: {
              required: () => values.return_images.length >=1 || "Must add at least one photo of the return products"
            }
          })}
          onChange={(e) => handleImages(e)}
        />
        <IconButton
          aria-label='upload picture'
          component='span'
        >
          <PhotoCamera />
        </IconButton>
        Add Photo
      </InputLabel>
    </Stack>
  );
}

export default ReturnImages;