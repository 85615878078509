import React from 'react';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import AccountInfo from '../components/AccountInfo';
import Password from '../components/Password';
import AddressInfo from '../components/AddressInfo';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ProfilePage = () => {
  const user = useSelector((state) => state.UserReducer.user);

 
  return (
    <Stack >     
        <Stack marginTop="50px">
          <Typography variant='h3'>My Profile</Typography>
          <AccountInfo user={user} />
          <Password user={user} />
          <AddressInfo user={user} />
        </Stack>
     
    </Stack>
  );
};

export default ProfilePage;
