import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppBar, Toolbar, Typography, Chip } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchBar from './components/SearchBar';
import AccountIcons from './components/AccountIcons';
import { useHistory,} from 'react-router-dom';
import Stack from '@mui/material/Stack';
import AccountMenu from './components/AccountMenu';

import * as utils from '../../util';
import * as act from '../../store/actions';
import StackedLogoComponent from './components/StackedLogo';
import HamburgerMenu from './Menu';
import MobileViewAlert from '../misc/components/MobileViewAlert';

const ShoppingNav = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  

  const [user, setUser] = useState(
    useSelector((state) => state.UserReducer.user)
  );
  const cart = useSelector((state) => state.CartReducer.userCart);
  const allProducts = useSelector((state) => state.ProductsReducer.allProducts);

  useEffect(() => {
    cart?.user_id && utils.cartCount(cart);
  }, [cart?.user_id]);

  return (
    <AppBar
      sx={{
        width: '100%',
        color: 'primary.darkFont',
        background: '#fff',
      }}
    >
      <MobileViewAlert/>
      <Toolbar
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '80%',
          margin: 'auto',
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          height='100px'
          justifyContent='space-between'
          width='90%'
        >
          <Stack direction='row' alignItems='center'>
            {user?.user_metadata?.zip ? (
              <Stack direction='row' alignItems='center'>
                <LocationOnIcon
                  sx={{
                    fontSize: '18px',
                    color: 'primary.radio',
                    margin: '0 5px',
                  }}
                />
                <Typography variant='p'>{user.user_metadata.zip}</Typography>{' '}
              </Stack>
            ) : null}
          </Stack>
          <StackedLogoComponent />

          <AccountMenu />
          <AccountIcons />
        </Stack>
        <SearchBar allProducts={allProducts} />
        <Stack
          direction='row'
          justifyContent='space-between'
          width='90%'
          margin='10px 0'
        >
          {utils.categories?.map((category, i) => {
            return (
              <Chip
                sx={({ breakpoints }) => ({
                  background: '#fff',
                  border: '2px solid #fbd200',
                  padding: '5px 10px',
                  [breakpoints.down('xlg')]: {
                    padding: '5px',
                  },
                  [breakpoints.down('lg')]: {
                    display: 'none',
                  },
                })}
                key={i}
                label={category}
                onClick={() => {
                  dispatch(act.searchByCategory(category));
                  history.push('/store/products');
                }}
              />
            );
          })}
          <HamburgerMenu
           categories={utils.categories}
            display='none'
            mediaSize='lg'
            media={{ display: 'flex' }}
          />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default ShoppingNav;
