import React, { useState } from 'react';
import PasswordModal from '../modals/PasswordModal';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import PasswordChangeModal from '../modals/PasswordChange';

const Password = (props) => {
  const { user } = props;
  const [open, setOpen] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);

  const showModal = () => {
    setOpen(!open);
  };
  return (
    <Card
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '400px',
        padding: '20px',
        margin: '20px 0',
      }}
    >
      <Typography variant='h4'>Password</Typography>
      <Typography variant='p'>********</Typography>
      <Typography variant='a' sx={{ mt: 2 }} onClick={() => setOpen(true)}>
        Change Password
      </Typography>
      <PasswordModal
        user={user}
        open={open}
        setOpen={setOpen}
        showModal={showModal}
        setPasswordSuccess={setPasswordSuccess}
      />
      <PasswordChangeModal
        open={passwordSuccess}
        setOpen={setPasswordSuccess}
      />
    </Card>
  );
};

export default Password;
