import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProductCard from '../../products/components/productCard';
import PrimaryButton from '../../inputs/Buttons/Button';
import { FarmLogo } from '../../farm/components/FarmLogo';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TransparentButton from '../../inputs/Buttons/TransparentButton';
import { MoreLikeItemCard } from '../../products/components/MoreLikeItemCard';

const Favorites = (props) => {
  const allProducts = useSelector((state) => state.ProductsReducer.allProducts);
  const favorites = useSelector(
    (state) => state.UserReducer.user.user_metadata.favorites
  );
  const allFarms = useSelector((state) => state.FarmReducer.allFarms);
  const user = useSelector((state) => state.UserReducer.user);

  const [products, setProducts] = useState([]);
  const [farms, setFarms] = useState([]);

  useEffect(() => {
    let prods = [];
    let farm = [];
    favorites.products.map((id) => {
      const prod = allProducts.find((product) => product.id === id);
      if (prod !== undefined) {
        prods.push(prod);
      }
    });
    favorites.farms.map((id) => {
      let favoriteFarm = allFarms.find((farm) => farm.id === id);
      if (favoriteFarm !== undefined) {
        farm.push(favoriteFarm);
      }
    });
    setProducts(prods);
    setFarms(farm);
  }, []);

  return (
    <Stack marginTop='50px'>
      <Typography variant='h3'>My Favorites</Typography>
      <Typography variant='h4' margin='30px 0'>
        Favorite Items
      </Typography>
      {favorites.products.length <= 0 ? (
        <Typography variant='p'>You have no favorite products</Typography>
      ) : (
        <Stack>
          <Stack direction='row'>
            {products.map((product) => {
              return (
                <MoreLikeItemCard
                  key={product.id}
                  product={product}
                  elevation={10}
                />
              );
            })}
          </Stack>
        </Stack>
      )}
      <Typography variant='h4' marginTop='100px' marginBottom='30px'>
        Favorite Farms
      </Typography>
      {farms.length <= 0 ? (
        <Typography variant='p'>You have no favorite farms</Typography>
      ) : (
        <Stack>
          <Stack direction='row'>
            {farms.map((farm) => {
              return (
                <FarmLogo
                  key={farm.id}
                  farm={farm}
                  linkDisplay={'none'}
                  milesDisplay={'none'}
                />
              );
            })}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default Favorites;
