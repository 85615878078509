import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as act from '../../../../store/actions';

const ReturnProducts = (props) => {
  const { product } = props;
  const methods = useFormContext();
  const { watch, control, setValue } = methods;
  const values = watch();

  const handleProductReturn = (e, product) => {
    if (values.return_products.includes(product.id)) {
      const index = values.return_products.indexOf(product.id);
      values.return_products.splice(index, 1);
    } else {
      setValue('return_products', [...values.return_products, product]);
    }
  };
  const [item, setItem] = useState();


  useEffect(() => {
    act
      .getProduct(product?.id)
      .then((response) => {
        setItem(response);
      })
      .catch((err) => {
        console.dir(err);
      });
  }, [product]);

  return (
    <Stack margin='20px 0'>
      <Stack key={item?.id} width='70%'>
        <Controller
          name='return_products'
          rules={{
            required: true,
            message: 'Must select at least one product to refund',
          }}
          render={({ field }) => {
            return (
              <FormControlLabel
                sx={{ width: '100%' }}
                control={
                  <Checkbox
                    value={product.id}
                    checked={field.id}
                    onChange={(e) => handleProductReturn(e, product)}
                    sx={{
                      color: 'primary.radio',
                      '&.Mui-checked': {
                        color: 'primary.radio',
                      },
                    }}
                  />
                }
                label={
                  <Stack direction='row' marginTop='10px'>
                    <img
                      width='100px'
                      height='100px'
                      style={{ objectFit: 'cover' }}
                      src={item?.images[0]}
                      alt={item?.name}
                    />
                    <Stack marginLeft='10px'>
                      <Typography variant='h6'>{product.name}</Typography>
                      <Typography component='p'>
                        ${product.price.toFixed(2)} / {item?.label}
                      </Typography>
                    </Stack>
                  </Stack>
                }
              />
            );
          }}
          control={control}
        />
      </Stack>
    </Stack>
  );
};

export default ReturnProducts;
