import React from "react";
import Typography from "@mui/material/Typography";

const Policies = (props) => {
  const { farm } = props;

  return (
    <Typography variant="p" component="p">{farm.policies ? farm.policies : "This farm has no policies"}</Typography>
  )
}
export default Policies;