import React from 'react';
import Chart from 'react-google-charts';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

const RefundChart = (props) => {
  const { totalRefundsRequested, totalRefundsSent, totalRefundsDecline } =
    props;

  const formatDonutChartData = (data) => {
    const formattedData = [['Return', 'ReturnCount']];

    formattedData.push(['Requested', data.totalRefundsRequested]);
    formattedData.push(['Sent', data.totalRefundsSent]);
    formattedData.push(['Denied', data.totalRefundsDecline]);

    return formattedData;
  };

  return (
    <Stack
      boxSizing='border-box'
      borderRadius='2px'
      width='100%'
      height='360px'
      marginBottom='15px'
    >
      <Typography variant='h6' lineHeight='22px' color='primary.lightFont'>
        Refunds
      </Typography>
      <Stack>
        <Stack
          width='100%'
          padding='0 20px'
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
        >
          <Typography
            variant='p'
            component='p'
            color='primary.lightFont'
            fontSize='16px'
          >
            Total Refunds Requested{' '}
            <Typography variant='p' color='primary.darkFont'>
              {totalRefundsRequested}
            </Typography>
          </Typography>
        </Stack>
        <Card elevation={20}>
          <Chart
            width={'100%'}
            height={'250px'}
            chartType='PieChart'
            data={formatDonutChartData({
              totalRefundsRequested,
              totalRefundsSent,
              totalRefundsDecline,
            })}
            loader={<Typography variant='h3'>Loading Chart</Typography>}
            options={{
              pieHole: 0.6,
              legend: { position: 'top' },
              legend: { alignment: 'center' },
              colors: ['#FBD200', '#006F45', '#0054BC'],
            }}
          />
        </Card>
      </Stack>
    </Stack>
  );
};

export default RefundChart;
