import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import ProductCard from '../components/productCard';
import { Filter } from '../components/filter';
import { Box, Typography } from '@mui/material';
import ShoppingNav from '../../nav/ShoppingNav';
import FilterMenu from '../components/filter/FilterMenu';

export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const SEARCHED_PRODUCTS = 'SEARCHED_PRODUCTS';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';

const ProductList = (props) => {
  const { categories, dietaryOptions } = props;

  const category = useSelector(
    (state) => state.ProductsReducer.searchedCategory
  );

  const [products, setProducts] = useState(
    useSelector((state) => state.ProductsReducer.searchedProducts)
  );
  const allProducts = useSelector((state) => state.ProductsReducer.allProducts);

  const HeaderImage = () => {
    switch (category) {
      case 'Bakery':
        return 'bakery';
      case 'Dairy':
        return 'dairy';
      case 'Fruits':
        return 'fruits';
      case 'Meat':
        return 'meat';
      case 'Nuts & Snacks':
        return 'nuts';
      case 'Pantry':
        return 'pantry';
      case 'Seafood':
        return 'seafood';
      case 'Vegetables':
        return 'veggies';
      case 'All':
        return 'market';
      default:
        return 'market';
    }
  };
  const imageName = HeaderImage();

  return (
    <Box
      sx={{
        margin: 'auto',
      
        width: '90%',
      }}
    >
      <ShoppingNav
        categories={categories}
        products={products ? products : []}
        setProducts={setProducts}
        allProducts={allProducts}
      />
      <Box className={`${imageName}`} sx={({ breakpoints }) => ({
        display: 'none',
        [breakpoints.up('sm')]: {
          display: "flex"
        }
      })}></Box>
      <Box
        sx={{
          display: 'flex',
          margin: '20px auto',
          width: '100%',
        }}
      >
        <Filter
          categories={categories ? categories : []}
          dietaryOptions={dietaryOptions ? dietaryOptions : []}
          products={products ? products : []}
          setProducts={setProducts}
          allProducts={allProducts}
        />
        <FilterMenu
          categories={categories ? categories : []}
          dietaryOptions={dietaryOptions ? dietaryOptions : []}
          products={products ? products : []}
          setProducts={setProducts}
          allProducts={allProducts}
        />
        <Box className='productList'>
          {products.length === 0 ? (
            <Typography
              variant='h3'
              sx={({ breakpoints }) => ({
                margin: ' 10px auto',
                [breakpoints.down('mobile')]: {
                  fontSize: '20px',
                },
              })}
            >
              {' '}
              Your search produced no results
            </Typography>
          ) : (
            products?.map((product) => {
              return (product.product_status === 'active') ?
                  <ProductCard
                    key={product.id}
                    elevation={20}
                    product={product}
                    cardHeight={'520px'}
                    {...props}
                  />
                : null
            })
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProductList;
