import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import Stack from '@mui/material/Stack';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

const SearchBar = (props) => {
  const { search, title } = props;

  return (
    <Stack marginTop='50px'>
      <FormControl>
        <InputLabel
          sx={{ fontSize: ' 20px' }}
          htmlFor='input-with-icon-adornment'
        >
          {title}
        </InputLabel>
        <Input
          onChange={search}
          id='input-with-icon-adornment'
          startAdornment={
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
    </Stack>
  );
};

export default SearchBar;
