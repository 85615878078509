import React from 'react';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { ListItem, ListItemText, ListItemIcon, List } from '@mui/material';

import { useHistory } from 'react-router-dom';
import Flags from '../accordions/Flags';
import Blocks from '../accordions/Blocks';

const SuperSideNav = (props) => {
  const history = useHistory();

  return (
    <List sx={{ padding: '0' }}>
      <ListItem
        onClick={() => history.push('/dashboard/user')}
        sx={{ cursor: 'pointer' }}
      >
        <ListItemIcon>
          <PersonAddAltOutlinedIcon sx={{ color: '#006F45' }} />
        </ListItemIcon>
        <ListItemText primary='Create User' />
      </ListItem>
      <Flags history={history} />
      <Blocks history={history} />
    </List>
  );
};
export default SuperSideNav;
