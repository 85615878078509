import React from "react";
import ResponsiveDrawer from "../../reusable/ResponsiveDrawer";
import AdminTopBar from "../../nav/AdminNav";
import SideBar from "../components/SideBar";
import AdminPages from "./AdminPages";

const AdminDashboard = (props) => {

  return (
    <ResponsiveDrawer
      drawerInfo={<SideBar/>}
      mainComponent={<AdminPages {...props} />}
      AppBar={<AdminTopBar />}
     bgcolor={'primary.formOutline2'}/>
  )
}
export default AdminDashboard;