import S3 from "react-aws-s3";

export const handleArrayDelete = (array, setArray, zip) => {
  const index = array.indexOf(zip);
  array.splice(index, 1);
  setArray(array);
};

export const handleKeyUp = (e) => {
  var letter = document.getElementById("letter");
  var capital = document.getElementById("capital");
  var number = document.getElementById("number");
  var length = document.getElementById("length");

  // Validate lowercase letters
  var lowerCaseLetters = /[a-z]/g;
  if (e.target.value.match(lowerCaseLetters)) {
    letter.classList.remove("invalid");
    letter.classList.add("valid");
    // letter.style.color = "green"
  } else {
    letter.classList.remove("valid");
    letter.classList.add("invalid");
    // letter.style.color = '#000';
  }

  // Validate capital letters
  var upperCaseLetters = /[A-Z]/g;
  if (e.target.value.match(upperCaseLetters)) {
    capital.classList.remove("invalid");
    capital.classList.add("valid");
  } else {
    capital.classList.remove("valid");
    capital.classList.add("invalid");
  }

  // Validate numbers
  var numbers = /[0-9]/g;
  if (e.target.value.match(numbers)) {
    number.classList.remove("invalid");
    number.classList.add("valid");
  } else {
    number.classList.remove("valid");
    number.classList.add("invalid");
  }

  // Validate length
  if (e.target.value.length >= 8) {
    length.classList.remove("invalid");
    length.classList.add("valid");
  } else {
    length.classList.remove("valid");
    length.classList.add("invalid");
  }
};

export const uploadImages = (e, name, setValue, options) => {
  
  const {
    setState,
    state,
    multi,
    array,
    arrayName,
    change,
    remove,
    num,
  } = options;
let trimmed = e.target.files[0].name.replaceAll(' ', '')

  let file = e.target.files[0];
  let newFileName = trimmed;
  
  const config = {
    bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  };
  const ReactS3Client = new S3(config);
  ReactS3Client.uploadFile(file, newFileName)
    .then((data) => {
      if (multi && !change) {
     
        setValue(`${arrayName}`, [...array, data.location]);
        
        setState &&
        setState({
          ...state,
          [arrayName]: [...array, data.location ],
        });
       
      } else if (multi && change) {
        remove(num);
        array.splice(num, 1, data.location);
       setValue(`${arrayName}`, [array]);
      } else if (!multi && change) {
        setValue(`${arrayName}`, data.location);
        setState({
          ...state,
          arrayName: data.location,
        });
      } else {
        setValue(`${arrayName}`, data.location);
        setState({
          ...state,
          [`${arrayName}`]: data.location,
        });
      }
    })
    .catch((e) => {
      return e
    });
};


export const handleFileUpload = async(e) => {
  try {

  
    let trimmed = e.target.files[0].name.replaceAll(' ', '')

    let file = e.target.files[0];
    let newFileName = trimmed;
  
    const config = {
      bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
      region: process.env.REACT_APP_AWS_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    };
    const ReactS3Client = new S3(config);
    const fileLink = await ReactS3Client.uploadFile(file, newFileName)
    return fileLink;
  } catch (e) {
    console.log(e)
  }
    
};

