import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import QuickGlance from '../components/QuickGlance';
import FarmsList from '../components/FarmsList';
import FarmPanel from '../components/FarmPanel';
import SideBar from '../components/SideBar';
import { API } from 'aws-amplify';
import Stack from '@mui/material/Stack';
import BlockUser from '../superComponents/components/Block/BlockUser';
import BlockFarm from '../superComponents/components/Block/BlockFarm';
import ReturnsByCustomer from '../components/ReturnsByCustomer';
import ReturnsByFarm from "../components/RefundsByFarm"
import CreateUser from '../superComponents/components/createNewUser';
import * as act from '../../../store/actions';
import FlagUsers from '../superComponents/components/Flag/FlagUsers';
import FlagFarm from '../superComponents/components/Flag/FlagFarm';
import UnflagFarm from '../superComponents/components/Unflag/UnflagFarms';
import UnflagUsers from '../superComponents/components/Unflag/UnflagUsers';
import UnblockUser from '../superComponents/components/Unblock/UnblockUser';
import UnblockFarm from '../superComponents/components/Unblock/UnblockFarm';

const AdminPages = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
 
  const priceDataInit = {
    total: 0,
    subtotal: 0,
    shipping: 0,
  };

  const ratingsInit = {
    one: 0,
    two: 0,
    three: 0,
    four: 0,
    five: 0,
  };

  const deliveryCountInit = {
    ship: 0,
    deliver: 0,
    pickup: 0,
  };

  const [loading, setLoading] = useState(true);
  const [loadingRatingStats, setLoadingRatingStats] = useState(false);
  const [totalRatingsCount, setTotalRatingsCount] = useState(ratingsInit);
  const [totalDeliveryCount, setTotalDeliveryCount] =
    useState(deliveryCountInit);
  const [farmRatingData, setFarmRatingData] = useState({});
  const [farmShippingData, setFarmShippingData] = useState({});

  // Counts
  const [productsCounts, setProductsCounts] = useState({ total: 0 });
  const [totalOrderCount, setTotalOrderCount] = useState(0);
  const [totalRefundsRequested, setTotalRefundsRequested] = useState(0);
  const [totalRefundsSent, setTotalRefundsSent] = useState(0);
  const [totalRefundsDenied, setTotalRefundsDenied] = useState(0);
  const [totalNewOrders, setTotalNewOrders] = useState(0);
  const [totalShippedOrders, setTotalShippedOrders] = useState(0);

  const [orders, setOrders] = useState([]);
  const [farmOrderData, setFarmOrderData] = useState({});
  const [farmReturnsRequestedData, setFarmReturnsRequestedData] = useState({});
  const [farmReturnsSentData, setFarmReturnsSentData] = useState({});
  const [totalPriceData, setTotalPriceData] = useState(priceDataInit);
  const [farmNewOrderData, setFarmNewOrderData] = useState({});
  const [farmShippedOrdersData, setFarmShippedOrdersData] = useState({});

  const farms = useSelector((store) => store.FarmReducer.allFarms);
  const users = useSelector((state) => state.UserReducer.allUsers);

  const generateShippingData = () => {
    const deliveryCountMap = { ...deliveryCountInit };
    const checkedShippingMap = {};

    farms.forEach((farm) => {
      const farmId = farm?.id;
      const { ship, pickup, deliver } = farm?.delivery_methods;

      if (ship) deliveryCountMap.ship += 1;
      if (deliver) deliveryCountMap.deliver += 1;
      if (pickup) deliveryCountMap.pickup += 1;

      checkedShippingMap[farmId] = { ship, deliver, pickup };
    });

    setTotalDeliveryCount(deliveryCountMap);
    setFarmShippingData(checkedShippingMap);
  };

  const generateReviewData = () => {
    const ratingsCountMap = { ...ratingsInit };
    const checkedRatingsMap = {};

    farms.forEach((farm) => {
      const farmId = farm?.id;
      const reviews = farm?.reviews || [];

      if (reviews.length) {
        const farmRatings = { ...ratingsInit };

        reviews.forEach((review) => {
          switch (review.rate) {
            case 1:
              ratingsCountMap.one += 1;
              farmRatings.one += 1;
              break;

            case 2:
              ratingsCountMap.two += 1;
              farmRatings.two += 1;
              break;

            case 3:
              ratingsCountMap.three += 1;
              farmRatings.three += 1;
              break;

            case 4:
              ratingsCountMap.four += 1;
              farmRatings.four += 1;
              break;

            case 5:
              ratingsCountMap.five += 1;
              farmRatings.five += 1;
              break;
            default:
              break;
          }
        });

        checkedRatingsMap[farmId] = farmRatings;
      }
    });

    setTotalRatingsCount(ratingsCountMap);
    setFarmRatingData(checkedRatingsMap);
  };

  const generateProductMap = (products, count) => {
    const productsCountMap = { total: count };

    farms.forEach((farm) => {
      const farmId = farm?.id;
      const farmProducts = products.filter(
        (product) =>
          (product.farm_id ? product.farm_id : product.farm_id) === farmId
      );

      productsCountMap[farmId] = { farmProducts, count: farmProducts.length };
    });

    setProductsCounts(productsCountMap);
  };

  const generateOrderData = async () => {
    try {
      API.get('FarmEraMarketAPI', '/orders')
        .then((response) => {
          const allOrders = response.orders;
          const orderCount = response.count;
          const farmOrdersMap = {};
          const farmReturnsRequestedMap = {};
          const farmReturnsSentMap = {};
          const farmNewOrdersMap = {};
          const farmShippedOrdersMap = {};
          const farmReturnsDeclinedMap = {};

          let returnsRequestedCount = 0;
          let returnsSentCount = 0;
          let returnsDeniedCount = 0;
          let newOrdersCount = 0;
          let shippedOrdersCount = 0;

          farms.forEach((farm) => {
            const farmId = farm?.id;

            farmOrdersMap[farmId] = [];
            farmReturnsRequestedMap[farmId] = [];
            farmReturnsSentMap[farmId] = [];
            farmNewOrdersMap[farmId] = [];
            farmShippedOrdersMap[farmId] = [];
            farmReturnsDeclinedMap[farmId] = [];

            allOrders.length >= 1 &&
              allOrders.forEach((order) => {
                if (order.farm_id === farmId) {
                  farmOrdersMap[farmId].push(order);

                  const status = order.order_status.toLowerCase();

                  switch (status) {
                    case 'new':
                      farmNewOrdersMap[farmId].push(order);
                      newOrdersCount += 1;
                      break;

                    case 'refund requested':
                      farmReturnsRequestedMap[farmId].push(order);
                      returnsRequestedCount += 1;
                      break;

                    case 'approved':
                      farmReturnsSentMap[farmId].push(order);
                      returnsSentCount += 1;
                      break;

                    case 'shipped':
                      farmShippedOrdersMap[farmId].push(order);
                      shippedOrdersCount += 1;
                      break;
                    case 'declined':
                      farmReturnsDeclinedMap[farmId].push(order);
                      returnsDeniedCount += 1;
                      break;

                    default:
                      break;
                  }
                }
              });
          });

          setOrders(allOrders);
          setTotalOrderCount(orderCount);
          setTotalRefundsRequested(returnsRequestedCount);
          setTotalRefundsSent(returnsSentCount);
          setTotalNewOrders(newOrdersCount);
          setTotalShippedOrders(shippedOrdersCount);
          setTotalRefundsDenied(returnsDeniedCount);

          setFarmOrderData(farmOrdersMap);
          setFarmReturnsRequestedData(farmReturnsRequestedMap);
          setFarmReturnsSentData(farmReturnsSentMap);
          setFarmNewOrderData(farmNewOrdersMap);
          setFarmShippedOrdersData(farmShippedOrdersMap);
        })
        .catch((err) => console.dir(err));
    } catch (error) {
      console.error(error);

      return [];
    }
  };

  const generatePriceData = () => {
    const priceMap = { subtotal: 0, total: 0, shipping: 0 };

    orders.length >= 1 &&
      orders?.forEach((order) => {
        const paymentInfo = order.payment;

        if (paymentInfo) {
          priceMap.total += paymentInfo.total;
          priceMap.subtotal += paymentInfo.subtotal;
          priceMap.shipping += paymentInfo.shipping;
        }
      });

    setTotalPriceData(priceMap);
    setLoading(false);
  };
  const products = useSelector((state) => state.ProductsReducer);
  const retrieveData = async () => {
    try {
      act.getAllProducts();

      generateProductMap(products.allProducts, products.productCount);
    } catch (error) {
      console.error(error, 'ERROR');

      return [];
    }
  };

  useEffect(() => {
    // generateFarmData();
    setLoading(true);
    generateShippingData();
    generateReviewData();

    retrieveData()
      .then(() => {
        generateOrderData();
      })
      .catch((error) => console.error(error));
  }, [farms]);

  useEffect(() => {
    generatePriceData();
  }, [orders]);

  useEffect(() => dispatch(act.getAllUsers()), [users?.length]);

  switch (params.path) {
    case 'overview':
      return (
        <QuickGlance
          farms={farms}
          totalRatingsCount={totalRatingsCount}
          totalDeliveryCount={totalDeliveryCount}
          totalPriceData={totalPriceData}
          productsCount={productsCounts.total}
          totalOrderCount={totalOrderCount}
          totalRefundsRequested={totalRefundsRequested}
          totalRefundsSent={totalRefundsSent}
          totalNewOrders={totalNewOrders}
          totalShippedOrders={totalShippedOrders}
          loadingRatingStats={loadingRatingStats}
          users={users}
          orders={orders}
          {...props}
        />
      );
    case 'farms':
      return (
        <FarmsList
          farms={farms}
          farmRatingData={farmRatingData}
          farmShippingData={farmShippingData}
          productsCountMap={productsCounts}
          farmOrderData={farmOrderData}
          farmNewOrderData={farmNewOrderData}
          farmReturnsRequestedData={farmReturnsRequestedData}
          farmReturnsSentData={farmReturnsSentData}
          farmShippedOrdersData={farmShippedOrdersData}
          {...props}
        />
      );

    case 'farm':
      return (
        <FarmPanel
          farms={farms}
          orders={orders}
          users={users}
          productsCountMap={productsCounts}
          farmRatingData={farmRatingData}
          farmShippingData={farmShippingData}
          farmOrderData={farmOrderData}
          farmNewOrdersData={farmNewOrderData}
          farmReturnsRequestedData={farmReturnsRequestedData}
          farmReturnsSentData={farmReturnsSentData}
          farmShippedOrdersData={farmShippedOrdersData}
          {...props}
        />
      );
    case 'user':
      return <CreateUser />;
    case 'flag-customer':
      return (
        <FlagUsers
          title='Flag Customer'
          searchTitle='Search Customer by Email'
          successTitle={'Customer Flagged Successfully'}
          toFlagTitle={'Customer to Flag'}
        />
      );
    case 'flag-farmer':
      return (
        <FlagUsers
          title='Flag Farmer'
          searchTitle='Search Farmer by Email'
          successTitle={'Farmer Flagged Successfully'}
          toFlagTitle={'Farmer to Flag'}
        />
      );
    case 'flag-farm':
      return (
        <FlagFarm
          title='Flag Farm'
          searchTitle='Search Farm by Name'
          successTitle={'Farm Flagged Successfully'}
          toFlagTitle={'Farm to Flag'}
        />
      );
    case 'unflag-farm':
      return (
        <UnflagFarm
          title={'Unflag Farm'}
          searchTitle={'Search Farm by Name'}
          successTitle={'Farm unFlagged Successful'}
          toFlagTitle={'Farm to Flag'}
        />
      );
    case 'unflag-farmer':
      return (
        <UnflagUsers
          title={'Unflag Farmer'}
          searchTitle={'Search Farmer by Email'}
          successTitle={'Farm unFlagged Successful'}
          toFlagTitle={'Farm to Flag'}
        />
      );
    case 'unflag-customer':
      return (
        <UnflagUsers
          title={'Unflag Customer'}
          searchTitle={'Search Customer by Email'}
          successTitle={'Customer unFlagged Successful'}
          toFlagTitle={'Customer to Flag'}
        />
      );
    case 'block-customer':
      return (
        <BlockUser
          title='Block Customer'
          searchTitle='Search Customer by Email'
          successTitle={'Customer Block Successful'}
          toFlagTitle={'Customer to Block'}
        />
      );
    case 'block-farm':
      return (
        <BlockFarm
          title='Block Farm'
          searchTitle='Search Farm by Name'
          successTitle={'Farm Block Successful'}
          toFlagTitle={'Farm to Block'}
        />
      );
    case 'block-farmer':
      return (
        <BlockUser
          title='Block Farmer'
          searchTitle='Search Farmer by Email'
          successTitle={'Farmer Block Successful'}
          toFlagTitle={'Farmer to Block'}
        />
      );
    case 'unblock-farmer':
      return (
        <UnblockUser
          title='Unblock Farmer'
          searchTitle='Search Farmer by Email'
          successTitle={'Farmer Unblock Successful'}
          toFlagTitle={'Farmer to Unblock'}
        />
      );
    case 'unblock-customer':
      return (
        <UnblockUser
          title='Unblock Customer'
          searchTitle='Search Customer by Email'
          successTitle={'Customer Unblock Successful'}
          toFlagTitle={'Customer to Block'}
        />
      );
    case 'unblock-farm':
      return (
        <UnblockFarm
          title='Unblock Farm'
          searchTitle='Search Farm by Name'
          successTitle={'Farm Unblock Successful'}
          toFlagTitle={'Farm to Unblock'}
        />
      );
    case 'refunds-by-customer':
      return <ReturnsByCustomer />;
    case 'refunds-by-farm':
      return <ReturnsByFarm />;
    default:
      return (
        <QuickGlance
          farms={farms}
          totalRatingsCount={totalRatingsCount}
          totalDeliveryCount={totalDeliveryCount}
          totalPriceData={totalPriceData}
          productsCount={productsCounts.total}
          totalOrderCount={totalOrderCount}
          totalRefundsRequested={totalRefundsRequested}
          totalRefundsSent={totalRefundsSent}
          totalNewOrders={totalNewOrders}
          totalShippedOrders={totalShippedOrders}
          loadingRatingStats={loadingRatingStats}
          users={users}
          orders={orders}
          {...props}
        />
      );
  }
};

export default AdminPages;
