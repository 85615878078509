import React from 'react';
import { useFormContext } from 'react-hook-form';
import TextField from "@mui/material/TextField";

const UseFormTextField = (props) => {
  const {name, label, rules, width, inputProps, onInput, onChange, onKeyDown, rows} = props;

  const { register, } = useFormContext({
    mode: 'onChange',
    revalidationMode:'onChange'
  })

 
  return (
    <TextField
      label={label}
      sx={{ width: width }}
      inputProps={inputProps}
      {...register(name, 
      rules)}
      rows={rows}
      multiline
      onInput={(e) => onInput(e)}
      onChange={(e) => onChange(e)}
      onKeyDown={(e) => onKeyDown(e)}
     
    />
  );
}

export default UseFormTextField;