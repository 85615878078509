import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MoreLikeItemCard } from '../products/components/MoreLikeItemCard';
import useMediaQuery from '@mui/material/useMediaQuery';
import ShoppingNav from '../nav/ShoppingNav';
import {
  imageBoxImages,
  categoryImages,
  featuresImages,
} from '../assets/imageList';
import * as act from '../../store/actions';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PrimaryButton from '../inputs/Buttons/Button';
import ImageListComponent from '../display/ImageList';

const MarketingPage = (props) => {
  const { categories } = props;
  const dispatch = useDispatch();
  const allProducts = useSelector((state) => state.ProductsReducer.allProducts);
  const [products, setProducts] = useState(
    useSelector((state) => state.ProductsReducer.searchedProducts)
  );
  const randomNumberStart = (Math.random() * products?.length).toFixed();
  useEffect(() => {}, [products]);

  const history = useHistory();
  const handleCategorySearch = (category) => {
    dispatch(act.searchByCategory(category, allProducts));
    props.history.push('/store/products');
  };
  const mdScreen = useMediaQuery('(max-width: 1000px )');
  const lgScreen = useMediaQuery('(max-width:850px)');
  const smScreen = useMediaQuery('(max-width:650px)');
  const mobile = useMediaQuery('(max-width:510px)');

  const handleShopNow = () => {
    //  setCategory('All');
    dispatch(act.getAllProducts());
    history.push('/store/products');
  };

  return (
    <Stack width='80%' margin='auto'>
      <ShoppingNav
        products={products}
        setProducts={setProducts}
        categories={categories}
        {...props}
      />
      <Stack
        marginTop='250px'
        marginBottom='20px'
        direction='row'
        width='100%'
        minHeight='480px'
        justifyContent='space-between'
        sx={({ breakpoints }) => ({
          [breakpoints.down('mobile')]: {
            flexDirection: 'column',
          },
        })}
      >
        <Stack
          width='49%'
          position='relative'
          height='480px'
          sx={({ breakpoints }) => ({
            backgroundImage:
              'url(https://farmerafiles130857-dev.s3.us-east-2.amazonaws.com/hero_image_f87343.jpg)',
            backgroundRepeat: 'noRepeat',
            backgroundPositionX: 'center',
            backgroundPositionY: 'center',
            backgroundSize: 'cover',
            [breakpoints.down('mobile')]: {
              width: '100%',
            },
          })}
        >
          <Stack
            position='absolute'
            bottom='0'
            width='100%'
            height='40%'
            sx={({ breakpoints }) => ({
              background: 'rgba(209, 204, 204, 0.5)',
              [breakpoints.down('xlg')]: {
                height: '50%',
              },
              [breakpoints.down('lg')]: {
                height: '70%',
              },
              [breakpoints.down('sm')]: {
                height: '100%',
              },
              [breakpoints.down('mobile')]: {
                height: '50%',
              },
            })}
          >
            <Typography variant='h1' margin='20px 50px'>
              Farm <span style={{ color: 'white' }}>fresh</span> products right
              at your
              <span
                style={{ color: 'white' }}
                variant='h1'
                color='white'
                margin='0 5px'
              >
                {' '}
                doorstep
              </span>
            </Typography>
            <PrimaryButton
              margin='0 50px'
              width='40%'
              sx={[
                ({ breakpoints }) => ({
                  [breakpoints.down('xlg')]: {
                    width: '50%',
                  },
                  [breakpoints.down('lg')]: {
                    width: '70%',
                  },
                  [breakpoints.down('mobile')]: {
                    width: '50%',
                  },
                }),
              ]}
              onClick={(e) => handleShopNow()}
            >
              Shop Now
            </PrimaryButton>
          </Stack>
        </Stack>
        <Stack
          width='49%'
          position='relative'
          height='480px'
          sx={({ breakpoints }) => ({
            backgroundImage:
              'url(https://farmerafiles130857-dev.s3.us-east-2.amazonaws.com/hero_image_xgfy8a.jpg)',
            backgroundRepeat: 'noRepeat',
            backgroundPositionX: 'center',
            backgroundPositionY: 'center',
            backgroundSize: 'cover',
            [breakpoints.down('mobile')]: {
              width: '100%',
              marginTop: '10px',
            },
          })}
        >
          <Stack
            position='absolute'
            bottom='0'
            width='100%'
            height='40%'
            sx={({ breakpoints }) => ({
              background: 'rgba(209, 204, 204, 0.5)',
              [breakpoints.down('xlg')]: {
                height: '50%',
              },
              [breakpoints.down('lg')]: {
                height: '70%',
              },
              [breakpoints.down('sm')]: {
                height: '100%',
              },
              [breakpoints.down('mobile')]: {
                height: '50%',
              },
            })}
          >
            <Typography variant='h1' margin='20px 50px'>
              Open your <span style={{ color: 'white' }}>online store</span>{' '}
              today and start
              <span style={{ color: 'white' }}> selling</span>
            </Typography>
            <PrimaryButton
              margin='0 50px'
              width='40%'
              sx={[
                ({ breakpoints }) => ({
                  [breakpoints.down('xlg')]: {
                    width: '50%',
                  },
                  [breakpoints.down('lg')]: {
                    width: '70%',
                  },
                  [breakpoints.down('mobile')]: {
                    width: '50%',
                  },
                }),
              ]}
              onClick={() => props.history.push('/marketing/farm')}
            >
              Open Farm Stand
            </PrimaryButton>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction='row' justifyContent='space-between' width="100%" sx={{overflow: 'hidden'}}>
        {allProducts
          ?.splice(parseInt(randomNumberStart), parseInt(randomNumberStart) + 3)
          .map((product) => {
            return product.product_status === 'active' ? (
              <MoreLikeItemCard
                elevation={10}
                key={product.id}
                product={product}
                deliveryDisplay={'none'}
                buttonDisplay={'none'}
              />
            ) : null;
          })}
      </Stack>
      <ImageListComponent
        margin='20px 0'
        images={categoryImages}
        gap={10}
        cols={5}
        row={2}
        width={'100%'}
        onClick={handleCategorySearch}
      />
      <ImageListComponent
        images={featuresImages}
        cols={3}
        rows={1}
        gap={10}
        width={360}
        imgHeight={lgScreen ? 0.7 : mdScreen ? 0.5 : 0.4}
        rowHeight={
          mobile ? 600 : smScreen ? 700 : lgScreen ? 800 : mdScreen ? 750 : 700
        }
        position='below'
        itemBar
      />
      <Stack
        sx={{ backgroundColor: 'primary.formOutline2' }}
        padding='5%'
        margin='50px 0'
        width='100%'
      >
        <Typography variant='h1' textAlign='center' marginBottom='5%'>
          What is Farm Era?
        </Typography>
        <Stack
          direction='row'
          justifyContent='space-between'
          width='100%'
          sx={({ breakpoints }) => ({
            [breakpoints.down('med')]: {
              flexDirection: 'column',
            },
          })}
        >
          <Stack
            width='32%'
            sx={({ breakpoints }) => ({
              [breakpoints.down('med')]: {
                width: '100%',
              },
            })}
          >
            <Typography variant='h6' paddingBottom='2%'>
              A Community Of Connected Farmers
            </Typography>
            <Typography variant='p'>
              Farm Era Market is a platform where farmers from around the
              country can showcase their products and share their farm bios,
              allowing customers to choose items without locality and
              availability limitations. Unlike grocery store chains, products
              are picked, packaged, and shipped straight from our friendly
              farmers. We help encourage the sustainability of farmers by
              expanding their community.
            </Typography>
          </Stack>
          <Stack
            width='32%'
            sx={({ breakpoints }) => ({
              [breakpoints.down('med')]: {
                width: '100%',
              },
            })}
          >
            <Typography
              variant='h6'
              paddingBottom='2%'
              sx={({ breakpoints }) => ({
                [breakpoints.down('med')]: {
                  padding: '2% 0',
                },
              })}
            >
              The Online Farmers Market {`\u00AE`}
            </Typography>
            <Typography variant='p'>
              We are a family business interested in the availability of fresh,
              health food. Farm Era Market was developed during the early stages
              of the Covid19 pandemic when local farmers' markets and
              restaurants began to shut down, forcing farmers to destroy crops
              and minimize planting. Our platform allows for access to
              nationwide farm products all in one place.
            </Typography>
          </Stack>
          <Stack
            width='32%'
            sx={({ breakpoints }) => ({
              [breakpoints.down('med')]: {
                width: '100%',
              },
            })}
          >
            <Typography
              variant='h6'
              paddingBottom='2%'
              sx={({ breakpoints }) => ({
                [breakpoints.down('med')]: {
                  padding: '2% 0',
                },
              })}
            >
              A Movement For A Healthier World
            </Typography>
            <Typography variant='p'>
              At Farm Era Market, we provide support for our customers by
              encouraging the mind, body, spirit connection to their environment
              through wholesome, fresh food. We offer healthy tips for menu
              planning and provide seasonal food highlights. Check out our
              up-and-coming food blog, so you can eat healthy while supporting
              and encouraging the sustainability of small farms
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <ImageListComponent
        images={imageBoxImages}
        rows={2}
        cols={3}
        gap={0}
        imgHeight={370}
        rowHeight={370}
        position='below'
      />
      <Footer />
    </Stack>
  );
};

export default MarketingPage;
