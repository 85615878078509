import { API } from 'aws-amplify';
import * as act from './index';
import * as utils from '../../util';
const api = 'FarmEraMarketAPI';

export const transferPaymentToStripeAccount = (
  amount,
  stripeAccount,
  order,
  history
) => {
  const myInIt = {
    body: {
      amount: amount * 100,
      payment_intent: order.payment.payment_intent,
      destination: stripeAccount,
    },
  };

  API.post(api, '/payments/transfer', myInIt)
    .then((response) => {
      order.payment.transfer = response;
      utils.addCompletedOrder(order, history);
    })
    .catch((err) => {return err});
};

export const UPDATE_STRIPE_BALANCE = 'UPDATE_STRIPE_BALANCE';

export const getBalance = (accountId) => {
  return (dispatch) => {
    const myInIt = {
      body: {
        stripe_account: accountId,
      },
    };

    API.post(api, '/balance', myInIt)
      .then((response) => {
        let data = {
          available: response.balance.available[0].amount / 100,
          pending: response.balance.pending[0].amount / 100,
        };

        dispatch({
          type: UPDATE_STRIPE_BALANCE,
          payload: data,
        });
      })
      .catch((err) => {
       return err
      });
  };
};

export const createStripeLink = (setLoading, setUser, user, setConnected) => {
  return (dispatch) => {
    setLoading(true);
    API.post(api, '/payments/onboard-user')
      .then((response) => {
        dispatch({
          type: 'ADD_STRIPE',
          payload: response.account.id,
        });
        window.open(response.url);
        setUser({
          ...user,
          user_metadata: {
            ...user.user_metadata,
            stripe_acct: response.account.id,
          },
        });
        setConnected(response.account.id);
        setLoading(false);
      })
      .catch((err) => {
        dispatch({
          type: 'ERROR',
          payload: `There was an error creating Stripe account ${err}`,
        });
      });
  };
};

export const sendRefund = (data) => {
  const {
    payment_intent,
    amount,
    reason,
    transfer_id,
    refund,
    order,
    setReturns,
    returns,
    allUsers,
    stateSetter,
    setError,
    setOpen,
  } = data;

  const myInIt = {
    body: {
      payment_intent: payment_intent,
      amount: Math.trunc(amount * 100),
      metadata: {
        order_id: data.id,
        refund_id: refund?.return?.id ? refund?.return.id : '',
      },
      reason: reason,
      transfer_id: transfer_id,
    },
  };
  API.post(api, '/refund', myInIt)
    .then((response) => {
      if (response.statusCode === 400) {
        setError(response.raw.message);
        return;
      }

      order.payment.refund = [...order.payment.refund, response];

      act.orderRefundUpdate(order);
      if (!refund) {
        setTimeout(() => {
          act.getFarmOrders(order.farm_id, stateSetter);
        }, 300);
      }
      if (refund) {
        refund.status = 'approved';
        act.updateRefund(refund, setReturns, returns, allUsers);
      }
      if (setOpen) {
        setOpen(false);
      }
    })
    .catch((e) => {
     return e
    });
};
