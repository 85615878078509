import React from 'react';
import Modal from '../../display/modal';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Terms = (props) => {
  const { open, setOpen } = props;

  return (
    <Modal
      top={'50%'}
      left={'50%'}
      width={'800px'}
      height={'800px'}
      open={open}
      TitleMessage={
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='h4'>Farm Era Terms and Conditions</Typography>
          <CloseIcon
            onClick={() => setOpen(false)}
            sx={{ marginRight: '20px' }}
          />
        </Box>
      }
      message={
        <Box sx={{ overflow: 'scroll' }}>
          <Typography variant='h6' sx={{ margin: ' 20px 0' }}>
            Eligibility:{' '}
          </Typography>
          <Typography component='p' variant='p'>
            To be eligible to sell products on Farm Era Market, all farmers,
            ranchers, and growers (“Market Participant”) must certify that they
            grew, raised, or produced these products on their farm. All Market
            Participants must own or lease the land the product is produced on
            and must participate in the production, sales, and marketing of all
            such products. Reselling products by other farmers, ranchers, and
            growers are not permitted. Selling products from another farm is
            only allowed if the Market Participant and the other farm are
            members of the same cooperative. Producers of specialty products
            such as bakers, dairy product makers, beverage makers,
            fisherman/waterman, meat processors, pasta makers, soap makers,
            etc., must source their products directly from a neighboring farmer,
            rancher, or grower and must be able to provide proof of origin.
            Market Participants must provide accurate personal information and
            keep that information up to date. Farm Era Market is authorized to
            verify all information in any manner provided by the Market
            Participant. Market Participants may not impersonate any person or
            use any name they are not legally allowed to use. All Market
            Participants must be eighteen (18) years or older and legally able
            to enter into a contractual agreement.
          </Typography>
          <Typography variant='h6' sx={{ margin: ' 20px 0' }}>
            {' '}
            Permits, Licenses, Regulations:
          </Typography>
          <Typography component='p' variant='p'>
            All Market Participants must abide by all applicable federal, state,
            and local health regulations. All Market Participants must comply
            with all regulations within their respective state, including, but
            not limited to, harvesting, food preparation and safety, and
            labeling of products. All Market Participants are solely responsible
            for obtaining all necessary and required permits and licenses. All
            Market Participants are solely responsible for paying for permits,
            licenses, fees, dues, and fines. All Market Participants are
            responsible for uploading all relevant reports, permits, and
            licenses required by the health department of the state or country
            where the product originated on the Farm Era Market website. All
            Market Participants must keep all permits and licenses current while
            using Farm Era Market.
          </Typography>
          <Typography variant='h6' sx={{ margin: ' 20px 0' }}>
            Subject to Removal:
          </Typography>{' '}
          <Typography component='p' variant='p'>
            Farm Era Market reserves the right to dismiss any Market Participant
            and/or remove any product being sold that does not adhere to the
            terms and policies outlined in this agreement or for any reason
            deemed necessary.
          </Typography>
          <Typography variant='h6' sx={{ margin: ' 20px 0' }}>
            Liability and Hold Harmless:{' '}
          </Typography>{' '}
          <Typography component='p' variant='p'>
            Market Participants understand and agree that Farm Era Market is not
            responsible for any liability they may have from selling products
            through Farm Era Market’s website. Proper handling of food is a
            critical part of ensuring the safety of consumers. All food items
            must always be properly handled. The Market Participants understand
            and agree that they are responsible for all liability and hold Farm
            Era Market harmless for any and all liability.
          </Typography>
          <Typography variant='h6' sx={{ margin: ' 20px 0' }}>
            Payment Policy:
          </Typography>{' '}
          <Typography component='p' variant='p'>
            Farm Era Market allows Market Participants to accept payment by
            authorized payments through Stripe Inc. (“Farm Era Market
            Payments”). These funds will be credited to a Market Participant’s
            payment account with Farm Era Market seven (7) days from the time of
            order. Market Participants can conveniently deposit any credited
            funds directly to their bank from their farm dashboard. Farm Era
            Market reserves the right to add or remove payment methods in its
            sole discretion. Market Participants may only use Farm Era Market
            Payments for the sale of any and all listed items on Farm Era
            Market. By setting up a profile, Market Participant agrees to ship,
            deliver, or allow for pickup of any sold items once they receive
            notification of payment for an order within a reasonable amount of
            time. The Market Participant Payment account allows the Market
            Participant to track payment amounts, refunds, and fees related to
            transactions and services. Funds displayed in a Market Participants
            account balance are eligible for deposit but may be subject to any
            applicable limits, holds, or deposit delays. Farm Era Market may use
            third-party service providers for services including, but not
            limited to, payment processing, card processing, disbursements,
            identity verification, fraud analysis, and regulatory compliance.
            Farm Era Market may terminate a Market Participant’s access to Farm
            Era Market Payments if there is any violation of the terms and
            policies of this agreement. Farm Era Market may refuse service of
            Farm Era Market Payments to anyone, for any reason, at any time.
          </Typography>{' '}
          <Typography variant='h6' sx={{ margin: ' 20px 0' }}>
            Fees:
          </Typography>{' '}
          <Typography component='p' variant='p'>
            Farm Era Market charges a 5% commission in addition to Stripe’s 2.9%
            + .30 cents per successful card charged. Market Participant agrees
            that any amount owed to Farm Era Market for a commission or to any
            third party payment processors will be removed from funds prior to
            deposit.
          </Typography>
          <Typography variant='h6' sx={{ margin: ' 20px 0' }}>
            Refund Policy:{' '}
          </Typography>{' '}
          <Typography component='p' variant='p'>
            If a refund is requested by the customer, Farm Era Market reserves
            the right to issue a refund and recoup the funds from the Market
            Participant. Refunds must be requested within five (5) days of
            receiving the product. The customer may initiate the refund through
            the Market Participants dashboard. Any funds used to pay a refund
            will be deducted from the Market Participant’s account balance. If
            there are insufficient funds to cover the amount, the balance will
            be charged to the Market Participant’s card on file. Farm Era Market
            reserves the right to decline or refund transactions that we believe
            to be high risk, fraudulent, against regulations or policies, or
            otherwise in violation of our terms and policies. Farm Era Market
            reserves the right, in our sole discretion, to seek reimbursement
            from Market Participant if we provide a refund to a customer because
            they did not promptly deliver the product or we otherwise determine
            a refund is warranted, we discover erroneous or duplicate
            transactions, we receive a chargeback from the customer’s credit
            card issuer or reversal of payment, or the Market Participant does
            not act in accordance with the terms and policies.
          </Typography>
          <Typography variant='h6' sx={{ margin: ' 20px 0' }}>
            Taxes:
          </Typography>{' '}
          <Typography component='p' variant='p'>
            The Market Participant acknowledges and agrees that they are not an
            employee or contractor of Farm Era Market. The Market Participant is
            responsible for any and all taxes from any transactions through Farm
            Era Market. Farm Era Market may, at their discretion, provide any
            and all information from the Market Participant to the Internal
            Revenue Service. Farm Era Market may submit information and/or forms
            to the Internal Revenue Service without assuming any liability for
            Market Participant’s tax responsibilities.
          </Typography>
          <Typography variant='h6' sx={{ margin: ' 20px 0' }}>
            Termination:
          </Typography>{' '}
          <Typography component='p' variant='p'>
            Market Participants may terminate this agreement at any time by
            canceling their profile. Farm Era Market may also terminate or
            suspend a Market Participant’s use of Farm Era Market at any time.
            If terminated, any non-disputed funds will be deposited into the
            Market Participant’s bank account. Farm Era Market reserves the
            right, upon termination, to offset any refunds, chargebacks,
            adjustments, or other amounts against any Market Participant account
            balance. Farm Era Market does not guarantee any level of sales.
          </Typography>
          <Typography variant='h6' sx={{ margin: ' 20px 0' }}>
            Modifications to this Agreement:
          </Typography>{' '}
          <Typography component='p' variant='p'>
            Farm Era Market may modify the terms and policies at any time with
            written notice to the Market Participant.
          </Typography>
          <Typography variant='h6' sx={{ margin: ' 20px 0' }}>
            Legal Disclaimer:
          </Typography>
          <Typography variant='p' marginTop='50px'>
            Farm Era Market along with all associated companies and employees,
            do not warrant, implied or otherwise, any items purchased from a
            third party through the website. Farm Era Market is not, in any
            circumstance, responsible for any damages, either to property or
            person, that may be caused by items purchased from a third party
            through the website. Consume items purchased from Farm Era Market at
            own risk. Farm Era Market is not responsible for fraudulent items.
            See Return Policy. By purchasing products through this website, you
            agree to the terms of this Disclaimer.{' '}
          </Typography>
          <Typography variant='h6' sx={{ margin: ' 20px 0' }}>
            Market Participant Agreement:
          </Typography>{' '}
          <Typography component='p' variant='p'>
            <b>BY CREATING A PROFILE ON THE FARM ERA MARKET WEBSITE,</b> I
            certify that I have read, understand, and agree to all terms and
            policies as outlined and referenced in this agreement. I further
            understand that should I fail to comply with the terms and policies,
            my participation in Farm Era Market may be terminated. At no point
            shall Farm Era Market assume any liability for my actions and I will
            hold them harmless in the event of any legal action.
          </Typography>
        </Box>
      }
    />
  );
};

export default Terms;
