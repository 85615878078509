import React from "react";
import { useSelector } from "react-redux";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from "@mui/material/Avatar";
import Rating from "@mui/material/Rating"
import Stack from "@mui/material/Stack"

const Reviews = (props) => {
  const { product } = props;

  const users = useSelector((state) => state.UserReducer.allUsers);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
       <Typography variant="h5">Product Reviews</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <Stack direction="row" flexWrap="wrap">
        {product.reviews.length === 0 ?  <Typography variant="p">No Current Reviews</Typography> : product.reviews.map((review, i) => {
          const user = users.find(
            (user) => user.user_id.split("|")[1] === review.user_id
          );

          return (
            <Stack key={i} direction="row" alignItems="center" sx={{ mr: 4, mb: 2, }}>
              <Avatar src={user?.picture} />
              <Stack sx={{  ml: 1 }}>
                <Typography variant="h4">
                  {user?.user_metadata.first_name}{" "}
                  {user?.user_metadata.last_name}
                </Typography>
                <Rating value={review?.rate} readOnly />

                <Typography variant="p">{review?.review}</Typography>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default Reviews;
