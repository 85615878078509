import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PrimaryButton from '../../../inputs/Buttons/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import * as act from '../../../../store/actions';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from "../../../display/popover";
import NewOrders from './components/NewOrders';
import Stripe from "./components/Stripe"

const FarmerDashboard = (props) => {
  
  const managedFarms = useSelector((state) => state.FarmReducer.managedFarms);
  
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <Stack
      width='80%'
      sx={({ breakpoints }) => ({
        [breakpoints.down('sm')]: {
          marginLeft: '0',
          margin: '0 auto',
        },
      })}
    >
     <Stripe /> 
      <Card
        sx={{
          width: '100%',
          border: '1px solid #90a18a',
          padding: '30px',
          margin: '20px',
          height: 'fit-content',
          maxHeight: '500px',
          overflow: 'scroll',
        }}
        elevation={5}
      >
        <Typography variant='h3'>New Orders</Typography>
        {managedFarms && managedFarms.length > 0
          ? managedFarms.map((farm) => {
              return <NewOrders farm={farm} key={farm.id} />;
            })
          : null}
      </Card>
    </Stack>
  );
};
export default FarmerDashboard;
