import React from 'react';

import {
  Radio,
  Button,
  Typography,
  Stack,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import PrimaryButton from "../../inputs/Buttons/Button"
import * as act from '../../../store/actions/index';


const UpdateProductStatus = (props) => {
  const { product, getFarmProducts, setOpen } = props;
  
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      product_status: product.product_status,
      id: product.id,
      farm_id: product.farm_id,
    },
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  
  const doSubmit = async (data) => {
    await act.updateProductStatus(data);
    setTimeout(() => {
      getFarmProducts(product.farmId);
    }, 1000)
    
    setOpen(false);
  };

  
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(doSubmit)}>
        <Stack direction='column' margin='20px auto' border="none">
          <Typography variant='h5'>{product.name}</Typography>
          <Typography variant='p'>
            Product ID: {product.id.split('-')[0]}
          </Typography>
          <img
            style={{ margin: '0 auto ' }}
            src={product.images[0]}
            alt={product.name}
            width='300'
          />
          <Controller
            name='product_status'
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <RadioGroup
                aria-label='status'
                onChange={onChange}
                value={value}
                name={name}
              sx={{margin: '0 auto'}}
              >
                <FormControlLabel
                  value='active'
                  sx={{ fontSize: '16px' }}
                  control={<Radio size='medium' />}
                  label='Active'
                />
                <FormControlLabel
                  value='not_active'
                  sx={{ fontSize: '16px' }}
                  control={<Radio size='medium' />}
                  label='Inactive'
                />
                <FormControlLabel
                  value='sold_out'
                  sx={{ fontSize: '16px' }}
                  control={<Radio size='medium' />}
                  label='Sold Out'
                />
              </RadioGroup>
            )}
            control={control}
          />

          <Stack direction='row' justifyContent='space-between'>
            <PrimaryButton width="200px" type={'submit'}>Update Status</PrimaryButton>
           
            <PrimaryButton width="200px" onClick={() => setOpen(false)}>Cancel</PrimaryButton>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default UpdateProductStatus;
