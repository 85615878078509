import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as act from '../../../store/actions';
import * as utils from '../../../util';
import ProductCard from './ProductCard';
import ProductImage from './productImage';

//components
import { FarmLogo } from '../../farm/components/FarmLogo';
import DeliveryOptions from './DeliveryOptions';
import OrderSummary from './OrderSummary';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import OutOfStock from '../modals/OutOfStock';

const ShoppingBasket = (props) => {
  const { handleProceedToCheckout, open, setOpen, outOfStock, errors } = props;
  const farms = useSelector((state) => state.FarmReducer.allFarms);
  const cart = useSelector((state) => state.CartReducer.userCart);

  const dispatch = useDispatch();

  // const favorite = useSelector((state) => state.UserReducer.favorites);
  // const user = useSelector((state) => state.UserReducer.user);

  let [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);

  //remove product from order
  const removeProduct = (i, j) => {
    cart.orders[i].products = cart.orders[i].products.filter(
      (prod) => prod.id !== cart.orders[i].products[j].id
    );
    if (cart.orders[i].products.length === 0) {
      cart.orders = cart.orders.filter((ord) => ord.id !== cart.orders[i].id);
    }
    utils.calculateTotals(cart, 'subtotal', setSubtotal);
    dispatch(act.saveCartToDB(cart));
  };

  return (
    <Stack direction='row' margin='auto' width='100%'>
      <Stack width='100%'>
        <Typography variant='h4'>Shopping Basket</Typography>
        {cart.orders?.map((order, i) => {
          const farm = farms.find((farm) => farm.id === order.farm_id);
          return (
            <Stack key={i} width='100%'>
              <Stack
                margin='10px 0'
                width='100%'
                borderTop='1px solid #bac2b7'
                borderBottom='1px solid #bac2b7'
                padding='10px 0'
                marginRight='30px'
              >
                <FarmLogo
                  farm={farm}
                  imgSize={{ width: '90px', height: '90px' }}
                  display={'none'}
                  linkDisplay={'none'}
                  milesDisplay={'none'}
                />
                {order.products.map((product, j) => {
                  return (
                    <Stack key={j} direction='row' alignItems='center'>
                      <OutOfStock
                        open={open.length > 0}
                        key={j}
                        setOpen={setOpen}
                        products={open}
                      />
                      <ProductImage
                        i={i}
                        j={j}
                        product={product}
                        removeProduct={removeProduct}
                      />
                      <ProductCard
                        product={product}
                        cart={cart}
                        setSubtotal={setSubtotal}
                        setTotal={setTotal}
                      />
                    </Stack>
                  );
                })}
              </Stack>
              {farm ? <DeliveryOptions farm={farm} i={i} cart={cart} /> : null}
            </Stack>
          );
        })}
      </Stack>
      <OrderSummary
        subtotal={subtotal}
        setSubtotal={setSubtotal}
        total={total}
        setTotal={setTotal}
        handleProceedToCheckout={handleProceedToCheckout}
        errors={errors}
        cart={cart}
      />
    </Stack>
  );
};
export default ShoppingBasket;
