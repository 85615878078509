import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { Typography, Checkbox, FormControlLabel, Stack } from '@mui/material';
import * as act from '../../../../store/actions';

const DietaryPreferences = (props) => {
  const { dietaryOptions, allProducts } = props;
  const { getValues, setValue } = useFormContext();
  const dietaryArray = useSelector(
    (state) => state.ProductsReducer.searchedDietary
  );

  const dispatch = useDispatch();

  const handleDietarySearch = (e) => {
    let array = dietaryArray;
    if (array.includes(e.target.value)) {
      let index = array.indexOf(e.target.value);
      array.splice(index, 1);
      setValue('dietary', array);
    } else {
      let newArray = [...array, e.target.value];

      setValue('dietary', newArray);
    }

    dispatch(act.searchByDietary(allProducts, getValues('dietary')));
  };

  return (
    <Stack>
      <Typography variant='h5' marginBottom='4%'>
        Dietary Preference
      </Typography>
      {dietaryOptions.map((option, i) => {
        return (
          <FormControlLabel
            sx={{ fontSize: '1rem' }}
            key={i}
            control={
              <Checkbox
                size='small'
                onChange={(e) => handleDietarySearch(e)}
                checked={dietaryArray?.includes(option)}
                value={option}
                sx={{
                  '&.Mui-checked': {
                    color: 'primary.radio',
                  },
                }}
              />
            }
            label={option}
          />
        );
      })}
    </Stack>
  );
};

export default DietaryPreferences;
