import React, { useState } from 'react';
import EditAccountInfo from '../modals/EditAccountInfo';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

const AccountInfo = (props) => {
  const { user } = props;
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(!open);
  };

  return (
    <Card
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '400px',
        padding: '20px',
        margin: '10px 0',
      }}
    >
      <Typography variant='h4'>Account Information</Typography>
      <Typography variant='p'>
        {user.user_metadata.first_name} {user.user_metadata.last_name}
      </Typography>
      <Typography variant='p'>{user.email}</Typography>
      <Typography variant='p'>{user.user_metadata.phone}</Typography>
      <Typography variant='a' sx={{ mt: 2 }} onClick={() => setOpen(true)}>
        Edit Account Info
      </Typography>
      <EditAccountInfo
        user={user}
        open={open}
        phone={user.user_metadata.phone}
        showModal={showModal}
        setOpen={setOpen}
      />
    </Card>
  );
};
export default AccountInfo;
