import React from 'react';
import Modal from '../../display/modal';
import { Stack } from '@mui/material';
import PrimaryButton from '../../inputs/Buttons/Button';

const Delete = (props) => {
  const { open, setOpen } = props;

  const handleClick = () => {
    props.onClick();
    setTimeout(() => {
      setOpen(false);
    }, 500);
  };

  return (
    <Modal
      open={open}
      top='50%'
      left='50%'
      TitleMessage={'Are you sure you would like to delete?'}
      message={
        <Stack
          direction='row'
          justifyContent={'center'}
          width='100%'
          marginTop='20px'
        >
          <PrimaryButton margin='0 30px 0 0' onClick={handleClick}>
            Delete
          </PrimaryButton>
          <PrimaryButton onClick={() => setOpen(false)}>Cancel</PrimaryButton>
        </Stack>
      }
    />
  );
};

export default Delete;
