import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import * as act from "../../../../../store/actions"

const CustomerInfo = (props) => {
  const { customerID, customer, setCustomer } = props;
 
  const allUsers = useSelector(state => state.UserReducer.allUsers)

  useEffect(async () => {
    const user = await act.getUser(customerID)
    setCustomer(user)

  },[])


 
  return (
    <Stack>
      
           <Stack>
      <Typography variant='h6'>
        {customer?.user_metadata?.first_name} {customer?.user_metadata?.last_name}
      </Typography>
      <Typography variant='p' component= 'p'>{customer?.user_metadata?.address}</Typography>
      <Typography variant='p' component= 'p'>
        {customer?.user_metadata?.city}, {customer?.user_metadata?.state}{' '}
        {customer?.user_metadata?.zip}
      </Typography>
      <Typography variant='p' component= 'p'>{customer?.email}</Typography>
          <Typography variant='p' component='p'>{customer?.user_metadata?.phone}</Typography>
      </Stack>
    </Stack>
  );
};
export default CustomerInfo;
