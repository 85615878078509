import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FarmLogo } from '../../../farm/components/FarmLogo';
import ReturnDeclinedModal from '../returns/returnDeclinedModal';
import OrderHistoryContent from './orderHistoryProducts';
import * as act from '../../../../store/actions';
import OrderHistoryButtons from './orderHistoryButtons';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const OrderHistory = (props) => {
  const { order } = props;
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState();

  const farms = useSelector((state) => state.FarmReducer.allFarms);
  const farm = farms.find((farm) => farm.id === order.farm_id);
  const orderDate = new Date(order.created_date).toLocaleDateString();
  const cart = useSelector((state) => state.CartReducer.userCart);
 

  const dispatch = useDispatch();

  const handleReorderOrder = (order) => {
    order.products.map((product) => {      
      dispatch(act.addToCart(cart, item, product.quantity));
    });
    dispatch(act.saveCartToDB(cart));
  };

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      width='100%'
      border='1px solid rgba(186, 194, 183, 0.8)'
      marginBottom='50px'
      padding='20px'
      borderRadius='5px'
    >
      <Stack marginRight='50px' width='100%'>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          borderBottom={`1px solid #90a18a`}
          width='100%'
        >
          <FarmLogo
            farm={farm}
            linkDisplay={'none'}
            ratingDisplay={'none'}
            milesDisplay='none'
          />
          <Typography variant='p' sx={{ opacity: '0.3' }}>
            View Invoice
          </Typography>
          <Stack>
            {order.delivery_selection.type === 'ship' ? (
              <>
                <Typography variant='p'>
                  Subtotal: ${order.payment.subtotal.toFixed(2)}
                </Typography>
                <Typography variant='p' borderBottom='1px solid gray'>
                  Shipping: ${order.payment.shipping.toFixed(2)}
                </Typography>
              </>
            ) : null}
            <Typography variant='p'>
              Total price: <b>${order.payment.total.toFixed(2)}</b>
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction='row'
          justifyContent='space-between'
          padding='1% 0'
          borderBottom={`1px solid #90a18a`}
          width='100%'
        >
          <Stack>
            <Typography variant='p'>
              Order Date: <b>{orderDate}</b>
            </Typography>
          </Stack>
          <Stack>
            <Typography variant='p'>
              Order Number: <b>{order.id}</b>
            </Typography>
          </Stack>
          <Stack>
            {order.order_status === 'refund declined' ? (
              <Typography variant='p'>
                Order Status:
                <Typography variant='a' onClick={() => setOpen(true)}>
                  {order.order_status}
                </Typography>
                <ReturnDeclinedModal
                  open={open}
                  setOpen={setOpen}
                  order={order}
                />
              </Typography>
            ) : (
              <Typography variant='p'>
                Order Status:<b>{order.order_status}</b>
              </Typography>
            )}
          </Stack>
        </Stack>

        {order?.products?.map((product, i) => {
         
          return (
            <OrderHistoryContent
              key={i + product.id}
              cart={cart}
              dispatch={dispatch}
              act={act}
              order={order}
              product={product}
              item={item}
              setItem={setItem}
            />
          );
        })}
      </Stack>
      <OrderHistoryButtons
        handleReorderOrder={handleReorderOrder}
        order={order}
      />
    </Stack>
  );
};
