import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as act from '../../../../../store/actions/index';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import SecondaryButton from '../../../../inputs/Buttons/SecondaryButton';
import ListingIcons from './ListingsIcons';

const ActiveListings = (props) => {
  const { farmProducts, getFarmProducts } = props;
  const params = useParams();
  const history = useHistory();

  const handleDelete = (product) => {
    const data = {
      product_status: 'archived',
      id: product.id,
      farm_id: product.farm_id,
    };
    act.updateProductStatus(data);
    setTimeout(() => {
      getFarmProducts();
    }, 1000);
  };

  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  return (
    <Stack marginTop='20px'>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h3' marginLeft='35px'>
          Active Listings
        </Typography>
        <SecondaryButton
          width='200px'
          onClick={() =>
            history.push(`/dashboard/listings/add_product/${params.id}`)
          }
          type='button'
        >
          Add Product
        </SecondaryButton>
      </Stack>

      <Stack direction='row' marginLeft='35px' flexWrap='wrap'>
        {!farmProducts[0] ? (
          <Typography variant='h4'>You have no active products</Typography>
        ) : (
          farmProducts.map((product, i) => {
            return (
              <Card
                key={i}
                elevation={10}
                sx={{
                  bgcolor: 'primary.backgroundColor2',
                  width: '310px',
                  marginRight: '20px',
                  marginTop: '20px',
                  borderRadius: '8px',
                  border: 'none',
                }}
              >
                <CardMedia
                  component='img'
                  image={product.images[0]}
                  height='170'
                  width='300'
                  alt={product.name}
                />
                <CardContent sx={{ padding: '10px 20px' }}>
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='p' fontWeight='bold'>
                      <Typography
                        variant='p'
                        sx={{ color: 'primary.secondary' }}
                      >
                        ${product.price.toFixed(2)}
                      </Typography>
                      /{product.label}
                    </Typography>
                    <Typography
                      variant='p'
                      fontWeight={'bold'}
                      sx={{ color: 'primary.error' }}
                    >
                      {product.inventory_qty}
                    </Typography>
                  </Stack>
                  <Typography variant='h5'>{product.name}</Typography>
                </CardContent>
                <Divider sx={{ color: 'primary.formOutline2' }} />
                <ListingIcons
                  product={product}
                  setOpenUpdate={setOpenUpdate}
                  openUpdate={openUpdate}
                  setOpenDelete={setOpenDelete}
                  openDelete={openDelete}
                  handleDelete={handleDelete}
                  getFarmProducts={getFarmProducts}
                />
              </Card>
            );
          })
        )}
      </Stack>
    </Stack>
  );
};
export default ActiveListings;
