import React from 'react';
import Chart from 'react-google-charts';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

const DeliveryMethodsChart = (props) => {
  const { farms, totalDeliveryCount } = props;

  const formatPieChartData = (data) => {
    const formattedData = [['Method', 'FarmCount']];

    formattedData.push(['Farms that Ship', data.ship]);
    formattedData.push(['Farms that Deliver', data.deliver]);
    formattedData.push(['Farms that Pick Up', data.ship]);

    return formattedData;
  };

  return (
    <Stack
      boxSizing='border-box'
      borderRadius='2px'
      width='100%'
      height='330px'
    >
      <Typography variant='h6' lineHeight='22px' color='primary.lightFont'>
        Delivery Method
      </Typography>

      <Stack
        width='100%'
        padding='0 20px'
        justifyContent='center'
        alignItems='flex-end'
      >
        <Typography
          variant='p'
          component='p'
          lineHeight='27px'
          color='primary.lightFont'
        >
          Total Farms Registered:{' '}
          <Typography variant='p' color='primary.darkFont'>
            {farms.length}
          </Typography>
        </Typography>
      </Stack>

      <Card elevation={20}>
        <Chart
          width={'100%'}
          height={'250px'}
          chartType='PieChart'
          loader={<Typography variant='h3'>Loading Chart</Typography>}
          data={formatPieChartData(totalDeliveryCount)}
          options={{
            legend: { position: 'right', alignment: 'center' },
            colors: ['#FBD200', '#006F45', '#0054BC'],
            is3D: true,
          }}
        />
      </Card>
    </Stack>
  );
};

export default DeliveryMethodsChart;
