import React from 'react';
import { useFormContext } from 'react-hook-form';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { range } from '../../../../util';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

export const PricingDetails = (props) => {
  const { product, handleChange, setProduct } = props;

  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleCheck = (e) => {
    let value = e.target.value;

    if (product.shipping_restrictions === e.target.value) {
      value = ' ';
    }
    setValue(e.target.name, e.target.value);
    setProduct({
      ...product,
      [e.target.name]: value,
    });
  };

  const getLabel = (num) => {
    let time;
    if (num === 1) {
      time = 'day';
    } else {
      time = 'days';
    }
    return time;
  };
  return (
    <Stack>
      <Typography variant='h5' marginBottom='5px'>
        3. Pricing
      </Typography>
      <Divider />
      <Stack direction='row' justifyContent='space-between' marginTop='20px'>
        <TextField
          className='smallInput'
          label={
            errors.price ? (
              <Typography variant='error'>{errors.price.message}</Typography>
            ) : (
              'Price'
            )
          }
          {...register('price', {
            validate: {
              greaterThan0: () => product.price > 0 || 'Price is required',
            },
            valueAsNumber: true,
            pattern: {
              value: /^[0-9]*$/,
              message: 'Entry must be numbers',
            },
            required: 'Must enter a value',
          })}
          defaultValue={product.price}
          onChange={(e) => handleChange(e, 'number')}
          required
        />
        <TextField
          className='smallInput'
          label={
            errors.weight ? (
              <Typography variant='error'>{errors.weight.message}</Typography>
            ) : (
              'Product Weight - in lbs for shipping purposes'
            )
          }
          {...register('weight', {
            validate: {
              greaterThan0: () =>
                product.weight > 0 || 'Product Weight is required',
            },
            valueAsNumber: true,
            pattern: {
              value: /^[0-9.]*$/,
              message: 'Entry can only be numbers',
            },
            required: 'Must enter a value',
          })}
          defaultValue={product.weight}
          onChange={(e) => handleChange(e, 'number')}
          placeholder='Numbers Only'
        />
        <TextField
          className='smallInput'
          label='Amount Type (e.g. lb, ounce)'
          {...register('label')}
          defaultValue={product.label}
          onChange={(e) => handleChange(e)}
        />
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <TextField
          className='mediumInput'
          size='medium'
          label={
            errors.inventory_qty ? (
              <Typography variant='error'>
                {errors.inventory_qty.message}
              </Typography>
            ) : (
              'Product Quantity e.g. 100 (for 100lbs)'
            )
          }
          {...register('inventory_qty', {
            valueAsNumber: true,
            pattern: {
              value: /^[0-9]*$/,
              message: 'Entry must be numbers',
            },
            required: 'Inventory Qty is required',
          })}
          onChange={(e) => handleChange(e, 'number')}
          defaultValue={product.inventory_qty}
        />
        <FormControl sx={{ margin: '10px 0', width: '49%' }}>
          <InputLabel sx={{ fontSize: '1rem' }} id='process_time'>
            Processing Time
          </InputLabel>
          <Select
            sx={{ fontSize: '1rem' }}
            labelId='process_time'
            id='process_time'
            name='process_time'
            defaultValue={product.process_time}
            {...register('process_time')}
            input={
              <OutlinedInput
                sx={{ fontSize: '1rem' }}
                label='Processing Time'
              />
            }
            MenuProps={MenuProps}
            onChange={(e) => {
              handleChange(e, 'number');
            }}
          >
            {range.map((name) => (
              <MenuItem sx={{ fontSize: '1rem' }} key={name} value={name}>
                {name} {getLabel(name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack marginTop='20px' marginLeft='3px'>
        <FormControl component='fieldset' variant='standard'>
          <FormLabel sx={{ fontSize: '1rem' }} component='legend'>
            Shipping and Delivery Restrictions (optional)
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              sx={{ fontSize: '1rem' }}
              control={
                <Checkbox
                  {...register('shipping_restrictions')}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                  checked={product.shipping_restrictions === 'delivery_only'}
                  onChange={handleCheck}
                  name='shipping_restrictions'
                  value='delivery_only'
                />
              }
              label='Delivery Only'
            />
            <FormControlLabel
              sx={{ fontSize: '1rem' }}
              control={
                <Checkbox
                  {...register('shipping_restrictions')}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                  checked={product.shipping_restrictions === 'ship_only'}
                  onChange={handleCheck}
                  name='shipping_restrictions'
                  value='ship_only'
                />
              }
              label='Shipping Only'
            />
            <FormControlLabel
              sx={{ fontSize: '1rem' }}
              control={
                <Checkbox
                  {...register('shipping_restrictions')}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                  checked={product.shipping_restrictions === 'pickup_only'}
                  onChange={handleCheck}
                  name='shipping_restrictions'
                  value='pickup_only'
                />
              }
              label='Pickup Only'
            />
          </FormGroup>
        </FormControl>
      </Stack>
    </Stack>
  );
};
