import React, { useState } from 'react';
import EditAddressInfo from '../modals/EditAddressInfo';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

const AddressInfo = (props) => {
  const { user } = props;

  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(!open);
  };
  return (
    <Card
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '400px',
        padding: '20px',
        margin: '10px 0',
      }}
    >
      <Typography variant='h4'>Address</Typography>
      <Typography variant='p'>{user.user_metadata.address}</Typography>
      <Typography variant='p'>
        {user.user_metadata.city}, {user.user_metadata.state}{' '}
        {user.user_metadata.zip}
      </Typography>
      <Typography variant='a' sx={{ mt: 2 }} onClick={() => setOpen(true)}>
        Edit Address
      </Typography>
      <EditAddressInfo
        user={user}
        open={open}
        setOpen={setOpen}
        showModal={showModal}
      />
    </Card>
  );
};
export default AddressInfo;
