import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';
import * as act from "../../../../../store/actions"

const OrderProducts = (props) => {
  const { orderProduct } = props;

  const [product, setProduct] = useState()
 
 

  useEffect(async() => {
    const response = await act.getProduct(orderProduct?.id)  
    setProduct(response)
  }, [])

  return (
    <Stack
      key={orderProduct?.id}
      direction='row'
      height='60px'
      justifyContent='space-between'
      padding='20px 0'
      sx={({ breakpoints }) => ({ width: '100%' })}
    >
      <Stack height='fit-content' width='50%'>
        <Typography variant='h6'>{product?.name}</Typography>
        <Typography variant='p' component='p'>
          Price:{' '}
          <b>
            ${product?.price}/{product?.label}
          </b>
        </Typography>
      </Stack>
      <Stack width='50%'>
        <Stack
          height='fit-content'
          width='50%'
          direction='row'
          alignItems={'center'}
        >
            <Typography variant='p' component='p'>
              Quantity: <b>{orderProduct?.quantity}</b>{' '}
            </Typography>
        </Stack>
      </Stack>
      <Stack height='fit-content' width='50%' alignItems={'center'}>
        <Typography variant='p' component={'p'}>
          Item Number: <b>{orderProduct?.id}</b>
        </Typography>
      </Stack>
    </Stack>
  );
};
export default OrderProducts;
