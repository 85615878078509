import React, { useState, useEffect } from 'react';
import defaultImage from '../../../images/FarmEraStackedLogo.svg';
import FarmBio from './FarmBio';
import DeliveryMethods from './DeliveryMethods';
import FarmRefunds from './FarmRefunds';
import FarmPayment from './FarmPayment';
import FarmProducts from './FarmProducts';
import FarmOrders from './FarmOrders';
import FarmRatingChart from './FarmRatingChart';
import SalesChart from './SalesChart';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack'
import * as act from "../../../store/actions";
import {useParams, useHistory} from "react-router-dom"

const FarmPanel = (props) => {
  const params = useParams();
  const history = useHistory();
  
  const {
    users,
    farmRatingData,
    productsCountMap,
    farmOrderData,
    farmReturnsSentData,
    farmReturnsRequestedData,
  } = props;

  const [farm, setFarm] = useState({});
  const [orderList, setOrderList] = useState([]);
  const subtotalReducer = (sum = 0, order) => (sum += order.payment.subtotal);

  const totalReducer = (sum = 0, order) => (sum += order.payment.total);

  const calculateCommission = (farmId) => {
    if (!farmOrderData[farmId]) return { stripe: 0, farmEra: 0 };
    const subtotal = farmOrderData[farmId].reduce(subtotalReducer, 0);
    const total = farmOrderData[farmId].reduce(totalReducer, 0);

    const amountToFarmEra = subtotal * 0.04;

    return {
      stripe: '$' + (total * 0.03).toFixed(2),
      farmEra: '$' + amountToFarmEra.toFixed(2),
    };
  };
  
  const goBack = () => history.push(`/dashboard/farms`);

  useEffect( async () => {
    const selectedFarm = await act.getFarmById(params.component)
    setFarm(selectedFarm);
   const orders = await act.getFarmOrders(params.component)
    setOrderList(orders);
  }, [params.component]);

  return (
    <Stack width='100%'marginTop="50px">
      <Typography
        variant='p'
        component='p'
        onClick={goBack}
        fontSize='20px'
        lineHeight='27px'
        color='primary.darkFont'
        sx={{ cursor: 'pointer' }}
      >
        {'< Return to all farms'}
      </Typography>
      <Stack margin='20px 0' direction='row' width='100%'>
        <Stack margin='0 20px' alignItems='flex-start'>
          <FarmBio farm={farm} defaultImage={defaultImage} />
          <DeliveryMethods farm={farm} />
          <FarmRefunds
            farm={farm}
            farmReturnsSentData={farmReturnsSentData}
            farmReturnsRequestedData={farmReturnsRequestedData}
          />
          <FarmPayment farm={farm} calculateCommission={calculateCommission} />
        </Stack>
        <Stack margin='0 20px' width='60%'>
          <SalesChart orders={orderList} />
          <FarmOrders farm={farm} farmOrderData={farmOrderData} users={users} />
          <FarmProducts farm={farm} productsCountMap={productsCountMap} />
          <FarmRatingChart farm={farm} farmRatingData={farmRatingData} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FarmPanel;
