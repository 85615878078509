import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import { useSelector } from 'react-redux';
import * as utils from '../../../util';

const ReturnsByCustomer = (props) => {
  const [customerReturns, setCustomerReturns] = useState([]);
  const allUsers = useSelector((state) => state.UserReducer.allUsers);

  var today = new Date();
  const prior30 = new Date(new Date().setDate(today.getDate() - 30));
  const prior60 = new Date(new Date().setDate(today.getDate() - 60));
  const prior90 = new Date(new Date().setDate(today.getDate() - 90));

  let thirtyDayCount = 0;
  let sixtyDayCount = 0;
  let ninetyDayCount = 0;
  const createData = (customer) => {
    const user = allUsers.find(
      (user) => user.user_id.split('|')[1] === customer.id
    );

    thirtyDayCount = 0;
    sixtyDayCount = 0;
    ninetyDayCount = 0;

    customer.returns.map((order) => {
      if (new Date(order.created_date) > new Date(prior30)) {
        return (thirtyDayCount += 1);
      } else if (new Date(order.created_date) > new Date(prior60)) {
        return (sixtyDayCount += 1);
      } else if (new Date(order.created_date) > new Date(prior90)) {
        return (ninetyDayCount += 1);
      }
    });

    const rowData = {
      id: customer.id,
      first_name: `${user?.user_metadata?.first_name}`,
      last_name: `${user?.user_metadata?.last_name}`,
      prior30Count: thirtyDayCount,
      prior60Count: sixtyDayCount,
      prior90Count: ninetyDayCount,
    };
    return rowData;
  };

  // const viewMore = (name) => props.history.push(`/dashboard/farm/${name}`);

  useEffect(() => {
    utils.getAllCustomerReturns(setCustomerReturns);
  }, []);

  const rows = customerReturns.map((customer) => createData(customer));

  return (
    <Stack width='80%' sx={{ overflow: 'scroll' }}>
      <Card elevation={10}>
        <TableContainer component={Paper}>
          <Table stickyHeader width='100%' aria-label='sticky table'>
            <TableHead sx={{ backgroundColor: '#EAEDE9' }}>
              <TableRow>
                <TableCell>Customer ID</TableCell>
                <TableCell align='left'>Customer Name</TableCell>
                <TableCell align='left'>Last 30 days</TableCell>
                <TableCell align='left'>Last 60 days</TableCell>
                <TableCell align='left'>Last 90 days</TableCell>
                <TableCell align='left'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow hover key={i}>
                  <TableCell component='th' scope='row'>
                    {`${row.id}`}
                  </TableCell>
                  <TableCell align='left'>
                    {row.first_name} {row.last_name}
                  </TableCell>
                  <TableCell width='120px' align='center'>
                    {row.prior30Count}
                  </TableCell>
                  <TableCell width='120px' align='center'>
                    {row.prior60Count}
                  </TableCell>
                  <TableCell width='120px' align='center'>
                    {row.prior90Count}
                  </TableCell>
                  <TableCell
                    align='left'
                    style={{ cursor: 'pointer' }}
                    // onClick={(e) => viewMore(row.name)}
                  >
                    {/* <span style={{ fontSize: '22px' }}>...</span> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Stack>
  );
};

export default ReturnsByCustomer;
