import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { useFormContext } from 'react-hook-form';
import PickupLocationsModal from './PickupLocations';

const DeliveryOptions = (props) => {
  const { cart, farm, i } = props;
  const [open, setOpen] = useState(false);

  const {
    register,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
    watch,
  } = useFormContext();

  const handleSelection = (value) => {
    clearErrors();

    setValue(`delivery_selection[${i}]`, value);
    setValue(`orders[${i}].delivery_selection.type`, value);
    cart.orders[i].delivery_selection.type = value;

    if (value === 'deliver' && farm.delivery_methods.delivery_fee > 0) {
      setValue(
        `orders[${i}].delivery_selection.info.amount`,
        farm.delivery_methods.delivery_fee
      );
      cart.orders[i].delivery_selection.info.amount =
        farm.delivery_methods.delivery_fee;
    }

    if (value === 'pickup') {
      setError(`pickup[${i}]`, {
        type: 'manual',
        message: 'Must choose a location for pickup',
      });
    }
  };
  return (
    <Stack margin='50px 0'>
      {errors?.delivery_selection && errors.delivery_selection[i] && (
        <Typography variant='error'>
          {errors.delivery_selection[i].message}
        </Typography>
      )}
      <RadioGroup
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: '100%',
          justifyContent: 'space-between',
        }}
        onChange={(e) => handleSelection(e.target.value)}
      >
        <Card
          elevation={4}
          sx={{ width: '350px', padding: '20px', margin: '10px 0' }}
        >
          <FormControlLabel
            sx={{ fontSize: '1rem' }}
            control={
              <Radio
                {...register(`delivery_selection[${i}]`, {
                  required: 'Must select at least one delivery type',
                })}
                // error={errors?.orders[i]?.delivery_selection.type}
                disabled={farm?.delivery_methods?.ship ? false : true}
                value='ship'
                sx={{
                  color: 'primary.radio',
                  '&.Mui-checked': {
                    color: 'primary.radio',
                  },
                }}
              />
            }
            label={
              <Stack>
                {farm?.delivery_methods.ship ? (
                  <span>
                    <Typography variant='h6'>
                      Ship to Home
                      <Typography fontWeight='normal' variant='p'>
                        {' '}
                        - Available
                      </Typography>
                    </Typography>
                    <Typography variant='p' component='p'>
                      {farm.name} currently offers Ship to Home
                    </Typography>
                  </span>
                ) : (
                  <span>
                    <Typography variant='h6'>
                      Ship to Home
                      <Typography fontWeight='normal' variant='p'>
                        {' '}
                        - Unavailable
                      </Typography>
                    </Typography>
                    <Typography variant='p' component='p'>
                      {farm.name} currently does not offer Ship to Home
                    </Typography>
                  </span>
                )}
              </Stack>
            }
          />
        </Card>
        <Card
          elevation={4}
          sx={{ width: '350px', padding: '20px', margin: '10px 0' }}
        >
          <FormControlLabel
            sx={{ fontSize: '1rem' }}
            control={
              <Radio
                {...register(`delivery_selection[${i}]`, {
                  required: 'Must select at least one delivery type',
                })}
                // error={errors?.orders[i]?.delivery_selection.type}
                disabled={farm.delivery_methods.deliver ? false : true}
                value='deliver'
                sx={{
                  color: 'primary.radio',
                  '&.Mui-checked': {
                    color: 'primary.radio',
                  },
                }}
              />
            }
            label={
              <Stack>
                {farm?.delivery_methods?.deliver ? (
                  <span>
                    <Typography variant='h6'>
                      Delivery to Home
                      <Typography fontWeight='normal' variant='p'>
                        {' '}
                        - Available
                      </Typography>
                    </Typography>
                    <Typography variant='p' component='p'>
                      {farm.name} currently offers Delivery to Home
                    </Typography>
                    {farm.delivery_methods.delivery_fee > 0 && (
                      <Typography variant='p' component='p'>
                        <b>
                          Delivery Fee ${farm.delivery_methods.delivery_fee}
                        </b>
                      </Typography>
                    )}
                  </span>
                ) : (
                  <span>
                    <Typography variant='h6'>
                      Delivery to Home
                      <Typography fontWeight='normal' variant='p'>
                        {' '}
                        - Unavailable
                      </Typography>
                    </Typography>
                    <Typography variant='p' component='p'>
                      {farm.name} currently does not offer Delivery to Home
                    </Typography>
                  </span>
                )}
              </Stack>
            }
          />
        </Card>

        <Card
          elevation={4}
          sx={{ width: '350px', padding: '20px', margin: '10px 0' }}
        >
          <FormControlLabel
            sx={{ fontSize: '1rem' }}
            control={
              <Radio
                {...register(`delivery_selection[${i}]`, {
                  required: 'Must select at least one delivery type',
                })}
                value='pickup'
                disabled={farm.delivery_methods.pickup ? false : true}
                sx={{
                  color: 'primary.radio',
                  '&.Mui-checked': {
                    color: 'primary.radio',
                  },
                }}
              />
            }
            label={
              <Stack>
                {farm?.delivery_methods?.pickup ? (
                  <span>
                    <Typography variant='h6'>
                      Pickup at Location
                      <Typography fontWeight='normal' variant='p'>
                        {' '}
                        - Available
                      </Typography>
                    </Typography>
                    <Typography variant='p' component='p'>
                      {farm.name} currently offers Pickup
                    </Typography>
                    {errors?.pickup && errors.pickup[i] && (
                      <Typography variant='error'>
                        {errors.pickup[i].message}
                      </Typography>
                    )}
                    <PickupLocationsModal
                      open={open}
                      setOpen={setOpen}
                      i={i}
                      cart={cart}
                      locations={farm.delivery_methods.locations}
                    />
                  </span>
                ) : (
                  <span>
                    <Typography variant='h6'>
                      Pickup at Location
                      <Typography fontWeight='normal' variant='p'>
                        {' '}
                        - Unavailable
                      </Typography>
                    </Typography>
                    <Typography variant='p' component='p'>
                      {farm.name} currently does not offer Pickup
                    </Typography>
                  </span>
                )}
              </Stack>
            }
          />
        </Card>
      </RadioGroup>
      {errors && <p>{errors.delivery_type}</p>}
    </Stack>
  );
};

export default DeliveryOptions;
