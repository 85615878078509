import { API } from 'aws-amplify';
import customId from 'custom-id';
import * as utils from '../../util';

const api = 'FarmEraMarketAPI';

export const UPDATE_USER_CART = 'UPDATE_USER_CART';
export const ERROR = 'ERROR';

export const createUserCart = (id) => {
  const myInIt = {
    body: {
      user_id: id,
      orders: [],
      payment: {
        total: 0,
        subtotal: 0,
        taxes: 0,
        shipping: 0,
      },
    },
  };
  return (dispatch) => {
    API.post(api, '/carts', myInIt)
      .then((response) => {
        dispatch({ type: UPDATE_USER_CART, payload: response });
      })
      .catch((err) => {
        dispatch({ type: ERROR, payload: err });
      });
  };
};

export const getUserCart = (id) => {
  return (dispatch) => {
    API.get(api, `/carts/${id}`)
      .then((response) => {
        let cart;
        !response.user_id
          ? (cart = {
              user_id: id,
              orders: [],
              payment: {
                subtotal: 0,
                total: 0,
                shipping: 0,
                tax: 0,
              },
            })
          : (cart = response);
        dispatch({ type: UPDATE_USER_CART, payload: cart });
      })
      .catch((err) => {
        dispatch({ type: ERROR, payload: err });
      });
  };
};
export function addToCart(cart, product, quantity) {
  const farmId = product.farm_id;
  // Search through cart orders for matching farm order
  product.quantity += quantity;

  for (let i = 0; i < cart.orders.length; i++) {
    const order = cart.orders[i];

    // if farm order is found
    if (order.farm_id === farmId) {
      const products = order.products;

      // search through the order for the product to insert
      for (let j = 0; j < products.length; j++) {
        // If product to insert found in order, update
        if (products[j].id === product.id) {
          products[j] = product;

          return {
            type: 'UPDATE_USER_CART',
            payload: cart,
          };
        }
      }

      // else add product to farm order
      order.products.push({
        id: product.id,
        quantity: product.quantity,
        price: product.price,
        weight: product.weight,
      });
      order.payment.subtotal = utils.calculateSubtotal(
        order.payment.subtotal,
        product.price,
        product.quantity
      );
      utils.calculateTotals(cart, 'subtotal');
      utils.calculateTotals(cart, 'shipping');
      return {
        type: 'UPDATE_USER_CART',
        payload: cart,
      };
    }
  }

  // if farm order not found create farm order
  const id = customId({});
  const newOrder = utils.formatNewOrder(product, cart.user_id, id);

  //add farm order to cart orders
  cart.orders.push(newOrder.orders[0]);

  utils.calculateTotals(cart, 'subtotal');
  utils.calculateTotals(cart, 'shipping');

  return {
    type: 'UPDATE_USER_CART',
    payload: cart,
  };
}

export const UPDATE_ITEM_COUNT = 'UPDATE_ITEM_COUNT';
export const LOADING = 'LOADING;';

export const saveCartToDB = (cart) => {
  let count = 0;
  const myInIt = {
    body: cart,
  };
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    API.post(api, '/carts', myInIt)
      .then((response) => {
        dispatch({ type: UPDATE_USER_CART, payload: response });
        response.orders.map((order) => {
          return order.products.map((product) => {
            count += product.quantity;
            return count;
          });
        });
        dispatch({ type: UPDATE_ITEM_COUNT, payload: count });
        dispatch({ type: LOADING, payload: false });
      })
      .catch((err) => {
        dispatch({ type: ERROR, payload: err });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const ADD_SHIPPING_RATES = 'ADD_SHIPPING_RATES';
export const SHIPPING_RATES_LOADING = 'SHIPPING_RATES_LOADING';

export const getRates = (info, order_id) => {
  const shipDate = new Date();
  shipDate.setDate(shipDate.getDate() + 3);

  return (dispatch) => {
    dispatch({ type: SHIPPING_RATES_LOADING, payload: true });
    const myInIt = {
      body: {
        address_to: info.addressTo,
        address_from: info.addressFrom,
        parcels: [
          {
            length: '10',
            width: '15',
            height: '3',
            distance_unit: 'in',
            weight: info.parcelWeight || 1,
            mass_unit: 'lb',
          },
        ],
        carrier_accounts: [
          // '050a0aa683df4fe98e087c8c2b4d2eed',
          '60134d91e5cd4c10bb8a73423fa64cf2',
          '74093166dd4647029e14ed3c3b2f0c50',
          // '9b76369af98042a58d8892d0a382c866',
          'b488c415dd1249b78555da6a94a5bbdb'
        ],
      },
    };

    API.post(api, '/shipping/get-rates', myInIt)
      .then((response) => {
        console.log(response);
        let rates = [];
        response.rates.map((rate) => {
          if (rate.estimated_days <= 2) {
            rates.push(rate);
          }
          return rates;
        });
        const shippingRates = {
          rates: rates,
          order_id: order_id,
        };
        dispatch({ type: ADD_SHIPPING_RATES, payload: shippingRates });
        dispatch({ type: SHIPPING_RATES_LOADING, payload: false });
      })
      .catch((err) => {
        dispatch({type: ERROR, payload: err})
      });
  };
};

export const RESET_SHIPPING_RATES = 'RESET_SHIPPING_RATES';
export const resetShippingRates = () => {
  return (dispatch) => {
    dispatch({ type: RESET_SHIPPING_RATES, payload: [] });
  };
};
