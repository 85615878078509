import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import * as act from '../../../store/actions';
//images
import {
  MyAccountIcon,
  ProfileIcon,
  CustomerOrderPurchases,
  CustomerMessages,
  CustomerFav,
  SignOutIcon,
} from '../../assets/customerIconImages';

export const CustomerSideNav = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <List>
      <ListItem
        display='flex'
        sx={{
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        onClick={() => history.push(`/dashboard`)}
      >
        <ListItemIcon>
          <MyAccountIcon {...props} />
        </ListItemIcon>
        <ListItemText
          primary='My Account'
          primaryTypographyProps={{
            fontSize: '18px',
          }}
        />
      </ListItem>
      <ListItem
        display='flex'
        sx={{
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        onClick={() => history.push(`/dashboard/profile`)}
      >
        <ListItemIcon>
          <ProfileIcon {...props} />
        </ListItemIcon>
        <ListItemText
          primary='Profile Information'
          primaryTypographyProps={{
            fontSize: '18px',
          }}
        />
      </ListItem>
      <ListItem
        display='flex'
        sx={{
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        onClick={() => history.push(`/dashboard/history`)}
      >
        <ListItemIcon>
          <CustomerOrderPurchases {...props} />
        </ListItemIcon>
        <ListItemText
          primary='Orders & Refunds'
          primaryTypographyProps={{
            fontSize: '18px',
          }}
        />
      </ListItem>
      <ListItem
        display='flex'
        sx={{
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        onClick={() => history.push('/dashboard/favorites')}
      >
        <ListItemIcon>
          <CustomerFav {...props} />
        </ListItemIcon>
        <ListItemText
          primary='Favorites'
          primaryTypographyProps={{
            fontSize: '18px',
          }}
        />
      </ListItem>
      <ListItem
        display='flex'
        sx={{
          justifyContent: 'space-between',
          cursor: 'pointer',
          opacity: '0.3',
        }}
      >
        <ListItemIcon>
          <CustomerMessages {...props} />
        </ListItemIcon>
        <ListItemText
          primary='Messages'
          primaryTypographyProps={{
            fontSize: '18px',
          }}
        />
      </ListItem>
      <ListItem
        display='flex'
        sx={{
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        onClick={() => dispatch(act.logout(history))}
      >
        <ListItemIcon>
          <SignOutIcon {...props} />
        </ListItemIcon>
        <ListItemText
          primary='SignOut'
          primaryTypographyProps={{
            fontSize: '18px',
          }}
        />
      </ListItem>
    </List>
  );
};

export default CustomerSideNav;
