import React from "react";
import AdminTopBar from '../components/nav/AdminNav';
import MyAccountNav from '../components/nav/MyAccountNav';
import FarmerNav from '../components/nav/FarmerNav';
import ShoppingNav from '../components/nav/ShoppingNav';
import { roles, categories } from "../util/data";
import {useSelector} from "react-redux"


 
const NavBar = (props) => {
  
  const { products, setProducts, filtered, setFiltered } = props;

  const user = useSelector(state => state.UserReducer.user)

    switch (user.app_metadata.roles[0]) {
      case roles.customer:
        return <MyAccountNav {...props} />;
      case roles.farmer:
        return <FarmerNav {...props} />;
      case roles.admin:
        return <AdminTopBar {...props} />;
      case roles.super:
        return <AdminTopBar {...props} />;
      default:
        return (
          <ShoppingNav
            products={products}
            setProducts={setProducts}
            filtered={filtered}
            setFiltered={setFiltered}
            categories={categories}
            {...props}
          />
        );
    }
};
  
export default NavBar;