import React from 'react';
import Button from '@mui/material/Button';

const PrimaryButton = (props) => {
  const { onClick, children, width, fontSize, margin, type, display, height, sx=[], disabled} = props;

  return (
    <Button
      onClick={onClick}
      variant='contained'
      color='primary'
      disableRipple
      disableTouchRipple
      disabled={disabled}
      type={type}
      sx={[
        {
          height: height ? height :'40px',
          display: display,
          width: width,
          fontSize: fontSize || '1rem',
          color: 'primary.darkFont',
          margin: margin,
          boxShadow: 'none',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'primary.mainHover',
            boxShadow: 'none',
          },
          '&:active': {
            backgroundColor: 'primary.mainPressed',
            boxShadow: 'none',
          },
          // [breakpoints.down(mediaSize)]: media
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Button>
  );
}

export default PrimaryButton;
