import React, { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PrimaryButton from '../../inputs/Buttons/Button';
import Popover from '@mui/material/Popover';
import { useParams } from 'react-router-dom';
import AddToBasket from '../../cart/modals/AddedToBasket';
import { FarmLogo } from '../../farm/components/FarmLogo';
import * as utils from '../../../util';
import { range } from '../../../util/data';
import { API } from 'aws-amplify';
import Favorite from '../../customer/components/Favorite';

const ProductDescription = (props) => {
  const {
    product,
    farm,
    setActiveImage,
    setProduct,
    addProductToCart,
    open,
    anchorEl,
    setAnchorEl,
    quantity,
    setQuantity,
  } = props;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPop = Boolean(anchorEl);
  const api = 'FarmEraMarketAPI';

  const params = useParams();

  const handleSelect = (e) => {
    setQuantity(e.target.value);
  };

  useEffect(() => {
    API.get(api, `/product/${params.id}`)
      .then((response) => {
        setProduct(response);
        setActiveImage(response.images[0].url);
      })
      .catch((err) => {
        console.dir(err);
      });
  }, [params.id]);

  return (
    <Stack sx={{ ml: 10 }}>
      <Typography variant='h3'>{product.name}</Typography>
      <Typography variant='h5' className='productDesc'>
        {product.description_short}
      </Typography>

      <Rating readOnly value={utils.calculateRating(product.reviews)} />
      <Typography variant='p' sx={{ display: 'flex', my: 0.5 }}>
        {product.reviews.length} reviews
      </Typography>
      <Typography variant='h2'>
        <Typography variant='span' color={'primary.secondary'}>
          ${product.price.toFixed(2)}
        </Typography>
        /{product.label}
      </Typography>
      <FormControl>
        <InputLabel id='quantity' sx={{ my: 3.2 }}>
          Quantity
        </InputLabel>
        <Select
          labelId='quantity'
          placeholder={`${range[0]} ${product.label}`}
          name={'quantity'}
          label={'Quantity:'}
          value={quantity}
          sx={{ width: '300px', my: 3 }}
          onChange={(e) => handleSelect(e)}
        >
          {range.map((num) => {
            return (
              <MenuItem key={num} value={num}>
                {num} {product.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <PrimaryButton
          width='300px'
          onClick={(e) => addProductToCart(e, quantity)}
        >
          Add to Basket
        </PrimaryButton>
        <Popover
          open={openPop}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Typography sx={{ color: 'primary.error', p: 2 }}>
            Sorry this product is sold out.
          </Typography>
        </Popover>
        <AddToBasket product={product} open={open} />
        <Favorite item={product.id} type='products' />
      </Stack>
      {farm && <FarmLogo display='flex' linkDisplay='none' farm={farm} />}
    </Stack>
  );
};

export default ProductDescription;
