import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';

import { InputLabel } from '@mui/material';

const UseFormSelect = (props) => {
  const { value, name, rules, onChange, array, menuLabel, label, width, defaultValue } =
    props;
  const { register, formState: {errors} } = useFormContext({
    mode: 'onChange',
    revalidationMode: 'onChange',
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  console.log(rules, errors)
  return (
    <FormControl>
      <InputLabel
        id={`${label}${name}`}
        style={{ margin: '10px 0', fontSize: '16px' }}
      >
        {label}
      </InputLabel>
      {
        <Select
          sx={{ margin: '10px 0', width: width, fontSize:'16px' }}
          labelId={`${label}${name}`}
          value={value}
          {...register(name, rules )}
          onChange={(e) => onChange(e)}
          defaultValue={defaultValue}
          input={<OutlinedInput label='Shipping Carriers' name={name}/>}
          MenuProps={MenuProps}
        >
          {array.map((item) => {
            return (
              <MenuItem key={item} value={item} sx={{fontSize: '16px'}}>
                {item}
                {menuLabel ? menuLabel : null}
              </MenuItem>
            );
          })}
        </Select>
      }
    </FormControl>
  );
};

export default UseFormSelect;
