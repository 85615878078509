import React from 'react';
import * as utils from '../../../util/index';
import { Box, Typography, Rating } from '@mui/material';

export const ProductContent = (props) => {
  const { product } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mb: 1,
        textOverflow: 'hidden ellipsis',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Typography variant='p' fontWeight={'bold'} sx={{ mb: 0.7, mt: 0 }}>
        <Typography variant='span' color={'secondary.main'}>
          ${product.price.toFixed(2)}
        </Typography>{' '}
        / {product.label}
      </Typography>
      <Typography variant='h5' noWrap>
        {product.name}
      </Typography>
      <Typography
        variant='p'
        noWrap={true}
        sx={{
          lineHeight: '1.1875rem',
          width: '90%',
          textOverflow: 'ellipsis ellipsis',
          fontSize: '14px',
        }}
      >
        {product.description_short}
      </Typography>
      <Rating
        value={utils.calculateRating(product.reviews)}
        size='small'
        readOnly
      />
    </Box>
  );
};
