import React from 'react';
import * as utils from '../../../util';
import NoImg from '../../../images/NoImg.svg';
import { Box, Avatar, Typography, Rating } from '@mui/material';
import Stack from "@mui/material/Stack";
import {useHistory} from "react-router-dom"

export const FarmLogo = (props) => {
  const { farm, display, linkDisplay,cityFontSize, ratingDisplay,ratingFontSize, ratingSize, milesDisplay, imgSize, nameFontSize } = props;
  const history=useHistory()
  let rating = 0;

  if (farm?.reviews?.length >= 1) {
    rating = utils.calculateRating(farm.reviews);
  }

  return (
    <Stack>
      {farm && (
        <Stack direction="row" width="350px" sx={{cursor: 'pointer'}} onClick={() => history.push(`/store/farmstand/${farm.id}`)}>
          <Avatar sx={imgSize} alt={farm.name} src={farm.farm_logo || NoImg} />
          <Stack
            marginLeft="3%"
            width="100%"
           
          >
            <Typography variant='h6' fontSize={nameFontSize}>{farm.name}</Typography>
            <Typography
              variant='p'
              fontSize={cityFontSize}
              className={`farmCity ${props.farmCity}`}
            >
              {farm.city}, {farm.state}
            </Typography>

            <Stack
              direction="row"
             alignItems="center"
            >
              <Rating
                value={rating}
                readOnly
                size={ratingSize ? ratingSize : 'small'}
                sx={{ display: ratingDisplay }}
              />
              <Typography variant='p' sx={{ display: ratingDisplay ? ratingDisplay : "flex", fontSize: ratingFontSize ? ratingFontSize : "12px", margin:"0 5px", alignItems:"center" }}>
                {farm?.reviews?.length} Reviews
              </Typography>
            </Stack>
            <Stack>
              <Typography variant='p' sx={{fontSize:"12px"}}>
                <b style={{display: milesDisplay}}>0.0 miles</b>{' '}
                <span style={{ display: linkDisplay }}>View in map</span>
                <span style={{ display: linkDisplay }}>Contact Farm</span>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
