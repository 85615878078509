const h5 = {
  fontSize: '1.125rem',
  fontWeight: 700,
  fontFamily: 'Raleway, sans-serif',
  color: '#494646',
};

const h1 = {
  fontSize: '2.25rem',
  fontWeight: 700,
  lineHeight: 1.28,
  fontFamily: 'Raleway, sans-serif',
  color: '#494646',
};
const h2 = {
  fontSize: '2rem',
  fontWeight: 700,
  fontFamily: 'Raleway, sans-serif',
  color: '#494646',
};

const h3 = {
  fontSize: '1.75rem',
  fontFamily: 'Raleway, sans-serif',
  color: '#494646',
  fontWeight: 700,
};
const h4 = {
  fontFamily: 'Raleway, sans-serif',
  color: '#494646',
  fontSize: '1.5rem',
  fontWeight: 700,
};

const h6 = {
  fontSize: '1.25rem',
  fontWeight: 700,
  fontFamily: 'Open Sans',
  color: '#494646',
};

const body1 = {
  fontSize: '0.875',
  fontFamily: 'Open Sans',
  color: '#494646',
};
const button = {
  fontFamily: 'Open Sans',
  fontSize: '1.125rem',
  color: '#494646',
};
const p = {
  fontSize: '1rem',
  fontFamily: 'Open Sans, sans-serif',
  color: '#494646',
};
const a = {
  fontSize: '1rem',
  fontFamily: 'Open Sans, sans-serif',
  color: '#0054bc',
  cursor: "pointer"
};
const small = {
  fontSize: '10px',
  fontFamily: 'Open Sans, sans-serif',
};
const error = {
  fontSize: '14px',
  fontFamily: 'Open Sans, sans-serif',
  color: 'red'
};

const typography = {
  h5,
  h6,
  body1,
  button,
  p,
  h1,
  h2,
  h3,
  h4,
  a,
  small,
  error
};

export default typography;
