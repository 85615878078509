import React from 'react';
import Footer from './Footer';
import { useSelector } from 'react-redux';

import PrimaryButton from '../inputs/Buttons/Button';
import TransparentButton from '../inputs/Buttons/TransparentButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  StackedLogoWhite,
  CustomerSupport,
  DeliveryOptions,
  Flexibility,
  Grow,
  InventoryManager,
  MarketBusiness,
  MultipleFarms,
  NoFee,
  QuickEasy,
  Reach,
  Stripe,
  Tutorials,
  WebMaintenance,
} from '../assets/farmIconImages';

const FarmerMarketingPage = (props) => {
  const userId = useSelector((state) => state.UserReducer.user.user_id);

  const handlePush = () => {
    if (!userId) {
      props.history.push('/auth/register');
    } else {
      props.history.push('/dashboard/add_farm');
    }
  };

  return (
    <Stack width='100%' margin='0'>
      <Box
        id='overlay'
        height='580px'
        width='100%'
        sx={{
          backgroundImage:
            'url(https://res.cloudinary.com/tripp4077/image/upload/v1614124850/farm-era-public-images/Rectangle_52_cimrrf.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Stack
          height='100%'
          padding='2% 3%'
          sx={{ background: 'rgba(53, 53, 53, 0.5)' }}
        >
          <StackedLogoWhite sx={{ fontSize: '100px', cursor: 'pointer' }} onClick={() => props.history.push('/')} />
          <Stack>
            <Typography
              variant='h1'
              margin='5% 0 3% 5%'
              width='35%'
              height='10%'
              sx={{ color: 'white', fontSize: '3.5rem', lineHeight: '3.9rem' }}
            >
              <span style={{ color: '#FBD200' }}>Everything</span> you need to
              sell your products
              <span style={{ color: '#FBD200' }}> online</span>
            </Typography>

            <TransparentButton
              onClick={handlePush}
              margin='100px'
              color='white'
              width='250px'
            >
              Open Farm Stand{' '}
            </TransparentButton>
          </Stack>
        </Stack>
      </Box>
      <Stack padding='5% 8%'>
        <Typography variant='h1'>Why choose Farm Era Market</Typography>
        <Stack direction='row' justifyContent='space-between' margin='2% 0'>
          <Stack margin='2% 0' width='calc((90%/4))'>
            <Grow sx={{ fontSize: '100px' }} />
            <Typography variant='h4' margin='4% 0'>
              Grow your business
            </Typography>
            <Typography
              variant='p'
              component='p'
              sx={{ fontSize: '1.1rem', lineHeight: '1.4rem' }}
            >
              Our next generation platform provides you easy to use tools and
              helps give you more freedom to manage your business.
            </Typography>
          </Stack>
          <Stack margin='2% 0' width='calc((90%/4))'>
            <NoFee sx={{ fontSize: '100px' }} />
            <Typography variant='h4' margin='4% 0'>
              Pay no listing fee
            </Typography>
            <Typography
              variant='p'
              component='p'
              sx={{ fontSize: '1.1rem', lineHeight: '1.4rem' }}
            >
              No matter the size of your farm, you'll pay no listing fees. List
              as little or as many products as you like!
            </Typography>
          </Stack>
          <Stack margin='2% 0' width='calc((90%/4))'>
            <Reach sx={{ fontSize: '100px' }} />
            <Typography variant='h4' margin='4% 0'>
              Reach more customers
            </Typography>
            <Typography
              variant='p'
              component='p'
              sx={{ fontSize: '1.1rem', lineHeight: '1.4rem' }}
            >
              We provide an intuitive marketplace to help take your business
              online. Connect with new customers locally, regionally and
              nationally.
            </Typography>
          </Stack>
          <Stack margin='2% 0' width='calc((90%/4))'>
            <WebMaintenance sx={{ fontSize: '100px' }} />
            <Typography variant='h4' margin='4% 0'>
              No website maintenance
            </Typography>
            <Typography
              variant='p'
              component='p'
              sx={{ fontSize: '1.1rem', lineHeight: '1.4rem' }}
            >
              Our advanced cloud servers are secure, always updated, and always
              connected 24/7. We help give you more control of your business.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ backgroundColor: 'primary.backgroundColor' }}>
        <Stack
          width='80%'
          padding='5% 3% 4.5% 8%'
          direction='row'
          alignItems='center'
        >
          <Stack width='35%' sx={{ lineHeight: '2.2rem' }}>
            <Typography variant='h1'>
              Join an extended farming community and make an impact
            </Typography>

            <PrimaryButton
              onClick={() => handlePush()}
              margin='4% 0'
              width='250px'
              fontSize='1.125rem'
              sx={{ borderRadius: '4px' }}
            >
              Get Started
            </PrimaryButton>
          </Stack>
          <Stack width='50%' margin='-4% 0 0 22%'>
            <Typography
              variant='p'
              component='p'
              sx={{ fontSize: '1.1rem', lineHeight: '1.6rem' }}
            >
              Our goal is to provide a marketplace that offers easy access to
              fresh and healthy food products by forging a direct connection
              between customers and farmers. At Farm Era Market, we are
              committed to establishing a community that centers on the needs of
              people who grow our food and forming stronger connections to what
              we eat.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack padding='5% 8%'>
        <Typography variant='h1'>Features Farm Era Market provides</Typography>
        <Stack
          direction='row'
          flexWrap='wrap'
          justifyContent='space-between'
          margin='2% 0'
        >
          <Stack width='calc((90%/3))' margin='1%'>
            <MultipleFarms sx={{ fontSize: '100px' }} />
            <Typography variant='h4' margin='4% 0'>
              Manage Multiple Farms
            </Typography>
            <Typography variant='p' component='p'>
              Set up one or more farm stands, even manage multiple pick-up
              locations! Incredible tools at your fingertips
            </Typography>
          </Stack>
          <Stack width='calc((90%/3))' margin='1%'>
            <DeliveryOptions sx={{ fontSize: '100px' }} />
            <Typography variant='h4' margin='4% 0'>
              Convenient Delivery Options
            </Typography>
            <Typography variant='p' component='p'>
              Customize your shipping options from one or multiple carriers,
              even schedule local deliveries. You customers will thank you by
              ordering regularly!
            </Typography>
          </Stack>
          <Stack width='calc((90%/3))' margin='1%'>
            <InventoryManager sx={{ fontSize: '100px' }} />
            <Typography variant='h4' margin='4% 0'>
              Inventory Manager
            </Typography>
            <Typography variant='p' component='p'>
              Maintain your inventory with our easy-to-use platform. Set your
              available product supply and let Farm Era Market do the rest!
            </Typography>
          </Stack>
          <Stack width='calc((90%/3))' margin='1%'>
            <QuickEasy sx={{ fontSize: '100px' }} />
            <Typography variant='h4' margin='4% 0'>
              Quick & Easy Set Up
            </Typography>
            <Typography variant='p' component='p'>
              Setting up your farm stand on our platform is super easy. Our
              convenient setup wizard will walk you through all the necessary
              steps.
            </Typography>
          </Stack>
          <Stack width='calc((90%/3))' margin='1%'>
            <Stripe sx={{ fontSize: '100px' }} />
            <Typography variant='h4' margin='4% 0'>
              Integrated Payments With Stripe
            </Typography>
            <Typography variant='p' component='p'>
              Accept a wide array of payment methods including Apple Pay and
              Google Pay! Never miss a sale and get paid quickly.
            </Typography>
          </Stack>
          <Stack width='calc((90%/3))' margin='1%'>
            <Flexibility sx={{ fontSize: '100px' }} />
            <Typography variant='h4' margin='4% 0'>
              Flexibility and Control
            </Typography>
            <Typography variant='p' component='p'>
              Manage your farm from any location or any device. Your farm stand
              is always available to accept new customers.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        height='500px'
        sx={{
          backgroundImage:
            'url(https://res.cloudinary.com/tripp4077/image/upload/v1614124917/farm-era-public-images/Rectangle_58_foyzdg.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Stack
          width='35.5%'
          margin='6%'
          padding='3%'
          alignItems='center'
          justifyContent='center'
          sx={{ background: 'white', lineHeight: '26px' }}
        >
          <Typography variant='h3' sx={{ color: 'primary.darkFont' }}>
            Farm Era Market is a web-based marketplace explicitly developed for
            farmers. Find out how we can help your bottom line.
          </Typography>

          <PrimaryButton
            onClick={handlePush}
            width='250px'
            margin='50px 0 0 0'
            fontSize='1.125rem'
          >
            Get Started
          </PrimaryButton>
        </Stack>
      </Stack>
      <Stack
        direction='row'
        margin='3% 8%'
        justifyContent='space-between'
        width='90%'
      >
        <Typography variant='h1' margin='40px 0'>
          Here to help you
        </Typography>
        <Stack width='50%'>
          <Stack direction='row' margin='20px 0' width='50%'>
            <CustomerSupport sx={{ fontSize: '100px' }} />
            <Stack padding='20px'>
              <Typography variant='h4' padding='0 0 20px 0'>
                Complimentary Customer Support
              </Typography>
              <Typography
                variant='p'
                component='p'
                sx={{ fontSize: '1.1rem', lineHeight: '1.4rem' }}
              >
                We provide top-notch support for our farmers, and the best part
                about it is that it's free of charge.
              </Typography>
            </Stack>
          </Stack>
          <Stack direction='row' margin='20px 0' width='50%'>
            <Tutorials sx={{ fontSize: '100px' }} />
            <Stack padding='20px'>
              <Typography variant='h4' padding='0 0 20px 0'>
                Tutorials for online selling
              </Typography>
              <Typography
                variant='p'
                component='p'
                sx={{ fontSize: '1.1rem', lineHeight: '1.4rem' }}
              >
                Sign up today and get access to our in-depth video tutorial
                library. Learn how to set up your farm stand, products,
                shipping, and more.
              </Typography>
            </Stack>
          </Stack>
          <Stack direction='row' margin='20px 0' width='50%'>
            <MarketBusiness sx={{ fontSize: '100px' }} />
            <Stack padding='20px'>
              <Typography variant='h4' padding='0 0 20px 0'>
                Market your business
              </Typography>
              <Typography
                variant='p'
                component='p'
                sx={{ fontSize: '1.1rem', lineHeight: '1.4rem' }}
              >
                We're always happy to assist our farmers in becoming more
                successful. Feel free to give us a shout!
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between" padding="2% 8%" width="90%">
        <Stack width="60%" padding=" 0 0 75px 0">
          <Typography variant='h1' padding="0 0 30px 0">Wait no longer and start today</Typography>
          <Typography variant='p' component='p' sx={{fontSize: "18px", lineHeight: "22px"}}>
            Join the Farm Era Market platform as we plant and grow a community
            that fosters a direct relationship between farmers and the
            well-informed health-conscious customer. See for yourself how you
            can boost your bottom line and increase your farm's reach.
          </Typography>
        </Stack>

        <PrimaryButton
        margin="50px 0 0 0" fontSize="1.125rem" width="250px"
          onClick={handlePush}
        >
          Get Started
        </PrimaryButton>
      </Stack>
      <Footer />
    </Stack>
  );
};

export default FarmerMarketingPage;
