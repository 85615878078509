import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';

const Unflag = (props) => {
  const history = useHistory();

  return (
    <Accordion
      elevation={0}
      sx={{
        bgcolor: 'primary.formOutline2',
        border: 'none',
        margin: '-10px 0',
        padding: '0',
      }}
    >
      <AccordionSummary
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={{ padding: '0px', border: 'none' }}
      >
        <ListItemText primary='Unflag' />
      </AccordionSummary>
      <AccordionDetails sx={{ border: 'none' }}>
        <ListItem
          sx={{ cursor: 'pointer' }}
          onClick={() => history.push('/dashboard/unflag-customer')}
        >
          <ListItemText primary='Unflag Customer' />
        </ListItem>
        <ListItem
          sx={{ cursor: 'pointer' }}
          onClick={() => history.push('/dashboard/unflag-farmer')}
        >
          <ListItemText primary='Unflag Farmer' />
        </ListItem>
        <ListItem
          sx={{ cursor: 'pointer' }}
          onClick={() => history.push('/dashboard/unflag-farm')}
        >
          <ListItemText primary='Unflag Farm' />
        </ListItem>
      </AccordionDetails>
    </Accordion>
  );
};

export default Unflag;
