import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import StackedLogoComponent from './components/StackedLogo';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import AccountIcons from './components/AccountIcons';
import AccountMenu from './components/AccountMenu';
import Stack from '@mui/material/Stack';

import * as utils from '../../util';

const MyAccountNav = (props) => {
  const [user, setUser] = useState(
    useSelector((state) => state.UserReducer.user)
  );
  const cart = useSelector((state) => state.CartReducer.userCart);

  useEffect(() => {
    cart?.user_id && utils.cartCount(cart);
  }, [cart?.user_id]);

  return (
    <Stack
      direction='row'
      alignItems='center'
      height='100px'
      justifyContent='space-between'
      width='90%'
    >
      <Stack direction='row' alignItems='center'>
        {user?.user_metadata?.zip ? (
          <Stack direction='row' alignItems='center'>
            <LocationOnIcon
              sx={{
                fontSize: '18px',
                color: 'primary.radio',
                margin: '0 5px',
              }}
            />
            <Typography variant='p'>{user.user_metadata.zip}</Typography>{' '}
          </Stack>
        ) : null}
      </Stack>
      <StackedLogoComponent />
      <AccountMenu />
      <AccountIcons />
    </Stack>
  );
};

export default MyAccountNav;
