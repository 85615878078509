import React from 'react';
import Modal from '../../display/modal';
import PrimaryButton from '../../inputs/Buttons/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const OrderFailureModal = (props) => {
  const { open, setOpen, error } = props;

  const handleClick = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      top='50%'
      left='50%'
      TitleMessage={'Order was not Submitted Successfully'}
      message={
        <Stack>
          <Typography variant='p'>{error?.code}</Typography>
          <PrimaryButton margin='20px 30%' onClick={() => handleClick()}>
            Close
          </PrimaryButton>
        </Stack>
      }
    />
  );
};

export default OrderFailureModal;
