import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SearchBar from './SearchBar';
import BlockIcon from '@mui/icons-material/Block';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router-dom";

const FarmsList = (props) => {
  const { farms, productsCountMap, farmOrderData } = props;
  const history = useHistory()

  const [displayFarms, setDisplayFarms] = useState([]);

  const reducer = (sum = 0, review) => {
    if (typeof sum === 'object') return sum.rate + review.rate;
    else return sum + review.rate;
  };

  const calculateAverageRating = (reviews) => {
    if (!Array.isArray(reviews)) return 0;

    const numberOfReviews = reviews.length;

    switch (numberOfReviews) {
      case 0:
        return 0;

      case 1:
        return reviews[0].rate;

      default:
        return reviews.reduce(reducer) / numberOfReviews;
    }
  };

  const createData = (farm, index) => {
    return {
      index: index + 1,
      id: farm.id,
      name: farm.name,
      location: `${farm.city}, ${farm.state}`,
      products: productsCountMap[farm.id]?.count || 0,
      rating: calculateAverageRating(farm.reviews),
      orders: farmOrderData[farm.id]?.length || 0,
      flagged: farm.flagged || false,
      blocked: farm.blocked || false
    };
  };

  const viewMore = (id) => history.push(`/dashboard/farm/${id}`);

  const searchFarms = (event) =>
    setDisplayFarms(
      farms.filter((farm) =>
        farm.name.toLowerCase().includes(event.target.value.toLowerCase())
      )
    );

  useEffect(() => setDisplayFarms(farms), [farms]);

  const rows = displayFarms.map((farm, index) => createData(farm, index));

  return (
    <Stack width='80%' sx={{ overflow: 'scroll' }}>
      <Stack>
        <SearchBar search={searchFarms} title=' Search By Farm' />
        <Typography variant='p' margin='20px' fontSize='30px'>
          {displayFarms.length} Total Farms
        </Typography>
      </Stack>
      {/* <Card elevation={10} > */}
      <TableContainer component={Paper}>
        <Table stickyHeader width='100%' aria-label='sticky table'>
          <TableHead sx={{ backgroundColor: '#EAEDE9' }}>
            <TableRow>
              <TableCell>Farm Number</TableCell>
              <TableCell align='left'>Farm Name</TableCell>
              <TableCell align='left'>Location</TableCell>
              <TableCell align='left'># Items Listed</TableCell>
              <TableCell align='left'>Avg Rating</TableCell>
              <TableCell align='left'>Total Order</TableCell>
              <TableCell align='left'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow hover key={`${row.name}-${row.index}`}>
                <TableCell component='th' scope='row'>
                  {`#${row.index}`}
                </TableCell>
                <TableCell
                  align='left'
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {row.name}{' '}
                  {row.blocked ? (
                    <BlockIcon sx={{ color: 'red', marginLeft: '10px' }} />
                  ) : row.flagged ? (
                    <EmojiFlagsIcon sx={{ color: 'red', marginLeft: '10px' }} />
                  ) : null}
                </TableCell>
                <TableCell align='left'>{row.location}</TableCell>
                <TableCell align='center'>{row.products}</TableCell>
                <TableCell align='center'>{row.rating.toFixed(0)}</TableCell>
                <TableCell align='center'>{row.orders}</TableCell>
                <Tooltip title="View More">
                  <TableCell
                    align='left'
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => viewMore(row.id)}
                  >
                    <span style={{ fontSize: '22px' }}>...</span>
                  </TableCell>
                </Tooltip>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* </Card> */}
    </Stack>
  );
};

export default FarmsList;
