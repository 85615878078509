import * as act from '../actions';

const InitialState = {
  userCart: {
    payment: {
      taxes: 0,
      total: 0,
      shipping: 0,
      subtotal: 0,
      refund: [],
      transfer: []
    },
    user_id: '',
    orders: [],
  },
  itemCount: 0,
  isLoading: false,
  error: '',
  shippingRates: [],
  ratesLoading: false,
};

export const CartReducer = (state = InitialState, action) => {
  switch (action.type) {
    case act.UPDATE_USER_CART:
      return {
        ...state,
        userCart: action.payload,
      };
    case act.ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case act.LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case act.ADD_SHIPPING_RATES:
        return {
          ...state,
          shippingRates: [...state.shippingRates, action.payload],
        };
    case act.RESET_SHIPPING_RATES:
      return {
        ...state,
        shippingRates: [],
      };
    case act.SHIPPING_RATES_LOADING: {
      return {
        ...state,
        ratesLoading: action.payload,
      };
    }
    // case act.ADD_SHIPPING_RATES_ERROR:
    //     return{
    //         ...state,
    //         error: action.payload
    //     }
    case act.UPDATE_ITEM_COUNT:
      return {
        ...state,
        itemCount: action.payload,
      };
    case act.LOGOUT:
      return {
        ...state,
        userCart: {
          userId: '',
          orders: [],
        },
        isLoading: '',
        itemCount: 0,
        error: '',
        shippingRates: [],
      };
    default:
      return state;
  }
};
