import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import * as utils from '../../../../util';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card'
import PrimaryButton from '../../../inputs/Buttons/Button';
import Stack from '@mui/material/Stack';
import State from '../../../misc/components/state';
import UseFormCheckbox from '../../../inputs/CheckBox/UseFormCheckbox';
import UseFormTextField from '../../../inputs/TextFields/UseFormTextField';

const PickupLocations = (props) => {
  const Days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const { farm, setFarm } = props;

  const {
    getValues,
    register,
    clearErrors,
    watch,
    setValue,
    control,
    unregister,
    trigger,
    resetField,
    setError,
    formState: { errors, isValid },
  } = useFormContext({
    defaultValues: {
      delivery_methods: {
        location: {
          name: '',
          address: '',
          city: '',
          state: utils.stateAbbrev[0],
          zip: '',
          pickup_day: Days[0],
          pickup_start: utils.time()[0],
          pickup_end: utils.time()[0],
        },
      },
    },
  });

  const pickup = watch(`delivery_methods.pickup`);

  const handleInput = (e) => {
    setFarm({
      ...farm,
      delivery_methods: {
        ...farm.delivery_methods,
        location: {
          ...farm.delivery_methods.location,
          [e.target.name]: e.target.value,
        },
      },
    });
    setValue(`${e.target.name}`, e.target.value);
  };

  const handleCheck = (value) => {
    setValue('delivery_methods.pickup', !value);
    setFarm({
      ...farm,
      delivery_methods: {
        ...farm.delivery_methods,
        pickup: !value,
      },
    });

    if (value) {
      clearErrors();
      setValue('delivery_methods.location', []);
      setValue('delivery_methods.locations', []);
      unregister('delivery_methods.location');
      setFarm({
        ...farm,
        delivery_methods: {
          ...farm.delivery_methods,
          location: [],
          pickup: false,
        },
      });
    }
    trigger();
  };
  console.log(errors);
  const removeLocation = (index) => {
    let locations = farm.delivery_methods.locations;
    locations.splice(index, 1);
    setValue('delivery_methods.locations', locations);
    setFarm({
      ...farm,
      delivery_methods: {
        ...farm.delivery_methods,
        locations: locations,
      },
    });
    trigger();
  };

  return (
    <Stack>
      <UseFormCheckbox
        label={'Pickup'}
        name='delivery_methods.pickup'
        value={farm.delivery_methods?.pickup}
        onChange={() => handleCheck(farm.delivery_methods.pickup)}
        checked={Boolean(farm.delivery_methods.pickup)}
        size='medium'
        color='primary'
      />

      {pickup ? (
        <Stack
          {...register('location', {
            validate: {
              length: () =>
                farm.delivery_methods.locations.length > 0 ||
                'Must add at least one location',
            },
          })}
        >
          {errors.location && <Typography variant="error">{errors.location.message}</Typography>}
          <Stack>
            <UseFormTextField
              name='delivery_methods.location.name'
              onInput={(e) => handleInput(e)}
              onChange={() => null}
              onKeyDown={() => null}
              rules={{
                // required: 'Pickup location name is required',
                shouldUnregister: true,
              }}
              label={
                errors?.locationName ? (
                  <Typography variant='error' color='primary.error'>
                    {errors.locationName.message}
                  </Typography>
                ) : (
                  'Pickup location name'
                )
              }
              defaultValue={
                farm.delivery_methods.location &&
                farm.delivery_methods.location[0]
                  ? farm.delivery_methods.location[
                      farm.delivery_methods.location.length - 1
                    ].name
                  : null
              }
            />
            <UseFormTextField
              name='delivery_methods.location.address'
              rules={{
                // required: 'Pickup address is required',
                shouldUnregister: true,
              }}
              label={
                errors?.locationAddress ? (
                  <Typography variant='error'>
                    {errors.locationAddress.message}
                  </Typography>
                ) : (
                  'Street address'
                )
              }
              defaultValue={
                farm.delivery_methods.location &&
                farm.delivery_methods.location[0]
                  ? farm.delivery_methods.location[
                      farm.delivery_methods.location.length - 1
                    ].address
                  : null
              }
              onInput={(e) => handleInput(e)}
              onChange={() => null}
              onKeyDown={() => null}
            />
            <Stack direction='row' width='100%' justifyContent='space-between'>
              <UseFormTextField
                name='delivery_methods.location.city'
                rules={{
                  // required: 'Pickup city is required',
                  shouldUnregister: true,
                }}
                label={
                  errors?.locationCity ? (
                    <Typography variant='error'>
                      {errors.locationCity.message}
                    </Typography>
                  ) : (
                    'City'
                  )
                }
                width='49%'
                defaultValue={
                  farm.delivery_methods.location &&
                  farm.delivery_methods.location[0]
                    ? farm.delivery_methods.location[
                        farm.delivery_methods.location.length - 1
                      ].city
                    : null
                }
                onInput={(e) => handleInput(e)}
                onChange={() => null}
                onKeyDown={() => null}
              />
              <State
                width='49%'
                name='delivery_methods.location.state'
                style='mediumSelect'
                onClick={(value) => {
                  setValue('delivery_methods.location.state', value);
                }}
                defaultValue={utils.stateAbbrev[0]}
              />
            </Stack>
            <Stack>
              <Stack direction='row' justifyContent='space-between'>
                <UseFormTextField
                  name='delivery_methods.location.zip'
                  rules={{
                    maxLength: {
                      value: 5,
                      message: 'Zip must be 5 digits',
                    },
                    minLength: {
                      value: 5,
                      message: 'Zip must be 5 digits',
                    },
                    shouldUnregister: true,
                    // required: 'Pickup zip code is required',
                  }}
                  label={
                    errors?.locationZip ? (
                      <Typography variant='error'>
                        {errors.locationZip.message}
                      </Typography>
                    ) : (
                      'Zip Code'
                    )
                  }
                  width='49%'
                  inputProps={{
                    maxLength: 5,
                  }}
                  defaultValue={
                    farm.delivery_methods.location &&
                    farm.delivery_methods.location[0]
                      ? farm.delivery_methods.location[
                          farm.delivery_methods.location.length - 1
                        ].zip
                      : null
                  }
                  onInput={(e) => handleInput(e)}
                  onChange={() => null}
                  onKeyDown={() => null}
                />
                <FormControl sx={{ margin: '10px 0', width: '49%' }}>
                  <InputLabel id='pickup-day' sx={{ fontSize: '1rem' }}>
                    {errors?.PickupDay ? (
                      <Typography variant='error'>
                        {errors.PickupDay.message}
                      </Typography>
                    ) : (
                      'Pickup Day'
                    )}
                  </InputLabel>
                  <Controller
                    name='delivery_methods.location.pickup_day'
                    rules={
                      pickup && {
                        shouldUnregister: true,
                      }
                    }
                    render={({ field: { name, value, onChange, onBlur } }) => (
                      <Select
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        sx={{ fontSize: '1rem' }}
                        labelId='pickup-day'
                        label='Pickup Day'
                      >
                        {Days.map((name, i) => (
                          <MenuItem
                            onClick={() =>
                              setValue(
                                'delivery_methods.location.pickup_day',
                                name
                              )
                            }
                            sx={{ fontSize: '1rem' }}
                            key={i}
                            value={name}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    control={control}
                    defaultValue={Days[0]}
                  />
                </FormControl>
              </Stack>
              <Stack direction='row' justifyContent='space-between'>
                <Controller
                  name='delivery_methods.location.pickup_start'
                  render={({ field: { name, value, onChange, onBlur } }) => (
                    <FormControl sx={{ margin: '10px 0', width: '49%' }}>
                      <InputLabel id='pickup-start' sx={{ fontSize: '1rem' }}>
                        {errors?.PickupStart ? (
                          <Typography variant='error'>
                            {errors.PickupStart.message}
                          </Typography>
                        ) : (
                          'Pickup Start Time'
                        )}
                      </InputLabel>
                      <Select
                        labelId='pickup-start'
                        label='Pickup Start Time'
                        sx={{ fontSize: '1rem' }}
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                      >
                        {utils.time().map((time, i) => {
                          return (
                            <MenuItem
                              onClick={() =>
                                setValue(
                                  'delivery_methods.location.pickup_start',
                                  time
                                )
                              }
                              key={i}
                              value={time}
                              sx={{ fontSize: '1rem' }}
                            >
                              {parseInt(time.split(':')[0]) === 12
                                ? `${time} PM`
                                : parseInt(time.split(':')[0]) >= 13
                                ? `${parseInt(time.split(':')[0] - 12)}:${
                                    time.split(':')[1]
                                  } PM`
                                : `${time} AM`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                  control={control}
                  defaultValue={utils.time()[1]}
                />
                <FormControl sx={{ margin: '10px 0', width: '49%' }}>
                  <InputLabel id='pickup-end' sx={{ fontSize: '1rem' }}>
                    {errors?.PickupEnd ? (
                      <Typography variant='error'>
                        {errors.PickupEnd.message}
                      </Typography>
                    ) : (
                      'Pickup End Time'
                    )}
                  </InputLabel>

                  <Controller
                    control={control}
                    name='delivery_methods.location.pickup_end'
                    render={({ field: { name, value, onChange, onBlur } }) => (
                      <Select
                        labelId='pickup-end'
                        label='Pickup End Time'
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        sx={{ fontSize: '1rem' }}
                      >
                        {utils.time().map((time, i) => {
                          return (
                            <MenuItem
                              onClick={() =>
                                setValue(
                                  'delivery_methods.location.pickup_end',
                                  time
                                )
                              }
                              key={i}
                              value={time}
                              sx={{ fontSize: '1rem' }}
                            >
                              {parseInt(time.split(':')[0]) === 12
                                ? `${time} PM`
                                : parseInt(time.split(':')[0]) >= 13
                                ? `${parseInt(time.split(':')[0] - 12)}:${
                                    time.split(':')[1]
                                  } PM`
                                : `${time} AM`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                    defaultValue={utils.time()[2]}
                  />
                </FormControl>
              </Stack>
            </Stack>
          </Stack>

          <PrimaryButton
            type='button'
            width='200px'
            onClick={(e) => {
              e.preventDefault();
              clearErrors();
              const pickupLoc = getValues('delivery_methods.location');

              if (!pickupLoc.name) {
                setError('locationName', {
                  type: 'required',
                  message: 'Pickup Name is required',
                });
              }
              if (!pickupLoc.address) {
                setError('locationAddress', {
                  type: 'required',
                  message: 'Pickup Address is required',
                });
              }
              if (!pickupLoc.city) {
                setError('locationCity', {
                  type: 'required',
                  message: 'Pickup City is required',
                });
              }
              if (!pickupLoc.zip) {
                setError('locationZip', {
                  type: 'required',
                  message: 'Pickup Zip is required',
                });
              }
              if (!pickupLoc.pickup_day) {
                setError('PickupDay', {
                  type: 'required',
                  message: 'Pickup Day is required',
                });
              }
              if (!pickupLoc.pickup_start) {
                setError('PickupStart', {
                  type: 'required',
                  message: 'Pickup Start Time is required',
                });
              }
              if (!pickupLoc.pickup_end) {
                setError('PickupEnd', {
                  type: 'required',
                  message: 'Pickup End Time is required',
                });
              } else if (
                parseInt(pickupLoc.pickup_end.split(':').join('')) <
                parseInt(pickupLoc.pickup_start.split(':').join(''))
              ) {
                setError('PickupEnd', {
                  type: 'greaterThan',
                  message: 'Pickup End must be later than Pickup Start',
                });
              }

              if (
                !!pickupLoc.name &&
                !!pickupLoc.address &&
                !!pickupLoc.city &&
                !!pickupLoc.state &&
                !!pickupLoc.zip &&
                !!pickupLoc.pickup_day &&
                !!pickupLoc.pickup_start &&
                !!pickupLoc.pickup_end &&
                !!(
                  parseInt(pickupLoc.pickup_end.split(':').join('')) >
                  parseInt(pickupLoc.pickup_start.split(':').join(''))
                )
              ) {
                setValue('delivery_methods.locations', [
                  ...farm.delivery_methods.locations,
                  pickupLoc,
                ]);
                setFarm({
                  ...farm,
                  delivery_methods: {
                    ...farm.delivery_methods,
                    locations: [...farm.delivery_methods.locations, pickupLoc],
                  },
                });
                resetField('delivery_methods.location', {
                  defaultValue: {
                    name: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                    pickup_day: '',
                    pickup_start: '',
                    pickup_end: '',
                  },
                });
              }
              clearErrors('delivery_methods.location');
            }}
          >
            Add Location
          </PrimaryButton>

          {farm.delivery_methods.locations ? (
            <Stack
              direction='row'
              flexWrap='wrap'
              marginTop='50px'
              sx={{
                gap: 0.5,
              }}
            >
              {farm.delivery_methods.locations.map((value, i) => {
                return (
                  <Card
                    key={i}
                    elevation={5}
                    sx={{
                      border: '1px solid gray',
                      boxShadow: '2px 2px 5px 1px',
                      margin: '0px 15px',
                      bgcolor: 'background.light',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <CardActions
                      sx={{ margin: '0px 5px 0 0', justifyContent: 'flex-end' }}
                    >
                      {' '}
                      <CloseIcon onClick={() => removeLocation(i)} />
                    </CardActions>
                    <CardContent
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '0',
                      }}
                    >
                      <Typography variant='h4'>{value.name}</Typography>
                      <Typography variant='p'>{value.address}</Typography>
                      <Typography variant='p'>
                        {value.city}, {value.state} {value.zip}
                      </Typography>
                      <Typography variant='p'>
                        Pickup Day: {value.pickup_day}
                      </Typography>
                      <Typography variant='p'>
                        Pickup Time:{' '}
                        {value.pickup_start.split(':')[0] === 12
                          ? value.pickup_start
                          : value.pickup_start.split(':')[0] > 12
                          ? `${
                              parseInt(value.pickup_start.split(':')[0]) - 12
                            }:${value.pickup_start.split(':')[1]}PM`
                          : `${value.pickup_start} AM`}{' '}
                        -
                        {value.pickup_end.split(':')[0] === 12
                          ? value.pickup_end
                          : value.pickup_end.split(':')[0] > 12
                          ? `${parseInt(value.pickup_end.split(':')[0]) - 12}:${
                              value.pickup_end.split(':')[1]
                            }PM`
                          : `${value.pickup_end} AM`}
                      </Typography>
                    </CardContent>
                  </Card>
                );
              })}
            </Stack>
          ) : null}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default PickupLocations;
