import React from 'react';
import Modal from '../../display/modal';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Disclaimer = (props) => {
  const { open, setOpen } = props;

  return (
    <Modal
      top={'50%'}
      left={'50%'}
      width={'800px'}
      height={'400px'}
      open={open}
      TitleMessage={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '50px',
          }}
        >
          <Typography variant='h4'>Legal Disclaimer:</Typography>
          <CloseIcon
            onClick={() => setOpen(false)}
            sx={{ marginRight: '20px' }}
          />
        </Box>
      }
      message={
        <Typography variant='p' marginTop='50px'>
          Farm Era Market along with all associated
          companies and employees, does not warrant, implied or otherwise, any
          items purchased from a third party through the website. Farm Era
          Market is not, in any circumstance, responsible for any damages,
          either to property or person, that may be caused by items purchased
          from a third party through the website.{' '}
          <b>Consume items purchased from Farm Era Market at own risk.</b> Farm
          Era Market is not responsible for fraudulent items. See Return Policy.
          By purchasing products through this website, you agree to the terms of
          this Disclaimer.
        </Typography>
      }
    />
  );
};

export default Disclaimer;
