import React, {useState} from "react";
import About from "./models/About";
import FooterLogo from "../../images/FooterLogo.svg";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link"
import Terms from "./models/Terms";
import Disclaimer from "./models/LegalDisclaimer"
import FAQ from "./models/FAQ"

const Footer = (props) => {
  const [aboutOpen, setAboutOpen] = useState(false)
  const [termsOpen, setTermsOpen] = useState(false)
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);
  const [FAQOpen, setFAQOpen] = useState(false);
  return (
    <Stack
      width='100%'
      padding='1% 10%'
      sx={{ backgroundColor: 'primary.backgroundColor' }}
    >
      <Stack direction='row' justifyContent='space-between' padding='2% 0'>
        <Stack
          width='25%'
          sx={({ breakpoints }) => ({
            [breakpoints.down('mobile')]: {
              width: '70%',
            },
          })}
        >
          <img width='88px' src={FooterLogo} alt='farmera logo' />
          <Typography
            variant='small'
            sx={{ fontSize: '0.8rem', lineHeight: '1.3rem', marginTop: '10px' }}
          >
            Get a seat at the table and become a part of an emerging movement.
            Investor opportunities are available. Reach out to us today!
          </Typography>
          <Stack
            padding='10% 2%'
            sx={{
              borderTop: 'primary.formOutline1',
              color: 'primary.copyright',
            }}
          >
            <p>{`\u00a9`} 2021 Farm Era</p>
          </Stack>
        </Stack>
        <Stack
          direction='row'
          width='70%'
          justifyContent='space-between'
          sx={({ breakpoints }) => ({
            [breakpoints.down('mobile')]: {
              flexDirection: 'column',
              justifyContent: 'flex-start',
              margin: '0 20px',
            },
          })}
        >
          <Stack sx={{ lineHeight: '1.7rem' }}>
            <Typography variant='p'>Help</Typography>
            <Link
              onClick={() => setFAQOpen(true)}
              sx={{
                textDecoration: 'none',
                fontSize: '0.8rem',
                color: 'primary.radio',
                cursor: 'pointer',
              }}
            >
              FAQ
            </Link>
            <Link
              sx={{
                textDecoration: 'none',
                fontSize: '0.8rem',
                color: 'primary.radio',
                cursor: 'pointer',
              }}
              href='mailto:hello@farmeramarket.com'
            >
              Contact Us - hello@farmeramarket.com
            </Link>
            <FAQ open={FAQOpen} setOpen={setFAQOpen} />
          </Stack>
          <Stack sx={{ lineHeight: '1.7rem' }}>
            <Typography variant='p'>About</Typography>
            <Link
              onClick={() => setAboutOpen(true)}
              sx={{
                textDecoration: 'none',
                fontSize: '0.8rem',
                color: 'primary.radio',
                cursor: 'pointer',
              }}
            >
              About Farm Era
            </Link>
            <About open={aboutOpen} setOpen={setAboutOpen} />
            {/* <Link to='/'>Farms</Link> */}
          </Stack>
          <Stack sx={{ lineHeight: '1.7rem' }}>
            <Typography variant='p'>Legal</Typography>
            <Link
              onClick={() => setTermsOpen(true)}
              sx={{
                textDecoration: 'none',
                fontSize: '0.8rem',
                color: 'primary.radio',
                cursor: 'pointer',
              }}
            >
              {' '}
              Terms and Conditions
            </Link>
            <Terms open={termsOpen} setOpen={setTermsOpen} />
            <Link
              onClick={() => setDisclaimerOpen(true)}
              sx={{
                textDecoration: 'none',
                fontSize: '0.8rem',
                color: 'primary.radio',
                cursor: 'pointer',
              }}
            >
              {' '}
              Disclaimer
            </Link>
            <Disclaimer open={disclaimerOpen} setOpen={setDisclaimerOpen} />
          </Stack>
        </Stack>
      </Stack>
      <Typography variant='small'  textAlign='center'>
        Website Powered by{' '}
        <Link
          sx={{ fontSize: "10px" }}
          onClick={() =>
            window.open('https://www.devshop24.com', { replace: true })
          }
        >
          DevShop24, LLC
        </Link>
      </Typography>
    </Stack>
  );
};

export default Footer;
