import React from 'react';
import { useSelector } from 'react-redux';
import { ProductContent } from './ProductContent';
import { FarmLogo } from '../../farm/components/FarmLogo';
import { Card, CardContent, CardMedia } from '@mui/material';
import {useHistory} from "react-router-dom"

export const MoreLikeItemCard = (props) => {
  const { product} = props;
  const farms = useSelector((state) => state.FarmReducer.allFarms);
  const farm = farms.find((farm) => farm.id === product.farm_id);
  const history = useHistory();
  
  const handleClick = (e, id) => {
    e.stopPropagation();
    history.push(`/store/product/${id}`);
  };

  return (
    <Card
      key={product.id}
      elevation={20}
      sx={{width:"265px", minWidth:'265px', marginRight: "20px", marginTop:"20px"}}
      onClick={(e) => handleClick(e, product.id)}
    >
      <CardMedia
        component='img'
        width='300px'
        height='150px'
        src={product.images[0]}
        alt={product.name}
      />
      <CardContent>
        <ProductContent product={product} />
        <FarmLogo linkDisplay={'none'} milesDisplay={'none'} ratingDisplay={'none'} farm={farm} />
      </CardContent>
    </Card>
  );
};
