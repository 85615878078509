import React from 'react';
import Chart from 'react-google-charts';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

const SalesChart = (props) => {
  const { orders } = props;

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const year = new Date().getFullYear();

  const generateAreaChartData = (orders) => {
   
    if (!Array.isArray(orders)) return [];
    const formattedData = [['month', 'Sales']];

    months.forEach((month, index) => {
      const sales = orders.filter((order) => {
        const date = order.created_date;
        if (date) {
          const orderMonth = new Date(order.created_date).getMonth();
          const orderYear = new Date(order.created_date).getFullYear();
          return orderYear === year && orderMonth === index + 1;
        } else return false;
      });

      const total = sales.reduce(
        (sum, order) => (sum += order.payment.total),
        0
      );
      formattedData.push([month, total]);
    });

    return formattedData;
  };

  return (
    <Stack
      width='100%'
      height='360px'
      boxSizing='border-box'
      borderRadius='2px'
      marginBottom='15px'
    >
      <Typography variant='h6' lineHeight='22px' color='primary.lightFont'>
        Sales
      </Typography>
      <Stack width='100%'>
        <Card elevation={20} style={{ marginTop: '23px' }}>
          <Chart
            width={'100%'}
            height={'300px'}
            chartType='AreaChart'
            loader={<Typography variant='h3'>Loading Chart</Typography>}
            data={generateAreaChartData(orders)}
            options={{
              hAxis: { title: 'Month', titleTextStyle: { color: '#333' } },
              vAxis: { minValue: 0 },
              curveType: 'function',
              colors: ['#0054BC'],
            }}
          />
        </Card>
      </Stack>
    </Stack>
  );
};

export default SalesChart;
