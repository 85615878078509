import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ReturnsHeader from '../components/dashboard/returns/ReturnsHeader';
import ReturnsInfo from '../components/dashboard/returns/ReturnsInfo';
import ReturnsButtons from '../components/dashboard/returns/returnsButtons';
import * as act from '../../../store/actions';

const Returns = (props) => {
  const params = useParams();
  const [returns, setReturns] = useState([]);
  const allUsers = useSelector((state) => state.UserReducer.allUsers);
  const [order, setOrder] = useState();

  useEffect(() => {
    act.getFarmReturns(params.id, setReturns);
  }, []);

  

  return (
    <Stack marginTop='50px' width='80%'>
      {returns && returns.length > 0 ? (
        <Stack>
          <Typography variant='h3' padding='20px'>
            Refunds
          </Typography>
          {returns?.map((refund, i) => {
            return (
              <Stack key={i}>
                <ReturnsHeader
                  refund={refund}
                  order={order}
                  setOrder={setOrder}
                />
                <Stack direction='row' marginBottom='50px'>
                  <ReturnsInfo refund={refund} order={order} />
                  <ReturnsButtons
                    refund={refund}
                    order={order}
                    setReturns={setReturns}
                    returns={returns}
                    id={params.id}
                    users={allUsers}
                  />
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      ) : (
        <Typography variant='h3' className='noReturns'>
          You currently have no refund requests
        </Typography>
      )}
    </Stack>
  );
};
export default Returns;
