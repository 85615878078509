import React, { useEffect, useState } from 'react';

import { API } from 'aws-amplify';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PrimaryButton from '../../inputs/Buttons/Button';
import FarmInfo from '../components/addFarm/farmInfo';

import ShippingDelivery from '../components/addFarm/ShippingDelivery';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack"

import * as act from '../../../store/actions/index';

const EditFarmSettings = (props) => {
  let api = 'FarmEraMarketAPI';
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [farm, setFarm] = useState();

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: farm,
  });

  useEffect(() => {
    if (farm && farm.id === params.component) {
      return;
    }
    API.get(api, `/farms/${params.component}`)
      .then((response) => {
        setFarm(response);
      })
      .catch((err) => {
        console.dir(err);
      });
  }, [params]);

  const doSubmit = (e) => {
    e.preventDefault();
    let returnData = farm;
    returnData.updatedDate = new Date();

    dispatch(act.updateFarm(returnData, history));
  };

  return (
  
      <FormProvider {...methods}>
        {farm ? (
          <Stack marginTop="50px"  width="80%">
            <FarmInfo farm={farm} edit setFarm={setFarm} />

            <ShippingDelivery farm={farm} setFarm={setFarm} />
            <Stack direction='row' justifyContent='space-between' margin="20px 0">
              <PrimaryButton
                sx={{
                  bgcolor: 'button.primary.main',
                  color: 'black',
                  width: '200px',
                }}
                className='medium primary1'
                onClick={(e) => doSubmit(e)}
              >
                Save Changes
              </PrimaryButton>
              <PrimaryButton
                sx={{
                  bgcolor: 'button.primary.main',
                  color: 'black',
                  width: '200px',
                  marginLeft: '20px',
                }}
                className='medium primary1'
                onClick={() => history.push('/dashboard')}
              >
                Cancel
              </PrimaryButton>
            </Stack>
          </Stack>
        ) : (
          <Typography variant='h6'>Loading</Typography>
        )}
      </FormProvider>
  
  );
};

export default EditFarmSettings;
