const QuickGlanceBoxes = [
  {
    name: "Total Farms",
  },
  {
    name: "Total Products",
  },
  {
    name: "Total Orders",
  },
  {
    name: "New Orders",
  },
  {
    name: "Shipped Orders",
  },
];

export default QuickGlanceBoxes;
