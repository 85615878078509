import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from "@mui/material/useMediaQuery"

const MobileViewAlert = () => {
  const [open, setOpen] = useState(true);
  const mobile = useMediaQuery('(max-width:510px)');
  return (
    <Box sx={{ width: '100%', display: mobile ? 'block' : 'none' }}>
      <Collapse in={open}>
        <Alert
          severity='info'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Farm Era is best viewed in desktop mode
        </Alert>
      </Collapse>
    </Box>
  );
}

export default MobileViewAlert;