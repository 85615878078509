import React from 'react';
import { states } from '../../../../util/data';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as act from '../../../../store/actions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import PrimaryButton from '../../../inputs/Buttons/Button';
import DialogModal from '../../../display/dialog';

const EditAddressInfo = (props) => {
  const { user, showModal, open } = props;
  const { register, control, watch } = useForm({
    mode: 'onChange',
  });

  const values = watch();
  const dispatch = useDispatch();

  const handleUpdateAccountInfo = () => {
    let user_id = user.user_id;
    delete values['user_id'];
    delete values['username'];
    dispatch(act.updateUser(user_id, values));
    showModal();
  };

  const content = (
    <Stack>
      <TextField
        sx={{ width: '500px' }}
        {...register('user_metadata.address')}
        defaultValue={user.user_metadata.address}
        label={'Street Address'}
      />
      <Stack direction='row' justifyContent='space-between' width='500px'>
        <TextField
          sx={{ width: '230px' }}
          {...register('user_metadata.address2')}
          defaultValue={user.user_metadata.address2}
          label={'Apt#, Floor, etc (optional)'}
        />
        <TextField
          sx={{ width: '230px' }}
          {...register('user_metadata.zip')}
          defaultValue={user.user_metadata.zip}
          label={'Zip Code'}
        />
      </Stack>
      <Stack direction='row' justifyContent='space-between' width='500px'>
        <TextField
          sx={{ width: '230px' }}
          {...register('user_metadata.city')}
          defaultValue={user.user_metadata.city}
          label={'City'}
        />
        <FormControl>
          <InputLabel id='state-label' sx={{ mt: 1.1, fontSize: '1rem' }}>
            State
          </InputLabel>
          <Controller
            name='user_metadata.state'
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Select
                variant='outlined'
                labelId='state-label'
                label={'State'}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                name={name}
                sx={{ width: '230px', mt: 1, fontSize: '1rem' }}
              >
                {states.map((state, i) => {
                  return (
                    <MenuItem key={i} value={state.abbreviation}>
                      {state.name}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
            control={control}
          defaultValue={user.user_metadata.state}
            
          />
        </FormControl>
      </Stack>
    </Stack>
  );

  const actions = (
    <Stack direction='row' justifyContent='space-between' width='70%'>
      <PrimaryButton
        type='button'
        width='150px'
        onClick={handleUpdateAccountInfo}
      >
        Save
      </PrimaryButton>
      <PrimaryButton type='button' width='150px' onClick={() => showModal()}>
        Cancel
      </PrimaryButton>
    </Stack>
  );
  return (
    <DialogModal
      open={open}
      title={'Edit Address Info'}
      content={content}
      actions={actions}
    />
  );
};

export default EditAddressInfo;
