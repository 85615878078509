import { API } from 'aws-amplify';
import * as act from '../actions';


export const GET_ALL_ORDERS = 'GET_ALL_ORDERS';
export const ADD_ORDER = 'ADD_ORDER';
export const ORDER_ERROR = 'ORDER_ERROR';
export const ADD_USER_ORDER_HISTORY = 'ADD_USER_ORDER_HISTORY';
export const GET_COMPLETED_ORDER = 'GET_COMPLETED_ORDER';


const api = 'FarmEraMarketAPI';

export const getAllOrders = () => {
  return (dispatch) => {
    API.get(api, '/orders')
      .then((response) => {
        dispatch({ type: GET_ALL_ORDERS, payload: response });
        return response.count;
      })
      .catch((err) => {
        dispatch({ type: ORDER_ERROR, payload: err });
      });
  };
};

export const USER_HISTORY_LOADING = 'USER_HISTORY_LOADING';

export const getUserOrderHistory = (id) => {
  return (dispatch) => {
    dispatch({ type: USER_HISTORY_LOADING, payload: true });
    API.get(api, `/orders/${id}`)
      .then((response) => {
        dispatch({ type: ADD_USER_ORDER_HISTORY, payload: response });
        dispatch({ type: USER_HISTORY_LOADING, payload: false });
      })
      .catch((err) => {
        dispatch({ type: ORDER_ERROR, payload: err });
      });
  };
};

export const addCompletedOrder = (order, history) => {
  const myInIt = {
    body: order,
  };
  API.post(api, '/orders', myInIt)
    .then(() => {
      if (!!history) {
        history.push('/dashboard/history');
      }
    })
    .catch((err) => {
     return err
    });
};

export const getCompletedOrder = (id) => {
  return (dispatch) => {
    API.get(api, `/order/${id}`)
      .then((response) => {
        dispatch({ type: GET_COMPLETED_ORDER, payload: response.Item });
      })
      .catch((err) => {
        dispatch({ type: ORDER_ERROR, payload: err });
      });
  };
};

export const addReturn = (returnOrder) => {
  const myInIt = {
    body: returnOrder,
  };
  // return dispatch => {
  API.post(api, '/returns', myInIt)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err
    });
  // }
};

export const updateOrderStatus = (data) => {
  const myInIt = {
    body: {
      key: Object.keys(data)[0],
      value: data.order_status,
      id: data.id,
    },
  };
  API.put(api, '/orders', myInIt)
    .then((response) => {
      return response
    })
    .catch((err) => {
      return err
    });
};

export const orderRefundUpdate = (order) => {
  const myInIt = {
    body: order,
  };
  API.post(api, '/order/refund', myInIt)
    .then(() => null)
    .catch((err) => {
     return err
    });
};

export const updateRefund = (refund, setReturns) => {
  const myInIt = {
    body: refund,
  };
 
  API.put(api, '/returns', myInIt)
    .then(() => {
     act.getFarmReturns(refund.farm_id, setReturns)
     
    })
    .catch((err) => {
      return err
    });
};
export const getReturnOrder = (id) => {
  try {
    const response = API.get(api, `/order/${id}`);
    return response;
  } catch (err) {
    return err
  }
};

export const updateTrackingInfo = (order) => {
  const myInIt = {
    body: order,
  };
  API.post(api, '/order/refund', myInIt)
    .then((response) => {
      return response
    })
    .catch((err) => {
     return err
    });
};
