import React, { useState } from 'react';
import  OutlinedInput from '@mui/material/OutlinedInput';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as act from '../../../store/actions';
import Stack from '@mui/material/Stack';
import PrimaryButton from '../../inputs/Buttons/Button';

const SearchBar = (props) => {
  const { allProducts } = props;
  const history = useHistory();
  const [query, setQuery] = useState(
    useSelector((state) => state.ProductsReducer.query)
  );
  const dispatch = useDispatch();

  return (
    <Stack
      width='90%'
      direction='row'
      alignItems='center'
      justifyContent='space-between'
    >
      <OutlinedInput
        sx={{ width: '85%' }}
        name='search'
        placeholder={'Search by product...'}
        // label='Product Search'
        size='small'
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <PrimaryButton
        width='180px'
        height='35px'
        onClick={(e) => {
          e.preventDefault();
          dispatch(act.filterProducts(e, query, allProducts, history));
        }}
      >
        Search
      </PrimaryButton>
    </Stack>
  );
};

export default SearchBar;
