import * as act from '../actions';

const InitialState = {
  searchedUser: {},
};

export const AdminReducer = (state = InitialState, action) => {
  switch (action.type) {
    case act.ADD_SEARCHED_USER:
      return {
        ...state,
        searchedUser: action.payload,
      };
    default:
      return state;
  }
};
