import React, { useState, useEffect } from 'react';
import CustomerInfo from './CustomerInfo';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import OrderIcons from './OrderIcons';
import OrderProducts from './OrderProducts';
import { useForm, FormProvider } from 'react-hook-form';
import * as act from "../../../../../store/actions"

const OrderInfo = (props) => {
  const {
    order,
    customerID,
    iconDisplay,
    width,
    margin,
    componentRef,
    setFarmOrders,
  } = props;

  const [updatedOpen, setUpdatedOpen] = useState(false);
  const [cancelledOpen, setCancelledOpen] = useState(false);
  const [customer, setCustomer] = useState();
 

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',

    defaultValues: {
      ...order,
      original_products: order.products,
      original_payment: order.payment,
      payment: order.payment,
      refundAmount: 0,
      reasonForCancellation: '',
    },
  });

  

  // useEffect(async () => {
  //   const user = await act.getUser(order.customer_id)
  //   setCustomer(user)
  // }, []);
  const orderDate = new Date(order?.created_date).toLocaleDateString();


  let date = new Date(order.created_date).toLocaleDateString();
  let processDate = new Date(order.created_date);
  let deliverDate = new Date(order.created_date);

  processDate.setDate(processDate.getDate() + 3);
  deliverDate.setDate(deliverDate.getDate() + 5);

  return (
    <FormProvider {...methods}>
      <Stack ref={componentRef ? componentRef : null}>
        <Stack
          margin='20px 0 0 0'
          direction='row'
          justifyContent='space-between'
          height='fit-content'
          padding='2%'
          sx={({ breakpoints }) => ({
            backgroundColor: 'primary.formOutline2',
            [breakpoints.down('med')]: {
              flexDirection: 'column',
            },
          })}
        >
          {/* <Stack
            direction='row'
            justifyContent='space-between'
            width='48%'
            sx={({ breakpoints }) => ({
              [breakpoints.down('xlg')]: {
                flexDirection: 'column',
              },
            })}
          > */}
          <CustomerInfo
            customerID={customerID}
            customer={customer}
            setCustomer={setCustomer}
          />
          <Stack
            height='60px'
            sx={({ breakpoints }) => ({
              [breakpoints.down('xlg')]: {
                marginTop: '20px',
              },
            })}
          >
            <Typography variant='p' component='p'>
              Order date: <b>{date}</b>
            </Typography>
            <Typography variant='p' component='p'>
              Total: <b>${order.payment.total.toFixed(2)}</b>
            </Typography>
          </Stack>
          {/* </Stack> */}
          {/* <Stack
            direction='row'
            justifyContent='space-between'
            width='48%'
            sx={({ breakpoints }) => ({
              [breakpoints.down('xlg')]: {
                flexDirection: 'column',
              },
            })}
          > */}
          <Stack height='60px'>
            <Typography variant='p' component='p'>
              Order Number: <b>{order.id}</b>
            </Typography>
            <Typography variant='p' component={'p'}>
              Shipping Preference: <b>{order.delivery_selection.type}</b>
            </Typography>
          </Stack>
          <Stack
            height='60px'
            sx={({ breakpoints }) => ({
              [breakpoints.down('xlg')]: {
                marginTop: '20px',
                justifyContent: 'flex-start',
              },
            })}
          >
            <Typography variant='p'>
              Process by: <b>{processDate.toLocaleDateString()}</b>
            </Typography>
            {order?.delivery_selection?.type === 'pickup' ? (
              <Typography variant='p' component={'p'}>
                Pickup at: <b>{order?.delivery_selection?.info.name}</b>
              </Typography>
            ) : order.delivery_selection?.type === 'ship' ? (
              <Typography variant='p' component='p'>
                Ship with: <b>{order?.delivery_selection?.info.provider}</b>
              </Typography>
            ) : order.delivery_selection.type === 'deliver' ? (
              <Typography variant='p' component='p'>
                Deliver by: <b>{deliverDate.toLocaleDateString()}</b>
              </Typography>
            ) : null}
          </Stack>
        </Stack>
        {/* </Stack> */}
        <Stack
          height='fit-content'
          direction='row'
          boxSizing='border-box'
          justifyContent='space-between'
          width='100%'
          padding='20px'
        >
          <Stack width={width}>
            {order.products.map((product, i) => {
              return (
                <OrderProducts
                  key={product.id}
                  orderProduct={product}
                  order={order}
                  updatedOpen={updatedOpen}
                  setUpdatedOpen={setUpdatedOpen}
                  i={i}
                />
              );
            })}
          </Stack>

          <Stack
            width={iconDisplay ? iconDisplay : '20%'}
            marginRight={margin ? margin : '50px'}
            visibility={iconDisplay ? 'hidden' : 'visible'}
          >
            <OrderIcons
              order={order}
              setFarmOrders={setFarmOrders}
              updatedOpen={updatedOpen}
              setUpdatedOpen={setUpdatedOpen}
              cancelledOpen={cancelledOpen}
              setCancelledOpen={setCancelledOpen}
              orderProducts={order.products}
             
           
            />
          </Stack>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
export default OrderInfo;
