import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Modal from '../../display/modal';
import PrimaryButton from '../../inputs/Buttons/Button';
import { useFormContext } from 'react-hook-form';

const PickupLocationsModal = (props) => {
  const { locations, open, setOpen, i, cart } = props;

  const {
    setValue,
    clearErrors,
    register,
    formState: { errors },
  } = useFormContext();

  const handleLocationSelection = (e) => {
    setValue(`orders[${i}].delivery_selection.info`, locations[e.target.value]);
    cart.orders[i].delivery_selection.info = locations[e.target.value];
    cart.orders[i].delivery_selection.pickup_selection = e.target.value;
  };

  const message = (
    <Stack>
      <RadioGroup
        row
        {...register(`delivery_info[${i}]`)}
        defaultValue={cart.orders[i].delivery_selection.pickup_selection}
        onClick={(e) => handleLocationSelection(e)}
      >
        {locations.map((location, j) => {
          return (
            <FormControlLabel
              key={j}
              control={
                <Radio
                  value={j}
                  sx={{
                    display: 'flex',
                    color: 'primary.radio',
                    '&.Mui-checked': {
                      color: 'primary.radio',
                    },
                  }}
                />
              }
              label={
                <Stack>
                  <Typography variant='h6'>{location.name}</Typography>
                  <Typography variant='p' component='p'>
                    {location.address}
                  </Typography>
                  <Typography variant='p' component='p'>
                    {location.city}, {location.state} {location.zip}
                  </Typography>
                  <Typography variant='p' fontWeight='normal' component='p'>
                    Pickup Day: {location.pickup_day}
                  </Typography>
                  <Typography variant='p' component='p'>
                    {location.pickup_start.split(':')[0] === 12
                      ? location.pickup_start
                      : location.pickup_start.split(':')[0] > 12
                      ? `${
                          parseInt(location.pickup_start.split(':')[0]) - 12
                        }:${location.pickup_start.split(':')[1]} PM`
                      : `${location.pickup_start} AM`}{' '}
                    -{' '}
                    {location.pickup_end.split(':')[0] === 12
                      ? location.pickup_end
                      : location.pickup_end.split(':')[0] > 12
                      ? `${parseInt(location.pickup_end.split(':')[0]) - 12}:${
                          location.pickup_end.split(':')[1]
                        } PM`
                      : `${location.pickup_end} AM`}
                  </Typography>
                </Stack>
              }
            />
          );
        })}
      </RadioGroup>
      <Stack direction='row'>
        <PrimaryButton
          margin='20px 10px 0 0'
          onClick={() => {
            if (errors.pickup) {
              clearErrors('pickup');
            }
            setOpen(false);
          }}
          sx={{
            bgcolor: 'primary.button',
            color: 'primary.text',
            '&:hover': { bgcolor: 'primary.action.hover' },
            height: '40px',
          }}
          className='button'
        >
          Save
        </PrimaryButton>
        <PrimaryButton
          margin='20px 0 0 10px'
          onClick={() => {
            setOpen(false);
            setValue(`cart.orders[${i}].delivery_selection.type`, '');
          }}
          sx={{
            bgcolor: 'primary.button',
            color: 'primary.text',

            '&:hover': { bgcolor: 'primary.action.hover' },
            height: '40px',
          }}
        >
          Cancel
        </PrimaryButton>
      </Stack>
    </Stack>
  );
  return (
    <Stack>
      <Typography variant='a' onClick={() => setOpen(true)}>
        Choose a location
      </Typography>
      <Modal
        open={open}
        setOpen={setOpen}
        TitleMessage={'Choose a pickup location'}
        top={'50%'}
        left={'50%'}
        message={message}
        width={700}
        messageStyle={{ display: 'flex' }}
      />
    </Stack>
  );
};

export default PickupLocationsModal;
