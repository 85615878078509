import React from 'react';
import {useSelector} from "react-redux"
import OrderInfo from './OrderInfo';
import Typography  from '@mui/material/Typography';
import Stack from "@mui/material/Stack"


const Orders = (props) => {
 
  const users = useSelector(state => state.UserReducer.allUsers)
  return (
    <Stack
      width="80%"
      marginTop='50px'
      sx={({ breakpoints }) => ({
        // [breakpoints.down('sm')]: {
        //   marginLeft: 0,
        //   margin: '0 auto',
        //   paddingRight: '50px',
        // },
      })}
    >
      <Typography variant="h3">Open Orders</Typography>
      {/* <Stack direction='row'>
        <Stack alignItems='center' margin='0 10px'>
          <UpdateIcon />
          <Typography variant='p' component='p'>
            Update Order
          </Typography>
        </Stack>
        <Stack alignItems='center' margin='0 10px' sx={{ opacity: '0.3' }}>
          <CreateInvoiceIcon />
          <Typography variant='p' component='p'>
            Print Packing Slip
          </Typography>
        </Stack>

        <Stack alignItems='center' margin='0 10px'>
          <ShippingLabelIcon />
          <Typography variant='p' component='p'>
            Shipping Label
          </Typography>
        </Stack>
        <Stack alignItems='center' margin='0 10px' sx={{ opacity: '0.3' }}>
          <MessageIcon />
          <Typography variant='p' component='p'>
            Message
          </Typography>
        </Stack>
        <Stack alignItems='center' margin='0 10px'>
          <CancelOrderIcon />
          <Typography variant='p' component='p'>
            Cancel Order
          </Typography>
        </Stack>
      </Stack> */}
      {props.orders.length === 0 ? (
        <Typography variant='h3' className='noOrdersText'>
          You have no open orders
        </Typography>
      ) : (
        props.orders.map((order) => {
          return (
            <OrderInfo
              order={order}
              key={order.id}
              customerID={order.customer_id}
              setFarmOrders={props.setFarmOrders}
              width='100%'
            />
          );
        })
      )}
    </Stack>
  );
};

export default Orders;
