import React from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useFormContext, useFieldArray } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import * as util from '../../../../../util';
import * as act from '../../../../../store/actions';

const FileUpload = (props) => {
  const Input = styled('input')({
    display: 'none',
  });

  const {
    register,
    setValue,
    formState: { errors },
    watch
  } = useFormContext({
    mode: 'onChange',
    reValidationMode: 'onChange',
  });

 
 

  const handleFiles = (e) => {
    util
      .handleFileUpload(e)
      .then((response) => { 
        setValue('files', response.location)
        
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const value = watch()

  return (
    <Stack>
      {' '}
      <InputLabel
        htmlFor='documents'
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '125px',
          alignItems: 'center',
          textAlign: 'center',
          margin: '10px 0',
          color: 'black',
          border: '1px solid black',
          padding: '5px',
        }}
      >
        <Input
          accept='.pdf'
          id='documents'
          type='file'
          name='files'
          onChange={(e) => handleFiles(e)}
        />
        <IconButton aria-label='upload picture' component='span'>
          <AttachFileOutlinedIcon />
        </IconButton>
        Upload File
      </InputLabel>{' '}
      <Typography variant='error' marginLeft='3px' fontSize='10px'>
        {' '}
        **File must be pdf format**
      </Typography>
      {value.files ?
        <iframe
          src={value.files}
          height='420px'
          width='300px'
          style={{ marginRight: '20px' }}
        /> : null
      }
      
      <InputLabel htmlFor='title' sx={{marginTop: "20px", fontSize:"16px"}}>Title of Document</InputLabel>
      <TextField
        name='title'
        size='small'
        {...register('title', { required: 'Title of Document is required' })}
      />
    </Stack>
  );
};

export default FileUpload;
