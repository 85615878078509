import React, { useState, useEffect } from 'react';
import { useSelector} from 'react-redux';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as act from '../../../../../store/actions';
import ViewOrder from '../../../modals/ViewOrder';

const NewOrders = (props) => {
  const { farm } = props;
  const [newOrders, setNewOrders] = useState([]);
  const [open, setOpen] = useState();
  const users = useSelector((state) => state.UserReducer.allUsers);
 
  useEffect(() => {
    act.getFarmNewOrders(farm.id, setNewOrders);

  }, []);

  return (
    <Stack>
      <Typography variant='h5'>{farm.name}</Typography>
      {newOrders && newOrders.length === 0 ? (
        <Typography variant='p'>No new Orders</Typography>
      ) : newOrders.length > 0 ? (
        newOrders.map((order, i) => {
          const orderDate = new Date(order.created_date)
            .toLocaleString()
            .split(',')[0];
          let customerInfo = users?.find(
            (user) => user.user_id.split('|')[1] === order.customer_id
          );

          return order.order_status === 'new' ? (
            <Stack
              direction='row'
              justifyContent='space-between'
              margin='5px 0'
              key={order.id}
            >
              <Typography
                variant='p'
                component='p'
                sx={{ color: 'primary.radio', cursor: 'pointer' }}
                onClick={() => setOpen(order.id)}
              >
                {order.id}
              </Typography>{' '}
              <Typography variant='p' component='p'>
                {orderDate}
              </Typography>
              <ViewOrder
                order={order}
                open={open === order.id}
                setOpen={setOpen}
                customerID={order.customer_id}
                farm={farm}
                setNewOrders={setNewOrders}
              />
            </Stack>
          ) : newOrders.length === 0 ? (
            <Typography variant='p'>New new Orders</Typography>
          ) : null;
        })
      ) : null}
    </Stack>
  );
};
export default NewOrders;
