import React, { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PrimaryButton from '../../inputs/Buttons/Button';

const OrderSummary = (props) => {
  const { subtotal, setSubtotal, total, setTotal, cart,errors } = props;

  let shipping = 0;
  let tax = 0;

  useEffect(() => {
    setSubtotal(0);
    setTotal(0);
    let newSubtotal = 0;
    cart.orders.map((order) => {
      return (newSubtotal += order.payment.subtotal);
    });
    setSubtotal(newSubtotal);
    setTotal(newSubtotal + tax + shipping);
  }, [subtotal]);

  return (
    <Stack
      border='1px solid #bac2b7'
      margin='40px'
      width='30%'
      height='fit-content'
    >
      <Typography variant='h4' padding='20px'>
        Order Summary
      </Typography>
      {errors.OutOfStock ? (
        <Typography variant='error' marginLeft="40px">{errors.OutOfStock.message}</Typography>
      ) : null}
      <Stack borderTop='1px solid #bac2b7' borderBottom='1px solid #bac2b7'>
        <Stack direction='row' justifyContent='space-between' padding='20px'>
          <Typography variant='h6'>Subtotal</Typography>
          <Typography variant='p'>$ {subtotal.toFixed(2)}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' padding='20px'>
          <Stack>
            <Typography variant='h6'>Shipping</Typography>
            <Typography variant='p' lineHeight='20px'>
              (Calculated at checkout)
            </Typography>
          </Stack>
          <Typography variant='p'>$ {shipping.toFixed(2)}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' padding='20px'>
          <Typography variant='h6'>Tax</Typography>
          <Typography variant='p'>$ {tax.toFixed(2)}</Typography>
        </Stack>
      </Stack>
      <Stack direction='row' justifyContent='space-between' padding='20px'>
        <Typography variant='h5'>Total</Typography>
        <Typography variant='p'>$ {total.toFixed(2)}</Typography>
      </Stack>
      <PrimaryButton width='100%' type='submit'>
        Proceed to Checkout
      </PrimaryButton>
    </Stack>
  );
};
export default OrderSummary;
