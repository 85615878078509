import React, { useEffect, useState } from 'react';
import Login from '../components/login';
import Register from '../components/register';
import {useParams} from "react-router-dom"

const AuthPage = (props) => {
  const [component, setComponent] = useState('login');
  const params = useParams()
  useEffect(() => {
    if (params.path) {
      setComponent(params.path)
    }
  }, [params])
  switch (component) {
    case 'login':
      return <Login setComponent={setComponent} />;
    case 'register':
      return <Register setComponent={setComponent} />;
    default:
      return <Login setComponent={setComponent} />;
  }
};

export default AuthPage;
