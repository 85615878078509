import React from 'react';
import { Overview, FarmStand, SignOutIcon } from '../../assets/farmIconImages';
import Refunds from '../accordions/returns';
import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import SuperSideNav from '../superComponents/components/SideNav';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { roles } from '../../../util';
import * as act from "../../../store/actions"

const SideBar = (props) => {
  const { userOpen, setUserOpen } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.UserReducer.user);
  return (
    <Drawer
      sx={{
        flexShrink: '0',
        zIndex: '0',
        overflowX: 'hidden',
        overflowY:"scroll",
        '.MuiDrawer-paper': {
          bgcolor: 'primary.formOutline2',
          width: '250px',
        },
        '.MuiPaper-root': {
          borderRight: 'none',
        },
      }}
      elevation={0}
      variant='permanent'
      anchor='left'
    >
      <Toolbar sx={{ marginTop: '50px', borderRight: 'none' }} />
      <List
        sx={{
          '.MuiTypography-root': {
            fontSize: '18px',
            color: '#006F45',
          },
        }}
      >
        <ListItem
          onClick={() => history.push(`/dashboard/overview`)}
          sx={{ cursor: 'pointer' }}
        >
          <ListItemIcon sx={{ cursor: 'pointer' }}>
            <Overview {...props} sx={{ cursor: 'pointer' }} />
          </ListItemIcon>
          <ListItemText primary='Overview' />
        </ListItem>
        <ListItem
          onClick={() => history.push(`/dashboard/farms`)}
          sx={{ cursor: 'pointer' }}
        >
          <ListItemIcon>
            <FarmStand {...props} />
          </ListItemIcon>
          <ListItemText primary='Farms' />
        </ListItem>
       
        <Refunds {...props} />
      
        {user.app_metadata.roles[0] === roles.super ? (
          <SuperSideNav open={userOpen} setOpen={setUserOpen} />
        ) : null}
        <ListItem
          onClick={() => dispatch(act.logout(history))}
          sx={{ cursor: 'pointer' }}
        >
          <ListItemIcon>
            <SignOutIcon {...props} />
          </ListItemIcon>
          <ListItemText primary='SignOut' />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default SideBar;
