import React from 'react';
import Stack from '@mui/material/Stack';
import { Box, Typography } from '@mui/material';

const BoxComponent = (props) => {
  const { name, count } = props;

  return (
    <Stack
      justifyContent='center'
      alignItems='center'
      padding='25px 18px'
      width='200px'
      height='103px'
      position='static'
      border='1px solid #bac2b7'
      boxSizing='border-box'
      borderRadius='2px'
      sx={{
        left: 0,
        top: 0,
        flex: 'none',
        order: 0,
        flexGrow: 0,
      }}
    >
      <Typography
        variant='p'
        component='p'
        fontWeight='600'
        lineHeight='1.25rem'
        fontSize='1rem'
        color='primary.lightFont'
      >
        {name}
      </Typography>
      <Typography
        variant='p'
        component='p'
        fontWeight='600'
        fontSize='1.125rem'
        lineHeight='1.25rem'
        color='primary.darkFont'
      >
        {count}
      </Typography>
    </Stack>
  );
};

export default BoxComponent;
