import React from 'react';
import { useFormContext } from 'react-hook-form';
import Stack from "@mui/material/Stack"

import { Box } from '@mui/material';
import PrimaryButton from '../../../inputs/Buttons/Button';
const ReturnButtons = (props) => {
  const { handleReturn, history } = props;
  const {
    trigger,
    formState: { isValid },
  } = useFormContext();

  const handleClick = (e) => {
    if (!isValid) {
      trigger();
    } else {
      handleReturn(e);
    }
  };
  return (
    <Stack direction="row" width="30%" justifyContent="space-between" marginTop="20px">
      <PrimaryButton onClick={(e) => handleClick(e)}>Submit</PrimaryButton>
      <PrimaryButton
        type='button'
        onClick={() => history.push('/dashboard/history')}
      >
        Cancel
      </PrimaryButton>
    </Stack>
  );
};
export default ReturnButtons;
