import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const Policies = (props) => {
  const { farm } = props;

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        
      >
        <Typography variant='h5'>Policies</Typography>
        
      </AccordionSummary>
      <AccordionDetails>
        {farm && <p className='content'>{farm.policies ? farm.policies : "This farm has no policies"}</p>}
      </AccordionDetails>
    </Accordion>
  );
};

export default Policies;
