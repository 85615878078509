import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OrderHistory } from '../components/orders/orderHistory';
import * as act from '../../../store/actions';

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography"

const OrderHistoryPage = (props) => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.OrderReducer.history);
  const user = useSelector((state) => state.UserReducer.user);
  const loading = useSelector((state) => state.OrderReducer.userHistoryLoading);
  

  useEffect(() => {
    dispatch(act.getUserOrderHistory(user.user_id.split('|')[1]));
  }, []);

  return (
    <Stack marginTop="50px">
      {loading ? (
        <Typography variant='h5'>Loading Order History</Typography>
      ) : !orders || orders.length === 0 ? (
        <Typography variant='h5' className='noOrders'>
          You have not placed any orders
        </Typography>
      ) : (
            orders.map((order) => {
          return (
           
              <OrderHistory order={order} {...props} key={order.id} />
           
          );
        })
      )}
    </Stack>
  );
};

export default OrderHistoryPage;
