import React from 'react';
import { useHistory } from 'react-router-dom';
import  StackedLogo  from '../../assets/svgImages/StackedLogo.svg';

const StackedLogoComponent = (props) => {
  const history = useHistory();

  return (
   <img src={StackedLogo} alt="Farm Era Logo" width="100px" height="100px" style={{cursor: 'pointer'}} onClick={() => history.push('/')}/>
  );
};

export default StackedLogoComponent;
