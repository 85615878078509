import React from 'react';
import {useHistory} from "react-router-dom"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';

const Flag = (props) => {
  const history = useHistory();

  return (
    <Accordion
      elevation={0}
      sx={{
        bgcolor: 'primary.formOutline2',
        border: 'none',
        margin: '-10px 0',
        padding: '0',
      }}
    >
      <AccordionSummary
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={{  border: 'none' }}
      >
        <ListItemText primary='Flag' />
      </AccordionSummary>
      <AccordionDetails sx={{  border: 'none' }}>
        <ListItem
          sx={{ cursor: 'pointer' }}
          onClick={() => history.push('/dashboard/flag-customer')}
        >
          <ListItemText primary='Flag Customer' />
        </ListItem>
        <ListItem
          sx={{ cursor: 'pointer' }}
          onClick={() => history.push('/dashboard/flag-farmer')}
        >
          <ListItemText primary='Flag Farmer' />
        </ListItem>
        <ListItem
          sx={{ cursor: 'pointer' }}
          onClick={() => history.push('/dashboard/flag-farm')}
        >
          <ListItemText primary='Flag Farm' />
        </ListItem>
      </AccordionDetails>
    </Accordion>
  );
};

export default Flag;
