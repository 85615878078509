import React from 'react';
import { useFormContext } from 'react-hook-form';
import Typography from "@mui/material/Typography"
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import UseFormSelect from '../../../inputs/Selects/UseFormSelect';
import UseFormCheckbox from '../../../inputs/CheckBox/UseFormCheckbox';

const ShipCarriers = (props) => {
  const { name, farm, setFarm } = props;

  const {
    watch,
    setValue,
    unregister,
    clearErrors,
    trigger,
    formState: { errors},
  } = useFormContext({
    mode: 'onBlur',
    reValidationMode: 'onChange',
  });


  const ship = watch(`${name}`);
  const freeShipping = watch('delivery_methods.free_shipping');

  const values = watch();

  const handleCheck = (value) => {
 
    setValue('delivery_methods.ship', Boolean(!value));
    setFarm({
      ...farm,
      delivery_methods: {
        ...farm.delivery_methods,
        ship: !value,
      },
    });
    if (value) {
      
      clearErrors('delivery_methods.ship_carriers')
      setValue('delivery_methods.ship_carriers', []);
      unregister('delivery_methods.ship_carriers');
      unregister('delivery_methods.free_shipping');
      setFarm({
        ...farm,
        delivery_methods: {
          ...farm.delivery_methods,
          ship_carriers: [],
          ship: !value,
          free_shipping: false,
        },
      });
    
    }
   
    trigger()
  };

  const removeCarrier = (index) => {
    let carriers = farm.delivery_methods.ship_carriers;
    carriers.splice(index, 1);
    setValue('delivery_methods.ship_carriers', carriers);
    setFarm({
      ...farm,
      delivery_methods: {
        ...farm.delivery_methods,
        ship_carriers: carriers,
      },
    });
    trigger()
  };

  return (
    <Stack sx={{ marginBottom: ship && '20px' }}>
      <UseFormCheckbox
        name='delivery_methods.ship'
        value={farm.delivery_methods.ship}
        label='Ship'
        onChange={() => {
          handleCheck(farm.delivery_methods.ship);
        }}
      />
      {ship ? (
        <Stack direction='row' flexWrap='wrap' width='100%'>
          <Stack width='49%'>
            {errors?.delivery_methods?.ship_carriers && <Typography variant="error">{errors.delivery_methods.ship_carriers.message}</Typography>}
            <UseFormSelect
              name='delivery_methods.ship_carriers'
              rules={{ validate: {
                length: (value) => value.length > 0 ||
                "Must select at least one carrier"
                            
                }
              }}
              label='Shipping Carriers'
              array={['UPS', 'FEDEX', 'USPS']}
              onChange={(e) => {
                clearErrors('delivery_methods.ship_carriers')
                let array = farm.delivery_methods.ship_carriers;
                const found = array.some((field) => field === e.target.value);
                if (!found) {
                  let newArray = [...array, e.target.value];
                  setValue(`delivery_methods.ship_carriers`, newArray);
                  setFarm({
                    ...farm,
                    delivery_methods: {
                      ...farm.delivery_methods,
                      ship_carriers: newArray,
                    },
                  });
                }
              }}
            />

            {farm.delivery_methods.ship_carriers ? (
              <Stack
                direction='row'
                flexWrap='wrap'
                marginTop='10px'
                sx={{
                  gap: 0.5,
                }}
              >
                {farm.delivery_methods.ship_carriers.map((value, i) => (
                  <Chip
                    key={value}
                    label={value}
                    onDelete={() => removeCarrier(i)}
                  />
                ))}
              </Stack>
            ) : null}
          </Stack>
          <Stack
            direction='row'
            alignItems='flex-start'
            marginLeft='30px'
            marginTop='10px'
          >
            <UseFormCheckbox
              name='delivery_methods.free_shipping'
              onChange={() => {
                setFarm({
                  ...farm,
                  delivery_methods: {
                    ...farm.delivery_methods,
                    free_shipping: !farm.delivery_methods.free_shipping,
                  },
                });
                setValue(
                  'delivery_methods.free_shipping',
                  !values.delivery_methods.free_shipping
                );
              }}
              value={values.delivery_methods?.free_shipping}
              label={'I would like to offer Free Shipping on orders over:'}
            />

            {freeShipping && (
              <Stack width='25%'>
                <UseFormSelect
                  name='free_shipping_amount'
                  rules={
                    freeShipping && { required: 'Please select an amount' }
                  }
                  onChange={(e) => {
                    setValue('free_shipping_amount', e.target.value);
                    setFarm({
                      ...farm,
                      delivery_methods: {
                        ...farm.delivery_methods,
                        free_shipping_amount: e.target.value,
                      },
                    });
                  }}
                  array={['0', 25, 50, 100]}
                  label={'Amount'}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
};

export default ShipCarriers;
