import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Search from '../Search';
import * as act from '../../../../../store/actions';
import PrimaryButton from '../../../../inputs/Buttons/Button';
import SuccessFlag from '../../modals/SuccessFlag';

const BlockUsers = (props) => {
  const { title, searchTitle, successTitle, toFlagTitle } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  

  const { register, watch, setValue } = methods;
  const values = watch();

  const [open, setOpen] = useState(false);

  const SearchUser = () => {
    dispatch(act.SearchUserByEmail(values.email, setValue));
  };
  const handleFlag = () => {
    setValue('user.user_metadata.blocked', true);
    setValue('user.user_metadata.blockedReason', values.blockedReason);
    act.blockUser(
      values.user.user_id,
      values.user.user_metadata.blocked,
      values.user.user_metadata.blockedReason,
      setOpen
    );
  };

  return (
    <FormProvider {...methods}>
      <Stack width='80%' alignItems='center' marginTop='50px'>
        <Typography variant='h3'>{title}</Typography>
        <Stack direction='row' alignItems='center' marginTop='20px'>
          <Search title={searchTitle} name='email' />
          <PrimaryButton margin='0 20px' onClick={() => SearchUser()}>
            Search
          </PrimaryButton>
        </Stack>
        <SuccessFlag
          title={successTitle}
          open={open}
          setOpen={setOpen}
          history={history}
        />
        <Stack>
          {values.user ? (
            <Stack marginTop='50px'>
              <Typography variant='h4'>{toFlagTitle}</Typography>
              <Typography variant='p' marginTop='20px'>
                <b>Name:</b> {values.user?.user_metadata?.first_name}{' '}
                {values.user?.user_metadata?.last_name}
              </Typography>

              <Typography variant='p'>
                <b>Email:</b> {values.user?.email}
              </Typography>
              <Typography variant='p'>
                <b>User Id:</b> {values.user?.user_id}
              </Typography>
            </Stack>
          ) : null}
          <TextField
            label='Reason for block'
            multiline
            rows={5}
            sx={{ width: '500px' }}
            {...register('blockedReason', {
              required: 'Must add a reason',
            })}
          />
        </Stack>
        <Stack direction='row' marginTop='20px'>
          <PrimaryButton width='250px' margin='0 20px 0 0' onClick={handleFlag}>
            {title}
          </PrimaryButton>
          <PrimaryButton
            width='250px'
            onClick={() => history.push('/dashboard')}
          >
            Cancel
          </PrimaryButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
export default BlockUsers;
