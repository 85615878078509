import React from 'react';
import Modal from '../../../display/modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PrimaryButton from '../../../inputs/Buttons/Button';

const ReturnDeclinedModal = (props) => {
  const { open, setOpen, order } = props;
  return (
    <Modal
      open={open}
      top='50%'
      left='50%'
      TitleMessage={'Refund Declined'}
      message={
        <Stack width='fit-content' padding='20px'>
          <Typography variant='p'>{order.payment.refund.message}</Typography>
          <PrimaryButton width='150px' onClick={() => setOpen(false)}>
            Close
          </PrimaryButton>
        </Stack>
      }
    />
  );
};

export default ReturnDeclinedModal;
