import React from 'react';
import Pickup from './PickupLocation';
import ShipCarriers from './ShipCarriers';
import Deliver from './Deliver';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useFormContext } from 'react-hook-form';

const ShippingDelivery = (props) => {
  const { farm, setFarm } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext({
    mode: 'onChange',
  });

  const atLeastOne = () => {
    if (farm.delivery_methods.ship || farm.delivery_methods.deliver || farm.delivery_methods.pickup) { return true }
    else {return "Must Select at least one form of delivery"}
  };

  return (
    <Stack {...register('shipping', { validate: atLeastOne })}>
      {errors.shipping && (
        <Typography variant='error'>{errors?.shipping?.message}</Typography>
      )}
      <Typography variant='h5'>Farm Shipping and Delivery</Typography>
      <ShipCarriers
        farm={farm}
        setFarm={setFarm}
        name={'delivery_methods.ship'}
        arrayName={'delivery_methods.ship_carriers'}
        defaultValue={[]}
      />
      <Deliver
        farm={farm}
        setFarm={setFarm}
        name={'delivery_methods.deliver'}
      />
      <Pickup farm={farm} setFarm={setFarm} name={'delivery_methods.pickup'} />
    </Stack>
  );
};

export default ShippingDelivery;
