import React from 'react';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography"

const Pickup = (props) => {
  const { farm, order, i } = props;

  return (
    <Stack key={i}>
      <Typography variant='h3'>
        Order from {farm.name} Pickup Location:
      </Typography>
      <Typography variant='p' component='p' marginTop='20px'>
        {order?.delivery_selection.info.name}
      </Typography>
      <Typography variant='p' component='p'>
        {order?.delivery_selection.info.address}
      </Typography>
      <Typography variant='p' component='p'>
        {order?.delivery_selection.info.city},{' '}
        {order?.delivery_selection.info.state}{' '}
        {order?.delivery_selection.info.zip}
      </Typography>
      <Stack direction='row' alignItems='center'>
        <Typography variant='h6'>Pickup Day: </Typography>
        <Typography variant='p' marginTop='7px' marginLeft='5px'>
          {order?.delivery_selection.info.pickup_day}
        </Typography>
      </Stack>
      <Stack direction='row' alignItems='center'>
        <Typography variant='h6'> Pickup Times: </Typography>
        <Typography variant='p' component='p' marginTop='7px' marginLeft='5px'>
          {order?.delivery_selection.info.pickup_start.split(':')[0] === 12
            ? order?.delivery_selection.info.pickup_start
            : order?.delivery_selection.info.pickup_start.split(':')[0] > 12
            ? `${
                parseInt(
                  order?.delivery_selection.info.pickup_start.split(':')[0]
                ) - 12
              }:${order?.delivery_selection.info.pickup_start.split(':')[1]} PM`
            : `${order?.delivery_selection.info.pickup_start} AM`}{' '}
          -{' '}
          {order?.delivery_selection.info.pickup_end.split(':')[0] === 12
            ? order?.delivery_selection.info.pickup_end
            : order?.delivery_selection.info.pickup_end.split(':')[0] > 12
            ? `${
                parseInt(
                  order?.delivery_selection.info.pickup_end.split(':')[0]
                ) - 12
              }:${order?.delivery_selection.info.pickup_end.split(':')[1]} PM`
            : `${order?.delivery_selection.info.pickup_end} AM`}
        </Typography>
      </Stack>
    </Stack>
  );
};
export default Pickup;
