import React from 'react';
import { UpdateIcon, DeleteIcon, EditIconBlack } from '../../../../assets/farmIconImages';
import UpdateProductStatusModal from '../../../modals/updateProductStatusModal';
import Delete from '../../../modals/delete';
import Popover from '../../../../display/popover';
import CardActions from '@mui/material/CardActions';
import { useHistory } from 'react-router-dom';

const ListingIcons = (props) => {
  const {
    product,
    setOpenUpdate,
    openUpdate,
    setOpenDelete,
    openDelete,
    handleDelete,
    getFarmProducts,
  } = props;

  const history = useHistory();

  return (
    <CardActions
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '50%',
        padding: '20px',
      }}
    >
      <Popover
        component={
          <EditIconBlack
            onClick={(e) =>
              history.push(
                `/dashboard/edit_product/${product.id}/${product.farm_id}`
              )
            }
          />
        }
        message={'Edit Item Info'}
      />
      <Popover
        component={
          <UpdateIcon
            sx={{ fontSize: '18px' }}
            onClick={() => {
              setOpenUpdate(product.id);
            }}
          />
        }
        message={'Update Item Status'}
      />{' '}
      <UpdateProductStatusModal
        open={openUpdate === product.id}
        setOpen={setOpenUpdate}
        product={product}
        id={product.id}
        getFarmProducts={getFarmProducts}
      />
      <Popover component={<DeleteIcon onClick={() => setOpenDelete(product.id)} />}  message={'Delete Item'} />
      <Delete
        open={openDelete === product.id}
        setOpen={setOpenDelete}
        onClick={() => handleDelete(product)}
      />
    </CardActions>
  );
};

export default ListingIcons;
