import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SearchArrow from '../../../../images/icons/searchArrow.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as act from '../../../../store/actions';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import DietaryPreferences from './DietaryPreference';
import { useForm, FormProvider } from 'react-hook-form';
import { Filter } from "../filter"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


export default function AccountMenu(props) {
  const { categories, dietaryOptions, products, setFiltered, allProducts } =
    props;
  const dispatch = useDispatch();
  const farms = useSelector((state) => state.FarmReducer.allFarms);

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      miles: 0,
      dietary: [],
      low_price: 0.0,
      high_price: 0.0,
    },
  });
  const { register } = methods;

  const miles = [0, 1, 2, 5, 10, 15, 20, 50, 100, 200];

  const values = methods.watch();

  const handleDistanceSearch = () => {
    dispatch(
      act.searchByDistance(
        allProducts,
        farms,
        values.miles,
        values.zip,
        setFiltered
      )
    );
  };

  const handlePriceSearch = () => {
    dispatch(
      act.searchByPrice(
        values.low_price,
        values.high_price,
        allProducts,
        setFiltered
      )
    );
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Stack
        sx={({ breakpoints }) => ({
          display: 'flex',

          [breakpoints.up('med')]: {
            display: 'none',
          },
        })}
      >
        <Tooltip title='Menu'>
          <Typography variant='p' onClick={handleClick} sx={({ breakpoints }) => ({
            fontSize: '24px',
            marginLeft: "20px",
            marginBottom: "20px"
          })}>
            Filter
          </Typography>
        </Tooltip>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 20,
          sx: {
            overflow: 'scroll',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            width: '100%',
            padding: 2
            // '& .MuiAvatar-root': {
            //   width: 50,
            //   height: 32,
            //   ml: -0.5,
            //   mr: 1,
            // },
            // '&:before': {
            //   content: '""',
            //   display: 'block',
            //   position: 'absolute',
            //   top: 0,
            //   right: 14,
            //   width: 10,
            //   height: 10,
            //   bgcolor: 'background.paper',
            //   transform: 'translateY(-50%) rotate(45deg)',
            //   zIndex: 0,
            // },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <FormProvider {...methods}>
          <Stack>
            <Typography variant='h5' marginBottom='4%'>
              Categories
            </Typography>
            {categories.map((category) => {
              return (
                <Typography
                  variant='p'
                  key={category}
                  sx={{ cursor: 'pointer', marginBottom: '3%' }}
                  onClick={() => dispatch(act.searchByCategory(category))}
                >
                  {category}
                </Typography>
              );
            })}
          </Stack>
          <Stack width='100%' margin='5% 0'>
            <Typography variant='h5'>Farm Location</Typography>
            <Stack direction='row' alignItems='center' flexWrap='wrap'>
              <Typography
                variant='p'
                sx={{
                  fontSize: '0.875rem',
                  marginRight: '2px',
                }}
              >
                Within{' '}
              </Typography>
              <Tooltip title={values.miles} placement='top'>
                <TextField
                  size='small'
                  sx={{
                    fontSize: '12px',
                    textAlign: 'center',
                    minWidth: '50px',
                    width: '40px',
                    height: '40px',
                    padding: '5px',
                  }}
                  {...register('miles')}
                  defaultValue={values.miles}
                />
              </Tooltip>
              <Typography
                variant='span'
                sx={{
                  fontSize: '0.875rem',
                  margin: '0 2px',
                }}
              >
                {' '}
                miles from
              </Typography>
              <TextField
                size='small'
                label='zip'
                sx={{
                  fontSize: '12px',
                  textAlign: 'center',
                  minWidth: '70px',
                  width: '40px',
                  height: '40px',
                  margin: '1%',
                  padding: '5px',
                }}
                {...register('zip')}
              />
              <ArrowForwardIcon
                onClick={() => handleDistanceSearch()}
                fontSize='small'
                sx={{ cursor: 'pointer' }}
              />
            </Stack>
          </Stack>
          <DietaryPreferences
            dietaryOptions={dietaryOptions ? dietaryOptions : []}
            products={products ? products : []}
            allProducts={allProducts}
          />
          <Stack className='priceDiv'>
            <h5>Price</h5>
            <Stack direction='row' alignItems='center' flexWrap='wrap'>
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  marginRight: '2px',
                }}
              >
                From{' '}
              </Typography>
              <TextField
                {...register('low_price')}
                placeholder={'$0.00'}
                size='small'
                sx={{
                  fontSize: '12px',
                  textAlign: 'center',
                  width: '70px',
                  height: '40px',
                  margin: '1%',
                  padding: '5px',
                }}
              />{' '}
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  marginRight: '2px',
                }}
              >
                to{' '}
              </Typography>
              <TextField
                {...register('high_price')}
                placeholder={'$0.00'}
                size='small'
                sx={{
                  fontSize: '12px',
                  textAlign: 'center',
                  width: '70px',
                  height: '40px',
                  margin: '1%',
                  padding: '5px',
                }}
              />
              <ArrowForwardIcon
                onClick={() => handlePriceSearch()}
                sx={{ cursor: 'pointer' }}
              />
            </Stack>
          </Stack>
        </FormProvider>
      </Menu>
    </React.Fragment>
  );
}
