import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FarmLogo } from '../../farm/components/FarmLogo';
import { ProductContent } from './ProductContent';
import AddToBasket from '../../cart/modals/AddedToBasket';
import * as act from '../../../store/actions';
import ProductCardFarmDelivery from './productCardFarmDelivery';
import Popover from '@mui/material/Popover';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { Card, Box, Button, CardMedia, CardContent } from '@mui/material';

const ProductCard = (props) => {
  const { product, deliveryDisplay, elevation } = props;
  const dispatch = useDispatch();
  const history = useHistory();


  const farms = useSelector((state) => state.FarmReducer.allFarms);
  const cart = useSelector((state) => state.CartReducer.userCart);
  const farm = farms.find((farm) => farm.id === product.farm_id);

  const handleClick = (e, id) => {
    e.stopPropagation();
    history.push(`/store/product/${id}`);
  };

  const [open, setOpen] = useState(false);
 
  const showModal = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  const addProductToCart = async (e, quantity) => {
  
    e.preventDefault();
    if (!cart.user_id) {
      props.history.push('/login');
    } else if (product.inventory_qty < 0) {
      setAnchorEl(e.currentTarget);
    } else {
      await dispatch(act.addToCart(cart, product, quantity));
      showModal();
      dispatch(act.saveCartToDB(cart));
    }
  };

   const [anchorEl, setAnchorEl] = React.useState(null);

   const handleClose = () => {
     setAnchorEl(null);
   };

  const openPop = Boolean(anchorEl);
  

  return (
    <Card
      elevation={elevation}
      sx={{
        minWidth: '265px',
        width: '265px',
        height: '520px',
        margin: '0 10px 30px 20px',
        borderRadius: '4px',
        position: 'relative',
        cursor: 'pointer',
      }}
    >
      <CardMedia
        component='img'
        height='150'
        image={product.images[0]}
        alt={product.name}
        onClick={(e) => handleClick(e, product.id)}
      />
      <CardContent onClick={(e) => handleClick(e, product.id)}>
        <ProductContent product={product} />
        {farm ? (
          <>
            <FarmLogo
              display={'flex'}
              linkDisplay={'none'}
              farm={farm}
              readOnly
            />
            <ProductCardFarmDelivery
              deliveryDisplay={deliveryDisplay}
              farm={farm}
            />
          </>
        ) : null}
      </CardContent>
      <Box className='primaryButton cardButton'>
        <Button onClick={(e) => addProductToCart(e, 1)}>Add to Basket</Button>
        <AddToBasket product={product} open={open} />
        <Popover
          open={openPop}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography sx={{ color: 'primary.error', p: 2 }}>
            Sorry this product is sold out.
          </Typography>
        </Popover>
      </Box>
    </Card>
  );
};
export default ProductCard;
