import { Stack } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';

const FarmShippingDelivery = (props) => {
  const { farm } = props;

  return (
    <Stack direction='row'>
      {farm && (
        <Stack direction='row' sx={{ display: 'flex', mr: 2 }}>
          {farm?.delivery_methods.ship && (
            <Stack sx={{ display: 'flex', flexDirection: 'column', mr: 3 }}>
              <Typography variant='h4'>Shipping Carriers</Typography>
              {farm.delivery_methods.ship_carriers.map((carrier, i) => {
                return (
                  <Typography key={i} variant='p' marginTop='10px'>
                    {carrier}
                  </Typography>
                );
              })}
            </Stack>
          )}
          {farm?.delivery_methods.deliver && (
            <Stack sx={{ display: 'flex', flexDirection: 'column', mr: 3 }}>
              <Typography variant='h4'>
                {farm.name} delivers to these zip codes
              </Typography>
              {farm.delivery_methods.zip_codes.map((code, i) => {
                return (
                  <Typography key={i} variant='p' marginTop='10px'>
                    {code}
                  </Typography>
                );
              })}
            </Stack>
          )}
          {farm?.delivery_methods.pickup && (
            <Stack className='pickup'>
              <Typography variant='h4'>
                Pickup locations for {farm?.name}
              </Typography>
              <Stack className='locations'>
                {farm.delivery_methods.locations?.map((location, i) => {
                  return (
                    <Stack key={i} marginBottom="30px">
                      <Typography variant='h5' marginTop="10px">
                        {location.name}
                      </Typography>
                      <Typography variant='p' component='p'>
                        {location.address}
                      </Typography>
                      <Typography variant='p' component='p'>
                        {location.city}, {location.state} {location.zip}
                      </Typography>
                      <Stack direction='row' alignItems='center'>
                        <Typography variant='h6' marginRight="5px">Pickup Day: </Typography>{' '}
                        <Typography variant='p' marginTop='5px'>
                          {location.pickup_day}
                        </Typography>{' '}
                      </Stack>
                      <Stack direction='row' alignItems='center'>
                        <Typography variant='h6' marginRight="5px">Pickup Times:</Typography>
                        <Typography variant='p' marginTop='5px'>
                          {location.pickup_start.split(':')[0] === 12
                            ? location.pickup_start
                            : location.pickup_start.split(':')[0] > 12
                            ? `${
                                parseInt(location.pickup_start.split(':')[0]) -
                                12
                              }:${location.pickup_start.split(':')[1]}PM`
                            : `${location.pickup_start} AM`}{' '}
                          -
                          {location.pickup_end.split(':')[0] === 12
                            ? location.pickup_end
                            : location.pickup_end.split(':')[0] > 12
                            ? `${
                                parseInt(location.pickup_end.split(':')[0]) - 12
                              }:${location.pickup_end.split(':')[1]}PM`
                            : `${location.pickup_end} AM`}
                        </Typography>{' '}
                        <Typography variant='p'></Typography>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default FarmShippingDelivery;
