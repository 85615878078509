import React from 'react';
import { useFormContext } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const OrderSummary = (props) => {
  const methods = useFormContext();
  const values = methods.getValues();

  // let tax = 0;

  return (
    <Stack
      margin='30px 40px'
      width='30%'
      height='fit-content'
      sx={{ border: '1px solid #bac2b7' }}
    >
      <Typography variant='h4' padding='20px'>
        Order Summary
      </Typography>
      <Stack
        sx={{
          borderTop: '1px solid #bac2b7',
          borderBottom: '1px solid #bac2b7',
        }}
      >
        <Stack direction='row' justifyContent='space-between' padding='20px'>
          <Typography variant='h6'>Subtotal</Typography>

          <Typography variant='p'>
            $ {values.cart.payment.subtotal?.toFixed(2) || 0}
          </Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' padding='20px'>
          <Stack>
            <Typography variant='h6'>Shipping</Typography>
          </Stack>
          <Typography variant='p'>
            $ {values.cart.payment.shipping?.toFixed(2) || 0}
          </Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' padding='20px'>
          <Typography variant='h6'>Tax</Typography>
          <Typography variant='p'>
            $ {values.cart.payment.tax?.toFixed(2) || 0}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction='row' justifyContent='space-between' padding='20px'>
        <Typography variant='h5'>Total</Typography>
        <Typography variant='p'>
          $ {values.cart.payment.total?.toFixed(2) || 0}
        </Typography>
      </Stack>
    </Stack>
  );
};
export default OrderSummary;
