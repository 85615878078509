import { Box, SvgIcon} from '@mui/material';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography"
import Vegetables from "../assets/svgImages/vegetables.svg";
import Fruits from '../assets/svgImages/fruits.svg';
import Dairy from '../assets/svgImages/dairy.svg';
import Meat from '../assets/svgImages/meat.svg';
import Pantry from '../assets/svgImages/pantry.svg';
import Seafoods from '../assets/svgImages/seafoods.svg';
import Snacks from '../assets/svgImages/snacks.svg';


export const imageBoxImages = [
  {
    img: 'https://res.cloudinary.com/tripp4077/image/upload/v1616353277/farm-era-public-images/MarketingPageFarm_Era/Rectangle_76_eajrfd.jpg',
  },
  {
    img: 'https://res.cloudinary.com/tripp4077/image/upload/v1616353277/farm-era-public-images/MarketingPageFarm_Era/Rectangle_77_eglurw.jpg',
  },
  {
    img: 'https://res.cloudinary.com/tripp4077/image/upload/v1616353277/farm-era-public-images/MarketingPageFarm_Era/Rectangle_78_n2e3yf.jpg',
  },
  {
    img: 'https://res.cloudinary.com/tripp4077/image/upload/v1616353277/farm-era-public-images/MarketingPageFarm_Era/Rectangle_79_rftzbd.jpg',
  },
  {
    img: 'https://res.cloudinary.com/tripp4077/image/upload/v1616353277/farm-era-public-images/MarketingPageFarm_Era/Rectangle_80_mmlylv.jpg',
  },
  {
    img: 'https://res.cloudinary.com/tripp4077/image/upload/v1616353277/farm-era-public-images/MarketingPageFarm_Era/Rectangle_81_sefzvn.jpg',
  },
];

export const categoryImages = [
  {
    img: Vegetables,
    title: 'Vegetables',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: Fruits,
    title: 'Fruits',
    rows: 1,
    cols: 1,
  },
  {
    img: Meat,
    title: 'Meat',
    rows: 1,
    cols: 1,
  },
  {
    img:Pantry,
    title: 'Pantry',
    rows: 1,
    cols: 1,
  },
  {
    img: Seafoods,
    title: 'Seafood',
    rows: 1,
    cols: 1,
  },
  {
    img: Dairy,
    title: 'Dairy',
    rows: 1,
    cols: 1,
  },
  {
    img: Snacks,
    title: 'Nuts & Snacks',
    rows: 1,
    cols: 1,
  },
];

export const featuresImages = [
  {
    img: 'https://res.cloudinary.com/tripp4077/image/upload/v1624478112/farm-era-public-images/MarketingPageFarm_Era/sprinkler_ah3l6j.jpg',
    title: (
      <Stack
        margin='auto'
        padding='20px 30px'
        textAlign='left'
        // sx={({ breakpoints }) => ({
        //   height: '200px',
        //   [breakpoints.down('xxlg')]: {
        //     padding: '10px',
        //     // height: '210px',
        //   },
        //   [breakpoints.down('xlg')]: {
        //     height: '220px',
        //   },
        //   [breakpoints.down('lg')]: {
        //     height: '240px',
        //   },
        //   [breakpoints.down('med')]: {
        //     height: '300px',
        //   },
        //   [breakpoints.down('sm')]: {
        //     height: '380px',
        //   },
        // })}
      >
        <Typography variant='h5' marginBottom='10px'>
          A direct link to farm fresh products
        </Typography>
        <Typography
          variant='p'
         >
          At Farm Era Market, you can enjoy a direct connection to any farm
          stand that sells products on our platform. Always fresh, and delivered
          to your front door.
        </Typography>
      </Stack>
    ),
  },
  {
    img: 'https://res.cloudinary.com/tripp4077/image/upload/v1616340287/farm-era-public-images/Rectangle_64_yvuooc.jpg',
    title: (
      <Stack
        margin='auto'
        padding='20px 30px'
        textAlign='left'
        // sx={({ breakpoints }) => ({
        //   // height: '200px',
        //   //   [breakpoints.down('xxlg')]: {
        //   //     padding: '10px',
        //   //     // height: '210px',
        //   //   },
        //   //   [breakpoints.down('xlg')]: {
        //   //     height: '220px',
        //   //   },
        //   //   [breakpoints.down('lg')]: {
        //   //     height: '240px',
        //   //   },
        //   //   [breakpoints.down('med')]: {
        //   //     height: '300px',
        //   //   },
        //   //   [breakpoints.down('sm')]: {
        //   //     height: '380px',
        //   //   },
        // })}
      >
        <Typography variant='h5' marginBottom='10px'>
          Support farmers with every purchase
        </Typography>
        <Typography variant='p' >
          Every purchase in our marketplace helps farmers earn a better living.
          Farm Era Market helps secure and incentivize the abundant availability
          of the highest quality products.
        </Typography>
      </Stack>
    ),
  },
  {
    img: 'https://res.cloudinary.com/tripp4077/image/upload/v1616340367/farm-era-public-images/Rectangle_65_quiviy.jpg',
    title: (
      <Stack
        margin='auto'
        padding='20px 30px'
        textAlign='left'
        // sx={({ breakpoints }) => ({
        //   height: '200px',
          // [breakpoints.down('xxlg')]: {
          //   padding: '10px',
          //   // height: '220px',
          // },
          // [breakpoints.down('xlg')]: {
          //   height: '220px',
          // },
          // [breakpoints.down('lg')]: {
          //   height: '240px',
          // },
          // [breakpoints.down('med')]: {
          //   height: '300px',
          // },
          // [breakpoints.down('sm')]: {
          //   height: '380px',
          // },
        // })}
      >
        <Typography variant='h5' marginBottom='10px'>
          Access to healthier options for all
        </Typography>
        <Typography variant='p' >
          Removing existing barriers by helping customers practicing
          health-related organic lifestyles have reliable access to fresh
          products. Farm Era Market makes eating healthy a whole lot easier.
        </Typography>
      </Stack>
    ),
  },
];

export const PickupAvailable = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d='M3.75 23.25L1.736 20.733C1.09773 19.9351 0.750003 18.9438 0.75 17.922V12.75C0.75 12.3522 0.908035 11.9706 1.18934 11.6893C1.47064 11.408 1.85218 11.25 2.25 11.25V11.25C2.64782 11.25 3.02936 11.408 3.31066 11.6893C3.59196 11.9706 3.75 12.3522 3.75 12.75V16.5'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M8.25006 23.1139V20.1139C8.25046 19.2241 7.98702 18.3541 7.49306 17.6139L6.35106 15.8999C6.25362 15.7375 6.12421 15.5964 5.97068 15.4854C5.81715 15.3744 5.64268 15.2957 5.45784 15.2541C5.27301 15.2125 5.08165 15.2089 4.89536 15.2434C4.70907 15.2779 4.53172 15.3499 4.37406 15.4549V15.4549C4.08943 15.6446 3.88406 15.9319 3.79674 16.2626C3.70941 16.5933 3.74617 16.9445 3.90006 17.2499L5.25006 19.4999'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M20.25 23.25L22.264 20.733C22.9023 19.9351 23.25 18.9438 23.25 17.922V12.75C23.25 12.3522 23.092 11.9706 22.8107 11.6893C22.5294 11.408 22.1478 11.25 21.75 11.25C21.3522 11.25 20.9706 11.408 20.6893 11.6893C20.408 11.9706 20.25 12.3522 20.25 12.75V16.5'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M15.75 23.1139V20.1139C15.7496 19.2241 16.013 18.3541 16.507 17.6139L17.649 15.8999C17.7464 15.7375 17.8758 15.5964 18.0294 15.4854C18.1829 15.3744 18.3574 15.2957 18.5422 15.2541C18.7271 15.2125 18.9184 15.2089 19.1047 15.2434C19.291 15.2779 19.4683 15.3499 19.626 15.4549C19.9106 15.6446 20.116 15.9319 20.2033 16.2626C20.2907 16.5933 20.2539 16.9445 20.1 17.2499L18.75 19.4999'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M12 0.75L6 4.179L12 7.607L18 4.179L12 0.75Z'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M7.5 12.75L12 15.322V7.60696L6 4.17896V9.74996'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M16.5 12.75L12 15.322V7.60696L18 4.17896V9.74996'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
};

export const ShippingAvailable = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d='M20.777 11.915L11.891 14.9L10.7 13.719L8.93404 14.313C8.81636 14.3524 8.71032 14.4204 8.62545 14.511C8.54058 14.6015 8.47954 14.7117 8.44781 14.8317C8.41608 14.9517 8.41466 15.0777 8.44367 15.1983C8.47269 15.319 8.53123 15.4306 8.61404 15.523L11.005 18.2C11.201 18.4195 11.4571 18.5765 11.7416 18.6516C12.0261 18.7266 12.3263 18.7163 12.605 18.622L15.222 17.743L14.692 21.523C14.6741 21.6496 14.6888 21.7786 14.7348 21.8979C14.7808 22.0172 14.8566 22.1227 14.955 22.2044C15.0533 22.2861 15.1709 22.3413 15.2966 22.3647C15.4223 22.388 15.5519 22.3789 15.673 22.338L17.034 21.881C17.1381 21.8459 17.2332 21.7883 17.3127 21.7125C17.3922 21.6367 17.4541 21.5443 17.494 21.442L19.488 16.309L21.976 15.473C22.4475 15.3139 22.8364 14.974 23.0572 14.5281C23.278 14.0822 23.3127 13.5669 23.1535 13.0955C22.9944 12.6241 22.6545 12.2352 22.2087 12.0143C21.7628 11.7935 21.2475 11.7589 20.776 11.918L20.777 11.915Z'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M18.289 12.751L15.509 10.837C15.4132 10.771 15.3033 10.7282 15.188 10.7121C15.0728 10.6959 14.9553 10.7069 14.845 10.744L13.507 11.194C13.3858 11.2346 13.2769 11.3055 13.1908 11.4C13.1048 11.4945 13.0443 11.6095 13.0152 11.734C12.9862 11.8585 12.9894 11.9884 13.0248 12.1113C13.0601 12.2342 13.1263 12.346 13.217 12.436L14.734 13.946'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M8.25 19.625H2.25C1.85218 19.625 1.47064 19.467 1.18934 19.1857C0.908035 18.9044 0.75 18.5228 0.75 18.125V7.625C0.75 7.22718 0.908035 6.84564 1.18934 6.56434C1.47064 6.28304 1.85218 6.125 2.25 6.125H17.25C17.6478 6.125 18.0294 6.28304 18.3107 6.56434C18.592 6.84564 18.75 7.22718 18.75 7.625V9.125'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M18.266 6.521L15.445 2.293C15.3083 2.08795 15.1232 1.91974 14.9061 1.80323C14.6889 1.68673 14.4464 1.62551 14.2 1.625H5.30001C5.05308 1.62502 4.80996 1.686 4.59226 1.80253C4.37455 1.91906 4.18897 2.08753 4.05201 2.293L1.23401 6.521'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M9.75 1.625V6.125'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
};

export const DeliveryAvailable = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d='M17.25 21.75V14.25C17.25 13.8522 17.092 13.4706 16.8107 13.1893C16.5294 12.908 16.1478 12.75 15.75 12.75H8.25C7.85218 12.75 7.47064 12.908 7.18934 13.1893C6.90804 13.4706 6.75 13.8522 6.75 14.25V20.25C6.75 20.6478 6.90804 21.0294 7.18934 21.3107C7.47064 21.592 7.85218 21.75 8.25 21.75H9.75'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M17.25 14.25H20.25C21.0456 14.25 21.8087 14.5661 22.3713 15.1287C22.9339 15.6913 23.25 16.4544 23.25 17.25V21.75H20.25'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M9.75 21.75C9.75 22.1478 9.90804 22.5294 10.1893 22.8107C10.4706 23.092 10.8522 23.25 11.25 23.25C11.6478 23.25 12.0294 23.092 12.3107 22.8107C12.592 22.5294 12.75 22.1478 12.75 21.75C12.75 21.3522 12.592 20.9706 12.3107 20.6893C12.0294 20.408 11.6478 20.25 11.25 20.25C10.8522 20.25 10.4706 20.408 10.1893 20.6893C9.90804 20.9706 9.75 21.3522 9.75 21.75V21.75Z'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M17.25 21.75C17.25 22.1478 17.408 22.5294 17.6893 22.8107C17.9706 23.092 18.3522 23.25 18.75 23.25C19.1478 23.25 19.5294 23.092 19.8107 22.8107C20.092 22.5294 20.25 22.1478 20.25 21.75C20.25 21.3522 20.092 20.9706 19.8107 20.6893C19.5294 20.408 19.1478 20.25 18.75 20.25C18.3522 20.25 17.9706 20.408 17.6893 20.6893C17.408 20.9706 17.25 21.3522 17.25 21.75Z'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M12.75 21.75H17.25'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M3.75 18.75H2.25C1.85218 18.75 1.47064 18.592 1.18934 18.3107C0.908035 18.0294 0.75 17.6478 0.75 17.25V6.75C0.75 6.35218 0.908035 5.97064 1.18934 5.68934C1.47064 5.40804 1.85218 5.25 2.25 5.25H17.25C17.6478 5.25 18.0294 5.40804 18.3107 5.68934C18.592 5.97064 18.75 6.35218 18.75 6.75V9.75'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M18.266 5.647L15.445 1.418C15.3083 1.21295 15.1232 1.04474 14.9061 0.928235C14.6889 0.811727 14.4464 0.750511 14.2 0.75H5.29998C5.05304 0.750018 4.80993 0.810998 4.59223 0.927528C4.37452 1.04406 4.18894 1.21253 4.05198 1.418L1.23398 5.647'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M9.75 0.75V5.25'
        stroke='#353535'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
};
