import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import * as utils from '../../../util';
import * as act from '../../../store/actions';

const Ship = (props) => {
  const { farm, order, i, setShipping, setSubtotal, farms } = props;
  const { register, setValue, watch } = useFormContext();
  const reducer = useSelector((state) => state.CartReducer);
  const dispatch = useDispatch();

  const values = watch();

  const handleShipping = (rate) => {
    if (
      farm.delivery_methods.free_shipping &&
      order.payment.subtotal >= farm.delivery_methods.free_shipping_amount
    ) {
      setValue(`cart.orders[${i}].payment.shipping`, 0);
      setValue(`cart.orders[${i}].delivery_selection.info.amount`, 0);
    } else {
      setValue(`cart.orders[${i}].payment.shipping`, Number(rate.amount));
      setValue(`cart.orders[${i}].delivery_selection.info.amount`, rate.amount);
    }

    setValue(`shippingInfo[${i}]`, rate.object_id);
    setValue(
      `cart.orders[${i}].delivery_selection.info.rate_id`,
      rate.object_id
    );

    setValue(
      `cart.orders[${i}].delivery_selection.info.shipment_id`,
      rate.shipment
    );
    setValue(
      `cart.orders[${i}].delivery_selection.info.provider`,
      rate.provider
    );
    setValue(
      `cart.orders[${i}].delivery_selection.info.service`,
      rate.servicelevel.name
    );

    utils.calculateTotals(values.cart, 'shipping', setShipping);
    utils.calculateTotals(values.cart, 'subtotal', setSubtotal);
  };

  useEffect(() => {
    dispatch(act.resetShippingRates());
    let rateInfo;
    let parcelWeight;

    values.cart.orders.map((order, i) => {
      if (order.delivery_selection.type === 'ship') {
        order.products.map((product) => {
          return (parcelWeight = +product.quantity * product.weight);
        });

        const farm = farms.find((farm) => farm.id === order.farm_id);
        rateInfo = {
          addressTo: {
            name: `${values.addressTo.first_name} ${values.addressTo.last_name}`,
            street1: values.addressTo.street1,
            street2: values.addressTo.street2,
            city: values.addressTo.city,
            state: values.addressTo.state,
            zip: values.addressTo.zip,
            country: 'USA',
            phone: values.addressTo.phone,
            email: values.addressTo.email,
          },
          addressFrom: {
            name: farm.name,
            street1: farm.address,
            city: farm.city,
            state: farm.state,
            zip: farm.zip,
            country: 'USA',
            phone: farm.phone,
            email: farm.email,
          },
          parcelWeight,
        };
        dispatch(act.getRates(rateInfo, order.id));
      }
      return null;
    });
  }, []);

  return (
    <Stack key={i}>
      <Typography variant='h6'>
        Select a shipping provider for {farm.name} order
      </Typography>
      <Stack width='60%' margin='20px 0'>
        <RadioGroup {...register(`shippingInfo`)} sx={{ display: 'flex' }}>
          {reducer.ratesLoading ? (
            <Typography variant='h4'>Loading</Typography>
          ) : (
            <Stack direction='row' flexWrap='wrap'>
                {reducer.shippingRates.map((rate, i) => {
                  console.log(rate, "shippingRates")
                if (order.id === rate.order_id) {
                  return rate.rates.map((rate) => {
                    console.log(rate, "rates")
                    return (
                      <FormControlLabel
                        control={
                          <Radio
                            onChange={() => handleShipping(rate, i)}
                            value={rate.object_id}
                          />
                        }
                        label={
                          <Stack margin='20px' width='100%' key={i}>
                            <Typography variant='p'>{rate.provider}</Typography>
                            <Typography variant='p' component='p'>
                              ${rate.amount}
                            </Typography>
                            <Typography variant='p' component='p'>
                              Shipping Time: {rate.estimated_days}{' '}
                              {rate.estimated_days === 1 ? 'day' : 'days'}
                            </Typography>
                          </Stack>
                        }
                      />
                    );
                  });
                }
                return null;
              })}
            </Stack>
          )}
        </RadioGroup>
      </Stack>
    </Stack>
  );
};
export default Ship;
