import React from 'react';
import { useParams } from 'react-router-dom';

import Listings from './Listings';
import EditFarm from './editFarmPage';
import FarmOrders from './FarmOrders';
import EditProductPage from './editProductPage';
import ProfilePage from '../../account/profile/pages/ProfilePage';
import Dashboard from '../components/dashboard/Dashboard';
import AddFarmPage from './addFarmPage';
import FarmStand from './FarmStand';
import ResponsiveDrawer from '../../reusable/ResponsiveDrawer';
import FarmerNav from '../../nav/FarmerNav';
import { FarmSideNav } from '../components/farmSideNav';
import Overview from './Overview';

const Pages = (props) => {
  const params = useParams();
  const { managedFarms, setManagedFarms } = props;

  switch (params.path) {
    case 'listings':
      return <Listings {...props} />;
    case 'overview':
      return (
        <Overview
          managedFarms={managedFarms}
          id={params.component}
          {...props}
        />
      );
    case 'edit_farm':
      return <EditFarm {...props} />;
    case 'add_farm':
      return (
        <AddFarmPage
          managedFarms={managedFarms}
          setManagedFarms={setManagedFarms}
          {...props}
        />
      );

    case 'orders':
      return <FarmOrders {...props} />;
    case 'edit_product':
      return <EditProductPage {...props} />;
    case 'profile':
      return <ProfilePage {...props} />;
    case 'farm-stand':
      return <FarmStand id={params.component} {...props} />;
    default:
      return <Dashboard {...props} />;
  }
};
const drawerInfo = <FarmSideNav />;

const FarmDashboard = (props) => {
  return (
    <ResponsiveDrawer
      drawerInfo={drawerInfo}
      AppBar={<FarmerNav />}
      mainComponent={<Pages {...props} />}
      bgcolor={'primary.formOutline2'}
    />
  );
};

export default FarmDashboard;
