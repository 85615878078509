import React from 'react';
import Info from '../../../../images/icons/infoIcon.svg';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Button,
 
} from '@mui/material';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton"
import * as utils from '../../../../util';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { styled } from '@mui/material/styles';

export const AddPhoto = (props) => {
  const { product, setProduct } = props;

  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const values = watch();

  const Input = styled('input')({
    display: 'none',
  });

  const handleImages = async (e) => {
    const options = {
      setState: setProduct,
      state: product,
      arrayName: e.target.name,
      multi: true,
      array: product.images,
    };
    utils.uploadImages(e, e.target.name, setValue, options);
  };

  const removeImage = (num) => {
    const images = product.images.splice(num - 1, 1);
    setValue('images', images);
    setProduct({
      ...product,
      images: images,
    });
  };
  return (
    <Stack marginBottom={'20px'}>
      <Typography variant='h5' marginBottom="5px">1. Add Photo</Typography>
      <Divider />
      {errors.images ? (
        <Typography variant='error'>{errors.images.message}</Typography>
      ) : null}
      <Stack
        direction="row" justifyContent="space-between" margin="20px 0 0 0">
        {[1, 2, 3, 4, 5].map((num) => {
          return (
            <Stack key={num}>
              {product.images[num - 1] ? (
                <React.Fragment>
                  <img
                    width='150'
                    height='120'
                    src={values.images[num - 1]}
                    default
                    alt={product.name}
                  />
                  <InputLabel
                    htmlFor={`image[${num}]`}
                    onClick={(e) => {
                      removeImage(num);
                    }}
                  >
                    <Button
                      color='primary'
                      onClick={(e) => {
                        removeImage(num);
                      }}
                      style={{ width: '150px' }}
                    >
                      Remove
                    </Button>
                  </InputLabel>
                </React.Fragment>
              ) : (
                <InputLabel
                  htmlFor={`image[${num}]`}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '125px',
                    alignItems: 'center',
                    textAlign: 'center',
                    margin: '20px 0',
                    color: 'black',
                    border: '1px solid black',
                    padding: '10px',
                  }}
                >
                  <Input
                    accept='image/*'
                    id={`image[${num}]`}
                    type='file'
                    name='images'
                    {...register('images', {
                      validate: {
                        required: () =>
                          product.images.length >= 1 ||
                          'Please add at least one product image',
                      },
                    })}
                    onChange={(e) => handleImages(e)}
                  />
                  <IconButton
                    color='primary'
                    aria-label='upload picture'
                    component='span'
                  >
                    <PhotoCamera />
                  </IconButton>
                  Add Product Image
                </InputLabel>
              )}
            </Stack>
          );
        })}
      </Stack>
      <Box>
        <Typography variant='small'>Up to 5 photos can be added</Typography>
        <img src={Info} className='infoIcon' alt={'Info Icon'} />
      </Box>
    </Stack>
  );
};
