import React from 'react';
import QuickGlanceBoxes from '../utils/QuickGlanceBoxes';
import DeliveryMethodsChart from './DeliveryMethodsChart';
import TotalSalesChart from './TotalSalesChart';
import RefundChart from './RefundChart';
import AllRatingsChart from './AllRatingsChart';
import UserChart from './UserChart';
import SalesChart from './SalesChart';
import BoxComponent from './Box';
import Stack from '@mui/material/Stack';

const QuickGlance = (props) => {
  const {
    users,
    farms,
    orders,
    totalRatingsCount,
    totalDeliveryCount,
    productsCount,
    totalOrderCount,
    totalPriceData,
    totalRefundsRequested,
    totalRefundsSent,
    totalNewOrders,
    totalShippedOrders,
  } = props;

  const generateBox = (name, i) => {
    if (name.toLowerCase().includes('orders')) {
      if (name.toLowerCase().includes('total'))
        return <BoxComponent key={i} name={name} count={totalOrderCount} />;
      else if (name.toLowerCase().includes('new'))
        return <BoxComponent key={i} name={name} count={totalNewOrders} />;
      else
        return <BoxComponent key={i} name={name} count={totalShippedOrders} />;
    } else if (name.toLowerCase().includes('farms'))
      return <BoxComponent key={i} name={name} count={farms.length} />;
    else return <BoxComponent key={i} name={name} count={productsCount} />;
  };

  return (
    <Stack marginTop="50px">
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
        width='80%'
        height='103px'
        padding='10px'
        margin='0 15px'
      >
        {QuickGlanceBoxes.map((box, i) => generateBox(box.name, i))}
      </Stack>
      <Stack width='100%' paddingTop='30px'>
        <SalesChart orders={orders} />
        <UserChart users={users} />
        <TotalSalesChart totalPriceData={totalPriceData} />
        <AllRatingsChart totalRatingsCount={totalRatingsCount} />
        <DeliveryMethodsChart
          farms={farms}
          totalDeliveryCount={totalDeliveryCount}
        />
        <RefundChart
          totalRefundsSent={totalRefundsSent}
          totalRefundsRequested={totalRefundsRequested}
        />
      </Stack>
    </Stack>
  );
};

export default QuickGlance;
