import React from 'react';
import Chart from 'react-google-charts';
import { Typography, Card } from '@mui/material';
import Stack from '@mui/material/Stack';

const TotalSalesChart = (props) => {
  const { totalPriceData } = props;
  const numberToDollar = (num) => Number(num.toFixed(2));
  const calculateCommision = (subtotal) => {
    const amountToFarmEra = subtotal * 0.05;
    const amountToFarmer = subtotal - amountToFarmEra;

    return {
      farmEra: Number(amountToFarmEra.toFixed(2)),
      farmer: Number(amountToFarmer.toFixed(2)),
    };
  };

  const generateBarChartData = (totalPriceData) => {
    if (!totalPriceData || !totalPriceData.total || !totalPriceData.subtotal)
      return;
    const formattedData = [
      ['Report', 'Reported Sales', { role: 'style' }, { role: 'annotation' }],
    ];

    formattedData.push([
      'Sales w/ tax',
      numberToDollar(totalPriceData.total),
      'color: #006f45',
      numberToDollar(totalPriceData.total),
    ]);
    formattedData.push([
      'Sales w/o tax',
      numberToDollar(totalPriceData.subtotal),
      'color: #fbd200',
      numberToDollar(totalPriceData.subtotal),
    ]);
    formattedData.push([
      'Sales Farms',
      calculateCommision(totalPriceData.subtotal).farmer,
      'color: #0054bc',
      calculateCommision(totalPriceData.subtotal).farmer,
    ]);
    formattedData.push([
      'Shipping',
      numberToDollar(totalPriceData.shipping),
      'color: #006f45',
      numberToDollar(totalPriceData.shipping),
    ]);
    formattedData.push([
      'Sales Farm Era',
      calculateCommision(totalPriceData.subtotal).farmEra,
      'color: #fbd200',
      calculateCommision(totalPriceData.subtotal).farmEra,
    ]);
    formattedData.push([
      'Sales Stripe',
      Number((totalPriceData.total * 0.03).toFixed(2)),
      'color: #0054bc',
      Number((totalPriceData.total * 0.03).toFixed(2)),
    ]);

    return formattedData;
  };

  return (
    <Stack height='380px' boxSizing='border-box' width='100%'>
      <Stack>
        <Typography variant='h6' lineHeight='22px' color='primary.lightFont'>
          Total Sales Report
        </Typography>

        <Card elevation={20} sx={{ marginTop: '28px' }}>
          <Chart
            width={'100%'}
            height={'300px'}
            chartType='BarChart'
            loader={<Typography variant='h3'>Loading Chart</Typography>}
            data={generateBarChartData(totalPriceData)}
            options={{
              legend: 'none',
              chartArea: { width: '70%' },
              hAxis: { minValue: 0 },
            }}
          />
        </Card>
      </Stack>
    </Stack>
  );
};

export default TotalSalesChart;
