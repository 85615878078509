import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import PrimaryButton from '../../../inputs/Buttons/Button';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';

const OrderHistoryProducts = (props) => {
  const { product, act, order } = props;
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.CartReducer.userCart);

  const handleReorderProduct = (product) => {
    dispatch(act.addToCart(cart, item, product.quantity));
    dispatch(act.saveCartToDB(cart));
  };

  const [item, setItem] = useState();

  useEffect(() => {
    act
      .getProduct(product?.id)
      .then((response) => {
        setItem(response);
      })
      .catch((err) => {
        console.dir(err);
      });
  }, [product]);

  return (
    <Stack margin='10px 0' width='100%'>
      {product && product.id ? (
        <Stack direction='row'>
          <CardMedia
            src={item?.images ? item.images[0] : null}
            component='img'
            sx={{ width: '15%', height:"100%", maxWidth: '150px', objectFit:'cover'}}
          />
          <Stack width='100%'>
            <CardContent
              sx={{ display: 'flex', width: '100%', paddingBottom: 0 }}
            >
              <Typography variant='p' display={'flex'} width='100%'>
                {item?.name}
              </Typography>

              <Typography
                variant='p'
                display={'flex'}
                width='100%'
                justifyContent={'center'}
              >
                Qty: <b>{product?.quantity}</b>
              </Typography>
              <Typography
                variant='p'
                display={'flex'}
                width='100%'
                justifyContent={'flex-end'}
              >
                Price:{' '}
                <b>
                  {' '}
                  ${item?.price?.toFixed(2)} / {item?.label}
                </b>
              </Typography>
            </CardContent>
            <CardActions>
              <PrimaryButton onClick={() => handleReorderProduct(product)}>
                Buy Again
              </PrimaryButton>
            </CardActions>
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
};
export default OrderHistoryProducts;
