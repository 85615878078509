import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const DeliveryMethods = (props) => {
  const { farm } = props;

  const pickupAddress = (farm) => {
    if (
      farm?.delivery_methods?.locations[0]?.address &&
      farm?.delivery_methods?.locations[0]?.city &&
      farm?.delivery_methods?.locations[0]?.state &&
      farm?.delivery_methods?.locations[0]?.zip
    ) {
      return `${farm.delivery_methods.locations[0].address} ${farm.delivery_methods.locations[0].city}, ${farm.delivery_methods.locations[0].state}. ${farm.delivery_methods.locations[0].zip}`;
    } else return 'N/A';
  };

  return (
    <Stack
      marginBottom='15px'
      width='300px'
      height='fit-content'
      border='1px solid #bac2b7'
      boxSizing='border-box'
      borderRadius='2px'
      padding='10px'
    >
      <Typography variant='h6' lineHeight='22px' marginTop='10px'>
        Delivery Methods
      </Typography>
      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        marginTop='10px'
      >
        <Typography variant='h6' marginRight='10px'>
          Ship:
        </Typography>
        <Typography variant='p' component='p' color='primary.lightFont'>
          {farm?.delivery_methods?.ship_carriers?.join(', ') || 'N/A'}
        </Typography>
      </Stack>
      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        marginTop='10px'
      >
        <Typography variant='h6' marginRight='10px'>
          Pickup:
        </Typography>
        <Typography variant='p' component='p' color='primary.lightFont'>
          {pickupAddress(farm)}
        </Typography>
      </Stack>
      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        marginTop='10px'
      >
        <Typography variant='h6' marginRight='10px'>
          Delivery:
        </Typography>
        <Typography variant='p' component='p' color='primary.lightFont'>
          {farm?.delivery_methods?.zip_codes?.join(', ') || 'N/A'}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default DeliveryMethods;
