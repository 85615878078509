import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Card from "@mui/material/Card"
import Stack from "@mui/material/Stack";
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const ShippingDelivery = (props) => {
  const { farm } = props;

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='h5'>Shipping and Delivery</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack>
          {farm && (
            <Stack direction="row" sx={{ mr: 2 }}>
              {farm?.delivery_methods.ship && (
                <Stack sx={{  mr: 3 }}>
                  <Typography variant='h5'>Shipping Carriers:</Typography>
                  {farm.delivery_methods.ship_carriers.map((carrier, i) => {
                    return (
                      <Typography key={i} variant='p' marginTop='10px'>
                        {carrier}
                      </Typography>
                    );
                  })}
                </Stack>
              )}
              {farm?.delivery_methods.deliver && (
                <Stack sx={{ mr: 3 }}>
                  <Typography variant='h5'>
                    {farm.name} delivers to these zip codes:
                  </Typography>
                  {farm.delivery_methods.zip_codes.map((code, i) => {
                    return (
                      <Typography key={i} variant='p' marginTop="10px">
                        {code}
                      </Typography>
                    );
                  })}
                </Stack>
              )}
              {farm?.delivery_methods.pickup && (
                <Stack >
                  <Typography variant='h5'>
                    Pickup locations for {farm?.name}:{' '}
                  </Typography>
                  <Stack >
                    {farm.delivery_methods.locations?.map((location, i) => {
                      return (
                        <Card
                          key={i}
                          sx={{ padding: '10px', margin: '10px 0' }}
                          elevation={4}
                        >
                          <Typography variant='h5' fontWeight={'bold'}>
                            {location.name}
                          </Typography>
                          <Typography variant='p'>
                            {location.address}
                          </Typography>
                          <Typography variant='p'>
                            {location.city}, {location.state} {location.zip}
                          </Typography>
                          <Stack direction='row' alignItems='center'>
                            <Typography variant='h6'>Pickup Day: </Typography>
                            <Typography
                              variant='p'
                              marginTop='5px'
                              marginLeft='5px'
                            >
                              {location.pickup_day}
                            </Typography>{' '}
                          </Stack>
                          <Stack direction='row' alignItems='center'>
                            <Typography variant='h6'>Pickup Times: </Typography>{' '}
                            <Typography
                              variant='p'
                              marginTop='5px'
                              marginLeft='5px'
                            >
                              {location.pickup_start.split(':')[0] === 12
                                ? location.pickup_start
                                : location.pickup_start.split(':')[0] > 12
                                ? `${
                                    parseInt(
                                      location.pickup_start.split(':')[0]
                                    ) - 12
                                  }:${location.pickup_start.split(':')[1]} PM`
                                : `${location.pickup_start} AM`}{' '}
                              -{' '}
                              {location.pickup_end.split(':')[0] === 12
                                ? location.pickup_end
                                : location.pickup_end.split(':')[0] > 12
                                ? `${
                                    parseInt(
                                      location.pickup_end.split(':')[0]
                                    ) - 12
                                  }:${location.pickup_end.split(':')[1]} PM`
                                : `${location.pickup_end} AM`}
                            </Typography>{' '}
                            <Typography variant='p'></Typography>
                          </Stack>
                        </Card>
                      );
                    })}
                  </Stack>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default ShippingDelivery;
