import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import BlockIcon from '@mui/icons-material/Block';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import Tooltip from "@mui/material/Tooltip";

const FarmBio = (props) => {
  const { farm, defaultImage } = props;

  return (
    <Stack
      marginBottom='15px'
      width='300px'
      height='221px'
      padding='10px'
      border='1px solid #bac2b7'
      boxSizing='border-box'
      borderRadius='2px'
    >
      <Stack direction='row' alignItems='center'>
        <Avatar
          alt={farm.name}
          width='48px'
          height='48px'
          src={farm.farm_logo || defaultImage}
        />
        {farm.blocked ? (
          <Tooltip title={farm.blockedReason}>
            <BlockIcon sx={{ color: 'red', marginLeft: '30px' }} />
          </Tooltip>
        ) : farm.flagged ? (
          <Tooltip title={farm.flaggedReason}>
            <EmojiFlagsIcon sx={{ color: 'red', marginLeft: '30px' }} />
          </Tooltip>
        ) : null}
      </Stack>
      <Typography variant='h6' lineHeight='22px' marginTop='10px'>
        {farm?.name}
      </Typography>

      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        marginTop='10px'
      >
        <Typography variant='h6' marginRight='10px'>
          Address:
        </Typography>
        <Typography variant='p' component={'p'} color='primary.lightFont'>
          {farm?.address} {farm?.city}, {farm?.state}. {farm?.zip}
        </Typography>
      </Stack>

      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        marginTop='10px'
      >
        <Typography variant='h6' marginRight='10px'>
          Phone:
        </Typography>
        <Typography variant='p' component={'p'} color='primary.lightFont'>
          {farm?.phone}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default FarmBio;
