import React from 'react';
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography"
import Stack from '@mui/material/Stack';


const FarmerFAQs = (props) => {
  const { expanded, handleChange } = props;

  return (
    <Stack margin="20px">
      <Typography variant='h4' marginBottom="20px">Farmer FAQs</Typography>
      <Accordion
        expanded={expanded === 'panel5'}
        onChange={handleChange('panel5')}
      >
        <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
          <Typography
            color='primary.secondary'
            sx={{
              fontSize: '18px',
            }}
          >
            How does Farm Era work
          </Typography>
        </AccordionSummary>
        <AccordionDetails className='accordionDetails'>
          <Typography>
            Farmers that sign up on the Farm Era Market platform can create
            their farm stand and offer their products to customers located in
            the US. Customers shopping in our marketplace will view products by
            category, most popular products, closest farms, or within a specific
            region. Farmers can select their preferred shipping carrier, include
            a customer pickup option, or even set a direct delivery preference.
            Once an order is processed, farmers can simply print a shipping
            label, pack the order, and ship directly to the customer.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel6'}
        onChange={handleChange('panel6')}
      >
        <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
          <Typography
            color='primary.secondary'
            sx={{
              fontSize: '18px',
            }}
          >
            How much does it cost to sell on Farm Era Market
          </Typography>
        </AccordionSummary>
        <AccordionDetails className='accordionDetails'>
          <Typography>
            Farm Era Market provides a platform that tracks product inventory,
            manages orders, and more. There are no fees for product listing,
            amount limits, or managing multiple farms. We only charge a 5%
            commission plus Stripe's 2.8% + .30 transaction fee per sale.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel7'}
        onChange={handleChange('panel7')}
      >
        <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
          <Typography
            color='primary.secondary'
            sx={{
              fontSize: '18px',
            }}
          >
            How fast will I get paid
          </Typography>
        </AccordionSummary>
        <AccordionDetails className='accordionDetails'>
          <Typography>
            Funds are credited every seven(7) days minus any customer return(s)
            or refund request(s). Payments can be electronically deposited to
            any bank account right from your farm dashboard.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel8'}
        onChange={handleChange('panel8')}
      >
        <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
          <Typography
            color='primary.secondary'
            sx={{
              fontSize: '18px',
            }}
          >
            Payment Processing
          </Typography>
        </AccordionSummary>
        <AccordionDetails className='accordionDetails'>
          <Typography>
            Farm Era Market uses Stripe for all payments. Signing up or
            connecting an existing Stripe account can be done right from our
            easy-to-use farm dashboard. Stripe charges a 2.8% + .30 fee per
            transaction.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default FarmerFAQs;
