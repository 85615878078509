import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import Password from './password';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import PrimaryButton from '../../inputs/Buttons/Button';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from "@mui/material/InputLabel"
import Link from '@mui/material/Link';
import LegalDisclaimer from '../../misc/models/LegalDisclaimer';
import * as act from '../../../store/actions/index';
import { roles } from '../../../util/data';

function Register(props) {
  const [termsOpen, setTermsOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      role: '',
      receiveEmails: false,
      farmeraTerms: false,
    },
  });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;
  let helpers = {
    history: history,
    dispatch: dispatch,
  };
  const error = useSelector((state) => state.UserReducer.error);

  const onSubmit = (data) => {
    dispatch(act.register(data, helpers));
  };

  const TermsModal = (
    <InputLabel>
      I agree to the Farm Era{' '}
      <Link
        onClick={() => setTermsOpen(!termsOpen)}
        sx={{
          textDecoration: 'none',
          fontSize: '0.8rem',
          color: 'primary.radio',
          cursor: 'pointer',
          fontSize: '16px',
        }}
      >
        Terms and Conditions
      </Link>
    </InputLabel>
  );

  return (
    <Stack
      margin='100px auto'
      width='70%'
      minHeight='70vh'
      height='fit-content'
      sx={{
        backgroundImage:
          'url(https://res.cloudinary.com/tripp4077/image/upload/v1608318614/farm-era-public-images/signup_h5qgmw.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={(data) => handleSubmit(onSubmit(data))}>
          <Stack
            minHeight='70vh'
            height='fit-content'
            padding='30px'
            marginLeft='45%'
            justifyContent='center'
            sx={{
              background: 'rgba(255, 255, 255, 0.6)',
            }}
          >
            <Typography variant='h2'>Create an account</Typography>
            {error && <Typography variant='error'>{error}</Typography>}
            <TextField
              className='largeInput'
              {...register('email', {
                required: 'Please enter an email address',
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: 'Invalid email address',
                },
              })}
              label={
                errors && errors.email ? (
                  <Typography variant='error'>
                    {errors.email.message}
                  </Typography>
                ) : (
                  'Email Address'
                )
              }
            />
            <Stack justifyContent='space-between' direction='row'>
              <TextField
                placeholder={'First Name'}
                {...register('first_name', {
                  required: 'First Name is required',
                })}
                label={
                  errors && errors.first_name ? (
                    <Typography variant='error'>
                      {errors.first_name.message}
                    </Typography>
                  ) : (
                    'First Name'
                  )
                }
                sx={{ width: '49%' }}
                error={errors.first_name}
              />
              <TextField
                {...register('last_name', {
                  required: 'Last Name is required',
                })}
                label={
                  errors && errors.last_name ? (
                    <Typography variant='error'>
                      {errors.last_name.message}
                    </Typography>
                  ) : (
                    'Last Name'
                  )
                }
                sx={{ width: '49%' }}
                error={errors.last_name}
              />
            </Stack>
            <Stack direction='row' justifyContent='space-between'>
              <Password />
              <TextField
                {...register('zip', {
                  maxLength: {
                    value: 5,
                    message: 'Please only use a 5-digit postal code',
                  },
                  required: 'Zip Code is required',
                })}
                inputProps={{
                  maxLength: 5,
                }}
                label={
                  errors && errors.zip ? (
                    <Typography variant='error'>
                      {errors.zip.message}
                    </Typography>
                  ) : (
                    'Zip Code'
                  )
                }
                sx={{ width: '49%' }}
              />
            </Stack>
            <Controller
              name='role'
              rules={{ required: 'Must select type of account to create' }}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    sx={{ fontSize: '1rem' }}
                    value={roles.farmer}
                    control={<Radio />}
                    label='I would like to open a farmer account'
                  />
                  <FormControlLabel
                    sx={{ fontSize: '1rem' }}
                    value={roles.customer}
                    control={<Radio />}
                    label='I would like to open a consumer account'
                  />
                  {errors && errors.role ? (
                    <Typography variant='error'>
                      {errors.role.message}
                    </Typography>
                  ) : null}
                </RadioGroup>
              )}
              control={control}
            />

            <Stack direction='row' justifyContent='space-between'>
              <FormControlLabel
                sx={{ fontSize: '1rem' }}
                label='Yes, I would like to receive emails.'
                control={
                  <Controller
                    name='receiveEmails'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    )}
                  />
                }
              />
              <FormControlLabel
                sx={{ fontSize: '1rem' }}
                label={TermsModal}
                control={
                  <Controller
                    name='farmeraTerms'
                    control={control}
                    rules={{
                      required: 'Must agree to Farm Era Terms and Conditions',
                    }}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    )}
                  />
                }
              />
              <LegalDisclaimer open={termsOpen} setOpen={setTermsOpen} />
              {errors && errors.farmeraTerms ? (
                <Typography variant='error'>
                  {errors.farmeraTerms.message}
                </Typography>
              ) : null}
            </Stack>

            <PrimaryButton
              onClick={handleSubmit(onSubmit)}
              width='250px'
              margin='20px auto'
            >
              Register an account
            </PrimaryButton>

            <Typography variant='p' textAlign='center' marginTop='10px'>
              Already a member?{' '}
              <Typography
                variant='a'
                onClick={() => {
                  dispatch(act.resetError());
                  props.setComponent('login');
                }}
              >
                Sign in
              </Typography>{' '}
            </Typography>
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  );
}
export default Register;
