import * as act from '../actions';

const InitialState = {
  newFarm: {
    id: '',
    user_id: '',
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    billing_address: {
      same_address: false,
      address: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
    },
    lat: '',
    long: '',
    reviews: [],
    farm_image: '',
    farm_logo: '',
    flagged: false,
    flaggedReason: null,
    stripe_acct: null,
    delivery_methods: {
      ship: false,
      ship_carriers: [],
      free_shipping: false,
      free_shipping_amount: 0,
      deliver: false,
      zip_codes: [],
      delivery_fee: 0,
      pickup: false,
      locations: [],
    },
    disclaimer_agreement: false,
    policies: '',
    bio: '',
    documents: []
  },
  allFarms: [],
  managedFarms: [],
  farmsError: '',
  returns: [],
};

export const FarmReducer = (state = InitialState, action) => {
  switch (action.type) {
    case act.ADD_FARM_INFO:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          user_id: action.payload.user_id,
          name: action.payload.name,
          address: action.payload.address,
          city: action.payload.city,
          state: action.payload.state,
          zip: action.payload.zip,
          phone: action.payload.phone,
          billing_address: action.payload.billing_address,
          lat: '',
          long: '',
          reviews: [],
          farm_image: action.payload.farm_image,
          farm_logo: action.payload.farm_logo,
          stripe_acct: '',
          delivery_methods: action.payload.delivery_methods,
          disclaimer_agreement: action.payload.disclaimer_agreement,
          policies: action.payload.farm_policies,
          bio: action.payload.farm_bio,
        },
      };
    case act.ADD_STRIPE:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          stripe_acct: action.payload,
        },
      };

    case act.ADD_FARM_SHIPPING:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          delivery_methods: {
            ship: action.payload.ship,
            ship_carriers: action.payload.ship_carriers,
            deliver: action.payload.deliver,
            zip_codes: action.payload.zip_codes,
            delivery_fee: action.payload.delivery_fee,
            free_ship_amount: action.payload.free_ship_amount,
            free_shipping: action.payload.free_shipping,
            pickup: action.payload.pickup,
            locations: action.payload.locations,
          },
        },
      };
    case act.ADD_LOCATION:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          delivery_methods: {
            ...state.newFarm.delivery_methods,
            locations: action.payload,
          },
        },
      };
    case act.ADD_FARMS:
      return {
        ...state,
        allFarms: action.payload,
      };
    case act.FARM_ERROR:
      return {
        ...state,
        farmsError: action.payload,
      };
    case act.ADD_SHIPPING_LABEL:
      return {
        ...state,
        shippingLabel: action.payload,
      };
    case act.ADD_MANAGED_FARMS:
      return {
        ...state,
        managedFarms: action.payload,
      };
    case act.ADD_FARM_RETURNS:
      return {
        ...state,
        returns: action.payload,
      };
    case act.RESET_NEW_FARM:
      return {
        ...state,
        newFarm: {
          id: '',
          user_id: '',
          name: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          phone: '',
          billing_address: {
            same_address: false,
            address: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
          },
          lat: '',
          long: '',
          reviews: [],
          farm_image: '',
          farm_logo: '',
          stripe_acct: '',
          delivery_methods: {
            ship: false,
            ship_carriers: [],
            free_shipping: false,
            free_shipping_amount: 0,
            deliver: false,
            zip_codes: [],
            delivery_fee: 0,
            pickup: false,
            locations: [],
          },
          disclaimer_agreement: false,
          policies: '',
          bio: '',
        },
      };
    case act.ADD_MANAGED_TO_FARMS:
      return {
        ...state,
        managedFarms: [...state.managedFarms, action.payload],
      };
    default:
      return state;
  }
};
