import React from 'react';
import { useParams } from 'react-router-dom';

import OrderHistoryPage from './orderHistoryPage';
import LeaveReviewPage from '../../forms/pages/LeaveReviewPage';
import ProfilePage from '../../account/profile/pages/ProfilePage';
import Returns from './Return';
import Favorites from "./favorites"
import Dashboard from '../components/dashboard';
import CustomerSideNav from '../components/customerSideNav';
import FarmStand from "../../farm/pages/FarmStand"
import ResponsiveDrawer from '../../reusable/ResponsiveDrawer';
import MyAccountNav from '../../nav/MyAccountNav';

const Pages = (props) => {
  const params = useParams();

  switch (params.path) {
    case 'history':
      return <OrderHistoryPage {...props} />;
    case 'review':
      return <LeaveReviewPage {...params} />;
    case 'favorites':
      return <Favorites {...props}/>

    case 'profile':
      return <ProfilePage {...props} />;
    case 'returns':
      return <Returns {...props} {...params} />;
      case 'farmstand':
      return <FarmStand {...props} {...params}/>
    default:
      return <Dashboard {...props} />;
  }
};

const drawerInfo = (
  <CustomerSideNav/>
)

const CustomerDashboard = (props) => {
  return (
    <ResponsiveDrawer drawerInfo={drawerInfo} AppBar={<MyAccountNav/>} mainComponent={<Pages {...props}/>}/>
  )

}

export default CustomerDashboard;