import React from 'react';
import { useSelector } from 'react-redux';
import StackedLogoComponent from './components/StackedLogo';

import Stack from "@mui/material/Stack"
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider"
import {useHistory} from "react-router-dom"
import {
 FarmAccountIcon
} from '../assets/farmIconImages';
import MobileViewAlert from '../misc/components/MobileViewAlert';

const FarmerNav = (props) => {
  const user = useSelector((state) => state.UserReducer.user);
  const history = useHistory();
 
  return (
    <Stack direction="row" justifyContent="space-between" width="80%">
      <MobileViewAlert />
      <StackedLogoComponent />
      <Divider />
      <Stack direction='row'>
        <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center' }}>
          <FarmAccountIcon
            onClick={() => history.push('/dashboard')}
            sx={{
              fontSize: '25px',
              margin: '0 10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          />
          {user.user_metadata.first_name} {user.user_metadata.last_name}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default FarmerNav;
