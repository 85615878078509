import React from 'react';
import PrimaryButton from '../../inputs/Buttons/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '../../display/dialog';
import { useDispatch } from 'react-redux';
import * as act from "../../../store/actions"

const OrderSuccessfulModal = (props) => {
  const { open, setOpen, user } = props;
const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(act.getUserOrderHistory(user.user_id.split('|')[1]))
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      title={
        <Stack alignItems='center'>
          <Typography variant='h3' margin='10px 0'>
            Thank you for your order.{' '}
          </Typography>
          <Typography variant='h4'>
            It has been Submitted Successfully
          </Typography>
        </Stack>
      }
      actions={
        <PrimaryButton margin='20px 30%' onClick={() => handleClick()}>
          Close
        </PrimaryButton>
      }
    />
  );
};

export default OrderSuccessfulModal;
