import React from 'react';
import DialogModal from '../../display/dialog';
import { stateAbbrev } from '../../../util/data';
import { useFormContext, Controller } from 'react-hook-form';
import State from '../../misc/components/state';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import PrimaryButton from '../../inputs/Buttons/Button';

const EditDeliveryInfo = (props) => {
  const { open, setOpen, i } = props;
  const { register, setValue, control, watch } = useFormContext({
    mode: 'onChange',
  });

  const values = watch();

  const handleDeliveryInfo = () => {
    setValue(`cart.orders[${i}].delivery_selection.info`, {
      ...values.cart.orders[i].delivery_selection.info,
      name: values.cart.orders[i].delivery_selection.info.name,
      street1: values.cart.orders[i].delivery_selection.info.street1,
      street2: values.cart.orders[i].delivery_selection.info.street2,
      city: values.cart.orders[i].delivery_selection.info.city,
      state: values.cart.orders[i].delivery_selection.info.state,
      zip: values.cart.orders[i].delivery_selection.info.zip,
    });
    setOpen(false);
  };

  const content = (
    <Stack>
      <TextField
        fullWidth
        {...register(`cart.orders[${i}].delivery_selection.info.street1`)}
        defaultValue={values.cart.orders[i].delivery_selection.info.street1}
        label={'Street Address'}
      />
      <Stack direction='row' justifyContent='space-between'>
        <TextField
          sx={{ width: '49%' }}
          {...register(`cart.orders[${i}].delivery_selection.info.street2`)}
          defaultValue={values.cart.orders[i].delivery_selection.info.street2}
          label={'Apt#, Floor, etc (optional)'}
        />
        <TextField
          sx={{ width: '49%' }}
          {...register(`cart.orders[${i}].delivery_selection.info.zip`)}
          defaultValue={values.cart.orders[i].delivery_selection.info.zip}
          label={'Zip Code'}
        />
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <TextField
          sx={{ width: '49%' }}
          {...register(`cart.orders[${i}].delivery_selection.info.city`)}
          defaultValue={values.cart.orders[i].delivery_selection.info.city}
          label={'City'}
        />
        <State
          name='state'
          width='49%'
          errorName={'errors.state'}
          onClick={(value) => {
            setValue(`cart.orders[${i}].delivery_selection.info.state`, value);
          }}
          defaultValue={values.cart.orders[i].delivery_selection.info.state}
        />
      </Stack>
    </Stack>
  );

  const actions = (
    <Stack direction='row' justifyContent='space-between' width='70%'>
      <PrimaryButton type='button' onClick={() => handleDeliveryInfo()}>
        Save
      </PrimaryButton>
      <PrimaryButton onClick={() => setOpen(false)}>Cancel</PrimaryButton>
    </Stack>
  );
  return (
    <DialogModal
      open={open}
      title={'Edit Delivery Information'}
      content={content}
      actions={actions}
    />
  );
};

export default EditDeliveryInfo;
