import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { FarmLogo } from '../farm/components/FarmLogo';
import * as act from '../../store/actions';
import ReviewSuccessful from './modals/ReviewSuccessful';
import Stack from '@mui/material/Stack';
import TransparentButton from '../inputs/Buttons/TransparentButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import FormControl from '@mui/material/FormControl';

export const FarmReview = (props) => {
  const {
    register,
    setValue,
    watch,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  const user = useSelector((state) => state.UserReducer.user);
  const dispatch = useDispatch();
  const values = watch();

  const { farm } = props;

  const [open, setOpen] = useState(false);

  const setRate = (e) => {
    setValue(e.target.name, parseInt(e.target.value));
  };

  const handleClick = async (e) => {
    const rateValid = await trigger('farmRate');
    const reviewValid = await trigger('farmReview');

    if (!rateValid && !reviewValid) {
      return;
    }
    e.preventDefault();
    farm.reviews.push({
      rate: getValues('farmRate'),
      review: getValues('farmReview'),
      user_id: user.user_id.split('|')[1],
    });

    dispatch(act.updateFarmInfo(farm.id, 'reviews', farm.reviews));
    setOpen(true);
  };

  return (
    <Stack margin='50px 0'>
      <FarmLogo
        imgSize={{ width: '90px', height: '90px' }}
        farm={farm}
        ratingDisplay={'none'}
        linkDisplay={'none'}
        milesDisplay={'none'}
      />
      <Typography variant='p' component='p' margin='20px 0 0'>
        Rate Farm
      </Typography>
      <Controller
        name='farmRate'
        rules={{
          validate: {
            required: () =>
              values.farmRate > 0 || 'Must select a rate to submit review',
          },
        }}
        render={({ field: { onChange, onBlur, name, value } }) => (
          <FormControl>
            <Rating
              value={value}
              name={name}
              onChange={(e) => {
                setRate(e);
              }}
            />
          </FormControl>
        )}
        control={control}
        defaultValue={0}
      />
      {errors?.farmRate ? (
        <Typography variant='error'>{errors.farmRate.message}</Typography>
      ) : null}

      <Typography variant='p' component='p' margin='20px 0 0 0'>
        Tell us your experience with the farm
      </Typography>
      <Stack direction='row'>
        <TextField
          {...register('farmReview', {
            required: 'Must also leave a description of rate.',
          })}
          multiline
          // fullWidth
          rows={4}
          style={{ width: '80%', marginRight: '10px' }}
        />
        {errors?.farmReview ? (
          <Typography variant='error'>{errors.farmReview.message}</Typography>
        ) : null}
        <ReviewSuccessful open={open} setOpen={setOpen} />
        <TransparentButton
          width='222px'
          margin='50px 0 0 0'
          onClick={(e) => handleClick(e)}
        >
          Submit Feedback
        </TransparentButton>
      </Stack>
    </Stack>
  );
};

export const ProductReview = (props) => {
  const {
    register,
    watch,
    setValue,
    control,
    trigger,
    formState: { errors },
  } = useFormContext();
  const dispatch = useDispatch();
  const { products, allProducts } = props;
  const user = useSelector((state) => state.UserReducer.user);
  const values = watch();
  const [open, setOpen] = useState(false);

  const handleClick = async (e, product, i) => {
    const rateValid = await trigger(`products[${i}].rate`);
    const reviewValid = await trigger(`products[${i}].review`);

    if (!rateValid && !reviewValid) {
      return;
    }

    e.preventDefault();
    products.map((item, i) => {
      const product = allProducts.find((prod) => prod.id === item.id);

      let rate = values.products[i].rate;
      let review = values.products[i].review;

      product.reviews.push({
        rate: rate,
        review: review,
        user_id: user.user_id.split('|')[1],
      });
      return dispatch(act.updateProduct(product));
    });
    setOpen(true);
  };

  const setRate = (e) => {
    setValue(e.target.name, parseInt(e.target.value));
  };

  return (
    <Stack width='100%'>
      {products?.map((product, index) => {
        const item = allProducts?.find((prod) => prod.id === product.id);
        return (
          <Stack key={index}>
            <Stack direction='row'>
              <img width='100px' src={item?.images[0]} alt={item?.name} />
              <Typography variant='h6' marginLeft='10px'>
                {item?.name}
              </Typography>
            </Stack>
            <Typography variant='p' component='p' margin='20px 0 0'>
              Rate Product
            </Typography>
            <Controller
              name={`products[${index}].rate`}
              rules={{
                validate: {
                  required: () =>
                    values.products[index].rate > 0 ||
                    'Must select a rate to submit review',
                },
              }}
              render={({ field: { onChange, onBlur, name, value } }) => (
                <FormControl>
                  <Rating
                    value={value}
                    name={name}
                    onChange={(e) => {
                      setRate(e);
                    }}
                  />
                </FormControl>
              )}
              control={control}
              defaultValue={0}
            />
            {errors.products && errors?.products[index].rate ? (
              <Typography variant='error'>
                {errors.products[index].rate.message}
              </Typography>
            ) : null}
            <Typography variant='p' component='p' margin='20px 0 0 0'>
              Tell us your experience with the product
            </Typography>
            <Stack direction='row'>
              <TextField
                type='text'
                {...register(`products[${index}].review`, {
                  required: 'Must also leave a description of review.',
                })}
                multiline
                rows={4}
                style={{ width: '80%', marginRight: '10px' }}
              />
              {errors.products && errors?.products[index].review ? (
                <Typography variant='error'>
                  {errors.products[index].review.message}
                </Typography>
              ) : null}
              <ReviewSuccessful open={open} setOpen={setOpen} />
              <TransparentButton
                width='222px'
                margin='50px 0 0 0'
                onClick={(e) => handleClick(e, product, index)}
              >
                Submit Feedback
              </TransparentButton>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};
