import React from 'react';
import Modal from '../../../display/modal';
import { useHistory } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PrimaryButton from '../../../inputs/Buttons/Button';

const SuccessUser = (props) => {
  const { open, setOpen } = props;
  const history = useHistory();
  return (
    <Modal
      open={open}
      top='50%'
      left='50%'
      message={
        <Stack>
          <Typography variant='h5'>User Created Successfully</Typography>
          <PrimaryButton
            width='150px'
            margin='20px auto'
            onClick={() => {
              setOpen(false);
              history.push('/dashboard');
            }}
          >
            Close
          </PrimaryButton>
        </Stack>
      }
    />
  );
};

export default SuccessUser;
