import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const FarmPayment = (props) => {
  const { farm, calculateCommission } = props;

  return (
    <Stack
      width='300px'
      height='fit-content'
      border='1px solid #bac2b7'
      marginBottom='15px'
    >
      <Typography
        variant='h6'
        lineHeight='22px'
        color='primary.lightFont'
        boxSizing='border-box'
        borderRadius='2px'
        padding='12px'
      >
        Payment Details
      </Typography>
      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        padding='10px'
      >
        <Typography variant='h6' marginRight='10px'>
          Stripe:
        </Typography>
        <Typography variant='p' component='p' color='primary.mediumFont'>
          {calculateCommission(farm?.id).stripe}
        </Typography>
      </Stack>
      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        padding='10px'
      >
        <Typography variant='h6' marginRight='10px'>
          Farm Era:
        </Typography>
        <Typography variant='p' component='p' color='primary.mediumFont'>
          {calculateCommission(farm?.id).farmEra}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default FarmPayment;
