import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Input from '@mui/material/Input';
import { keyframes } from '@emotion/react';
import FormGroup from '@mui/material/FormGroup';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#989898',
      color: '#494646',
      fontWeight: 500,
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '##989898',
      },
      '::placeholder': {
        color: '#989898',
      },
    },
    invalid: {
      iconColor: '#eb5757',
      color: '#eb5757',
    },
  },
};

const CardField = ({ onChange }) => (
  <Stack
    alignItems='center'
    marginLeft='15px'
    sx={{ borderTop: '1px solid #006f45 ' }}
  >
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </Stack>
);

const voidAnimation = keyframes`
void-animation-out:{
0%: {
  opacity: 1
}
}`;
const fade = keyframes`{
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }`;

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <Stack alignItems='center' marginLeft='15px'>
    <Input
      fullWidth
      variant='standard'
      sx={{
        backgroundColor: 'transparent',
        animation: `1ms ${voidAnimation}`,
        fontSize: '1rem',
        padding: '11px 15px 11px 0',
      }}
      label={label}
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </Stack>
);

const ErrorMessage = ({ children }) => (
  <Stack
    sx={{
      color: 'primary.error',
      fontSize: '13px',
      transform: 'translateY(-15px)',
      opacity: 0,
      animation: `${fade} 150ms ease-out`,
      animationDelay: '50ms',
      animationFillMode: 'forwards',
      willChange: 'opacity, transform',
    }}
    position='absolute'
    justifyContent='center'
    padding='0 15px'
    marginTop='0px'
    width='100%'
    className='ErrorMessage'
    role='alert'
  >
    <svg width='16' height='16' viewBox='0 0 17 17'>
      <path
        fill='#FFF'
        d='M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z'
      />
      <path
        fill='#6772e5'
        d='M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z'
      />
    </svg>
    {children}
  </Stack>
);

const CheckoutForm = (props) => {
  const {
    billingDetails,
    setBillingDetails,
    setError,
    error,
    setCardComplete,
  } = props;
  return (
    <Stack
      width='100%'
      margin='20px'
      sx={{ animation: `${fade} 200ms ease-out` }}
    >
      <FormGroup
        sx={{
          boxShadow:
            '0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),inset 0 1px 0 #006f45',
          borderRadius: '4px',
          margin: ' 0 15px 20px',
          padding: '0',
          backgroundColor: 'white',
          willChange: 'opacity, transform',
          borderStyle: 'none',
        }}
      >
        <Field
          label='Name'
          id='name'
          type='text'
          placeholder='Jane Doe'
          required
          autoComplete='name'
          value={billingDetails.name}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, name: e.target.value });
          }}
        />
        <Field
          label='Email'
          id='email'
          type='email'
          placeholder='janedoe@gmail.com'
          required
          autoComplete='email'
          value={billingDetails.email}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, email: e.target.value });
          }}
        />
        <Field
          label='Phone'
          id='phone'
          type='tel'
          placeholder='(941) 555-0123'
          required
          autoComplete='tel'
          value={billingDetails.phone}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, phone: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup
        sx={{
          boxShadow:
            '0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),inset 0 1px 0 #006f45',
          borderRadius: '4px',
          margin: ' 0 15px 20px',
          padding: '0',
          backgroundColor: 'white',
          willChange: 'opacity, transform',
          borderStyle: 'none',
        }}
      >
        <CardField
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
      </FormGroup>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </Stack>
  );
};

export default CheckoutForm;
