import React from 'react';
import Stack from '@mui/material/Stack';
import PrimaryButton from '../../inputs/Buttons/Button';
import TextField from '@mui/material/TextField';
import { useFormContext } from 'react-hook-form';
import Typography from '@mui/material/Typography';

const CancelOrder = (props) => {
  const { setCancelledOpen, handleCancel } = props;

  const {
    register,
    watch,
    trigger,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const values = watch();

  return (
    <Stack>
      <TextField
        {...register('reasonForCancellation', {
          required: 'Reason is Required',
        })}
        label={
          errors?.reasonForCancellation ? (
            <Typography variant='error'>
              {errors.reasonForCancellation.message}
            </Typography>
          ) : (
            'Reason for Cancellation'
          )
        }
        defaultValue={values.reasonForCancellation}
      />
      <Stack direction='row'>
        <PrimaryButton
          width='250px'
          margin='20px 10px 0 0'
          onClick={() => {
            clearErrors();
            trigger();
            handleCancel(values);
          }}
        >
          Confirm
        </PrimaryButton>
        <PrimaryButton
          width='250px'
          margin='20px 0 0 10px'
          onClick={() => setCancelledOpen(false)}
        >
          Close
        </PrimaryButton>
      </Stack>
    </Stack>
  );
};

export default CancelOrder;
