import * as act from '../actions/index';
const initialState = {
  user: {
    email: '',
    username: '',
    password: '',
    user_metadata: {
      first_name: '',
      last_name: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      avatar: '',
      acceptTerms: false,
      receiveEmails: false,
      flagged: false,
      flaggedReason: '',
      long: 0,
      lat: 0,
      stripe_account: '',
      favorites: {
        products: [],
        farms: []
      },
    },
    user_id: '',
    app_metadata: {
      roles: [0],
    },
    billingSame: true,
  },
  changePassword: '',
  showPassword: false,
  authResults: {},
  error: '',
  isLoading: false,

  allUsers: [],
  stripeBalance: 0,
};

export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case act.LOGIN_SUCCESS:
      return {
        ...state,
        user: {
          email: action.payload.email,
          username: action.payload.username || action.payload.email,
          app_metadata:
            action.payload['https://farmera.us.auth.com/app_metadata'],
          user_metadata:
            action.payload['https://farmera.us.auth.com/user_metadata'],
          user_id: action.payload.sub,
        },
      };
    case act.LOGOUT:
      return {
        user: {
          email: '',
          username: '',
          user_metadata: {
            first_name: '',
            last_name: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
          },
          app_metadata: {
            roles: [],
          },
          billingSame: true,
        },
        changePassword: '',
        authResults: {},
        error: '',
      };
    case act.AUTH_RESULT_SUCCESS:
      return {
        ...state,
        authResults: action.payload,
      };
    case act.UPDATE_USER_METADATA_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          user_metadata: action.payload.user_metadata,
        },
      };
    case act.CHANGE_PASSWORD:
      return {
        ...state,
        changePassword: action.payload,
      };
    case act.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: {
          email: action.payload.email,
          username: action.payload.username,
          user_id: action.payload.user_id,
          app_metadata: action.payload.app_metadata,
          user_metadata: action.payload.user_metadata,
        },
      };
    case act.ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case act.LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case act.ADD_FAVORITE:
      return {
        ...state,
        favorites: action.payload,
      };
    case act.ADD_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case act.UPDATE_STRIPE_BALANCE:
      return {
        ...state,
        stripeBalance: action.payload,
      };
    case act.ADD_STRIPE:
      return {
        ...state,
        user: {
          ...state.user,
          user_metadata: {
            ...state.user.user_metadata,
            stripe_acct: action.payload,
          },
        },
      };
    default:
      return state;
  }
};
