import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useForm, FormProvider } from 'react-hook-form';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import * as act from '../../../store/actions';
import DietaryPreferences from './filter/DietaryPreference';

export const Filter = (props) => {
  const { categories, dietaryOptions, products, setFiltered, allProducts } =
    props;
  const dispatch = useDispatch();
  const farms = useSelector((state) => state.FarmReducer.allFarms);

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      miles: 0,
      dietary: [],
      low_price: 0.0,
      high_price: 0.0,
    },
  });
  const { register } = methods;

  const values = methods.watch();

  const handleDistanceSearch = () => {
    dispatch(
      act.searchByDistance(
        allProducts,
        farms,
        values.miles,
        values.zip,
        setFiltered
      )
    );
  };

  const handlePriceSearch = () => {
    dispatch(
      act.searchByPrice(
        values.low_price,
        values.high_price,
        allProducts,
        setFiltered
      )
    );
  };

  return (
    <FormProvider {...methods}>
      <Stack
        width='28%'
        padding='1%'
        height='fit-content'
        sx={({ breakpoints }) => ({
          border: '1px solid #bac2b7',
          [breakpoints.down('xlg')]: {
            width: '35%',
          },
          [breakpoints.down('lg')]: {
            width: '45%',
          },
          [breakpoints.down('med')]: {
            display: 'none',
          },
        })}
      >
        <Stack>
          <Typography variant='h5' marginBottom='4%'>
            Categories
          </Typography>
          {categories.map((category) => {
            return (
              <Typography
                variant='p'
                key={category}
                sx={{ cursor: 'pointer', marginBottom: '3%' }}
                onClick={() => dispatch(act.searchByCategory(category))}
              >
                {category}
              </Typography>
            );
          })}
        </Stack>
        <Stack width='100%' margin='5% 0'>
          <Typography variant='h5'>Farm Location</Typography>
          <Stack direction='row' alignItems='center' flexWrap='wrap'>
            <Typography
              variant='p'
              sx={{
                fontSize: '0.875rem',
                marginRight: '2px',
              }}
            >
              Within{' '}
            </Typography>
            <Tooltip title={values.miles} placement='top'>
              <TextField
                size='small'
                sx={{
                  fontSize: '12px',
                  textAlign: 'center',
                  minWidth: '50px',
                  width: '40px',
                  height: '40px',
                  padding: '5px',
                }}
                {...register('miles')}
                defaultValue={values.miles}
              />
            </Tooltip>
            <Typography
              variant='span'
              sx={{
                fontSize: '0.875rem',
                margin: '0 2px',
              }}
            >
              {' '}
              miles from
            </Typography>
            <TextField
              size='small'
              label='zip'
              sx={{
                fontSize: '12px',
                textAlign: 'center',
                minWidth: '70px',
                width: '40px',
                height: '40px',
                margin: '1%',
                padding: '5px',
              }}
              {...register('zip')}
            />
            <ArrowForwardIcon
              onClick={() => handleDistanceSearch()}
              fontSize='small'
              sx={{ cursor: 'pointer' }}
            />
          </Stack>
        </Stack>
        <DietaryPreferences
          dietaryOptions={dietaryOptions ? dietaryOptions : []}
          products={products ? products : []}
          allProducts={allProducts}
        />
        <Stack className='priceDiv'>
          <h5>Price</h5>
          <Stack direction='row' alignItems='center' flexWrap='wrap'>
            <Typography
              sx={{
                fontSize: '0.875rem',
                marginRight: '2px',
              }}
            >
              From{' '}
            </Typography>
            <TextField
              {...register('low_price')}
              placeholder={'$0.00'}
              size='small'
              sx={{
                fontSize: '12px',
                textAlign: 'center',
                width: '70px',
                height: '40px',
                margin: '1%',
                padding: '5px',
              }}
            />{' '}
            <Typography
              sx={{
                fontSize: '0.875rem',
                marginRight: '2px',
              }}
            >
              to{' '}
            </Typography>
            <TextField
              {...register('high_price')}
              placeholder={'$0.00'}
              size='small'
              sx={{
                fontSize: '12px',
                textAlign: 'center',
                width: '70px',
                height: '40px',
                margin: '1%',
                padding: '5px',
              }}
            />
            <ArrowForwardIcon
              onClick={() => handlePriceSearch()}
              sx={{ cursor: 'pointer' }}
            />
          </Stack>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
