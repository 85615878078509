import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PrimaryButton from '../../inputs/Buttons/Button';
import EditDeliveryInfo from '../modals/EditDeliveryAddress';

const Deliver = (props) => {
  const { order, farm, i } = props;
  const { watch } = useFormContext();
  const values = watch();

  let phone = values.addressTo.phone;

  const [open, setOpen] = useState(false);

  return (
    <Stack margin='20px 0' key={i}>
      <Typography variant='h3'>
        Order from {farm.name} to be delivered to:
      </Typography>
      <Typography variant='p' component='p' marginTop='20px'>
        {values.cart.orders[i].delivery_selection.info.street1}
      </Typography>
      <Typography variant='p' component='p'>
        {values.cart.orders[i].delivery_selection.info.street2}
      </Typography>

      <Typography variant='p' component='p'>
        {values.cart.orders[i].delivery_selection.info.city},{' '}
        {values.cart.orders[i].delivery_selection.info.state}{' '}
        {values.cart.orders[i].delivery_selection.info.zip}
      </Typography>
      <Typography variant='p' component='p'>
        {phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}
      </Typography>
      <Typography variant='p' component='p'>
        {values.cart.orders[i].delivery_selection.info.email}
      </Typography>

      <PrimaryButton
        width='250px'
        margin='20px 0'
        onClick={() => setOpen(true)}
      >
        Edit Delivery Address
      </PrimaryButton>
      <EditDeliveryInfo
        address={order.delivery_selection.info}
        open={open}
        setOpen={setOpen}
        i={i}
      />
    </Stack>
  );
};
export default Deliver;
