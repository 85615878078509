import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const ProductDescription = (props) => {
  const { product } = props;

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='h5'>Product Description</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <Typography variant="p">{product?.description_long}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default ProductDescription;
