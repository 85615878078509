import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import Stack from '@mui/material/Stack';
import Deliver from './Deliver';
import Ship from './Ship';
import Pickup from './Pickup';

const DeliveryInfo = (props) => {
  const { setShipping, setSubtotal } = props;

  const farms = useSelector((state) => state.FarmReducer.allFarms);
  const cart = useSelector((state) => state.CartReducer.userCart);

  const { watch, setValue } = useFormContext();
  const values = watch();

  useEffect(() => {
    values.cart.orders.map((order, i) => {
      if (order.delivery_selection.type === 'deliver') {
        setValue(`cart.orders[${i}].delivery_selection.info`, {
          name: `${values.addressTo.first_name} ${values.addressTo.last_name}`,
          street1: values.addressTo.street1,
          street2: values.addressTo.street2,
          city: values.addressTo.city,
          state: values.addressTo.state,
          zip: values.addressTo.zip,
          phone: values.addressTo.phone,
          email: values.addressTo.email,
          amount: cart.orders[i].delivery_selection.info.amount
        });
      }
      return null;
    });
  }, []);

  return (
    <Stack width='100%' margin='20px auto'>
      {cart.orders.map((order, i) => {
        const farm = farms.find((farm) => farm.id === order.farm_id);
        return (
          <Stack key={i}>
            {order.delivery_selection.type === 'ship' ? (
              <Ship
                order={order}
                farm={farm}
                setShipping={setShipping}
                setSubtotal={setSubtotal}
                i={i}
                farms={farms}
              />
            ) : null}
            {order.delivery_selection.type === 'deliver' ? (
              <Deliver
                order={order}
                farm={farm}
                i={i}
                setShipping={setShipping}
                setSubtotal={setSubtotal}
              />
            ) : null}
            {order.delivery_selection.type === 'pickup' ? (
              <Pickup order={order} farm={farm} />
            ) : null}
          </Stack>
        );
      })}
    </Stack>
  );
};
export default DeliveryInfo;
