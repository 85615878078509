import { createStore, applyMiddleware, compose } from "redux";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";


import rootReducer from "./reducers/index.js";

const middlewares = [thunk];

export const store = createStore(
  rootReducer,
  applyMiddleware(...middlewares)
);

export const persistor = persistStore(store);

// export default { store, persistor };
