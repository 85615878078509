import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import PrimaryButton from '../../../../inputs/Buttons/Button';
import { Controller, useForm } from 'react-hook-form';
import * as act from '../../../../../store/actions';
import Dialog from '../../../../display/dialog';

const ReturnApproved = (props) => {
  const { refund, open, setOpen, returns, users, setReturns, order } = props;
  const [error, setError] = useState()
  const {
    register,

    watch,
    control,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: 0,
      reason: 'duplicate',
    },
  });
  const values = watch();

  const content = (
    <Stack>
      <Typography variant='p'>
        Refund amount up to: {order?.payment?.subtotal}
      </Typography>
      {error ? <Typography variant="error">{error}</Typography>: null}
      <TextField
        autoFocus
        className='largeInput'
        {...register(`amount`)}
        label={'Amount of refund'}
      />
      <FormControl className='mediumSelect'>
        <InputLabel id='reason' sx={{ fontSize: '1rem' }}>
          Reason for Refund
        </InputLabel>
        <Controller
          name={`reason`}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <Select
              variant='outlined'
              labelId='reason'
              label={'Reason for Refund'}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              name={name}
            >
              {[
                {
                  label: 'Duplicate',
                  value: 'duplicate',
                },
                {
                  label: 'Fraudulent',
                  value: 'fraudulent',
                },
                {
                  label: 'Requested by Customer',
                  value: 'requested_by_customer',
                },
              ].map((item, i) => {
                return (
                  <MenuItem key={i} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          )}
          control={control}
          defaultValue={values.reason}
        />
      </FormControl>
    </Stack>
  );
  const actions = (
    <Stack direction='row'>
      <PrimaryButton
        width='150px'
        margin='20px 10px 0 0'
        onClick={() => {
          if (!order.payment.refund) {
            order.payment.refund = [];
          }
          act.sendRefund({
            payment_intent: order.payment.payment_intent,
            amount: values.amount,
            reason: values.reason,
            transfer_id: order.payment.transfer.id,
            refund: refund,
            order: order,
            setReturns: setReturns,
            returns: returns,
            users,
            setError, 
            setOpen
          });
         
        }}
      >
        Confirm
      </PrimaryButton>
      <PrimaryButton
        width='150px'
        margin='20px  0 0 10px'
        onClick={() => setOpen(false)}
      >
        Cancel
      </PrimaryButton>
    </Stack>
  );

  return (
    <Dialog
      open={open}
      title={'Refund Approval'}
      content={content}
      actions={actions}
    />
  );
};

export default ReturnApproved;
