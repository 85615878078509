import React from "react";
import EditProduct from "../components/addProducts/editProduct";


const EditProductPage = (props) => {
  
  return (
    <EditProduct product={props.product} {...props} />
  );
};

export default EditProductPage;
