import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import * as utils from '../../../util';

const State = ({ name, rules, defaultValue, style, onClick, width }) => {
  const {
    formState: { errors },
  } = useFormContext({
    mode: 'onBlur',
    reValidationMode: 'onBlur',
    defaultValues: { state: utils.states[0].name },
  });

  return (
    <Controller
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onBlur, value, name, ref } }) => (
        <FormControl
          sx={{
            margin: '10px 0',
            width: width,
          }}
        >
          <InputLabel id='state-label' sx={{ fontSize: '1rem' }}>
            {errors && errors[name] ? `${errors[name].message}` : ' State'}
          </InputLabel>
          <Select
            variant='outlined'
            labelId='state-label'
            sx={{ fontSize: '1rem' }}
            label={'State'}
            onBlur={onBlur}
            value={value}
            name={name}
            required
            // onChange={onChange}
          >
            {utils.stateAbbrev.map((state, i) => {
              return (
                <MenuItem
                  sx={{ fontSize: '1rem' }}
                  onClick={() => {
                    onClick(state.abbreviation);
                  }}
                  key={i}
                  value={state.abbreviation}
                >
                  {state.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default State;
