import React from 'react';
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { useFormContext } from 'react-hook-form';
import { states } from '../../../../util/data';
import { styled } from '@mui/material/styles';
import * as utils from '../../../../util';
import State from '../../../misc/components/state';
import BillingAddress from './billingAddress';
import ReactPhoneInput from 'react-phone-input-mui';
import UseFormTextField from '../../../inputs/TextFields/UseFormTextField';
import UseFormCheckbox from '../../../inputs/CheckBox/UseFormCheckbox';

const FarmInfo = (props) => {
  const { farm, setFarm, edit } = props;
  
  const createPhoneLabel = () => {
    if (errors?.phone) {
      return <Typography variant='error'>{errors.phone.message}</Typography>;
    } else {
      return 'Phone Number';
    }
  };
  const {
    formState: { errors },
    setValue,
    watch,
    register,
  } = useFormContext({
    mode: 'onChange',
    reValidationMode: 'onChange',
  });

  const values = watch();
  const handleImages = async (e) => {
    const options = {
      setState: setFarm,
      state: farm,
      arrayName: e.target.name,
    };
    utils.uploadImages(e, e.target.name, setValue, options);
  };

  const handleInput = (e) => {
    setFarm({
      ...farm,
      [e.target.name]: e.target.value,
    });
    setValue(`${e.target.name}`, e.target.value);
  };

  const Input = styled('input')({
    display: 'none',
  });

  return (
    <Stack>
      <UseFormTextField
        name='name'
        rules={{
          required: 'Farm Name is required',
        }}
        label={
          errors?.name ? (
            <Typography variant='error'>{errors.name.message}</Typography>
          ) : (
            'Farm Name'
          )
        }
        onInput={(e) => handleInput(e)}
        onChange={() => null}
        onKeyDown={() => null}
        defaultValue={farm.name}
        fullWidth
      />
      <UseFormTextField
        name='address'
        rules={{
          required: 'Address is required',
        }}
        label={
          errors?.address ? (
            <Typography variant='error'>{errors.address.message}</Typography>
          ) : (
            'Street Address'
          )
        }
        defaultValue={farm.address}
        onInput={(e) => handleInput(e)}
        onChange={() => null}
        onKeyDown={() => null}
        fullWidth
      />

      <Stack direction='row' justifyContent='space-between'>
        <UseFormTextField
          name='zip'
          rules={{
            required: 'Zip code is required',
            maxLength: {
              value: 5,
              message: 'Zip code must be 5 digits',
            },
          }}
          label={
            errors?.zip ? (
              <Typography variant='error'>{errors.zip.message}</Typography>
            ) : (
              'Zip Code'
            )
          }
          maxLength={5}
          onInput={(e) => handleInput(e)}
          onChange={() => null}
          onKeyDown={() => null}
          defaultValue={farm.zip}
          width='49%'
        />
        <UseFormTextField
          name='city'
          rules={{
            required: 'City is required',
          }}
          label={
            errors?.city ? (
              <Typography variant='error'>{errors.city.message}</Typography>
            ) : (
              'City'
            )
          }
          defaultValue={farm.city}
          onInput={(e) => handleInput(e)}
          onChange={() => null}
          onKeyDown={() => null}
          width='49%'
        />
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <State
          name='state'
          style='mediumSelect'
          width='49%'
          errorName={'errors.state'}
          onClick={(value) => {
            setValue('state', value);
            setFarm({
              ...farm,
              state: value,
            });
          }}
          defaultValue={edit ? farm.state : states[0].abbreviation}
        />
        <ReactPhoneInput
          {...register('phone', {
            required: 'Phone is required',
          })}
          id='phone'
          component={TextField}
          defaultCountry={['us']}
          // placeholder={createPhoneLabel()}
          inputExtraProps={{
            name: 'phone',
            margin: 'normal',
            autoComplete: 'phone',
            width: '100%',

            label: createPhoneLabel(),
          }}
          onlyCountries={['us']}
          containerStyle={{ width: '49%' }}
          value={farm.phone}
          defaultValue={farm.phone}
          onChange={(e) => {
            handleInput({
              target: {
                name: 'phone',
                value: e,
              },
            });
          }}
        />
      </Stack>
      <Stack
        sx={{
          margin: '10px 0',
          display: 'flex',
          flexDirection: 'column',
          width: '150px',
          minHeight: '150px',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h5' sx={{ margin: '10px 0' }}>
          Farm Logo
        </Typography>
        {farm?.farm_logo && typeof farm?.farm_logo !== 'object' ? (
          <React.Fragment>
            <img width='150' src={farm?.farm_logo} default alt='farm_logo' />
            <InputLabel htmlFor='farm-logo'>
              <Input
                accept='image/*'
                id='farm-logo'
                type='file'
                name='farm_logo'
                {...register('farm_logo')}
                onChange={(e) => handleImages(e)}
              />
              <Button
                color='primary'
                aria-label='upload picture'
                component='span'
                style={{ width: '150px' }}
              >
                Change
              </Button>
            </InputLabel>
          </React.Fragment>
        ) : (
          <InputLabel
            htmlFor='farm-logo'
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '125px',
              alignItems: 'center',
              textAlign: 'center',
              margin: '20px 0',
              color: 'black',
              border: '1px solid black',
              padding: '10px',
            }}
          >
            <Input
              accept='image/*'
              id='farm-logo'
              type='file'
              name='farm_logo'
              {...register('farm_logo')}
              onChange={(e) => handleImages(e)}
            />
            <IconButton
              color='primary'
              aria-label='upload picture'
              component='span'
            >
              <PhotoCamera />
            </IconButton>
            Add Farm Logo
          </InputLabel>
        )}
      </Stack>
      <Stack
        sx={{
          margin: '10px 0',
          display: 'flex',
          flexDirection: 'column',
          width: '150px',
          minHeight: '150px',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h5' sx={{ marginTop: '10px' }}>
          Farm Image
        </Typography>
        {farm?.farm_image && typeof farm?.farm_image !== 'object' ? (
          <React.Fragment>
            <img width='150' src={farm?.farm_image} alt='farm_image' />
            <InputLabel htmlFor='farm-logo'>
              <Input
                accept='image/*'
                id='farm-image'
                type='file'
                name='farm_image'
                {...register('farm_image')}
                onChange={(e) => handleImages(e)}
              />
              <Button
                color='primary'
                aria-label='upload picture'
                component='span'
                sx={{ width: '150px' }}
              >
                Change
              </Button>
            </InputLabel>
          </React.Fragment>
        ) : (
          <InputLabel
            htmlFor='farm-image'
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '125px',
              alignItems: 'center',
              textAlign: 'center',
              margin: '20px 0',
              color: 'black',
              border: '1px solid black',
              padding: '10px',
            }}
          >
            <Input
              accept='image/*'
              id='farm-image'
              type='file'
              name='farm_image'
              {...register('farm_image')}
              onChange={(e) => handleImages(e)}
            />
            <IconButton
              color='primary'
              aria-label='upload picture'
              component='span'
            >
              <PhotoCamera />
            </IconButton>
            Add Farm Image
          </InputLabel>
        )}
      </Stack>
      <Stack>
        <Typography variant='h5'>Farm Bio</Typography>
        <UseFormTextField
          name='farm_bio'
          label='Description (optional)'
          defaultValue={farm.farm_bio}
          onInput={(e) => handleInput(e)}
          onChange={() => null}
          onKeyDown={() => null}
          rows={4}
        />
      </Stack>
      <Stack>
        <Typography variant='h5'>Farm Policies</Typography>
        <UseFormTextField
          name='farm_policies'
          label='Policies (optional)'
          defaultValue={farm.farm_policies}
          onInput={(e) => handleInput(e)}
          onChange={() => null}
          onKeyDown={() => null}
          rows={4}
        />
      </Stack>
    </Stack>
  );
};

export default FarmInfo;
