import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';

//images
import { EmptyBasket } from '../../assets/farmIconImages';
import { MoreLikeItemCard } from '../../products/components/MoreLikeItemCard';

const EmptyCart = (props) => {
  const { products } = props;
  const history = useHistory();
  return (
    <Stack margin='50px auto' width='70%'>
      <Stack alignItems='center'>
        <Typography variant='h2' fontWeight={'bold'}>
          Your shopping basket is empty
        </Typography>
        <EmptyBasket sx={{ fontSize: '115px', margin: '50px' }} />
        <Typography variant='p'>
          Support your local farmers by shopping with FarmEra
        </Typography>
        <Typography
          variant='p'
          onClick={() => history.push('/store/products')}
          sx={{ cursor: 'pointer', color: 'primary.radio' }}
        >
          Shop Now
        </Typography>
      </Stack>
      <Stack height='300px' justifyContent='space-between'>
        <Typography variant='h5'>Items you may like</Typography>
        <Stack direction='row'>
          {products?.slice(0, 3).map((product) => {
            return (
              <MoreLikeItemCard
                key={product.id}
                product={product}
                history={history}
              />
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};
export default EmptyCart;
