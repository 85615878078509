export const breakpoints = {
  // mobile: 650,
  xs:650,
  sm: 750,
  med: 900,
  lg: 1200,  
  xxlg: 1350,
  big: 1450,
  xlg: 1700
  
}
