import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Stepper from '../../display/Stepper';
import ContactInfo from '../components/ContactInfo';
import DeliveryInfo from '../components/DeliveryInfo';
import OrderSummary from '../components/OrderSummary';
import CardComponent from '../components/Payment';
import * as act from '../../../store/actions';
import * as utils from '../../../util';
import { API } from 'aws-amplify';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Stack from '@mui/material/Stack';
import { useHistory } from 'react-router-dom';
import OrderFailureModal from '../modals/OrderFailure';

const CheckoutPage = (props) => {
  const { setOrderSuccessOpen } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const [cart, setCart] = useState(
    useSelector((state) => state.CartReducer.userCart)
  );

  const [openFailure, setOpenFailure] = useState(false);

  const [shipping, setShipping] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    email: '',
    phone: '',
    name: '',
  });

  const [user, setUser] = useState(
    useSelector((state) => state.UserReducer.user)
  );

  const farms = useSelector((state) => state.FarmReducer.allFarms);

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      billingSame: true,
      addressTo: {
        name: `${user.user_metadata.first_name} ${user.user_metadata.last_name}`,
        address_line1: user.user_metadata.address,
        city_locality: user.user_metadata.city,
        state_province: user.user_metadata.state,
        postal_code: user.user_metadata.zip,
        country_code: 'USA',
        phone: user.user_metadata.phone,
        email: user.email,
      },
      cart,
    },
  });

  const { watch, getValues } = methods;
  const values = watch();
  let addressTo = getValues('addressTo');

  useEffect(() => {
    values.cart && utils.calculateTotals(values.cart, 'shipping', setShipping);
    utils.calculateTotals(values.cart, 'subtotal', setSubtotal);
    setTotal(subtotal + shipping);
  }, [values.cart]);

  const getShippingRates = () => {
    cart.orders.map((order) => {
      order.customerInfo = addressTo;
      if (`order.delivery_selection.info.type` === 'ship') {
        let parcelWeight = 0;
        const addressFrom = {
          name: order.farm.name,
          address_line1: order.farm.address,
          city_locality: order.farm.city,
          state_province: order.farm.state,
          postal_code: order.farm.zip,
          country_code: 'USA',
          phone: order.farm.phone,
          email: order.farm.email,
        };
        addressTo = {
          name: `${order.customerInfo.first_name} ${order.customerInfo.last_name}`,
          address_line1: order.customerInfo.address,
          city_locality: order.customerInfo.city,
          state_province: order.customerInfo.state,
          postal_code: order.customerInfo.zip,
          country_code: 'USA',
          phone: order.customerInfo.phone,
          email: order.customerInfo.email,
        };

        order.products.map((product) => {
          return (parcelWeight = +(product.quantity * product.weight));
        });
        dispatch(
          act.getRates({ addressTo, addressFrom, parcelWeight }, order.id)
        );
        dispatch(act.updateUser(order.customer_id, order.customerInfo));
      }
    });
  };

  const handleUserUpdate = () => {
    const data = {
      ...user,
      user_metadata: {
        ...user.user_metadata,
        first_name: values.addressTo.first_name,
        last_name: values.addressTo.last_name,
        address: values.addressTo.street1,
        address2: values.addressTo.first_name,
        city: values.addressTo.city,
        state: values.addressTo.state,
        zip: values.addressTo.zip,
      },
    };
    act.updateUserMetadata(user.user_id, data.user_metadata);
  };

  const handlePayment = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    let clientSecret;
    setProcessing(true);
    if (!stripe || !elements) {
      return;
    }
    let cardElement = elements.getElement(CardElement);
    try {
      const myInIt = {
        body: {
          amount: values.cart.payment.total,

          id: cart.orders[0].id,
        },
      };
      API.post('FarmEraMarketAPI', '/payments/payment_intent', myInIt)
        .then(async (response) => {
          console.log(response, "response payment intent")
          clientSecret = response.client_secret;
          let pm_id;
          await stripe
            .createPaymentMethod({
              type: 'card',
              card: cardElement,
              billing_details: billingDetails,
            })
            .then((response) => {
              console.log(response, "payment methods")
              pm_id = response.paymentMethod.id;
            })
            .catch((err) => {
              console.log(err);
            });
          await stripe
            .confirmCardPayment(clientSecret, {
              payment_method: {
                card: cardElement,
                billing_details: billingDetails,
              },
            })
            .then((response) => {
              console.log(response, "confirm card payment")
              if (response.error) {
                setError(response.error);
                setOpenFailure(true);
                return;
              }
              if (response.paymentIntent) {
                setOrderSuccessOpen(true);
                values.cart.orders.map((order) => {
                  order.payment.total =
                    order.payment.subtotal +
                    order.payment.shipping +
                    order.payment.tax;
                  order.customer_id = user.user_id.split('|')[1];
                  order.created_date = new Date();
                  order.payment.payment_method = pm_id;
                  order.payment.payment_intent = response.paymentIntent.id;
                  const farm = farms.find((farm) => farm.id === order.farm_id);
                  function multiply(amount, multiplier) {
                    return amount * multiplier;
                  }
                  const toFarmEra = multiply(order.payment.subtotal, 0.05);
                  let amount = order.payment.subtotal - toFarmEra;

                  act.transferPaymentToStripeAccount(
                    amount,
                    farm.stripe_acct,
                    order
                  );

                  act.addCompletedOrder(order);
                });

                if (response.paymentIntent.status === 'succeeded') {
                  // utils.sendOrderConfirmation(cart.orders, farms, user);
                  cart.orders.map((order) => {
                    // utils.sendFarmOrderConfirmation(order, farms);
                    return order.products.map(async (product) => {
                      const response = await act.getProduct(product.id);
                      const prevInv = Number(product.inventory_qty);
                      response.inventory_qty = prevInv - product.quantity;
                      response.quantity = 0;
                      if (response.inventory_qty <= 0) {
                        response.product_status = 'sold_out';
                        act.updateProduct(response);
                      }
                      return dispatch(act.updateProduct(response));
                    });
                  });

                  dispatch(
                    act.saveCartToDB({
                      user_id: user.user_id.split('|')[1],
                      orders: [],
                      payment: {
                        total: 0,
                        subtotal: 0,
                        tax: 0,
                        shipping: 0,
                      },
                    })
                  );
                }
              }
              history.push('/dashboard/history');
            })
            .catch((err) => {
              console.dir(err);
            });
        })
        .catch((err) => {
          console.log(err, 'payment intent err');
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FormProvider {...methods}>
      <Stack direction='row' margin='50px auto' width='100%'>
        <OrderFailureModal
          open={openFailure}
          setOpen={setOpenFailure}
          error={error}
        />
        <Stepper
          handleFunction={getShippingRates}
          handleFinish={handlePayment}
          handleUpdate={handleUserUpdate}
          step={0}
          processing={processing}
          setProcessing={setProcessing}
          stepLabels={['Contact Info', 'Delivery Detail', 'Payment']}
          StepContent={[
            <ContactInfo user={user} cart={cart} />,
            <DeliveryInfo
              user={user}
              cart={cart}
              setCart={setCart}
              shipping={shipping}
              setShipping={setShipping}
              setTotal={setTotal}
              subtotal={subtotal}
              setSubtotal={setSubtotal}
            />,
            <CardComponent
              user={user}
              total={total}
              cart={cart}
              billingDetails={billingDetails}
              setBillingDetails={setBillingDetails}
              error={error}
              setError={setError}
              processing={processing}
              setProcessing={setProcessing}
              setCardComplete={setCardComplete}
            />,
          ]}
        />
        <OrderSummary
          shipping={shipping}
          cart={cart}
          total={total}
          setTotal={setTotal}
          subtotal={subtotal}
        />
      </Stack>
    </FormProvider>
  );
};
export default CheckoutPage;
