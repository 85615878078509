import React from 'react';
import Stack from '@mui/material/Stack';
import Modal from '../../display/modal';
import UpdateProductStatus from '../components/UpdateProductStatus';

const UpdateProductStatusModal = (props) => {
  const { product, open, setOpen, getFarmProducts } = props;

  return (
    <Modal
      open={open}
      top='50%'
      left='50%'
      TitleMessage={'Update Product Status'}
      message={
        <Stack width='500px' border='none'>
          <UpdateProductStatus
            product={product}
            setOpen={setOpen}
            getFarmProducts={getFarmProducts}
          />
        </Stack>
      }
    />
  );
};

export default UpdateProductStatusModal;
