import React from 'react';
import Chart from 'react-google-charts';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

const AllRatingsChart = (props) => {
  const { totalRatingsCount } = props;

  const sumObjectValues = (obj) =>
    Object.values(obj).reduce((sum, val) => (sum += val));

  const generateRatingChartData = (totalRatingsCount) => {
    const formattedData = [['Reviews', 'Reviews']];

    formattedData.push(['Five-star', totalRatingsCount.five]);
    formattedData.push(['Four-star', totalRatingsCount.four]);
    formattedData.push(['Three-star', totalRatingsCount.three]);
    formattedData.push(['Two-star', totalRatingsCount.two]);
    formattedData.push(['One-star', totalRatingsCount.one]);

    return formattedData;
  };

  return (
    <Stack
      height='400px'
      boxSizing='border-box'
      width='100%'
      marginBottom='15px'
    >
      <Typography variant='h6' lineHeight='22px' color='primary.lightFont'>
        Farm Review Report
      </Typography>
      <Stack
        width='100%'
        padding='0 20px'
        justifyContent='center'
        alignItems='flex-end'
      >
        <Typography
          variant='p'
          component='p'
          lineHeight='27px'
          color='primary.lightFont'
        >
          Total farm reviews:{' '}
          <Typography variant='p' color='primary.darkFont'>
            {sumObjectValues(totalRatingsCount)}
          </Typography>
        </Typography>
      </Stack>

      <Card elevation={20}>
        <Chart
          width={'100%'}
          height={'300px'}
          chartType='BarChart'
          loader={<Typography variant='h5'>Loading Chart</Typography>}
          data={generateRatingChartData(totalRatingsCount)}
        />
      </Card>
    </Stack>
  );
};

export default AllRatingsChart;
