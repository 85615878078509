import React from 'react';
import { useFormContext } from 'react-hook-form';
import { categories, dietaryOptions } from '../../../../util';

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider"

export const ProductDetails = (props) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { product, setProduct, handleChange } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const appendArray = (e) => {
    if (product[e.target.name].includes(e.target.value)) {
      return;
    } else {
      setProduct({
        ...product,
        [e.target.name]: [...product[e.target.name], e.target.value],
      });
      setValue(`${e.target.name}`, [...product[e.target.name], e.target.value]);
    }
  };

  const remove = (array, i) => {
    array.splice(i, 1);
    setValue('dietary', array);
    setProduct({
      ...product,
      dietary: array,
    });
  };

  return (
    <Stack marginTop="20px">
      <Typography variant='h5' marginBottom="5px">2. Product Details</Typography>
      <Divider />
      <Stack marginTop="20px">
        <TextField
          variant='outlined'
          label={
            !!errors.name ? (
              <Typography variant='error'>{errors.name.message}</Typography>
            ) : (
              'Product Name'
            )
          }
          {...register('name', { required: `Product Name is required` })}
          placeholder={'Enter Product Name'}
          onChange={(e) => handleChange(e)}
          className='largeInput'
          defaultValue={product.name}
          required
        />

        <TextField
          margin='normal'
          variant='outlined'
          name='description_short'
          label={'Brief Description (optional)'}
          {...register('description_short')}
          placeholder={'Enter Brief Description'}
          onChange={(e) => handleChange(e)}
          className='largeInput'
          defaultValue={product.description_short}
        />
        <TextField
          multiline
          onChange={(e) => handleChange(e)}
          rows={4}
          label={
            !!errors.description_long ? (
              <Typography variant='error'>
                {errors.description_long.message}
              </Typography>
            ) : (
              'Item Description'
            )
          }
          {...register('description_long', {
            required: `Item description is required`,
          })}
          className='largeInput'
          required
          onChangeCapture={(e) => handleChange(e)}
          defaultValue={product.description_long}
        />
        <Stack direction="row" justifyContent="space-between">
          <FormControl sx={{ margin: '10px 0', width: '49%' }}>
            <InputLabel sx={{ fontSize: '1rem' }} id='category'>
              {!!errors.category ? (
                <Typography variant='error'>
                  {errors.category.message}
                </Typography>
              ) : (
                'Product Category'
              )}
            </InputLabel>
            <Select
              labelId='category'
              id='category'
              defaultValue={product.category}
              {...register('category', {
                required: 'Please select a category',
              })}
              input={
                <OutlinedInput
                  sx={{ fontSize: '1rem' }}
                  label='Product Category'
                />
              }
              MenuProps={MenuProps}
              sx={{ fontSize: '1rem' }}
              onChange={(e) => {
                handleChange(e, 'string');
              }}
            >
              {categories.map((name) => (
                name !== "All" ?
                <MenuItem sx={{ fontSize: '1rem' }} key={name} value={name}>
                  {name}
                </MenuItem> : null
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ margin: '10px 0', width: '49%' }}>
            <InputLabel sx={{ fontSize: '1rem' }} id='dietary'>
              {!!errors.dietary ? (
                <Typography variant='error'>
                  {errors.dietary.message}
                </Typography>
              ) : (
                'Dietary Options'
              )}
            </InputLabel>
            <Select
              labelId='dietary'
              id='dietary'
              sx={{ fontSize: '1rem' }}
              value={''}
              {...register('dietary', {
                validate: {
                  required: () =>
                    product.dietary.length >= 1 ||
                    'Please select at least one dietary option',
                },
              })}
              onChange={(e) => appendArray(e)}
              input={
                <OutlinedInput
                  sx={{ fontSize: '1rem' }}
                  label='Dietary Options'
                />
              }
              MenuProps={MenuProps}
            >
              {dietaryOptions.map((name, i) => (
                <MenuItem sx={{ fontSize: '1rem' }} key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>

            {product.dietary ? (
              <Stack
                direction="row"
                sx={{
                  
                  flexWrap: 'wrap',
                  gap: 0.5,
                  marginTop: '10px',
                }}
              >
                {product.dietary.map((value, i) => (
                  <Chip
                    key={value}
                    label={value}
                    onDelete={() => remove(product.dietary, i)}
                  />
                ))}
              </Stack>
            ) : null}
          </FormControl>
        </Stack>
      </Stack>
    </Stack>
  );
};
